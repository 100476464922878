import { computed, ref } from 'vue'
import { useSession } from './useSession'

const LOCALSTORAGE_KEY = 'Lyka::BuildABox::GettingStarted::Dismissed'

const dismissed = ref(localStorage.getItem(LOCALSTORAGE_KEY) === String(true))

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useGettingStarted = () => {
  const { hasPreviousSession } = useSession()

  const hide = (): void => {
    dismissed.value = true
    localStorage.setItem(LOCALSTORAGE_KEY, String(true))
  }

  const visible = computed<boolean>(() => {
    return !dismissed.value && !hasPreviousSession
  })

  return {
    hide,
    visible,
  }
}
