<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { LykaButton } from '@lyka/ui'
import JSConfetti from 'js-confetti'
import { formatDate } from '@/utils/formatDate'
import { formatDeliveryTimeRange } from '@/utils/formatDeliveryTime'

const props = defineProps<{
  deliveryDate?: Date
  deliveryTime?: string
  email?: string
  animalNames?: string[]
}>()

const emit = defineEmits<{
  (e: 'dismiss'): void
}>()

const canvas = ref<HTMLCanvasElement>()

const deliveryDate = computed(() => {
  if (!props.deliveryDate) return ''

  const dateSegments = formatDate(props.deliveryDate).split(' ')
  const date = `${dateSegments[0]}, ${dateSegments.slice(1).join(' ')}`
  const time = props.deliveryTime ? formatDeliveryTimeRange(props.deliveryTime).toUpperCase() : ''

  return `${date}, ${time}`.trimEnd()
})

const names = computed(() => {
  const animalNames = [...(props.animalNames ?? [])]
  const last = animalNames.pop()

  if (!last) return ''

  // Make it look like "Puppy1, Puppy2 and Puppy3"
  return [animalNames.join(', '), last].filter((name) => !!name).join(' and ')
})

onMounted(async () => {
  if (canvas.value) {
    const jsConfetti = new JSConfetti({ canvas: canvas.value })
    jsConfetti.addConfetti()
  }
})
</script>

<template>
  <div class="tw-absolute tw-w-full tw-h-full tw-left-0 tw-top-0">
    <canvas ref="canvas" class="tw-w-full tw-h-full"></canvas>
  </div>

  <div class="tw-flex tw-items-center tw-justify-center tw-mt-16">
    <img alt="green-tick" src="@/assets/images/steps/completed/green-tick.svg" />
  </div>

  <div class="tw-px-8 tw-text-center tw-mt-12">
    <h2 class="tw-h2 tw-text-4xl xs:tw-text-6xl">Your order has been sent to the kitchen</h2>
  </div>

  <div class="tw-px-8 tw-mt-6 tw-text-center">
    <p v-if="names">We can&apos;t wait for {{ names }} to try delicious Lyka meals.</p>

    <div class="tw-bg-cream tw-mt-6 tw-p-6 tw-flex tw-flex-col tw-items-center tw-gap-6">
      <p v-if="deliveryDate" class="tw-text-center">
        Estimated arrival: <br />
        <span class="tw-font-bold">{{ deliveryDate }}</span>
      </p>

      <p v-if="email" class="tw-text-center">
        Email receipt to: <br />
        <span class="tw-font-bold">{{ email }}</span>
      </p>

      <p class="tw-text-center tw-text-semidark-gray">
        We&apos;ll never charge you without sending an SMS and email first, giving you time to make changes
      </p>
    </div>

    <LykaButton variant="success" class="tw-w-full tw-mt-6" @click="emit('dismiss')"> Continue </LykaButton>
  </div>
</template>
