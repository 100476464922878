<script setup lang="ts">
import { ref } from 'vue'

defineProps<{
  large?: boolean
}>()

const hidden = ref(true)

const show = (): void => {
  hidden.value = false
}

const hide = (): void => {
  hidden.value = true
}
</script>

<template>
  <div class="help-tooltip" :data-large="large" @mouseenter="show" @mouseleave="hide">
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="tw-w-[1em] tw-h-[1em]"
    >
      <path
        d="M6 9.75C6.2085 9.75 6.3855 9.677 6.531 9.531C6.677 9.3855 6.75 9.2085 6.75 9C6.75 8.7915 6.677 8.6145 6.531 8.469C6.3855 8.323 6.2085 8.25 6 8.25C5.7915 8.25 5.6145 8.323 5.469 8.469C5.323 8.6145 5.25 8.7915 5.25 9C5.25 9.2085 5.323 9.3855 5.469 9.531C5.6145 9.677 5.7915 9.75 6 9.75ZM5.4375 7.359H6.57825C6.57825 6.974 6.612 6.70075 6.6795 6.53925C6.7475 6.37775 6.9115 6.172 7.1715 5.922C7.5365 5.568 7.78925 5.266 7.92975 5.016C8.07025 4.766 8.1405 4.48975 8.1405 4.18725C8.1405 3.61475 7.94525 3.14875 7.55475 2.78925C7.16425 2.42975 6.66675 2.25 6.06225 2.25C5.53125 2.25 5.0705 2.3905 4.68 2.6715C4.289 2.953 4.0155 3.33325 3.8595 3.81225L4.875 4.2345C4.969 3.9425 5.1175 3.716 5.3205 3.555C5.5235 3.3935 5.7605 3.31275 6.0315 3.31275C6.323 3.31275 6.5625 3.396 6.75 3.5625C6.9375 3.729 7.03125 3.94775 7.03125 4.21875C7.03125 4.45825 6.9505 4.67175 6.789 4.85925C6.6275 5.04675 6.44775 5.229 6.24975 5.406C5.88525 5.7395 5.65875 6.013 5.57025 6.2265C5.48175 6.44 5.4375 6.8175 5.4375 7.359ZM6 12C5.177 12 4.401 11.8438 3.672 11.5312C2.943 11.2188 2.305 10.789 1.758 10.242C1.211 9.695 0.78125 9.057 0.46875 8.328C0.15625 7.599 0 6.823 0 6C0 5.1665 0.15625 4.38775 0.46875 3.66375C0.78125 2.94025 1.211 2.305 1.758 1.758C2.305 1.211 2.943 0.78125 3.672 0.46875C4.401 0.15625 5.177 0 6 0C6.8335 0 7.61225 0.15625 8.33625 0.46875C9.05975 0.78125 9.695 1.211 10.242 1.758C10.789 2.305 11.2188 2.94025 11.5312 3.66375C11.8438 4.38775 12 5.1665 12 6C12 6.823 11.8438 7.599 11.5312 8.328C11.2188 9.057 10.789 9.695 10.242 10.242C9.695 10.789 9.05975 11.2188 8.33625 11.5312C7.61225 11.8438 6.8335 12 6 12Z"
        fill="currentColor"
      />
    </svg>

    <div role="tooltip" :aria-expanded="!hidden" class="help-tooltip-content">
      <slot />
    </div>
  </div>
</template>

<style>
.help-tooltip {
  @apply tw-relative tw-z-[5] tw-text-base tw-inline-flex tw-w-[1em] tw-h-[1em] tw-cursor-help tw-items-center tw-justify-center tw-whitespace-normal;
  @apply data-[large=true]:tw-text-lg;
}

.help-tooltip-content {
  @apply tw-shadow-md tw-absolute tw-left-1/2 -tw-translate-x-1/2 tw-bottom-full tw-bg-secondary tw-text-sm tw-p-4 tw-space-y-3 tw-rounded-lg tw-mb-3 tw-hidden aria-expanded:tw-block tw-max-w-xs tw-w-max tw-whitespace-normal;
}

.help-tooltip h5 {
  @apply tw-font-bold;
}
</style>
