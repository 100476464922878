<script lang="ts" setup>
import { onUpdated, ref } from 'vue'
import LykaButton from './LykaButton.vue'
import LykaIcon from './LykaIcon.vue'

const props = withDefaults(
  defineProps<{
    active?: boolean
    disabled?: boolean
    submit?: boolean
    invalid?: boolean
  }>(),
  {},
)

const emits = defineEmits<{
  (e: 'open'): void
  (e: 'submit'): void
  (e: 'continue'): void
}>()

const contentForm = ref<HTMLFormElement>()
const touched = ref(false)
const opened = ref(false)

const onSubmit = (): void => {
  if (props.invalid) {
    return
  }

  if (props.submit) {
    emits('submit')
  } else {
    emits('continue')
  }
}

onUpdated(() => {
  touched.value = true
})

const onToggle = (ev: Event): void => {
  if (ev.target instanceof HTMLDetailsElement && !props.disabled) {
    opened.value = ev.target.open

    ev.target.classList.toggle('open', ev.target.open)
    ev.target.classList.toggle('closed', !ev.target.open)
  }
}

const onAnimationEnd = (ev: Event): void => {
  if (ev.target instanceof HTMLElement) {
    ev.target.classList.remove('open', 'closed')
  }
}

const toggle = (ev: Event): void => {
  const parent = (ev.currentTarget as HTMLElement).parentElement

  if (parent instanceof HTMLDetailsElement && !parent.open && !props.disabled) {
    emits('open')
  }
}
</script>

<template>
  <div>
    <details :open="active" class="details" @toggle="onToggle">
      <summary class="tw-px-1" @click.prevent="toggle">
        <div
          class="tw-relative tw-flex tw-items-center tw-justify-between tw-text-left tw-outline-none tw-pb-6 tw-space-x-2 tw-px-1tw-appearance-none"
          :class="{ '!tw-pb-0': active }"
        >
          <div class="tw-flex tw-items-center tw-space-x-4 tw-select-none tw-cursor-pointer">
            <LykaIcon v-if="!submit && !invalid && touched" name="Tick" class="tw-text-green tw-text-lg" />
            <slot name="title" />
          </div>
          <slot name="icons" />

          <LykaButton v-if="!submit && touched && !opened && !disabled" size="sm" variant="subtle">Edit</LykaButton>
        </div>
        <div v-if="touched && !opened && $slots.summary" class="tw-pb-6">
          <slot name="summary" />
        </div>
      </summary>

      <div class="content" @animationend="onAnimationEnd">
        <div class="tw-space-y-8 tw-py-2 tw-px-1 tw-transition-all">
          <slot name="description" />
          <form ref="contentForm" @submit.prevent="onSubmit">
            <slot name="form" />

            <div class="tw-my-4">
              <template v-if="submit">
                <slot name="submit" />
              </template>

              <LykaButton
                v-else
                type="submit"
                disabled-variant="secondary"
                size="xl"
                :disabled="invalid"
                class="tw-w-full tw-mt-4"
                >Continue</LykaButton
              >
            </div>
          </form>
        </div>
      </div>
    </details>
  </div>
</template>

<style scoped>
@keyframes open {
  0% {
    grid-template-rows: 0fr;
    opacity: 0;
  }
  100% {
    grid-template-rows: 1fr;
    opacity: 1;
  }
}

.content {
  overflow: hidden;
  display: grid;
  animation-duration: 250ms;
  transform: translateZ(0);
}

.content > div {
  min-height: 0;
}

.details.open > .content {
  animation-name: open;
  animation-timing-function: ease-out;
}

.details.closed > .content {
  animation: none;
}
</style>
