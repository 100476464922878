import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import mitt from 'mitt'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { useStepsStore } from './steps'
import { unwrapData, useApi } from '@/composables/useApi'
import type { UserProfile } from '@/models/UserProfile'
import type { LocationStepData } from '@/steps/location'
import type { ContactDetailsStepData } from '@/steps/contactDetails'
import type { CheckoutStepData } from '@/steps/checkout'

export type UserData = (LocationStepData & ContactDetailsStepData & CheckoutStepData)['user']

const emitter = mitt<{
  added: Partial<UserData>
}>()

export const useUserStore = defineStore('user', () => {
  const stepsStore = useStepsStore()

  const userProfile = ref<UserProfile>()

  const on = emitter.on
  const off = emitter.off

  const addUser = (data: Partial<UserData>): void => {
    emitter.emit('added', data)
  }

  const user = computed(() => {
    const user: Partial<UserData> = {}

    stepsStore.steps.forEach((step) => {
      const { data } = step()

      if ('user' in data) {
        Object.assign(user, data.user)
      }
    })

    return user as UserData
  })

  const identifyUser = (): void => {
    if (userProfile.value) {
      useSegment().identify(String(userProfile.value.id), {
        email: userProfile.value.email,
        firstName: userProfile.value.firstname,
        lastName: userProfile.value.lastname,
      })
    }
  }

  const loadUserProfile = async (): Promise<void> => {
    const { data } = await useApi({
      authenticate: true,
    }).get<UserProfile>('users/getUserProfile', {}, unwrapData)

    userProfile.value = data

    identifyUser()
  }

  return {
    on,
    off,
    user,
    addUser,
    loadUserProfile,
    userProfile,
  }
})
