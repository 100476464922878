<script setup lang="ts">
import { computed } from 'vue'
import type { MealPlan } from '@/models/MealPlan'
import { formatMoney } from '@/utils/formatMoney'
import type { MealPriceOption } from '@/stores/mealPlans'
import { useProductPrice } from '@/composables/useProductPrice'
import { useCoupons } from '@/composables/useCoupons'

const props = defineProps<{
  mealPlan: MealPlan
  mealPriceDisplay: MealPriceOption
  multipleDogs: boolean
  showTotalPrice?: boolean
}>()

const { getDiscountedPrice, isProductDiscounted, getDiscountDescription } = useProductPrice()

const { dollarDiscountCouponAttempt, socialGiftingCoupon, activeCoupon } = useCoupons()

const hasSocialGiftingCoupon = computed(() => socialGiftingCoupon.value)

const price = computed(() => {
  switch (props.mealPriceDisplay) {
    case 'meal': {
      return props.mealPlan.mealPrice
    }
    case 'day': {
      return props.mealPlan.priceDaily
    }
    case 'week': {
      return props.mealPlan.priceWeekly
    }
    default: {
      return props.mealPlan.totalPrice
    }
  }
})

const totalDiscountedPriceDivision = computed(() => {
  switch (props.mealPriceDisplay) {
    case 'meal': {
      return 7 * props.mealPlan.frequency * 2
    }
    case 'day': {
      return 7 * props.mealPlan.frequency
    }
    case 'week': {
      return props.mealPlan.frequency
    }
    default: {
      return 1
    }
  }
})

const discountedDisplayPrice = computed(() => {
  const totalDiscountedPrice = getDiscountedPrice('meals', props.mealPlan.totalPrice)

  return totalDiscountedPrice / totalDiscountedPriceDivision.value
})

const discounted = computed(() => {
  // if a dollar discount coupon was attempted to be applied, don't show the discount
  if (dollarDiscountCouponAttempt.value) {
    return false
  }

  return isProductDiscounted('meals')
})

const discountLabel = computed(() => {
  let discountTarget = 'first box'

  if (hasSocialGiftingCoupon.value) {
    discountTarget = `first 2 deliveries`
  }

  if (activeCoupon.value?.multiBoxDiscountEnabled) {
    discountTarget = `first ${activeCoupon.value?.multiBoxDiscounts?.length} deliveries`
  }

  if (discounted.value) {
    return `${getDiscountDescription('meals')} your ${discountTarget}`
  }

  return undefined
})

const totalPrice = computed(() => {
  return props.mealPlan.totalPrice
})

const discountedTotalPrice = computed(() => {
  return getDiscountedPrice('meals', props.mealPlan.totalPrice)
})
</script>

<template>
  <div class="tw-flex tw-flex-col tw-text-p1-t tw-font-light tw-mb-2">
    <div v-if="discounted" class="tw-mb-2">
      <div>{{ discountLabel }}</div>
      <div v-if="!showTotalPrice" class="tw-mt-2">
        <s>{{ formatMoney(price) }} per {{ mealPriceDisplay }}</s>
      </div>
    </div>

    <span class="tw-font-stylized tw-text-5xl">
      {{ formatMoney(discountedDisplayPrice) }}/<small>{{ mealPriceDisplay }}</small>
    </span>
    <span v-if="multipleDogs">(for all your puppers)</span>

    <div v-if="showTotalPrice">
      Total: <s v-if="discounted">{{ formatMoney(totalPrice) }}</s
      >&nbsp;
      <strong>{{ formatMoney(discountedTotalPrice) }}</strong>
    </div>
  </div>
</template>
