<script setup lang="ts">
import { computed, ref } from 'vue'
import { LykaCarousel, LykaContainer } from '@lyka/ui'
import PlanCardV2 from './PlanCardV2.vue'
import PlanCaloriesModal from './PlanCaloriesModal.vue'
import PlanTherapeuticWarning from './PlanTherapeuticWarning.vue'
import { type MealPlan, MealPlanType } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import type { MealPriceOption } from '@/stores/mealPlans'
import { useCoupons } from '@/composables/useCoupons'

const props = defineProps<{
  selectedMealPlanType: MealPlanType | null
  mealPriceDisplay: MealPriceOption
  mealPlans: MealPlan[]
  mealWeights: MealPlanWeight[]
  showTotalPrice?: boolean
  therapeutic?: boolean
}>()

const emits = defineEmits<{
  (e: 'select', mealPlan: MealPlan): void
}>()

const { dollarDiscountCouponAttempt } = useCoupons()

const hasMealPlans = computed(() => {
  return !!props.mealPlans.length
})

const orderedMealPlans = computed<MealPlan[]>(() => {
  return props.mealPlans
})

const mealPlanInfo = ref<MealPlan>()

const showMealPlanInfo = (mealPlan: MealPlan): void => {
  mealPlanInfo.value = mealPlan
}

const hideMealPlanInfo = (): void => {
  mealPlanInfo.value = undefined
}

const onPlanSelected = (mealPlan: MealPlan): void => {
  emits('select', mealPlan)
}
</script>

<template>
  <div class="tw-space-y-8">
    <h1 class="tw-h3">Choose how you want to start feeding with Lyka</h1>

    <PlanTherapeuticWarning v-if="therapeutic"></PlanTherapeuticWarning>

    <div
      v-if="dollarDiscountCouponAttempt"
      class="tw-mx-auto tw-mb-2 tw-text-green-dark tw-bg-secondary tw-py-4 tw-px-10 tw-w-fit tw-flex tw-justify-center tw-m-auto"
    >
      <strong>Your discount is applied at checkout</strong>
    </div>

    <div class="tw-flex tw-justify-center tw-gap-4 -tw-mx-6 md:tw-mx-0">
      <LykaCarousel v-if="hasMealPlans" breakpoint="md">
        <PlanCardV2
          v-for="mealPlan in orderedMealPlans"
          :key="mealPlan.type"
          :meal-price-display="mealPriceDisplay"
          :meal-plan="mealPlan"
          :meal-weights="mealWeights"
          :show-total-price="showTotalPrice"
          hide-starter-daily-price
          :not-recommended="therapeutic && mealPlan.type === MealPlanType.Half"
          @select="onPlanSelected"
          @info="showMealPlanInfo"
        />
      </LykaCarousel>

      <LykaContainer v-else size="md">
        <h4 class="tw-lead">
          We're sorry. We had some trouble loading your plan. Please try refreshing your browser or contact support.
        </h4>
      </LykaContainer>
    </div>

    <PlanCaloriesModal
      v-if="mealPlanInfo"
      :meal-plan="mealPlanInfo"
      :meal-weights="mealWeights"
      @close="hideMealPlanInfo"
    />
  </div>
</template>
