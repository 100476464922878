<script lang="ts" setup>
import { computed } from 'vue'
import PlanCardSummaryItem from './PlanCardSummaryItem.vue'
import { formatNumber } from '@/utils/formatNumber'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import { type MealPlan, MealPlanType } from '@/models/MealPlan'
import TruckIcon from '@/components/icons/TruckIcon.vue'
import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import DogIcon from '@/components/icons/DogIcon.vue'

const props = defineProps<{
  mealWeights: MealPlanWeight[]
  mealPlan: MealPlan
}>()

const dailyMealWeight = computed(() => {
  const total = props.mealWeights.reduce((sum, { servingWeight }) => {
    return sum + servingWeight
  }, 0)

  if (props.mealPlan.type === MealPlanType.Half) {
    return total / 2
  }

  return total
})

const planSupplyWeeks = computed(() => {
  return props.mealPlan.frequency
})
</script>

<template>
  <div class="tw-flex tw-justify-center tw-w-full tw-gap-6">
    <PlanCardSummaryItem>
      <template #icon>
        <DogIcon role="image" title="Meal weight" />
      </template>
      <template #first> {{ formatNumber(dailyMealWeight) }}g </template>
      <template #second> per day </template>
    </PlanCardSummaryItem>

    <PlanCardSummaryItem>
      <template #icon>
        <CalendarIcon role="image" title="Meals duration" />
      </template>
      <template #first> {{ planSupplyWeeks }} week </template>
      <template #second> food supply </template>
    </PlanCardSummaryItem>

    <PlanCardSummaryItem>
      <template #icon>
        <TruckIcon role="image" title="Delivery fee" />
      </template>
      <template #first> Delivery </template>
      <template #second> included </template>
    </PlanCardSummaryItem>
  </div>
</template>
