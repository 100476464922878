import { Builder } from './Builder'
import type { Address } from '@/models/Address'
import { CheckoutOrder, type HowDidYouHear } from '@/models/CheckoutOrder'
import type { Coupon } from '@/models/Coupon'
import type { DeliveryDetails } from '@/models/DeliveryDetails'
import type { CompletedDog } from '@/models/Dog'
import type { MealPlan } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import type User from '@/models/User'

export class CheckoutOrderBuilder extends Builder {
  private order = new CheckoutOrder()

  reset(): void {
    this.order = new CheckoutOrder()
  }

  addPassword(password: string): CheckoutOrderBuilder {
    this.order.password = password

    return this
  }

  addMealPlan(mealPlan: MealPlan): CheckoutOrderBuilder {
    this.order.mealPlan = mealPlan

    return this
  }

  addUser(user: User): CheckoutOrderBuilder {
    this.order.user = user

    return this
  }

  addMealWeights(mealWeights: MealPlanWeight[]): CheckoutOrderBuilder {
    this.order.mealPlanWeights = mealWeights

    return this
  }

  addAddress(address: Address): CheckoutOrderBuilder {
    this.order.address = address

    return this
  }

  addDeliveryDetails(deliveryDetails: DeliveryDetails): CheckoutOrderBuilder {
    this.order.deliveryDetails = deliveryDetails

    return this
  }

  addDogs(dogs: CompletedDog[]): CheckoutOrderBuilder {
    dogs.forEach((dog) => {
      this.addDog(dog)
    })

    return this
  }

  addDog(dog: CompletedDog): CheckoutOrderBuilder {
    this.order.dogs?.push(dog)

    return this
  }

  addProducts(quantities: Record<number, number>, everyBox = false): CheckoutOrderBuilder {
    this.order.productQuantities = quantities
    this.order.productsInEveryBox = everyBox

    return this
  }

  addCoupon(coupon: Coupon): CheckoutOrderBuilder {
    this.order.coupon = coupon

    return this
  }

  addStripeToken(token: string): CheckoutOrderBuilder {
    this.order.stripeToken = token
    this.order.stripeConfirmationToken = undefined

    return this
  }

  addStripeConfirmationToken(token: string): CheckoutOrderBuilder {
    this.order.stripeConfirmationToken = token
    this.order.stripeToken = undefined

    return this
  }

  addStripePaymentIntent(paymentIntentId: string): CheckoutOrderBuilder {
    this.order.stripePaymentIntent = paymentIntentId

    return this
  }

  addSignupPaymentMethod(signupPaymentMethod: string): CheckoutOrderBuilder {
    this.order.signupPaymentMethod = signupPaymentMethod
    return this
  }

  addHowDidYouHear(howDidYouHear: HowDidYouHear): CheckoutOrderBuilder {
    this.order.howDidYouHear = howDidYouHear

    return this
  }

  addAnonymousUserId(anonymousUserId: string | undefined): CheckoutOrderBuilder {
    this.order.anonymousUserId = anonymousUserId

    return this
  }

  addRecaptchaToken(token: string): CheckoutOrderBuilder {
    this.order.recaptchaToken = token

    return this
  }

  addReferrer(uuid: string): CheckoutOrderBuilder {
    this.order.referrer = uuid

    return this
  }

  get(): Partial<CheckoutOrder> {
    const order = this.order
    this.reset()

    return order
  }
}
