<script setup lang="ts">
import { computed } from 'vue'
import { createElementId } from '../utils/createElementId'
import IconTick from './icon/Tick.vue'

type InputValue = undefined | null | string | number | boolean
type ModelValue = Array<InputValue> | InputValue

const props = withDefaults(
  defineProps<{
    name: string
    id?: string
    label?: string
    description?: string
    disabled?: boolean
    button?: boolean
    checkbox?: boolean
    modelValue?: ModelValue
    required?: boolean
    inputValue?: InputValue
    rounded?: 'full' | 'none' | 'left' | 'right'
    block?: boolean
    type?: 'button' | 'fancy' | 'standard'
    size?: 'sm' | 'md' | 'lg'
  }>(),
  {
    type: 'standard',
    size: 'md',
  },
)

const emits = defineEmits<{
  (e: 'update:modelValue', value: ModelValue): void
}>()

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  },
})

const radioRadius = computed(() => {
  switch (props.rounded) {
    case 'full': {
      return 'tw-rounded-3xl'
    }
    case 'none': {
      return ''
    }
    case 'left': {
      return 'tw-rounded-l-full'
    }
    case 'right': {
      return 'tw-rounded-r-full'
    }
    default: {
      return 'tw-rounded-3xl'
    }
  }
})

const radioStyles = computed(() => {
  const baseStyles = props.disabled ? '' : 'tw-text-alt hover:tw-text-alt-hover focus:tw-text-alt-hover tw-group'

  switch (props.type) {
    case 'button': {
      return `tw-radio-btn tw-px-4 tw-text-center tw-justify-center ${baseStyles}`
    }
    case 'fancy': {
      return `tw-radio-fancy tw-px-6 tw-py-4 ${baseStyles}`
    }
    default: {
      return 'tw-radio tw-space-x-2'
    }
  }
})

const radioCheckStyles = computed(() => {
  switch (props.type) {
    case 'button': {
      return 'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-border tw-border-gold group-hover:tw-border-alt-hover group-focus:tw-border-alt-hover focus:tw-ring-0'
    }
    case 'fancy': {
      return 'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-border tw-border-gold group-hover:tw-border-success-hover group-focus:tw-border-success-hover'
    }
    default: {
      return 'tw-aspect-square tw-h-4'
    }
  }
})

const radioWidth = computed(() => {
  if (props.block) {
    return 'tw-w-full'
  }
  return props.type === 'button' ? 'tw-w-full' : ''
})

const radioHeight = computed(() => {
  if (props.type === 'standard') {
    return ''
  }
  switch (props.size) {
    case 'sm': {
      return 'tw-global-height--small'
    }
    case 'lg': {
      return 'tw-global-height--large'
    }
    default: {
      return 'tw-global-height'
    }
  }
})
const buttonDisabled = computed(() => (props.disabled ? 'tw-opacity-40 tw-cursor-not-allowed' : 'tw-cursor-pointer'))

const inputId = props.id ? props.id : createElementId()
</script>

<template>
  <label
    :for="inputId"
    :class="[radioWidth, radioStyles, radioHeight, buttonDisabled]"
    class="tw-flex tw-items-center tw-relative tw-select-none tw-gap-2 tw-flex-wrap"
  >
    <input
      :id="inputId"
      v-bind="$attrs"
      v-model="value"
      :value="inputValue"
      :type="checkbox ? 'checkbox' : 'radio'"
      class="tw-absolute tw-opacity-0 tw-h-0 tw-w-0 tw-peer"
      :disabled="disabled"
      :tabindex="disabled ? '-1' : '0'"
      :required="required"
      :name="name"
    />
    <span class="tw-checkmark" :class="[radioRadius, radioCheckStyles]" />
    <span
      v-if="props.type === 'fancy'"
      class="tw-checkmark-fancy tw-h-5 tw-aspect-square tw-rounded-3xl tw-border-2 tw-border-input tw-text-body tw-mr-2 tw-z-10 tw-flex tw-items-center tw-justify-center tw-text-xs"
    >
      <IconTick />
    </span>
    <span
      v-if="label || $slots.default"
      class="tw-radio-label tw-leading-4 tw-z-10"
      :class="{ 'tw-whitespace-nowrap': type === 'button' }"
    >
      <template v-if="$slots.default"><slot /></template>
      <template v-else>{{ label }}</template>
    </span>

    <span v-if="$slots.description" class="tw-text-left tw-hidden peer-checked:tw-block">
      <slot name="description" />
    </span>
  </label>
</template>

<style lang="postcss">
.tw-checkmark {
  @apply tw-border tw-bg-transparent tw-border-input tw-z-0;
}
.tw-radio input:checked ~ .tw-checkmark {
  @apply tw-border-alt tw-bg-alt;
}

.tw-radio-btn input:checked ~ .tw-checkmark {
  @apply tw-border-alt tw-bg-secondary;
}
.tw-radio-btn input:checked ~ .tw-radio-label {
  @apply tw-text-alt;
}

.tw-radio-btn .tw-checkmark,
.tw-radio-fancy .tw-checkmark {
  @apply tw-bg-white;
}
.tw-radio-fancy input:checked ~ .tw-checkmark {
  @apply tw-bg-success tw-bg-opacity-10 tw-border-success;
}
.tw-radio-fancy input:checked ~ .tw-radio-label {
  @apply tw-text-alt;
}
.tw-radio-fancy input:checked ~ .tw-checkmark-fancy {
  @apply tw-border-success tw-text-success;
}
</style>
