<script setup lang="ts">
import { computed } from 'vue'
import { LykaButton } from '@lyka/ui'
import { assetUrl } from '@lyka/utils'
import PlanCardPriceV2 from './PlanCardPriceV2.vue'
import { type MealPlan, MealPlanType } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import StarsIcon from '@/components/icons/StarsIcon.vue'

const props = defineProps<{
  mealPlan: MealPlan
  mealWeights: MealPlanWeight[]
  hideStarterDailyPrice?: boolean
  notRecommended?: boolean
}>()

const emits = defineEmits<{
  (e: 'select', plan: MealPlan): void
  (e: 'info', plan: MealPlan): void
}>()

const image = computed<string>(() => {
  return assetUrl(`images/steps/plans/bowls/${props.mealPlan.type}.webp`)
})

const elementId = computed<string>(() => {
  return `plan-${props.mealPlan.type}`
})

const title = computed<string>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Full:
      return 'Full Bowl Plan'
    case MealPlanType.Half:
      return 'Half Bowl Plan'
    case MealPlanType.Starter:
      return 'Starter Box'
    default:
      return ''
  }
})

const description = computed<string>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Full:
      return 'Complete and balanced diet'
    case MealPlanType.Half:
      return 'Mix Lyka with other food'
    case MealPlanType.Starter:
      return 'Taste test with a smaller start'
    default:
      return ''
  }
})

const deliveryDescription = computed<string>(() => {
  const { type, frequency } = props.mealPlan

  switch (type) {
    case MealPlanType.Starter:
      return 'Start with 2 weeks of food'
    default:
      return `Delivered every ${frequency > 1 ? `${frequency} weeks` : 'week'}`
  }
})

const isOversizedStarter = computed<boolean>(() => {
  return props.mealPlan.type === MealPlanType.Starter && props.mealPlan.frequency === 1
})

const percentOfCalories = computed<string>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Full:
      return '100%'
    case MealPlanType.Half:
      return '50%'
    case MealPlanType.Starter:
      return isOversizedStarter.value ? '50%' : '100%'
    default:
      return ''
  }
})

const buttonLabel = computed<string>(() => {
  switch (props.mealPlan.type) {
    case MealPlanType.Full:
      return 'Choose Full Bowl'
    case MealPlanType.Half:
      return 'Choose Half Bowl'
    case MealPlanType.Starter:
      return 'Choose Starter Box'
    default:
      return ''
  }
})

const popular = computed<boolean>(() => {
  return props.mealPlan.type === MealPlanType.Full
})

const multipleDogs = computed<boolean>(() => {
  return props.mealWeights.length > 1
})

const showInfoDialog = (): void => {
  emits('info', props.mealPlan)
}
</script>

<template>
  <div :id="elementId" class="tw-relative tw-pt-5 tw-min-w-[18rem] tw-w-[18rem]">
    <div
      v-if="popular"
      class="tw-h-9 tw-absolute tw-bg-success tw-text-white tw-rounded-3xl tw-flex tw-px-3 tw-py-1 tw-gap-3 tw-align-center tw-top-0 tw-left-1/2 -tw-translate-x-1/2 tw-items-center tw-z-10"
    >
      <StarsIcon />
      <span class="tw-whitespace-nowrap">Most popular</span>
    </div>

    <div class="tw-bg-white tw-px-6 tw-py-8 tw-rounded-2xl tw-text-center tw-text-alt tw-relative tw-shrink-0">
      <div class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-space-y-4">
        <div>
          <img :src="image" alt="A photo of the Lyka food" class="tw-h-32 tw-w-auto tw-mx-auto" height="120" />
        </div>
        <div class="tw-space-y-4">
          <h3 class="tw-h2 tw-bold-success tw-text-6xl" v-html="title" />

          <p clas="tw-text-lg">{{ description }}</p>

          <div class="tw-border-y tw-border-mint-green tw-py-4 tw-space-y-1">
            <div class="tw-font-bold">{{ deliveryDescription }}</div>
            <div class="tw-flex tw-justify-center tw-gap-2 tw-items-center">
              <button
                type="button"
                class="tw-select-none focus:tw-outline-none tw-underline hover:tw-no-underline tw-inline-flex tw-gap-1 tw-items-center tw-text-green tw-font-bold"
                @click="showInfoDialog"
              >
                <span>{{ percentOfCalories }} of their diet</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20" fill="none">
                  <path
                    fill="#10B193"
                    d="M10 20a10 10 0 1 0 0-20 10 10 0 0 0 0 20ZM6.633 6.457A2.191 2.191 0 0 1 8.695 5h2.278a2.465 2.465 0 0 1 1.226 4.605l-1.261.723a.94.94 0 0 1-.938.922.935.935 0 0 1-.938-.938v-.527c0-.336.18-.644.473-.812l1.73-.993a.59.59 0 0 0-.292-1.101H8.695a.308.308 0 0 0-.293.207l-.015.047a.937.937 0 0 1-1.196.57.94.94 0 0 1-.57-1.195l.016-.047-.004-.004ZM8.75 13.75a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div class="tw-flex tw-flex-col tw-space-y-2">
          <PlanCardPriceV2
            :meal-plan="mealPlan"
            :hide-starter-daily-price="hideStarterDailyPrice"
            :multiple-dogs="multipleDogs"
          />

          <LykaButton
            :id="`plan-select-${mealPlan.type}`"
            block
            :variant="popular ? 'primary' : 'secondary'"
            size="lg"
            @click="emits('select', mealPlan)"
          >
            {{ buttonLabel }}
          </LykaButton>
        </div>
      </div>
    </div>

    <slot name="footer" />
  </div>
</template>

<style lang="postcss">
.tw-bold-success strong {
  @apply tw-text-success;
}
</style>
