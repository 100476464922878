<script lang="ts" setup>
import type { VNode } from 'vue'
import { computed, ref, useSlots } from 'vue'

type AnyVNode = VNode<any, any>

const emits = defineEmits<{
  (e: 'submit'): void
}>()

const activeIndex = ref(0)
const slots = useSlots()

const children = computed((): AnyVNode[] => {
  if (slots.default) {
    return slots.default()
  }
  return [] as AnyVNode[]
})

const showStep = (index: number): void => {
  activeIndex.value = index
}

const nextStep = (index: number): void => {
  activeIndex.value = Math.min(children.value.length - 1, ++index)
}

const onSubmit = (): void => {
  // if we are here, all continues and the final submit have passed validate
  emits('submit')
}
</script>

<template>
  <div v-for="(child, index) in children" :key="index">
    <component
      :is="child"
      :key="child.key"
      :active="index === activeIndex"
      :disabled="index > activeIndex"
      @open="showStep(index)"
      @continue="nextStep(index)"
      @submit="onSubmit"
    ></component>

    <hr v-if="index !== children.length - 1" :key="index" class="tw-text-mint-green tw-my-4 -tw-mx-4 lg:tw-mx-0" />
  </div>
</template>
