<script setup lang="ts">
import { LykaCarousel } from '@lyka/ui'
import { assetUrl } from '@lyka/utils/src/assetUrl'
import TrustpilotReviews from '@/components/TrustpilotReviews.vue'

interface Benefit {
  icon: string
  heading: string
  description: string
}

const benefits: Benefit[] = [
  {
    icon: assetUrl('images/steps/contact-details/benefits/more-energy.svg'),
    heading: 'More energy',
    description: 'A <b>high bioavailability</b> diet can make your dog more playful (that means lots more zoomies!).',
  },
  {
    icon: assetUrl('images/steps/contact-details/benefits/show-stopping-coat.svg'),
    heading: 'Show-stopping coat',
    description:
      'Many dog parents see a softer and shinier coat thanks to <b>Omega 3s and skin-supporting ingredients.</b>',
  },
  {
    icon: assetUrl('images/steps/contact-details/benefits/poo-perfection.svg'),
    heading: 'Poo perfection',
    description:
      'Our <b>low glycemic load recipes</b>  means <b>less gas, better digestion and smaller, sturdier poos.</b>',
  },
  {
    icon: assetUrl('images/steps/contact-details/benefits/optimal-weight.svg'),
    heading: 'Optimal weight',
    description: 'With tailored serving sizes, dogs can achieve and <b>maintain their ideal body shape and weight!</b>',
  },
  {
    icon: assetUrl('images/steps/contact-details/benefits/balanced-behaviour.svg'),
    heading: 'Balanced behaviour',
    description:
      'Dogs are more content on Lyka. <b>Our low-carb diet, rich in Omega-3</b>, helps reduce stress and anxiety.',
  },
]
</script>

<template>
  <div class="benefits">
    <img
      src="@/assets/images/steps/contact-details/bowl.webp"
      alt="A Lyka box exploding with delicious dog food"
      class="tw-absolute tw-top-full tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/4 xl:tw-left-full xl:-tw-translate-x-1/3 xl:tw-top-1/2 xl:-tw-translate-y-1/2 xl:tw-max-w-xl"
      width="640"
      height="624"
    />

    <div class="tw-max-w-sm tw-mx-auto md:tw-max-w-md xl:tw-mx-0 tw-space-y-12 lg:tw-space-y-16">
      <div class="tw-space-y-6">
        <h2 class="tw-h2 tw-text-inherit xl:tw-h1 xl:tw-text-inherit">
          <span class="tw-whitespace-nowrap tw-inline-block">Experience the</span>
          <b class="tw-inline-block tw-text-green tw-whitespace-nowrap">benefits of fresh</b>
          <span class="tw-inline-block tw-whitespace-nowrap">with Lyka.</span>
        </h2>
        <p class="tw-lead tw-text-inherit">
          Join thousands of dogs across Australia improving their health with every meal.
        </p>
      </div>

      <div>
        <LykaCarousel hide-arrows dark :autoscroll="5000">
          <div
            v-for="(benefit, index) in benefits"
            :key="index"
            class="tw-text-offwhite tw-w-full tw-min-w-full tw-text-center tw-space-y-2"
          >
            <div class="tw-w-full tw-max-w-sm tw-mx-auto tw-space-y-2">
              <img :src="benefit.icon" alt="Icon" aria-hidden="true" class="tw-mx-auto" />
              <h4 class="tw-h4 tw-text-inherit">{{ benefit.heading }}</h4>
              <p class="tw-lead tw-text-inherit [&>b]:tw-text-green tw-font-light" v-html="benefit.description" />
            </div>
          </div>
        </LykaCarousel>
      </div>

      <TrustpilotReviews template="5419b6ffb0d04a076446a9af" theme="dark" :height="40" />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.benefits {
  @apply tw-relative tw-max-w-full xl:tw-max-w-3xl tw-bg-green-dark tw-text-offwhite tw-rounded-2xl tw-text-left tw-px-8 tw-pt-12 tw-overflow-hidden tw-pb-[25%];
  @apply md:tw-px-12 md:tw-pt-24  md:tw-pb-[20%];
  @apply lg:tw-p-16 lg:tw-pt-24 lg:tw-pb-[15%];
  @apply xl:tw-pr-52 xl:tw-pb-24 2xl:tw-pt-32;

  background-image: url('@/assets/images/steps/contact-details/benefits/pattern.webp');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
