<script setup lang="ts"></script>

<template>
  <div class="md:tw-flex md:tw-items-center md:tw-gap-20 tw-max-w-4xl tw-mx-auto">
    <div class="tw-min-w-max tw-text-center tw-mb-8">
      <img
        src="@/assets/images/steps/plans/what-happens-next.svg"
        alt="A delivery box"
        width="239"
        height="302"
        class="tw-w-28 lg:tw-w-auto tw-mx-auto"
      />
    </div>

    <div class="tw-text-center md:tw-text-left tw-space-y-4">
      <h2 class="tw-h2">What happens after your <span class="tw-text-green">Starter Box?</span></h2>

      <p class="tw-lead">Your flexible plan starts and makes mealtime easy.</p>

      <p>
        If your pup’s loving Lyka, simply sit back and relax. Your next delivery will be scheduled and you’ll get a
        confirmation email ahead of time.
      </p>
      <p>You can skip an order, pause or cancel your flexible plan at any time.</p>
    </div>
  </div>
</template>
