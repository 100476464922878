<template>
  <svg
    class="tw-fill-current tw-w-[1em] tw-h-[1em]"
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    viewBox="0 -960 960 960"
    width="48"
  >
    <path
      d="M480.118-330Q551-330 600.5-379.618q49.5-49.617 49.5-120.5Q650-571 600.382-620.5q-49.617-49.5-120.5-49.5Q409-670 359.5-620.382q-49.5 49.617-49.5 120.5Q310-429 359.618-379.5q49.617 49.5 120.5 49.5Zm-.353-58Q433-388 400.5-420.735q-32.5-32.736-32.5-79.5Q368-547 400.735-579.5q32.736-32.5 79.5-32.5Q527-612 559.5-579.265q32.5 32.736 32.5 79.5Q592-453 559.265-420.5q-32.736 32.5-79.5 32.5ZM480-200q-138 0-251.5-75T53.145-473.077Q50-478 48.5-485.174 47-492.348 47-500t1.5-14.826Q50-522 53.145-526.923 115-650 228.5-725T480-800q138 0 251.5 75t175.355 198.077Q910-522 911.5-514.826 913-507.652 913-500t-1.5 14.826q-1.5 7.174-4.645 12.097Q845-350 731.5-275T480-200Zm0-300Zm-.169 240Q601-260 702.5-325.5 804-391 857-500q-53-109-154.331-174.5-101.332-65.5-222.5-65.5Q359-740 257.5-674.5 156-609 102-500q54 109 155.331 174.5 101.332 65.5 222.5 65.5Z"
    />
  </svg>
</template>
