import {
  type Appearance,
  type Stripe,
  type StripeCardElement,
  type StripeElements,
  type StripeElementsOptionsMode,
  type StripePaymentElement,
  loadStripe,
} from '@stripe/stripe-js'
import type { StripePaymentToken } from '@/composables/useCheckout'

const STRIPE_API_KEY = import.meta.env.VITE_STRIPE_API_KEY

const CARD_STYLES = {
  base: {
    color: '#005648',
    fontFamily: 'Ginger',
    fontWeight: '400',
    fontSmoothing: 'antialiased',
    fontSize: '18px',
    backgroundColor: '#FFF',
    '::placeholder': {
      color: '#DBD2C2',
    },
  },
  invalid: {
    color: '#D32F2F',
    iconColor: '#D32F2F',
  },
} as const

const ELEMENTS_APPEARANCE: Appearance = {
  theme: 'flat',
  variables: {
    fontFamily: 'Ginger',
    fontWeightNormal: '400',
    fontSizeBase: '16px',
    borderRadius: '8px',
    colorPrimary: '#10b193',
    colorBackground: 'white',
    colorText: '#005648',
    gridRowSpacing: '1rem',
  },
  rules: {
    '.TabLabel': {
      fontWeight: '400',
    },
    '.Label': {
      fontSize: 'var(--fontSizeBase)',
      marginBottom: 'var(--p-spacing2)',
    },
    '.Input': {
      border: 'solid 1px #DBE6DC',
    },
  },
}

const ELEMENTS_OPTIONS: StripeElementsOptionsMode = {
  fonts: [
    {
      family: 'Ginger',
      src: `url(https://lyka.com.au/blog/fonts/ginger/F37Ginger-Regular.otf)`,
      weight: '400',
    },
  ],
  appearance: ELEMENTS_APPEARANCE,
}

const CREATE_CARD_OPTIONS = { style: CARD_STYLES } as const

let instance: Stripe | undefined

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStripe = () => {
  const load = async (): Promise<Stripe> => {
    if (!instance) {
      const stripe = await loadStripe(STRIPE_API_KEY)

      if (stripe) {
        instance = stripe
      } else {
        throw new Error('Failed to load Stripe')
      }
    }

    return instance
  }

  const createElements = async (options?: StripeElementsOptionsMode): Promise<StripeElements> => {
    const stripe = await load()

    return stripe.elements({
      ...ELEMENTS_OPTIONS,
      ...options,
    })
  }

  const createCardElement = async (): Promise<StripeCardElement> => {
    const card = (await createElements()).create('card', CREATE_CARD_OPTIONS)

    return card
  }

  // https://docs.stripe.com/payments/payment-element
  const createPaymentElement = async (
    options: StripeElementsOptionsMode,
  ): Promise<{
    elements: StripeElements
    payment: StripePaymentElement
  }> => {
    const elements = await createElements({
      mode: 'payment',
      currency: 'aud',
      paymentMethodCreation: 'manual',
      setupFutureUsage: 'off_session',
      // https://docs.stripe.com/api/payment_method_configurations/update#update_payment_method_configurations-name
      paymentMethodConfiguration: options.paymentMethodConfiguration,
      amount: options.amount,
      appearance: ELEMENTS_APPEARANCE,
    })

    const payment = elements.create('payment')

    return {
      elements,
      payment,
    }
  }

  const createCardToken = async (card: StripeCardElement): Promise<string> => {
    const stripe = await load()

    const { token, error } = await stripe.createToken(card)

    if (error) {
      throw new Error(error.message)
    } else {
      return token.id
    }
  }

  // https://docs.stripe.com/payments/finalize-payments-on-the-server
  const createConfirmationToken = async (elements: StripeElements): Promise<string> => {
    const stripe = await load()

    const { error, confirmationToken } = await stripe.createConfirmationToken({
      elements,
    })

    if (error) {
      throw new Error(error.message)
    } else {
      return confirmationToken.id
    }
  }

  /**
   * Payment flow A
   * 1. Submit payment
   * 2. Create confirmation token
   * 3. Send token to BE
   * 4. Two possibilities
   * 4.1 Success
   * 4.2 Additional authorisation required (move to flow B)
   */
  const handleConfirmationToken = async (stripeElements: StripeElements): Promise<StripePaymentToken> => {
    // show apple pay payment confirmation (or submit for credit card)
    const { error: submitError } = await stripeElements.submit()
    if (submitError) {
      throw new Error(submitError.message ?? 'There was an error submitting payment, please try again.')
    }

    // create confirmation token
    return {
      confirmationToken: await createConfirmationToken(stripeElements),
    }
  }

  /**
   * Payment flow B
   * 1. Using provided payment intent client secret, show next payment step
   * 2. Two possibilities
   * 2.1 Intent is set to success - send payment intent to BE
   * 2.2 Intent failed - error state
   */
  const handlePaymentIntentValidation = async (paymentIntentClientSecret: string): Promise<boolean> => {
    const stripe = await load()

    const { error } = await stripe.handleNextAction({
      clientSecret: paymentIntentClientSecret,
    })

    if (error) {
      throw new Error(error.message)
    }

    return true
  }

  return {
    createCardElement,
    createCardToken,

    createPaymentElement,

    handleConfirmationToken,
    handlePaymentIntentValidation,
  }
}
