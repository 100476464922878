import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { useApi } from './useApi'
import { type DeserializedToken, useAuthentication } from './useAuthentication'
import { useCheckoutOrderSerializer } from './serializers/useCheckoutOrderSerializer'
import { useCheckoutOrderCreatedEvents } from './events/useCheckoutOrderCreatedEvents'
import type { PaymentMethod } from './useCheckout'
import type { CheckoutOrder, HowDidYouHear } from '@/models/CheckoutOrder'
import { useCheckoutStep } from '@/steps/checkout'
import { useDogsStore } from '@/stores/dogs'
import { useDataStore } from '@/stores/data'
import type { Coupon } from '@/models/Coupon'
import { CheckoutOrderBuilder } from '@/builders/CheckoutOrderBuilder'
import type { DeliveryDetails } from '@/models/DeliveryDetails'
import { useTreatsStep } from '@/steps/treats'
import { type MealPlan } from '@/models/MealPlan'
import type { Dog } from '@/models/Dog'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import type { Address } from '@/models/Address'
import { useUserStore } from '@/stores/user'
import { toError } from '@/utils/toError'
import {
  BREEDER_OPTION_NAME,
  DOG_TRAINER_OPTION_NAME,
  OTHER_OPTION_NAME,
  VET_OPTION_NAME,
} from '@/models/CheckoutOrder'

export interface PendingPurchaseConfirmationResponse {
  clientSecretId: string
  paymentIntentId: string
}
export interface CheckoutOrderResult {
  status?: number
  success: boolean
  data?: CompletePurchaseResponse['data'] | PendingPurchaseConfirmationResponse
  error?: string
  message?: string
}

export interface CompletePurchaseResponse {
  message: 'Success' | string
  confirmationData?: PendingPurchaseConfirmationResponse
  data?: DeserializedToken & { eventId: string }
}

interface CheckoutOrderArguments {
  stripeToken:
    | {
        token: string
      }
    | {
        confirmationToken: string
      }
  stripePaymentIntent?: string
  signupPaymentMethod?: PaymentMethod
  password: string
  totalPrice: number
  coupon?: Coupon
  referrer?: string
  mealPlan: MealPlan
  mealWeights: MealPlanWeight[]
  recaptchaToken: string
}

const ORDER_SUCCESS_MESSAGE = 'Success'
const ORDER_ADDITIONAL_VERIFICATION_MESSAGE = 'Your payment provider requires additional verification.'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCheckoutOrder = ({
  stripeToken,
  stripePaymentIntent,
  signupPaymentMethod,
  password,
  totalPrice,
  coupon,
  referrer,
  mealPlan,
  mealWeights,
  recaptchaToken,
}: CheckoutOrderArguments) => {
  const dataStore = useDataStore()
  const checkoutStep = useCheckoutStep()
  const userStore = useUserStore()
  const authentication = useAuthentication()

  const getHowDidYouHear = (): HowDidYouHear | undefined => {
    const id = checkoutStep.data.howDidYouHear.option

    if (id) {
      const name = dataStore.findRecord('howDidYouHear', id)?.name

      if (name) {
        const isOtherOption = [
          OTHER_OPTION_NAME,
          BREEDER_OPTION_NAME,
          VET_OPTION_NAME,
          DOG_TRAINER_OPTION_NAME,
        ].includes(name)

        const other = isOtherOption ? checkoutStep.data.howDidYouHear.other || 'Other' : undefined

        return {
          option: id,
          name,
          other,
        }
      }
    }

    return undefined
  }

  const createOrder = async (): Promise<Required<CheckoutOrder>> => {
    const treatsStep = useTreatsStep()
    const deliveryDetails = checkoutStep.data.delivery as Required<DeliveryDetails>
    const address = checkoutStep.data.address as Required<Address>
    const productQuantities = treatsStep.data.products.treats
    const productsEveryBox = treatsStep.data.products.everyBox
    const howDidYouHear = getHowDidYouHear()
    const anonymousUserId = await useSegment().getAnonymousId()
    const userStore = useUserStore()

    const order = new CheckoutOrderBuilder()
      .addPassword(password)
      .addUser(userStore.user)
      .addDogs(useDogsStore().getDogs() as Array<Required<Dog>>)
      .addMealPlan(mealPlan)
      .addMealWeights(mealWeights)
      .addAddress(address)
      .addDeliveryDetails(deliveryDetails)
      .addProducts(productQuantities, productsEveryBox)
      .addAnonymousUserId(anonymousUserId)
      .addRecaptchaToken(recaptchaToken)

    if ('confirmationToken' in stripeToken) {
      order.addStripeConfirmationToken(stripeToken.confirmationToken)
    } else {
      order.addStripeToken(stripeToken.token)
    }

    if (stripePaymentIntent) {
      order.addStripePaymentIntent(stripePaymentIntent)
    }

    if (signupPaymentMethod) {
      order.addSignupPaymentMethod(signupPaymentMethod)
    }

    if (howDidYouHear) {
      order.addHowDidYouHear(howDidYouHear)
    }

    if (coupon) {
      order.addCoupon(coupon)
    }

    if (referrer) {
      order.addReferrer(referrer)
    }

    return order.get() as Required<CheckoutOrder>
  }

  const identifyUser = ({ userId, fullName }: { userId: string | number; fullName: string }): void => {
    useSegment().identify(String(userId), {
      fullName,
    })
  }

  const saveAuthToken = (data: DeserializedToken): void => {
    authentication.setToken(data)
  }

  const submitOrder = async (usePaymentIntentFlow = false): Promise<CheckoutOrderResult> => {
    const order = await createOrder()

    try {
      const payload = useCheckoutOrderSerializer(order).serialize()
      const endpoint = usePaymentIntentFlow ? `complete` : `completePurchase`
      const { data, status } = await useApi().post<CompletePurchaseResponse>(`checkout/${endpoint}`, payload)

      if (status === 402 && data.message === ORDER_ADDITIONAL_VERIFICATION_MESSAGE) {
        return {
          status,
          success: false,
          data: data.confirmationData,
        }
      }

      if (data.message === ORDER_SUCCESS_MESSAGE && data.data) {
        saveAuthToken(data.data)
        identifyUser(data.data)
        await userStore.loadUserProfile()

        useCheckoutOrderCreatedEvents({
          order,
          totalPrice,
          eventId: data.data.eventId,
          paymentMethod: signupPaymentMethod,
        }).send()

        return {
          status,
          success: true,
          data: data.data,
        }
      }

      return {
        status,
        success: false,
        error: data.message,
      }
    } catch (err) {
      console.error(err)

      return {
        success: false,
        error: toError(err).message,
      }
    }
  }

  return {
    submitOrder,
    createOrder,
  }
}
