export const formatDeliveryTime = (time: string): string => {
  const [h, m] = time.split(':')

  const hours = parseInt(h ?? '')
  const minutes = parseInt(m ?? '')

  if (isNaN(hours) || isNaN(minutes)) {
    return ''
  }

  const meridiem = hours >= 12 ? 'pm' : ('am' as const)

  let output = String(hours % 12 === 0 ? 12 : hours % 12)

  if (minutes > 0) {
    output += `:${minutes}`
  }

  return output + meridiem
}

export const formatDeliveryTimeRange = (timeRange: string): string => {
  const [start, end] = timeRange.split('-')

  if (!start || !end) {
    return 'Unknown delivery time'
  }

  return `${formatDeliveryTime(start)} - ${formatDeliveryTime(end)}`
}
