import { type captureException, type captureMessage } from '@sentry/browser'

interface Sentry {
  captureMessage: typeof captureMessage
  captureException: typeof captureException
}

declare global {
  interface Window {
    Sentry?: Sentry
    __SENTRY__?: {
      hub: Sentry
    }
  }
}

const getSentry = (): Sentry | undefined => {
  // If Sentry is installed using the JS script it will exist as window.Sentry
  // If Sentry is installed via Nuxt it will will exist as window.__SENTRY__.hub
  return window.Sentry ?? window.__SENTRY__?.hub
}

const PROJECT_NAME = 'build-a-box'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSentry = () => {
  const log = (message: string): void => {
    getSentry()?.captureMessage(message)
  }

  const error = (exception: unknown): void => {
    getSentry()?.captureException(exception, {
      tags: {
        project: PROJECT_NAME,
      },
    })
  }

  return {
    log,
    error,
  }
}
