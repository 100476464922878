export enum ActivityLevelName {
  Chilled = 'chilled',
  Active = 'active',
  Hyper = 'hyper',
}

export interface ActivityLevel {
  id: number
  name: ActivityLevelName
  customerDescription: string
  customerText: string
  description: string
}

export const getActivityLevelDescription = (activityLevel: ActivityLevel): string => {
  switch (activityLevel.name) {
    case ActivityLevelName.Chilled: {
      return 'Mellow, loves sleep.'
    }
    case ActivityLevelName.Active: {
      return 'Energetic, small walks.'
    }
    case ActivityLevelName.Hyper: {
      return 'Athletic, long walks & runs'
    }
    default: {
      return ''
    }
  }
}

export const getActivityLevelDuration = (activityLevel: ActivityLevel): string => {
  switch (activityLevel.name) {
    case ActivityLevelName.Chilled: {
      return '<30 minutes exercise'
    }
    case ActivityLevelName.Active: {
      return '30-90 minutes exercise'
    }
    case ActivityLevelName.Hyper: {
      return '>90 minutes exercise'
    }
    default: {
      return ''
    }
  }
}
