<script setup lang="ts">
import { computed } from 'vue'
import CheckoutSeparator from './CheckoutSeparator.vue'
import CheckoutProductGamePlan from './products/CheckoutProductGamePlan.vue'
import CheckoutProductHypoallergenicProGuide from './products/CheckoutProductHypoallergenicProGuide.vue'
import CheckoutProductGoToGutHelper from './products/CheckoutProductGoToGutHelper.vue'
import { isTherapeuticRecipe } from '@/models/Recipe'
import { useRecipesStore } from '@/stores/recipes'

const includesHypoProGuide = computed(() => {
  return useRecipesStore().selectedRecipes.every(isTherapeuticRecipe)
})
</script>

<template>
  <div class="tw-flex tw-flex-col">
    <CheckoutProductGoToGutHelper />

    <CheckoutSeparator />

    <CheckoutProductHypoallergenicProGuide v-if="includesHypoProGuide" />
    <CheckoutProductGamePlan v-else />
  </div>
</template>
