import z from 'zod'
import { Step } from './Step'
import { registerStep } from './stepRegistry'
import { useLoadingPlanStep } from './loadingPlan'
import { StepName } from '@/stores/steps'

const Schema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  marketingCommunication: z.boolean(),
})

export type NoRecipesStepData = z.infer<typeof Schema>

class NoRecipesStep extends Step<NoRecipesStepData> {
  name = StepName.NoRecipes
  schema = Schema
  title = 'Your Plan'
  showSteps = false

  constructor() {
    super()
    this.loadState()
  }

  initialState(): NoRecipesStepData {
    return {
      firstName: '',
      lastName: '',
      email: '',
      marketingCommunication: true,
    }
  }

  loadState(): void {}
  saveState(): void {}

  prev(): Step {
    return useLoadingPlanStep().prev()
  }

  next(): undefined {
    return undefined
  }
}

export const useNoRecipesStep = registerStep(NoRecipesStep)
