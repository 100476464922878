import z from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { useLocationStep } from './location'
import { type DogStep, Step } from './Step'
import { registerStep } from './stepRegistry'
import { StepName } from '@/stores/steps'
import { type DogData, MAX_DOGS, useDogsStore } from '@/stores/dogs'
import { GTMEventAction, useGTM } from '@/composables/useGTM'

const Schema = z.object({
  dogs: z
    .array(
      z.object({
        name: z.string().trim().min(1),
      }),
    )
    .min(1)
    .max(MAX_DOGS),
})

export type NameStepData = z.infer<typeof Schema>

class NameStep extends Step<NameStepData> implements DogStep {
  schema = Schema
  name = StepName.Name
  dogsStore = useDogsStore()
  title = 'Pupper Names'
  showCouponBanner = true

  constructor() {
    super()
    this.loadState()

    this.dogsStore.on('added', this.addDog.bind(this))
    this.dogsStore.on('removed', this.removeDog.bind(this))
  }

  addDog({ name = '' }: Partial<DogData> = {}): void {
    this.data.dogs.push({ name })
  }

  removeDog(index: number): void {
    this.data.dogs.splice(index, 1)
  }

  loadDog(): void {}

  onComplete(): void {
    super.onComplete()

    useGTM().sendAction(GTMEventAction.NAMED_PUPPERS, this.data.dogs.length)
    useSegment().track('Name Completed', { dogCount: this.data.dogs.length })
  }

  initialState(): NameStepData {
    return {
      dogs: [],
    }
  }

  prev(): undefined {
    return undefined
  }

  next(): Step {
    return useLocationStep()
  }

  loadState(): void {
    super.loadState()

    // Emit an event for each dog that was added so that we can correctly track
    // the number of dogs that exist.
    this.data.dogs.forEach((_, index) => this.dogsStore.emit('loaded', index))
  }
}

export const useNameStep = registerStep(NameStep)
