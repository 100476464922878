<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
    <g>
      <path
        fill="currentColor"
        d="m19 9.5-1.25-2.75L15 5.5l2.75-1.25L19 1.5l1.25 2.75L23 5.5l-2.75 1.25L19 9.5Zm0 14-1.25-2.75L15 19.5l2.75-1.25L19 15.5l1.25 2.75L23 19.5l-2.75 1.25L19 23.5Zm-10-3L6.5 15 1 12.5 6.5 10 9 4.5l2.5 5.5 5.5 2.5-5.5 2.5L9 20.5Zm0-4.85 1-2.15 2.15-1-2.15-1-1-2.15-1 2.15-2.15 1 2.15 1 1 2.15Z"
      />
    </g>
  </svg>
</template>
