import type { SegmentEvent } from '@segment/analytics-next'
import { useCookies } from './useCookies'

type EventGatewayResult =
  | {
      success: true
    }
  | {
      success: false
      error: string
    }

type SegmentEventData = Pick<SegmentEvent, 'properties' | 'anonymousId' | 'userId' | 'event'>

type EventGateway = () => {
  send: (event: SegmentEventData) => Promise<boolean>
}

const FACEBOOK_FBP_COOKIE_NAME = '_fbp'
const FACEBOOK_FBC_COOKIE_NAME = '_fbc'

export const useEventGateway: EventGateway = () => {
  const send = async (event: SegmentEventData): Promise<boolean> => {
    if (!import.meta.env.VITE_EVENT_GATEWAY_URL) {
      return false
    }

    const context: SegmentEvent['context'] = {}

    const url = new URL('/event', import.meta.env.VITE_EVENT_GATEWAY_URL)

    const fbp = useCookies().get(FACEBOOK_FBP_COOKIE_NAME)
    const fbc = useCookies().get(FACEBOOK_FBC_COOKIE_NAME)

    context.fbp = fbp
    context.fbc = fbc

    const response = await fetch(url, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...event, context }),
    })

    const result = (await response.json()) as EventGatewayResult

    return result.success
  }

  return {
    send,
  }
}
