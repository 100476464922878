import { computed, ref } from 'vue'
import { unwrapData, useApi } from './useApi'
import type { DeliveryData } from './useCheckout'
import { useVerifyPostcode } from './useVerifyPostcode'
import type { DeliveryDate } from '@/models/DeliveryDate'
import type { DeliverySlot } from '@/models/DeliverySlot'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useDeliveryDates = () => {
  const api = useApi()
  const verifyPostcode = useVerifyPostcode()

  const dates = ref<DeliveryDate[]>([])
  const loading = ref(false)

  const availableDeliveryDates = computed(() => {
    return dates.value.filter(({ available }) => available)
  })

  const soonestDeliveryDate = computed(() => {
    const [first] = availableDeliveryDates.value

    return first
  })

  const soonestDeliverySlot = computed(() => {
    return soonestDeliveryDate.value?.slots.find(({ available }) => available)
  })

  const getSlotsForDate = (date: string): DeliverySlot[] => {
    return (
      availableDeliveryDates.value
        .find((deliveryDate) => deliveryDate.date === date)
        ?.slots.filter(({ available }) => available) ?? []
    )
  }

  const checkDeliverySlotValid = ({ date, slot }: Pick<DeliveryData, 'date' | 'slot'>): boolean => {
    // Find the matching date for the delivery
    const dateMatch = availableDeliveryDates.value.find(
      (deliveryDate) => deliveryDate.available && deliveryDate.date === date,
    )

    // Find the slot for the delivery
    const slotMatch = dateMatch?.slots.find((deliverySlot) => deliverySlot.available && deliverySlot.cutoffId === slot)

    return !!(dateMatch && slotMatch)
  }

  const clearDeliveryDates = (): void => {
    dates.value = []
  }

  const queryDeliveryDates = async (postcode: string): Promise<void> => {
    try {
      loading.value = true
      dates.value = []

      // First verify that the postcode is valid. We need to do this as a postcode
      // such as 2004 which is used for post office boxes will return valid delivery
      // slots. We don't delivery to PO Boxes so need to return no results.
      const valid = await verifyPostcode.verify(postcode)

      if (!valid) {
        return
      }

      const [ymd] = new Date().toISOString().split('T')

      const params = {
        postcode,
        weeks: 3,
        ignoreChargeCutoff: 1,
        baseDate: ymd,
      }

      const { data } = await api.post<DeliveryDate[]>('shipping/deliverydates/postcodeonly', params, unwrapData)

      if (Array.isArray(data)) {
        dates.value = data
      }
    } finally {
      loading.value = false
    }
  }

  return {
    soonestDeliveryDate,
    soonestDeliverySlot,
    availableDeliveryDates,
    loading,
    getSlotsForDate,
    checkDeliverySlotValid,
    clearDeliveryDates,
    queryDeliveryDates,
  }
}
