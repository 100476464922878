<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  arrow?: boolean | 'top-left' | 'top'
  heading?: string
  compact?: boolean
  dark?: boolean
}>()

const arrowPosition = computed<string | undefined>(() => {
  if (props.arrow === true) {
    return 'top-left'
  }

  return props.arrow || undefined
})
</script>

<template>
  <div class="lyka-message">
    <div v-if="$slots.icon" class="lyka-message-icon">
      <slot name="icon" />
    </div>
    <div v-else-if="arrow" :data-position="arrowPosition" class="lyka-message-arrow" />

    <div class="lyka-message-content" :data-icon="!!$slots.icon" :data-compact="compact" :data-dark="dark">
      <h5 v-if="heading" class="lyka-message-heading">{{ heading }}</h5>
      <slot />
    </div>
  </div>
</template>

<style lang="postcss">
.lyka-message {
  @apply tw-relative;
}

.lyka-message-arrow {
  @apply tw-absolute tw-transform tw-border-l-[12px] tw-border-r-[12px] tw-border-x-transparent tw-border-b-[10px] tw-border-b-secondary;

  /* Top */
  @apply data-[position=top-left]:tw-bottom-full data-[position=top-left]:tw-translate-x-2/3;

  /* Top-Left */
  @apply data-[position=top]:tw-bottom-full data-[position=top]:tw-left-1/2 data-[position=top]:-tw-translate-x-1/2;
}

.lyka-message-icon {
  @apply tw-left-1/2 tw-absolute tw-w-9 tw-h-9 tw-rounded-full tw-bg-green-dark tw-text-white tw-bottom-full -tw-translate-x-1/2 tw-translate-y-1/2 tw-flex tw-items-center tw-justify-center;
}

.lyka-message-content {
  @apply tw-bg-secondary tw-text-alt tw-p-2.5 tw-rounded-xl tw-z-10 tw-text-sm;

  @apply data-[dark=true]:tw-bg-dark data-[dark=true]:tw-text-offwhite;

  @apply data-[compact=true]:tw-p-4 data-[compact=true]:sm:tw-text-sm;

  @apply data-[icon=true]:tw-py-6;
}

.lyka-message-heading {
  @apply tw-text-xl tw-font-bold tw-mb-2;
}
</style>
