// https://www.notion.so/lykapetfood/GTM-Events-41267803a80e4285bb805e9244e98230

export enum GTMEventAction {
  // Journey events
  STARTED_JOURNEY = 'started_journey',
  RESUMED_JOURNEY = 'resumed_journey',
  NAMED_PUPPERS = 'named_puppers',
  PROVIDED_POSTCODE = 'provided_postcode',
  PROVIDED_GENDER = 'selected_gender',
  PROVIDED_WEIGHT = 'provided_weight',
  PROVIDED_AGE = 'provided_age',
  PROVIDED_BODY_SHAPE = 'selected_body_shape',
  PROVIDED_BREED = 'selected_breed',
  PROVIDED_ADULT_WEIGHT = 'provided_adult_weight',
  PROVIDED_ACTIVITY_LEVEL = 'selected_body_activity',
  PROVIDED_FUSSINESS = 'selected_fusiness',
  PROVIDED_ALLERGIES = 'selected_intolerances',
  PROVIDED_ILLNESSES = 'selected_health_issues',
  PROVIDED_PERSONAL_DETAILS = 'provided_person_details',
  PROVIDED_RECIPES = 'selected_bowls',
  SELECTED_PLAN = 'selected_plan',
  ATTRIBUTION_SURVEY = 'attribution_survey',

  // General
  CLICKED_BACK = 'clicked_back',

  // Name step
  ADDED_DOG = 'added_dog',
  REMOVED_DOG = 'removed_dog',

  // Recipes
  REMOVED_RECIPE = 'removed_bowl',
  ADDED_RECIPE = 'added_bowl',

  // Treats
  ADDED_TREATS = 'added_treats',
  SKIPPED_TREATS = 'skipped_treats',
  INCREASED_TREATS = 'increased_treats',
  DECREASED_TREATS = 'decreased_treats',
  CHANGED_TREATS_PURCHASE_MODE = 'changed_treats_purchase_mode',
  VIEWED_TREAT_DETAILS = 'viewed_treat_details',

  // Delivery
  CHANGED_DELIVERY_TIME = 'changed_delivery_time',
  CHANGED_DELIVERY_DATE = 'changed_delivery_date',
  ADDED_DELIVERY_INSTRUCTIONS = 'added_delivery_instructions',

  // Checkout
  APPLIED_DISCOUNT = 'applied_discount',
  ADDED_PAYMENT_DETAILS = 'added_payment_details',
  CHECKED_OUT = 'checked_out',
  EDIT_TREATS_CHECKOUT = 'edited_treats_checkout',
  ADDED_TREATS_CHECKOUT = 'second_added_treats',
}

export enum GTMEventCategory {
  ACQUISITON_JOURNEY = 'acquisition_journey',
}

export enum GTMEventName {
  BAB_EVENT = 'bab-flow-event',
  SMS_OPT_IN = 'sms-opt-in',
  IMPACT_ORDER_DETAILS = 'impactOrderDetails',
  RECIPE_NOT_FOUND = 'recipe-not-found',
}

export interface ImpactOrderProduct {
  subTotal: number
  category: string
  sku: string
  quantity: number
  name: string
  frequency?: number
}

export interface GTMEventData {
  [GTMEventName.RECIPE_NOT_FOUND]: {
    customerfirstName: string
    customerlastName: string
    email: string
    comingFrom: string
  }
  [GTMEventName.BAB_EVENT]: {
    'event-name': string
    'event-label': string
    'event-category': string
  }
  [GTMEventName.IMPACT_ORDER_DETAILS]: {
    affiliateMarketingData: {
      orderId: string
      customerId: ''
      customerEmail: string
      customerStatus: 'New'
      currencyCode: 'AUD'
      orderPromoCode: string
      orderDiscount: 0
      Text1: string
      items: ImpactOrderProduct[]
    }
  }
  [GTMEventName.SMS_OPT_IN]: {
    customerSmsOptIn: boolean
    email: string
  }
}

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[]
  }
}

const PRICE_RANGES = new Set<[number, number]>([
  [0, 10],
  [10, 20],
  [20, 30],
  [30, 50],
  [50, 70],
  [70, 100],
  [100, 150],
  [150, 200],
  [200, 300],
  [300, 500],
  [500, 1000],
])

const createGTMEvent = (eventName: GTMEventName, eventData: object): void => {
  const event = {
    event: eventName,
    ...eventData,
  }

  window.dataLayer ??= []
  window.dataLayer?.push(event)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useGTM = () => {
  const sendAction = (action: GTMEventAction, label?: string | number | boolean): void => {
    createGTMEvent(GTMEventName.BAB_EVENT, {
      'event-name': action,
      'event-label': label,
      'event-category': GTMEventCategory.ACQUISITON_JOURNEY,
    })
  }

  const sendEvent = <E extends GTMEventName>(eventName: E, eventData: GTMEventData[E]): void => {
    createGTMEvent(eventName, {
      ...eventData,
    })
  }

  const toPriceRange = (price: number): string | undefined => {
    if (price > 1_000) {
      return '$1000+'
    }

    for (const [min, max] of PRICE_RANGES) {
      if (price >= min && price < max) {
        return `$${min}-${max}`
      }
    }

    return undefined
  }

  return {
    sendAction,
    sendEvent,
    toPriceRange,
  }
}
