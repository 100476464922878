<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'
import type * as icons from './icon'

export type Icon = keyof typeof icons

const props = defineProps<{
  name: Icon
}>()

const dynamicComponent = computed(() => {
  return defineAsyncComponent(() => import(`./icon/${props.name}.vue`))
})
</script>

<template>
  <component :is="dynamicComponent" />
</template>
