import { usePreviouslyFedStep } from '@/steps/previouslyFed'

// Transform previously fed data from a single value to an array of values
export const migratePreviouslyFedFromSingleToMultipleValues = (): void => {
  const previouslyFed = usePreviouslyFedStep()

  previouslyFed.data.dogs.forEach((dog) => {
    const { foodType } = dog.previouslyFed

    dog.previouslyFed.foodTypes ??= []

    if (Number.isInteger(foodType)) {
      dog.previouslyFed.foodTypes = [foodType as number]
      delete dog.previouslyFed.foodType
    }
  })

  previouslyFed.saveState()
}
