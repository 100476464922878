import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { StepName } from '@/stores/steps'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCheckoutStepViewedEvent = () => {
  const send = async (stepName: StepName): Promise<void> => {
    await useSegment().track('Checkout Step Viewed', {
      stepName,
    })
  }

  return {
    send,
  }
}
