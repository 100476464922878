import z from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type DogStep, Step } from './Step'
import { useBodyShapeStep } from './bodyShape'
import { useAgeStep } from './age'
import { registerStep } from './stepRegistry'
import { StepName } from '@/stores/steps'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { type DogData, useDogsStore } from '@/stores/dogs'

const Schema = z.object({
  dogs: z
    .array(
      z.object({
        weight: z.number().min(1).max(99),
      }),
    )
    .min(1),
})

const DEFAULT_VALUE = 0

export type WeightStepData = z.infer<typeof Schema>

class WeightStep extends Step<WeightStepData> implements DogStep {
  schema = Schema
  name = StepName.Weight
  dogsStore = useDogsStore()
  title = 'Pupper Weight'

  constructor() {
    super()

    this.loadState()

    this.dogsStore.on('added', this.addDog.bind(this))
    this.dogsStore.on('removed', this.removeDog.bind(this))
    this.dogsStore.on('loaded', this.loadDog.bind(this))
  }

  addDog({ weight = DEFAULT_VALUE }: DogData = {}): void {
    this.data.dogs.push({ weight })
  }

  removeDog(index: number): void {
    this.data.dogs.splice(index, 1)
  }

  loadDog(index: number): void {
    if (!this.data.dogs[index]) {
      this.data.dogs[index] = { weight: DEFAULT_VALUE }
    }
  }

  onComplete(): void {
    super.onComplete()

    this.data.dogs.forEach((dog) => {
      useGTM().sendAction(GTMEventAction.PROVIDED_WEIGHT, dog.weight)
    })

    useSegment().track('Weight Completed', {
      dogs: this.data.dogs.map(({ weight }) => {
        return {
          weight,
        }
      }),
    })
  }

  initialState(): WeightStepData {
    return {
      dogs: [],
    }
  }

  prev(): Step {
    return useAgeStep()
  }

  next(): Step {
    return useBodyShapeStep()
  }
}

export const useWeightStep = registerStep(WeightStep)
