<script setup lang="ts">
import { LykaModal, LykaTab, LykaTabList, LykaTabPanel } from '@lyka/ui'
import { computed, ref } from 'vue'
import { assetUrl } from '@lyka/utils'
import RecipesForDogCardModalRecipe from './RecipesForDogCardModalRecipe.vue'
import RecipesForDogCardModalIngredients from './RecipesForDogCardModalIngredients.vue'
import RecipesForDogCardModalAnalysis from './RecipesForDogCardModalAnalysis.vue'
import { type Recipe, RecipeName } from '@/models/Recipe'

const props = defineProps<{
  recipe: Recipe
  open: boolean
}>()

enum Tab {
  Recipe,
  Ingredients,
  Analysis,
}

const tab = ref(Tab.Recipe)

// These titles aren't returned from the API so we have to hardcode them
const title = computed(() => {
  switch (props.recipe.name) {
    case RecipeName.Beef:
      return 'Grass-Fed Beef Bowl'
    case RecipeName.Chicken:
      return 'Free-Range Chicken Bowl'
    case RecipeName.Turkey:
      return 'Barn-Raised Turkey Bowl'
    case RecipeName.Kangaroo:
      return 'Wild-Caught Kangaroo Bowl'
    case RecipeName.Lamb:
      return 'Grass-Fed Lamb Bowl'
    case RecipeName.Fish:
      return 'Wild-Caught Fish Bowl'
    default:
      return ''
  }
})

const recipeImage = computed(() => {
  return assetUrl(`images/recipes/${props.recipe.name}/bowl.webp`)
})

const recipeImageDescription = computed(() => {
  return props.recipe.recipeDescriptionHeading
})
</script>

<template>
  <LykaModal :open="open" :title="title" size="lg">
    <LykaTabList class="tw--mt-6">
      <LykaTab :selected="tab === Tab.Recipe" @select="tab = Tab.Recipe"> Recipe </LykaTab>
      <LykaTab :selected="tab === Tab.Ingredients" @select="tab = Tab.Ingredients"> Ingredients </LykaTab>
      <LykaTab :selected="tab === Tab.Analysis" @select="tab = Tab.Analysis"> Analysis </LykaTab>
    </LykaTabList>

    <div class="tw-flex tw-gap-6 sm:tw-gap-10 tw-pt-12 tw-items-start">
      <div
        class="tw-hidden md:tw-flex tw-justify-center tw-items-center tw-bg-secondary tw-w-1/4 lg:tw-w-[24rem] tw-shrink-0 tw-aspect-[20/17]"
      >
        <img :src="recipeImage" :alt="recipeImageDescription" width="220" />
      </div>

      <LykaTabPanel :visible="tab === Tab.Recipe">
        <RecipesForDogCardModalRecipe :recipe="recipe" />
      </LykaTabPanel>

      <LykaTabPanel :visible="tab === Tab.Ingredients">
        <RecipesForDogCardModalIngredients :recipe="recipe" />
      </LykaTabPanel>

      <LykaTabPanel :visible="tab === Tab.Analysis">
        <RecipesForDogCardModalAnalysis :recipe="recipe" />
      </LykaTabPanel>
    </div>
  </LykaModal>
</template>
