import { z } from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type DogStep, type ModifySchema, Step } from './Step'
import { useGenderStep } from './gender'
import { useAgeStep } from './age'
import { registerStep } from './stepRegistry'
import { StepName } from '@/stores/steps'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { type DogData, useDogsStore } from '@/stores/dogs'
import { useDataStore } from '@/stores/data'

export enum BreedType {
  Pure = 'pure',
  Mix = 'mix',
  Unknown = 'unknown',
}

// This is the ID for the old unknown breed. We no longer use this
export const OLD_UNKNOWN_DOG_BREED_ID = 196

// Dalmation breeds
export const DALMATION_DOG_BREED_IDS = [
  70, // Dalmatian
  230, // Dalmadoodle (Dalmation/Poodle)
]

const MixedBreedSchema = z.object({
  type: z.literal(z.nativeEnum(BreedType).enum.Mix),
  primary: z.number().int(),
  secondary: z.number().int(),
})

const PureBreedSchema = z.object({
  type: z.literal(z.nativeEnum(BreedType).enum.Pure),
  primary: z.number().int(),
  secondary: z.null(),
})

const UnknownBreedSchema = z.object({
  type: z.literal(z.nativeEnum(BreedType).enum.Unknown),
  primary: z.number().int(),
  secondary: z.null(),
})

type BreedSchema = z.infer<typeof PureBreedSchema | typeof MixedBreedSchema | typeof UnknownBreedSchema>

export const Schema = z.object({
  dogs: z
    .array(
      z.object({
        breed: z.discriminatedUnion('type', [UnknownBreedSchema, MixedBreedSchema, PureBreedSchema]),
      }),
    )
    .min(1),
})

export type BreedStepData = ModifySchema<
  z.infer<typeof Schema>,
  {
    dogs: {
      breed: {
        type: BreedType
        primary: number | null
        secondary: number | null
      }
    }[]
  }
>

const DEFAULT_VALUE = {
  type: BreedType.Pure,
  primary: null,
  secondary: null,
} as const

class BreedStep extends Step<BreedStepData> implements DogStep {
  name = StepName.Breed
  schema = Schema
  dogsStore = useDogsStore()
  title = 'Breed'

  constructor() {
    super()
    this.loadState()

    this.dogsStore.on('added', this.addDog.bind(this))
    this.dogsStore.on('removed', this.removeDog.bind(this))
    this.dogsStore.on('loaded', this.loadDog.bind(this))
  }

  addDog({ breed = DEFAULT_VALUE }: DogData = {}): void {
    this.data.dogs.push({ breed: { ...breed } })
  }

  removeDog(index: number): void {
    this.data.dogs.splice(index, 1)
  }

  loadDog(index: number): void {
    this.data.dogs[index] ??= { breed: { ...DEFAULT_VALUE } }
  }

  onComplete(): void {
    super.onComplete()

    this.data.dogs.forEach((dog) => {
      useGTM().sendAction(GTMEventAction.PROVIDED_BREED, dog.breed.type)
    })

    useSegment().track('Breed Completed', {
      dogs: this.data.dogs.map(({ breed }) => {
        const breedPrimary = breed.primary ? useDataStore().findRecord('breeds', breed.primary)?.name : undefined
        const breedSecondary = breed.secondary ? useDataStore().findRecord('breeds', breed.secondary)?.name : undefined

        return {
          breedPrimary: breedPrimary ?? null,
          breedSecondary: breedSecondary ?? null,
          breedType: breed.type,
        }
      }),
    })
  }

  initialState(): BreedStepData {
    return {
      dogs: [],
    }
  }

  prev(): Step {
    return useGenderStep()
  }

  next(): Step {
    return useAgeStep()
  }

  updateBreed(dogIndex: number, { primary, secondary, type }: BreedSchema): void {
    if (this.data.dogs[dogIndex]) {
      this.data.dogs[dogIndex] = { breed: { primary, secondary, type } }
    }

    this.validate()
  }
}

export const useBreedStep = registerStep(BreedStep)
