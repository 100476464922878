import { z } from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type DogStep, type ModifySchema, Step } from './Step'
import { StepName } from '@/stores/steps'
import { useDogsStore } from '@/stores/dogs'

const Schema = z.object({
  dogs: z
    .array(
      z.object({
        hasIllnesses: z.boolean(),
      }),
    )
    .min(1),
})

export type HasIllnessesStepData = ModifySchema<
  z.infer<typeof Schema>,
  {
    dogs: {
      hasIllnesses: boolean | null
    }[]
  }
>

/* @deprecated This step is no longer used */
class HasIllnessStep extends Step<HasIllnessesStepData> implements DogStep {
  name = StepName.HasIllnesses
  schema = Schema
  dogsStore = useDogsStore()
  title = 'Health Issues'
  removed = true

  constructor() {
    super()
    this.loadState()
    this.dogsStore.on('loaded', this.loadDog.bind(this))
  }

  onComplete(): void {
    useSegment().track('Health Issues Completed', {
      dogs: this.data.dogs.map((dog) => {
        return {
          hasHealthIssues: !!dog.hasIllnesses,
        }
      }),
    })
  }

  addDog(): void {}

  removeDog(index: number): void {
    this.data.dogs.splice(index, 1)
  }

  loadDog(): void {}

  initialState(): HasIllnessesStepData {
    return {
      dogs: [],
    }
  }

  prev(): undefined {
    return undefined
  }

  next(): undefined {
    return undefined
  }
}

/* @deprecated This step is no longer used */
export const useHasIllnessesStep = (): HasIllnessStep => new HasIllnessStep()
