<script setup lang="ts">
import { LykaButton } from '@lyka/ui'
import NavigationWrap from '@/components/common/NavigationWrap.vue'
import StepNavigationButtonMask from '@/components/StepNavigationButtonMask.vue'

const props = defineProps<{
  disabled: boolean
  submitting: boolean
  mobile?: boolean
}>()
</script>

<template>
  <div
    v-if="mobile"
    class="tw-fixed tw-left-0 tw-bottom-0 tw-w-full tw-z-10 tw-bg-body tw-shadow-[2px,0,10px,1px,rgba(0,0,0,0.05)]"
  >
    <NavigationWrap>
      <StepNavigationButtonMask :disabled="disabled">
        <LykaButton :disabled="disabled" block type="submit" :loading="submitting">
          <template v-if="props.submitting">Processing…</template>
          <slot v-else />
        </LykaButton>
      </StepNavigationButtonMask>
    </NavigationWrap>
  </div>

  <StepNavigationButtonMask v-else :disabled="disabled">
    <LykaButton
      data-testid="checkout-submit"
      size="xl"
      disabled-variant="secondary"
      :disabled="disabled"
      block
      type="submit"
      :loading="submitting"
      variant="alt"
    >
      <template v-if="props.submitting">Processing…</template>
      <slot v-else />
    </LykaButton>
  </StepNavigationButtonMask>
</template>
