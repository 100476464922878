import { useBreedStep } from '@/steps/breed'
import { useDataStore } from '@/stores/data'

// Remove inactive breeds from the breed step. We've added `active: boolean` to breeds to indicate whether a breed is
// active or not. If a user has a breed selected that is no longer active, we need to remove it from their state.
export const removeInactiveBreeds = (): void => {
  const breeds = useDataStore().breeds
  const breedStep = useBreedStep()

  breedStep.data.dogs.forEach((dog, index) => {
    const primaryInactive = dog.breed.primary && !breeds.find(({ id }) => dog.breed.primary === id)

    if (primaryInactive) {
      breedStep.data.dogs[index]!.breed.primary = null
    }

    const secondaryInactive = dog.breed.secondary && !breeds.find(({ id }) => dog.breed.primary === id)

    if (secondaryInactive) {
      breedStep.data.dogs[index]!.breed.secondary = null
    }

    if (primaryInactive || secondaryInactive) {
      breedStep.saveState()
    }
  })
}
