const cookies = new Map<string, string>()

type CookieProperties = Record<string, string | number | boolean>

export const asCookieString = (cookie: CookieProperties): string => {
  return Object.entries(cookie)
    .map(([k, v]) => {
      if (v === true) {
        return k
      }

      return [k, v].join('=')
    })
    .join('; ')
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCookies = () => {
  const set = (name: string, value: string, maxAgeSeconds?: number): void => {
    const cookie: CookieProperties = {
      [name]: value,
      Domain: location.hostname,
      Path: '/',
      Secure: true,
    }

    if (maxAgeSeconds) {
      cookie['Max-Age'] = maxAgeSeconds
    }

    document.cookie = asCookieString(cookie)

    cookies.set(name, value)
  }

  const get = (name: string): string | undefined => {
    return (
      cookies.get(name) ??
      document.cookie
        .split('; ')
        .find((row) => row.startsWith(`${name}=`))
        ?.split('=')[1]
    )
  }

  const remove = (name: string): void => {
    cookies.delete(name)
    document.cookie = `${name}=; Expires=${new Date(0).toUTCString()}; Domain=${location.hostname}; path=/; Secure;`
  }

  const clear = (): void => {
    cookies.clear()
  }

  return {
    set,
    get,
    clear,
    remove,
  }
}
