import z from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { Step } from './Step'
import { useContactDetailsStep } from './contactDetails'
import { usePlanStep } from './plan'
import { registerStep } from './stepRegistry'
import { StepName } from '@/stores/steps'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { useDogsStore } from '@/stores/dogs'
import { useRecipesStore } from '@/stores/recipes'
import type { RecipeName } from '@/models/Recipe'

const Schema = z.object({
  dogs: z
    .array(
      z.object({
        recipes: z.array(z.number().int()).min(1),
        viewed: z.boolean().optional(),
      }),
    )
    .min(1),
})

export type RecipesStepData = z.infer<typeof Schema>

class RecipesStep extends Step<RecipesStepData> {
  name = StepName.Recipes
  schema = Schema
  dogsStore = useDogsStore()
  title = 'Meals'
  showSteps = false

  constructor() {
    super()
    this.loadState()

    this.dogsStore.on('added', ({ recipes = [] }) => {
      this.data.dogs.push({ recipes: [...recipes] })
    })

    this.dogsStore.on('removed', (index) => {
      this.data.dogs.splice(index, 1)
    })

    this.dogsStore.on('loaded', (index) => {
      this.data.dogs[index] ??= { recipes: [] }
    })

    // If the illnesses or allergies are updated, reset that dog's recipes
    this.dogsStore.on('*', (eventName, index) => {
      if (['illnessesUpdated', 'illnessesRemoved', 'allergiesUpdated', 'allergiesRemoved'].includes(eventName)) {
        if (typeof index === 'number') {
          this.data.dogs[index] = { recipes: [] }
        }
      }
    })
  }

  onComplete(): void {
    super.onComplete()

    this.data.dogs.forEach((dog) => {
      useGTM().sendAction(GTMEventAction.PROVIDED_RECIPES, dog.recipes.length)
    })

    const recipesStore = useRecipesStore()

    useSegment().track('Recipes Completed', {
      dogs: this.data.dogs.map((dog) => {
        return {
          recipes: dog.recipes.reduce<RecipeName[]>((recipes, recipeId) => {
            const record = recipesStore.findRecipe(recipeId)

            if (record) {
              recipes.push(record.name)
            }

            return recipes
          }, []),
        }
      }),
    })
  }

  initialState(): RecipesStepData {
    return {
      dogs: [],
    }
  }

  prev(): Step {
    return useContactDetailsStep()
  }

  next(): Step {
    return usePlanStep()
  }
}

export const useRecipesStep = registerStep(RecipesStep)
