import type { App, Plugin } from 'vue'

declare global {
  interface Window {
    FrontChat?: (event: string, options?: { chatId: string; useDefaultLauncher: boolean }) => void
  }
}

const isMobile = (): boolean => document.body.clientWidth < 768

export const frontChatPlugin: Plugin = {
  install(_: App, { chatId, hideOnMobile }: { chatId?: string; hideOnMobile?: boolean }) {
    if (chatId) {
      window.FrontChat?.('init', { chatId, useDefaultLauncher: hideOnMobile ? !isMobile() : true })
    }
  },
}
