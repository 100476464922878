<script setup lang="ts">
import { capitalize, computed, onMounted, reactive, ref, watch } from 'vue'
import { clone } from 'remeda'
import { LykaContainer } from '@lyka/ui'
import { useExperiments } from '@lyka/vue-common/composables/useExperiments'
import TrustpilotReviews from '../TrustpilotReviews.vue'
import MiniHr from '../common/MiniHr.vue'
import AnyQuestion from '../common/AnyQuestion.vue'
import StepBackButton from '../../components/StepBackButton.vue'
import StepNavigation from '../StepNavigation.vue'
import TickIcon from '../icons/TickIcon.vue'
import PlanFirstBox from './plan/PlanFirstBox.vue'
import PlanSocialGiftingStarter from './plan/PlanSocialGiftingStarter.vue'
import PlanOptions from './plan/PlanOptions.vue'
import PlanOptionsV3 from './plan/PlanOptionsV3.vue'
import PlanStepLoading from './plan/PlanStepLoading.vue'
import { type MealPlan, MealPlanType, getMealPlanName } from '@/models/MealPlan'
import type { PlanStepData } from '@/steps/plan'
import { AcquisitionPage, useAcquisitionData } from '@/composables/useAcquisitionData'
import { useMealPlansStore } from '@/stores/mealPlans'
import PlanSocialGiftingWhatHappensNext from '@/components/steps/plan/PlanSocialGiftingWhatHappensNext.vue'
import { useCoupons } from '@/composables/useCoupons'
import { usePlansCalculatedEvent } from '@/composables/events/segment/usePlansCalculatedEvent'
import { useRecipesStore } from '@/stores/recipes'
import { isTherapeuticRecipe } from '@/models/Recipe'

const props = defineProps<{
  stepData: PlanStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const mealPlanStore = useMealPlansStore()
const recipesStore = useRecipesStore()
const coupons = useCoupons()

const data = reactive(clone(props.stepData))
const loading = ref(true)

const hasTherapeuticRecipes = computed<boolean>(() => {
  return recipesStore.selectedRecipes.some(isTherapeuticRecipe)
})

const hasSocialGiftingCoupon = computed(() => coupons.socialGiftingCoupon.value)

const starterPlan = computed(() => {
  return mealPlanStore.mealPlans.find((plan) => plan.type === 'starter')
})

const nextButtonLabel = computed(() => {
  const { type } = props.stepData.plan

  if (type) {
    const name = capitalize(getMealPlanName(type).replace(' plan', ''))

    return `Choose ${name}`
  }

  return 'Select a plan'
})

const inPlanRedesignSeptemberExperiment = useExperiments().inExperiment('bab-plan-redesign-september')

watch(
  () => data,
  (newData) => emits('save', newData),
  { deep: true },
)

const onPlanSelected = async (plan: MealPlan | undefined, submit = true): Promise<void> => {
  data.plan.type = plan?.type ?? null

  if (submit) {
    emits('submit', data)
  } else {
    emits('save', data)
  }
}

const autoSelectFullPlan = (): void => {
  data.plan.type = MealPlanType.Full

  emits('save', data)
}

onMounted(async () => {
  try {
    const { mealPlans, mealWeights } = await mealPlanStore.loadMeals()

    usePlansCalculatedEvent({ mealPlans, mealWeights }).send()

    if (inPlanRedesignSeptemberExperiment && !data.plan.type) {
      autoSelectFullPlan()
    }
  } finally {
    loading.value = false

    useAcquisitionData().send(AcquisitionPage.Plan)
  }
})
</script>

<template>
  <div ref="stepNav">
    <PlanStepLoading v-if="loading" />
    <div v-else class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
      <LykaContainer size="lg" class="tw-text-center">
        <div class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
          <!-- Social Gifting Coupon -->
          <PlanSocialGiftingStarter
            v-if="hasSocialGiftingCoupon"
            :meal-plan="starterPlan!"
            :meal-weights="mealPlanStore.mealWeights"
          />

          <template v-else-if="inPlanRedesignSeptemberExperiment">
            <PlanOptionsV3
              :selected-meal-plan-type="data.plan.type"
              :meal-plans="mealPlanStore.mealPlans"
              :meal-weights="mealPlanStore.mealWeights"
              :therapeutic="hasTherapeuticRecipes"
              @select="(mealPlan) => onPlanSelected(mealPlan, false)"
            />
          </template>

          <!-- Default -->
          <template v-else>
            <PlanOptions
              meal-price-display="day"
              :selected-meal-plan-type="data.plan.type"
              :meal-plans="mealPlanStore.mealPlans"
              :meal-weights="mealPlanStore.mealWeights"
              show-total-price
              :therapeutic="hasTherapeuticRecipes"
              @select="onPlanSelected"
            />
          </template>

          <div class="tw-space-y-8">
            <h3 class="tw-h2">Feeding with Lyka is flexible</h3>
            <ul class="tw-space-y-1 tw-text-base sm:tw-text-lg md:tw-text-xl tw-w-max tw-mx-auto">
              <li class="tw-flex tw-gap-3 tw-items-center"><TickIcon /> Cancel anytime, hassle-free</li>
              <li class="tw-flex tw-gap-3 tw-items-center"><TickIcon /> Control your delivery time</li>
              <li class="tw-flex tw-gap-3 tw-items-center"><TickIcon /> Simple swaps and changes</li>
            </ul>
          </div>
        </div>
      </LykaContainer>

      <LykaContainer size="lg" class="tw-text-center">
        <div class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
          <PlanFirstBox v-if="hasSocialGiftingCoupon">
            <template #heading>
              Included in your
              <span class="tw-text-green tw-whitespace-nowrap">Starter Box</span>
            </template>
          </PlanFirstBox>

          <PlanSocialGiftingWhatHappensNext v-if="hasSocialGiftingCoupon" />
        </div>
      </LykaContainer>

      <LykaContainer size="lg" class="tw-text-center">
        <div class="tw-space-y-12 md:tw-space-y-16 lg:tw-space-y-32">
          <PlanFirstBox v-if="!hasSocialGiftingCoupon" />

          <div>
            <LykaContainer size="md" class="tw-space-y-4 tw-mb-8">
              <h2 class="tw-h2 tw-mb-6"><b class="tw-text-green tw-block">Real changes.</b> Real dogs.</h2>

              <p class="tw-lead">
                Taking pride of place in dog bowls around the country, discover how Lyka is changing the game and
                improving lives.
              </p>
            </LykaContainer>

            <TrustpilotReviews />
          </div>

          <div class="tw-space-y-2 sm:tw-space-y-6">
            <MiniHr />

            <AnyQuestion center />
          </div>

          <StepBackButton
            v-if="!hasSocialGiftingCoupon && !inPlanRedesignSeptemberExperiment"
            @click="emits('previous')"
          />
        </div>
      </LykaContainer>

      <StepNavigation
        v-if="hasSocialGiftingCoupon"
        @next="onPlanSelected(starterPlan!)"
        @previous="emits('previous')"
      />
    </div>
  </div>

  <StepNavigation
    v-if="inPlanRedesignSeptemberExperiment"
    :next-text="nextButtonLabel"
    :next-disabled="!valid"
    @previous="emits('previous')"
    @next="emits('submit', data)"
  />
</template>
