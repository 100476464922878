import { AllergenName } from './Allergy'
import { IllnessName } from './Illness'
import type { Ingredient, IngredientAggregate } from './Ingredient'
import type { RecipeNutritionCategory } from './RecipeNutritionCategory'

export enum RecipeName {
  Beef = 'beef',
  Chicken = 'chicken',
  Turkey = 'turkey',
  Lamb = 'lamb',
  Kangaroo = 'kangaroo',
  Fish = 'fish',
  Goat = 'goat',
}

export const THERAPEUTIC_RECIPES: ReadonlySet<RecipeName> = new Set([RecipeName.Goat])

export const RECIPE_ALLERGENS: ReadonlyMap<RecipeName, Set<AllergenName>> = new Map<RecipeName, Set<AllergenName>>([
  [RecipeName.Chicken, new Set([AllergenName.Chicken, AllergenName.Egg, AllergenName.FishOil])],
  [RecipeName.Beef, new Set([AllergenName.Beef, AllergenName.Fish, AllergenName.FishOil])],
  [RecipeName.Lamb, new Set([AllergenName.Lamb, AllergenName.FishOil])],
  [RecipeName.Turkey, new Set([AllergenName.Turkey, AllergenName.FishOil])],
  [RecipeName.Kangaroo, new Set([AllergenName.Kangaroo, AllergenName.FishOil])],
  [RecipeName.Fish, new Set([AllergenName.Fish, AllergenName.FishOil])],
])

export const RECIPE_AVOID_ILLNESSES: ReadonlyMap<RecipeName, Set<IllnessName>> = new Map<RecipeName, Set<IllnessName>>([
  [RecipeName.Beef, new Set([IllnessName.IBD, IllnessName.Pancreatitis])],
  [RecipeName.Lamb, new Set([IllnessName.IBD, IllnessName.Pancreatitis])],
  [
    RecipeName.Kangaroo,
    new Set([IllnessName.Cancer, IllnessName.LiverDisease, IllnessName.KidneyDisease, IllnessName.UrinaryIssues]),
  ],
  [RecipeName.Goat, new Set([IllnessName.KidneyDisease, IllnessName.Pancreatitis])],
])

export interface Recipe {
  id: number
  name: RecipeName
  displayText: string
  healthBenefitsShortDescription: string | null
  paragraph1: string
  paragraph2: string
  ingredients: Array<Ingredient | IngredientAggregate>
  recipeDescriptionHeading: string
  recipesNutritionCategories: RecipeNutritionCategory[]
  metabolisedEnergy: number
  calciumPhosphorousRatio: number
  omega6Omega3Ratio: number
  marketingDisplayOrder: number
  humanGradeCallouts: Record<HumanGradeCalloutKey, HumanGradeCallout>
}

export type HumanGradeCalloutKey = 'grassFed' | 'freeRange' | 'barnRaised' | 'wildCaught' | 'wildCaughtSea'

export interface HumanGradeCallout {
  displayName: string
  imageReference: string
}

type NutritionData = Array<{
  name: string
  percent: number
  dryPercent: number
}>

export const getNutritionData = (recipe: Recipe): NutritionData => {
  return recipe.recipesNutritionCategories.map((nutrient) => {
    const name = nutrient.nutritionCategories.name
    const percent = nutrient.averageCompositionPercentage
    const dryPercent = nutrient.averageCompositionDryMealPercentage

    return {
      name,
      percent,
      dryPercent,
    }
  })
}

const VITAMINS_MINERALS_ID = 0

export const getMicroNutrientIngredients = (recipe: Recipe): Ingredient[] => {
  const ingredient = recipe.ingredients.find(({ id }) => id === VITAMINS_MINERALS_ID) as IngredientAggregate | undefined

  if (ingredient) {
    return ingredient.ingredient.childIngredients
  }

  return []
}

export const isTherapeuticRecipe = ({ name }: Pick<Recipe, 'name'>): boolean => {
  return THERAPEUTIC_RECIPES.has(name)
}

interface SuitableRecipesInput {
  illnesses?: IllnessName[]
  allergies?: AllergenName[]
}

export const isRecipeSuitable = (
  recipe: Pick<Recipe, 'name'>,
  { illnesses = [], allergies = [] }: SuitableRecipesInput = {},
): boolean => {
  // Fish is always unsuitable for first box
  if (recipe.name === RecipeName.Fish) {
    return false
  }

  const recipeAllergens = RECIPE_ALLERGENS.get(recipe.name)
  const recipeIllnesses = RECIPE_AVOID_ILLNESSES.get(recipe.name)

  // Exclude if it contains allergens
  if (recipeAllergens && allergies.some((allergy) => recipeAllergens.has(allergy))) {
    return false
  }

  // Exclude if not suitable for illness
  if (recipeIllnesses && illnesses.some((illness) => recipeIllnesses.has(illness))) {
    return false
  }

  return true
}
