import type { StateTerritory } from '@/models/Address'
import type { CheckoutOrder } from '@/models/CheckoutOrder'
import { type Dog, getBreedTypeName, getDogBirthDate } from '@/models/Dog'
import type { MealPlanType } from '@/models/MealPlan'

// Either stripe_token or stripe_payment_method_token
type CheckoutOrderStripeToken =
  | {
      stripe_token: string
    }
  | {
      stripeConfirmationToken: string
    }

export type CheckoutOrderRequest = {
  stripePaymentIntent?: string
  signupPaymentMethod?: string
  anonymousUserId?: string
  recaptcha_token: string
  user: {
    email: string
    password: string
    firstName: string
    lastName: string
    smsOptIn: boolean
    mobile: string
    deliveryAddress: {
      address_line_1?: string
      address_line_2?: string
      suburb: string
      postcode: number
      state: StateTerritory
      isWork: boolean
      business_name?: string
      deliveryInstructions?: string
      delivery_date: string // Y-m-d
      deliverySlot: {
        cutoff_id: number
      }
    }
  }
  dogDetails: Array<{
    key: string
    isActive: true // Always true
    name: string
    gender: string
    breed: {
      type: string
      primary: { id: number }
      secondary?: { id: number }
    }
    age: { is: string; bornYear: number; bornMonth: number; bornDay: number | null }
    weight: { current: number; adult: number }
    // Sic
    fusinessLevel: { id: number }
    activity: { id: number }
    bodyShape: { id: number }
    hasAllergies: boolean
    allergies?: Array<{ id: number }>
    hasIllness: boolean
    illnesses?: Array<{ id: number }>
    plan_weight: number
    serving_weight: number
    selectedRecipes: string[]
  }>
  selectedPlan: {
    type: MealPlanType
  }
  products: {
    every_box: boolean
    products: Array<{ id: number; quantity: number }>
  }
  coupon: {
    applied: boolean
    couponCode?: string
    couponType?: string
  }
  howDidYouHear:
    | {
        id: number
        other_reason: string
      }
    | {}
  referrer?: string
} & CheckoutOrderStripeToken

const serializeDog = (order: CheckoutOrder, dog: Dog, index: number): CheckoutOrderRequest['dogDetails'][0] => {
  const mealWeight = order.mealPlanWeights[index]!
  const dogBirthDate = getDogBirthDate(dog)

  return {
    key: String(index + 1),
    name: dog.name,
    isActive: true,
    gender: dog.gender!,
    breed: {
      type: getBreedTypeName(dog),
      primary: { id: dog.breed.primary!.id },
      secondary: dog.breed.secondary ? { id: dog.breed.secondary.id } : undefined,
    },
    age: {
      is: dog.age.is,
      bornYear: dogBirthDate.bornYear,
      bornMonth: dogBirthDate.bornMonth,
      bornDay: dogBirthDate.bornDay,
    },
    weight: { current: dog.weight.current, adult: dog.weight.adult },
    // Sic
    fusinessLevel: { id: dog.fusinessLevel!.id },
    activity: { id: dog.activity!.id },
    bodyShape: { id: dog.bodyShape!.id },
    hasAllergies: !!dog.allergies.length,
    allergies: dog.allergies.length ? dog.allergies.map(({ id }) => ({ id })) : undefined,
    hasIllness: dog.hasIllness,
    illnesses: dog.hasIllness ? dog.illnesses.map(({ id }) => ({ id })) : undefined,
    plan_weight: mealWeight.planWeight,
    serving_weight: mealWeight.servingWeight,
    selectedRecipes: dog.recipes.map(({ name }) => name),
  }
}

const serializeOrder = (order: CheckoutOrder): CheckoutOrderRequest => {
  if (!order.recaptchaToken) {
    throw new Error('reCAPTCHA token is missing')
  }

  if (!order.stripeToken && !order.stripeConfirmationToken) {
    throw new Error('stripeToken is missing')
  }

  if (!order.password) {
    throw new Error('password is missing')
  }

  if (!order.address) {
    throw new Error('address is missing')
  }

  if (!order.deliveryDetails) {
    throw new Error('deliveryDetails is missing')
  }

  if (!order.mealPlan) {
    throw new Error('mealPlan is missing')
  }

  if (!order.user) {
    throw new Error('user is missing')
  }

  const stripeToken: CheckoutOrderStripeToken = order.stripeToken
    ? { stripe_token: order.stripeToken! }
    : { stripeConfirmationToken: order.stripeConfirmationToken! }

  return {
    ...stripeToken,
    stripePaymentIntent: order.stripePaymentIntent,
    signupPaymentMethod: order.signupPaymentMethod,
    anonymousUserId: order.anonymousUserId,
    recaptcha_token: order.recaptchaToken,
    user: {
      ...order.user,
      password: order.password,
      deliveryAddress: {
        address_line_1: order.address.addressLine1,
        address_line_2: order.address.addressLine2 ?? undefined,
        suburb: order.address.suburb,
        postcode: parseInt(order.address.postcode),
        state: order.address.state,
        deliveryInstructions: order.deliveryDetails.instructions,
        isWork: order.address.isWork,
        business_name: order.address.businessName,
        delivery_date: order.deliveryDetails.date,
        deliverySlot: {
          cutoff_id: order.deliveryDetails.slot,
        },
      },
    },
    products: {
      every_box: order.productsInEveryBox,
      products: Object.entries(order.productQuantities).map(([id, quantity]) => {
        return {
          id: parseInt(id),
          quantity,
        }
      }),
    },
    howDidYouHear: order.howDidYouHear.option
      ? {
          id: order.howDidYouHear.option,
          other_reason: order.howDidYouHear.other ?? order.howDidYouHear.name,
        }
      : {},
    coupon: {
      applied: !!order.coupon,
      couponCode: order.coupon?.couponCode,
      couponType: order.coupon?.couponType,
    },
    dogDetails: order.dogs.map((dog, index) => serializeDog(order, dog, index)),
    selectedPlan: {
      type: order.mealPlan.type,
    },
    referrer: order.referrer,
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCheckoutOrderSerializer = (order: CheckoutOrder) => {
  const serialize = (): CheckoutOrderRequest => serializeOrder(order)

  return {
    serialize,
  }
}
