<script setup lang="ts">
import { LykaButton } from '@lyka/ui'

const props = defineProps<{
  imageSrc: string
  title: string
  name: string
  selected: boolean
  price?: string
}>()

const emit = defineEmits<{
  (e: 'select', name: string): void
  (e: 'detailsClick', name: string): void
}>()

const showDetails = (e: Event): void => {
  emit('detailsClick', props.name)
  e.stopPropagation()
}
</script>

<template>
  <div
    class="tw-bg-cream tw-relative tw-rounded-2xl tw-border-2 tw-border-transparent tw-cursor-pointer tw-flex tw-flex-col"
    :class="{ selected }"
    @click="emit('select', name)"
  >
    <img :src="imageSrc" class="tw-rounded-t-2xl" :alt="title" />

    <span class="treat-card-checkbox tw-absolute tw-left-2.5 tw-top-2.5" :class="{ checked: selected }">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="11"
        fill="none"
        viewBox="0 0 13 9"
        class="tw-fill-current"
      >
        <path
          d="M12.249.395a.858.858 0 0 1 0 1.213L5.393 8.463a.858.858 0 0 1-1.214 0L.751 5.035a.858.858 0 0 1 1.213-1.213l2.823 2.82L11.038.395a.859.859 0 0 1 1.214 0h-.003Z"
        />
      </svg>
    </span>

    <div class="tw-p-4 tw-flex tw-flex-col tw-grow tw-gap-1.5 tw-flex tw-flex-col">
      <div class="tw-font-bold tw-grow">{{ title }}</div>

      <div>
        <span v-if="price" class="tw-line-through tw-text-secondary tw-text-semidark-gray">{{ price }}</span>
        <span class="tw-ml-1.5">Free</span>
      </div>

      <div>
        <LykaButton type="button" variant="success" decoration text-link @click="showDetails"> See details </LykaButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.selected {
  @apply tw-border-2 tw-border-success;
}

.treat-card-checkbox.checked {
  @apply tw-bg-success tw-text-white;
}

.treat-card-checkbox {
  @apply tw-rounded-full tw-w-8 tw-h-8 tw-border-2 tw-border-success tw-flex tw-items-center tw-justify-center tw-bg-offwhite tw-text-transparent tw-scale-75 tw-transition-all tw-origin-center tw-transform-gpu;
}
</style>
