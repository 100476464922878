import { type GTMEventData, GTMEventName, type ImpactOrderProduct, useGTM } from '@/composables/useGTM'
import { type CheckoutOrder, getOrderId } from '@/models/CheckoutOrder'
import { getDiscountedPrice } from '@/models/Coupon'
import { useTreatsStore } from '@/stores/treats'

export const getTreatProductItems = (order: CheckoutOrder): ImpactOrderProduct[] => {
  const treatsStore = useTreatsStore()
  const treats: ImpactOrderProduct[] = []

  for (const [treatId, treatQuantity] of Object.entries(order.productQuantities)) {
    if (!treatQuantity) {
      continue
    }

    const treat = treatsStore.findProduct(parseInt(treatId))

    if (treat) {
      const price = getDiscountedPrice(treat.price, order.coupon)

      treats.push({
        subTotal: price,
        category: 'Treats',
        sku: treat.customerSubDisplayName,
        quantity: treatQuantity,
        name: treat.displayName,
      })
    }
  }

  return treats
}

const getPlanProductItems = (order: CheckoutOrder): ImpactOrderProduct[] => {
  const { mealPlan, coupon } = order

  if (!mealPlan) {
    return []
  }

  const price = getDiscountedPrice(mealPlan.totalPrice, coupon)

  return [
    {
      subTotal: price,
      category: 'Meal Plan',
      sku: mealPlan.type,
      quantity: order.dogs.length,
      name: `${mealPlan.type} Bowl Plan`,
      frequency: mealPlan.frequency,
    },
  ]
}

const getProductItems = (order: CheckoutOrder): ImpactOrderProduct[] => {
  return [...getPlanProductItems(order), ...getTreatProductItems(order)]
}

export const getEventData = async (
  order: CheckoutOrder,
): Promise<GTMEventData[GTMEventName.IMPACT_ORDER_DETAILS] | undefined> => {
  const orderId = await getOrderId(order)

  if (!orderId) {
    return
  }

  const hasTreats = Object.values(order.productQuantities).length

  const { coupon } = order

  return {
    affiliateMarketingData: {
      orderId,
      customerId: '', // I don't know why this is blank
      customerEmail: orderId, // I don't know why but this is the same as the order ID
      customerStatus: 'New',
      currencyCode: 'AUD',
      orderPromoCode: coupon?.couponCode ?? '',
      orderDiscount: 0,
      Text1: hasTreats ? String(order.productsInEveryBox) : '',
      items: getProductItems(order),
    },
  }
}

export const useImpactOrderDetailsEvent = ({ order }: { order: CheckoutOrder }): { send(): Promise<void> } => {
  const gtm = useGTM()

  const send = async (): Promise<void> => {
    const { user, mealPlan } = order

    if (!user) {
      throw new Error('user is missing from order')
    }

    if (!mealPlan) {
      throw new Error('mealPlan is missing from order')
    }

    const eventData = await getEventData(order)

    if (eventData) {
      gtm.sendEvent(GTMEventName.IMPACT_ORDER_DETAILS, eventData)
    }
  }

  return {
    send,
  }
}
