import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { EventSender } from '../EventSender'
import { useCoupons } from '@/composables/useCoupons'
import { useCheckoutStartedRequest } from '@/composables/useCheckoutStartedRequest'

export const useCheckoutStartedEvent: EventSender = (returning = false) => {
  const send = async (): Promise<void> => {
    const coupon = useCoupons().activeCoupon.value
    const referral = !!useCoupons().referrer.value

    const eventId = await useCheckoutStartedRequest().send()

    await useSegment().track(
      'Checkout Started',
      {
        eventId,
        referral,
        currency: 'AUD',
        coupon: coupon?.couponCode,
        discount: coupon?.discount,
        returning,
      },
      true,
    )
  }

  return {
    send,
  }
}
