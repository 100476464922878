<template>
  <div class="terms-card">
    <div v-if="$slots.icon" class="terms-card-icon">
      <slot name="icon" />
    </div>
    <div class="terms-card-content">
      <slot />
    </div>
  </div>
</template>

<style>
.terms-card {
  @apply tw-bg-cream-dark tw-rounded-2xl tw-p-5 tw-pb-6 tw-text-sm tw-mt-8 tw-text-left tw-flex tw-gap-3;
}

.terms-card-content {
  @apply tw-space-y-3;
}

.terms-card-content h4 {
  @apply tw-text-sm tw-font-bold;
}

.terms-card-content ul {
  @apply tw-list-disc tw-pl-6 tw-space-y-2 tw-font-light;
}

.terms-card-icon {
  @apply tw-mt-1;
}
</style>
