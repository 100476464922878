<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import { clone } from 'remeda'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import StepNavigation from '../StepNavigation.vue'
import AllergiesListDog from './allergies/AllergiesListDog.vue'
import StepsContainer from '@/components/StepContainer.vue'
import { useDataStore } from '@/stores/data'
import type { AllergiesStepData } from '@/steps/allergies'
import { useDogsStore } from '@/stores/dogs'

const props = defineProps<{
  stepData: AllergiesStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const data = reactive(clone(props.stepData))
const allergens = useDataStore().allergens
const dogsStore = useDogsStore()

watch(
  () => data,
  (newData) => emits('save', newData),
  { deep: true },
)

const disabled = computed(() => !props.valid)

const updateDogAllergies = (dogIndex: number, allergies: (typeof props)['stepData']['dogs'][0]['allergies']): void => {
  data.dogs[dogIndex]!.allergies = allergies
  dogsStore.emit('allergiesUpdated', dogIndex)
}

const submit = ({ target }: Event): void => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  emits('submit', data)
}
</script>

<template>
  <StepsContainer>
    <form id="formAllergies" v-submit class="tw-space-y-8" @submit.prevent="submit">
      <AllergiesListDog
        v-for="(dog, index) in data.dogs"
        :key="index"
        :allergens="allergens"
        :dog-index="index"
        :dog-name="dogsStore.dogName(index)!"
        :dog-allergies="dog.allergies"
        @update="(allergies) => updateDogAllergies(index, allergies)"
      />

      <StepNavigation submit :next-disabled="disabled" form="formAllergies" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
