import { z } from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type DogStep, Step } from './Step'
import { registerStep } from './stepRegistry'
import { usePreviouslyFedStep } from './previouslyFed'
import { useIllnessesStep } from './illnesses'
import { StepName } from '@/stores/steps'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { type DogData, useDogsStore } from '@/stores/dogs'
import { useDataStore } from '@/stores/data'

const Schema = z.object({
  dogs: z.array(
    z.object({
      allergies: z.array(z.number().int()),
    }),
  ),
})

export interface AllergiesStepData {
  dogs: Array<{
    allergies: number[] | null
  }>
}

class AllergiesStep extends Step<AllergiesStepData> implements DogStep {
  name = StepName.Allergies
  schema = Schema
  dogsStore = useDogsStore()
  title = 'Intolerances'

  constructor() {
    super()
    this.loadState()

    this.dogsStore.on('added', this.addDog.bind(this))
    this.dogsStore.on('removed', this.removeDog.bind(this))
    this.dogsStore.on('loaded', this.loadDog.bind(this))

    this.dogsStore.on('allergiesRemoved', (index) => {
      this.data.dogs[index] = { allergies: null }
    })
  }

  loadDog(index: number): void {
    this.data.dogs[index] ??= { allergies: [] }
  }

  addDog({ allergies = null }: DogData = {}): void {
    this.data.dogs.push({ allergies })
  }

  removeDog(index: number): void {
    this.data.dogs.splice(index, 1)
  }

  onComplete(): void {
    super.onComplete()

    this.data.dogs.forEach((dog) => {
      useGTM().sendAction(GTMEventAction.PROVIDED_ALLERGIES, dog.allergies?.length ?? 0)
    })

    const dataStore = useDataStore()

    useSegment().track('Intolerances List Completed', {
      dogs: this.data.dogs.map((dog) => {
        const allergens = dog.allergies ? dataStore.findRecords('allergies', dog.allergies) : []

        return {
          intolerances: allergens.map((allergen) => allergen.name),
        }
      }),
    })
  }

  initialState(): AllergiesStepData {
    return {
      dogs: [],
    }
  }

  prev(): Step {
    return usePreviouslyFedStep()
  }

  next(): Step {
    return useIllnessesStep()
  }
}

export const useAllergiesStep = registerStep(AllergiesStep)
