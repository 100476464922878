import { useApi } from './useApi'

interface RequestData {
  email: string
  firstName: string
  lastName: string
  postcode: string
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useNotifyUnservicedLocation = () => {
  const api = useApi()

  const submit = async ({ firstName, lastName, email, postcode }: RequestData): Promise<void> => {
    await api.post('marketing/buildabox/notifyUnservicedPostcode', {
      email,
      firstname: firstName,
      lastname: lastName,
      postcode,
    })
  }

  return {
    submit,
  }
}
