/// <reference types="@types/grecaptcha" />

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useRecaptcha = () => {
  if (!window.grecaptcha) {
    throw new Error('reCAPTCHA has not been loaded')
  }

  const getToken = async (): Promise<string> => {
    const token = await window.grecaptcha.execute(import.meta.env.VITE_RECAPTCHA_SITE_KEY, { action: 'checkout' })

    return token
  }

  return {
    getToken,
  }
}
