import { useBeenHereBeforeRequest } from './useBeenHereBeforeRequest'
import { useStepsStore } from '@/stores/steps'
import { getURLParam } from '@/utils/getURLParam'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useRecoverSession = () => {
  const customerHash = getURLParam('continue')

  const importData = async (customerHash: string): Promise<boolean> => {
    const { load } = useBeenHereBeforeRequest({ customerHash })

    return load()
  }

  const load = async (): Promise<boolean> => {
    if (customerHash) {
      const success = await importData(customerHash)

      if (success) {
        useStepsStore().goToLastStep()

        return true
      }
    }

    return false
  }

  return {
    load,
  }
}
