const formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  maximumFractionDigits: 2,
})

export const formatMoney = (dollars: number): string => {
  return (
    formatter
      .format(dollars)
      // Strip cents if there aren't any
      .replace(/\D00(?=\D*$)/, '')
  )
}
