declare global {
  interface Window {
    obApi?(eventType: 'track', eventName: string): void
  }
}

export enum OutbrainEventName {
  BAB_START = 'BAB Start',
  CONTACT_DETAILS_SUBMITTED = 'Pack Leader',
  CHECKOUT_COMPLETE = 'Checkout Complete',
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useOutbrain = () => {
  const track = (eventName: OutbrainEventName): void => {
    window.obApi?.('track', eventName)
  }

  return {
    track,
  }
}
