<script setup lang="ts">
import { computed, onMounted, onUnmounted } from 'vue'
import IconClose from './icon/Close.vue'
import LykaButton from './LykaButton.vue'

const props = withDefaults(
  defineProps<{
    title?: string
    closable?: boolean
    size: 'sm' | 'md' | 'lg'
  }>(),
  {
    open: true,
    closable: true,
  },
)

const emits = defineEmits<{
  (e: 'close'): void
}>()

const close = (): void => {
  emits('close')
}

const onBackdropMousedown = ({ target, currentTarget }: MouseEvent): void => {
  if (currentTarget instanceof Element && target instanceof Element && target.isSameNode(currentTarget)) {
    close()
  }
}

const onDocumentKeydown = (ev: KeyboardEvent): void => {
  if (props.closable && ev.key === 'Escape') {
    close()
  }
}

const sizeClassNames = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'tw-w-sm'
    case 'lg':
      return 'tw-max-w-5xl'
    default:
      return 'tw-max-w-2xl'
  }
})

onMounted(() => {
  document.body.style.overflowY = 'hidden'
  document.addEventListener('keydown', onDocumentKeydown)
})

onUnmounted(() => {
  document.body.style.overflowY = 'visible'
  document.removeEventListener('keydown', onDocumentKeydown)
})
</script>

<template>
  <dialog
    class="tw-fixed tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-40 tw-w-full tw-h-full tw-top-0 tw-z-40 tw-text-left tw-p-6 !tw-my-0"
    aria-modal="true"
    @mousedown="onBackdropMousedown"
  >
    <div
      class="tw-flex tw-flex-col tw-w-full tw-max-h-full tw-bg-cream tw-rounded-2xl tw-overflow-hidden tw-relative"
      :class="sizeClassNames"
    >
      <div v-if="closable" class="tw-absolute tw-top-4 tw-right-4 tw-z-50">
        <LykaButton basic size="xs" text="Close modal" class="modal-close" @click="close">
          <template #iconLeft>
            <IconClose class="tw-text-3xl" />
          </template>
        </LykaButton>
      </div>

      <header
        v-if="title"
        class="tw-flex tw-justify-between tw-items-center tw-h-16 tw-px-6 sm:tw-px-8 tw-bg-secondary tw-text-alt tw-shrink-0 tw-relative"
        :class="{ 'tw-pr-12': closable }"
      >
        <h4 class="tw-lead">
          {{ title }}
        </h4>
      </header>

      <div class="tw-text-alt tw-flex-1 tw-py-10 tw-px-6 sm:tw-px-8 tw-overflow-auto">
        <slot />
      </div>

      <div
        v-if="$slots.footer"
        class="tw-w-full shadow tw-py-4 tw-px-6 sm:tw-px-8 tw-flex tw-items-center tw-justify-between"
      >
        <slot name="footer" />
      </div>
    </div>
  </dialog>
</template>

<style lang="postcss">
.shadow {
  box-shadow: 2px 0px 10px 1px rgba(0, 0, 0, 0.05);
}
</style>
