import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { EventSender } from '../EventSender'
import type { Product } from '@/models/Product'
import type { Recipe } from '@/models/Recipe'

type EventData =
  | {
      type: 'treat'
      product: Product
    }
  | {
      type: 'recipe'
      product: Recipe
      recommended: boolean
    }

export const useProductAddedEvent: EventSender = ({ product, type, ...data }: EventData) => {
  const send = (): void => {
    const name = product.name
    const category = type
    const recommended = 'recommended' in data ? data.recommended : undefined

    useSegment().track('Product Added', {
      category,
      name,
      recommended,
    })
  }

  return {
    send,
  }
}

export const useProductRemovedEvent: EventSender = ({ product, type, ...data }: EventData) => {
  const send = (): void => {
    const name = product.name
    const category = type
    const recommended = 'recommended' in data ? data.recommended : undefined

    useSegment().track('Product Removed', {
      category,
      name,
      recommended,
    })
  }

  return {
    send,
  }
}
