<script lang="ts" setup>
import { computed } from 'vue'
import { LykaChip, LykaInput, LykaRadio } from '@lyka/ui'
import { type FoodType, getFoodTypeBrandable } from '@/models/FoodType'
import { useDogsStore } from '@/stores/dogs'
import type { PreviouslyFedStepData } from '@/steps/previouslyFed'
import StepHeading from '@/components/StepHeading.vue'

const props = defineProps<{
  dogIndex: number
  foodTypes: FoodType[]
  dog: PreviouslyFedStepData['dogs'][0]
}>()

const emits = defineEmits<{
  (e: 'update', dogIndex: number, data: typeof props.dog): void
}>()

const dogsStore = useDogsStore()

const foodTypesBrandable = computed<boolean>(() => {
  const selectedFoodTypes = props.foodTypes.filter((foodType) => {
    return props.dog.previouslyFed.foodTypes.includes(foodType.id)
  })

  return selectedFoodTypes.some((foodType) => {
    return getFoodTypeBrandable(foodType)
  })
})

const dogName = computed(() => {
  return dogsStore.dogName(props.dogIndex)
})

const dogFoodTypes = computed({
  get() {
    return props.dog.previouslyFed.foodTypes
  },
  set(value) {
    emits('update', props.dogIndex, {
      previouslyFed: {
        ...props.dog.previouslyFed,
        foodTypes: value,
      },
    })
  },
})

const dogFoodBrand = computed({
  get() {
    return props.dog.previouslyFed.foodBrand
  },
  set(value) {
    emits('update', props.dogIndex, {
      previouslyFed: {
        ...props.dog.previouslyFed,
        foodBrand: value,
      },
    })
  },
})

const dogFoodTypeUnknown = computed({
  get() {
    return !props.dog.previouslyFed.foodTypes?.length
  },
  set() {
    emits('update', props.dogIndex, {
      previouslyFed: {
        ...props.dog.previouslyFed,
        foodTypes: [],
      },
    })
  },
})
</script>

<template>
  <div class="tw-space-y-12">
    <div>
      <StepHeading> What has {{ dogName }} been eating? </StepHeading>
      <div class="tw-space-y-12">
        <div class="tw-flex tw-justify-center tw-flex-wrap tw-gap-1 tw-items-center">
          <LykaChip
            v-for="foodType in foodTypes"
            :key="foodType.id"
            v-model="dogFoodTypes"
            type="checkbox"
            size="lg"
            block
            :input-value="foodType.id"
            :name="`food-type-${dogIndex}`"
            >{{ foodType.text }}</LykaChip
          >
        </div>

        <LykaRadio
          v-model="dogFoodTypeUnknown"
          radio
          type="button"
          size="lg"
          block
          :input-value="true"
          :name="`food-type-${dogIndex}`"
          label="I'm not sure"
        />
      </div>
    </div>

    <div v-if="foodTypesBrandable">
      <div class="tw-mb-8">
        <h3 class="tw-h3">What brands of food?</h3>
        <p class="tw-font-light">Separate brands with a comma</p>
      </div>

      <LykaInput
        v-model="dogFoodBrand"
        :name="`food-brand-${dogIndex}`"
        placeholder="Enter brand of food (optional)"
        error-message="Please enter the previous brand of food"
      />
    </div>
  </div>
</template>
