<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useTrustpilot } from '@/composables/useTrustpilot'

const props = withDefaults(
  defineProps<{
    template?: string
    height?: number
    theme?: 'light' | 'dark'
  }>(),
  {
    height: 500,
    template: '539adbd6dec7e10e686debee',
    theme: 'light',
  },
)

const BUSINESS_UNIT_ID = import.meta.env.VITE_TRUSTPILOT_BUSINESSUNIT_ID
const BUSINESS_NAME = import.meta.env.VITE_TRUSTPILOT_BUSINESSUNIT_NAME

const trustbox = ref<HTMLElement>()

const textColor = computed(() => {
  if (props.theme === 'dark') {
    return '#FCFBF9'
  }

  return '#005648'
})

onMounted(async () => {
  const trustpilot = await useTrustpilot()

  if (trustpilot && trustbox.value) {
    trustpilot.loadFromElement(trustbox.value)
  }
})
</script>

<template>
  <div
    ref="trustbox"
    class="trustpilot-widget"
    data-locale="en-AU"
    :data-template-id="template"
    :data-businessunit-id="BUSINESS_UNIT_ID"
    :data-businessunit-name="BUSINESS_NAME"
    :data-style-height="`${height}px`"
    data-style-width="100%"
    :data-theme="theme"
    data-stars="5"
    data-review-languages="en"
    :data-text-color="textColor"
  >
    <a href="https://www.trustpilot.com/review/example.com" target="_blank" rel="noopener">Trustpilot</a>
  </div>
</template>
