import { useApi } from './useApi'

interface PostcodeData {
  message: 'Success' | string
  data:
    | []
    | {
        id: number
        postcode: number
        shippingZoneId: number
        onbaordingStatus: boolean
        notes?: string
        babVersionTwo: boolean
      }
  total?: number
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useVerifyPostcode = () => {
  const api = useApi()

  const verify = async (
    postcode: string,
  ): Promise<{
    valid: boolean
    dogs?: number
  }> => {
    try {
      const { data } = await api.get<PostcodeData>('shipping/postcodes', { postcode })

      return {
        valid: data.message === 'Success',
        dogs: data.total,
      }
    } catch (err) {
      return {
        valid: false,
      }
    }
  }

  return {
    verify,
  }
}
