import { z } from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type DogStep, Step } from './Step'
import { registerStep } from './stepRegistry'
import { useLoadingPlanStep } from './loadingPlan'
import { useAllergiesStep } from './allergies'
import { StepName } from '@/stores/steps'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { type DogData, useDogsStore } from '@/stores/dogs'
import { useDataStore } from '@/stores/data'
import { IllnessName } from '@/models/Illness'

const Schema = z.object({
  dogs: z
    .array(
      z.object({
        hasIllnesses: z.boolean(),
        unknownIllness: z.boolean(),
        illnesses: z.array(z.number().int()),
      }),
    )
    .min(1)
    .refine((dogs) => {
      // If the dog has illnesses, it must have at least one illness or an unknown illness to be valid
      return dogs.every((dog) => {
        const { hasIllnesses } = dog

        if (hasIllnesses) {
          return !!dog.illnesses.length || dog.unknownIllness
        }

        return dog.hasIllnesses === false
      })
    }),
})

export interface IllnessesStepData {
  dogs: {
    hasIllnesses: boolean | null
    unknownIllness: boolean
    illnesses: number[]
  }[]
}

type IllnessEventData = Array<{ healthIssues: IllnessName[] }>

const DEFAULT_VALUE = {
  hasIllnesses: null,
  unknownIllness: false,
  illnesses: [],
}

const getIllnessesEventData = (dogs: IllnessesStepData['dogs']): IllnessEventData => {
  const dataStore = useDataStore()

  return dogs.map((dog) => {
    if (dog.unknownIllness) {
      return {
        healthIssues: [IllnessName.Unkown],
      }
    } else {
      return {
        healthIssues: dataStore.findRecords('illnesses', dog.illnesses).map((record) => record.name),
      }
    }
  })
}

class IllnessesStep extends Step<IllnessesStepData> implements DogStep {
  name = StepName.Illnesses
  schema = Schema
  dogsStore = useDogsStore()

  title = 'Health Issues'

  constructor() {
    super()
    this.loadState()

    this.dogsStore.on('added', ({ illnesses = [], unknownIllness = false, hasIllnesses = false }) => {
      this.data.dogs.push({ illnesses, unknownIllness, hasIllnesses })
    })

    this.dogsStore.on('removed', (index) => {
      this.data.dogs.splice(index, 1)
    })

    this.dogsStore.on('loaded', (index) => {
      this.data.dogs[index] ??= { illnesses: [], unknownIllness: false, hasIllnesses: false }
    })

    this.dogsStore.on('illnessesRemoved', (index) => {
      this.data.dogs[index] = { illnesses: [], unknownIllness: false, hasIllnesses: false }
    })
  }

  onComplete(): void {
    super.onComplete()

    this.data.dogs.forEach((dog) => {
      useGTM().sendAction(GTMEventAction.PROVIDED_ILLNESSES, dog.illnesses.length)
    })

    useSegment().track('Health Issues List Completed', {
      dogs: getIllnessesEventData(this.data.dogs),
    })
  }

  initialState(): IllnessesStepData {
    return {
      dogs: [],
    }
  }

  addDog({ hasIllnesses = null, illnesses = [], unknownIllness = false }: DogData = {}): void {
    this.data.dogs.push({ hasIllnesses, illnesses, unknownIllness })
  }

  removeDog(index: number): void {
    this.data.dogs.splice(index, 1)
  }

  loadDog(index: number): void {
    this.data.dogs[index] ??= { ...DEFAULT_VALUE }
  }

  getValid(): boolean {
    return super.getValid()
  }

  prev(): Step {
    return useAllergiesStep()
  }

  next(): Step {
    return useLoadingPlanStep()
  }
}

export const useIllnessesStep = registerStep(IllnessesStep)
