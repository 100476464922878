<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    class="tw-fill-current tw-w-[1em] tw-h-[1em]"
  >
    <path
      fill-rule="evenodd"
      d="M7.293.293a1 1 0 0 1 1.414 0l7 7a1 1 0 0 1 0 1.414l-7 7a1 1 0 0 1-1.414-1.414L12.586 9H1a1 1 0 1 1 0-2h11.586L7.293 1.707a1 1 0 0 1 0-1.414Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
