import { z } from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { Step } from './Step'
import { useRecipesStep } from './recipes'
import { registerStep } from './stepRegistry'
import { useLoadingPlanStep } from './loadingPlan'
import { useCheckoutStep } from './checkout'
import { StepName } from '@/stores/steps'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { OutbrainEventName, useOutbrain } from '@/composables/useOutbrain'
import { useUserStore } from '@/stores/user'

const Schema = z.object({
  user: z.object({
    firstName: z.string().trim().min(1),
    email: z.string().email(),
    marketingCommunication: z.boolean(),
  }),
})

export type ContactDetailsStepData = z.infer<typeof Schema>

class ContactDetailsStep extends Step<ContactDetailsStepData> {
  name = StepName.ContactDetails
  schema = Schema
  userStore = useUserStore()
  title = 'About You'
  showSteps = false

  constructor() {
    super()
    this.loadState()

    this.userStore.on('added', ({ firstName = '', email = '' }) => {
      Object.assign(this.data.user, {
        firstName,
        email,
      })
    })
  }

  onComplete(): void {
    super.onComplete()
    const eventLabel = this.data.user.marketingCommunication ? 'opted_in' : 'opted_out'

    useGTM().sendAction(GTMEventAction.PROVIDED_PERSONAL_DETAILS, eventLabel)
    useOutbrain().track(OutbrainEventName.CONTACT_DETAILS_SUBMITTED)

    useSegment().identify({ firstName: this.data.user.firstName, email: this.data.user.email })
  }

  initialState(): ContactDetailsStepData {
    return {
      user: {
        firstName: '',
        email: '',
        marketingCommunication: true,
      },
    }
  }

  update(data: ContactDetailsStepData): void {
    super.update(data)

    const checkout = useCheckoutStep()

    // Sync these values with those in the checkout step
    checkout.data.user.email = data.user.email
    checkout.data.user.firstName = data.user.firstName
  }

  prev(): Step {
    return useLoadingPlanStep().prev()
  }

  next(): Step {
    return useRecipesStep()
  }
}

export const useContactDetailsStep = registerStep(ContactDetailsStep)
