import flagsmith from 'flagsmith'
import type { IFlags } from 'flagsmith/types'
import { ref } from 'vue'

const init = async (anonymousOrUserId?: string): Promise<void> => {
  // https://docs.flagsmith.com/clients/javascript
  await flagsmith.init({
    environmentID: import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_KEY,
    identity: anonymousOrUserId,
  })
}

const EXPERIMENT_FLAG_PREFIX = 'experiment-'

export const experimentsFromFlags = (flags: IFlags<string>): Record<string, string> => {
  return Object.entries(flags).reduce<Record<string, string>>((experiments, [flagName, { value, enabled }]) => {
    if (flagName.startsWith(EXPERIMENT_FLAG_PREFIX) && enabled && typeof value === 'string') {
      const experimentName = flagName.replace(EXPERIMENT_FLAG_PREFIX, '')

      if (experimentName) {
        experiments[experimentName] = value
      }
    }

    return experiments
  }, {})
}

const initialized = ref(false)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFlagsmith = () => {
  const loadFlags = async (anonymousOrUserId?: string): Promise<IFlags<string>> => {
    if (!initialized.value) {
      await init(anonymousOrUserId)
      initialized.value = true
    }

    return flagsmith.getAllFlags()
  }

  const getFlags = (): IFlags => {
    return flagsmith.getAllFlags()
  }

  const isEnabled = (flagName: string): boolean => {
    return getFlags()[flagName]?.enabled ?? false
  }

  return {
    loadFlags,
    getFlags,
    isEnabled,
  }
}
