<script setup lang="ts">
import LykaCard from '@lyka/ui/components/LykaCard.vue'
import { computed } from 'vue'
import LocationDogsInPostcodeFooter from './LocationDogsInPostcodeFooter.vue'
import TickCircleFilledIcon from '@/components/icons/TickCircleFilledIcon.vue'

const props = defineProps<{
  postcode: string
  numberOfDogs?: number
  localities: string[]
}>()

const showDogNumbers = computed(() => {
  if (Number.isInteger(props.numberOfDogs) && props.numberOfDogs! >= 15) {
    return true
  }

  return false
})
</script>

<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-h-full tw-space-y-8 tw-text-center">
    <div>
      <h2 class="tw-h3">
        <strong class="tw-font-bold tw-block">Great News!</strong>
        <span class="tw-font-bold">Lyka is available</span> in your Area
      </h2>

      <LykaCard class="tw-w-full tw-mt-16 tw-relative" background-color="secondary">
        <div class="tw-absolute tw-left-1/2 -tw-top-4 -tw-translate-x-1/2 -tw-translate-y-1/2">
          <TickCircleFilledIcon />
        </div>

        <div class="tw-h4">{{ postcode }}</div>

        <div v-if="localities.length">
          {{ localities.slice(0, 3).join(', ') }}
          {{ localities.length > 3 ? ` &amp; more` : '' }}
        </div>
      </LykaCard>
    </div>

    <LocationDogsInPostcodeFooter v-if="showDogNumbers" :number-of-dogs="numberOfDogs!" />
  </div>
</template>
