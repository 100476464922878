<script setup lang="ts">
import { computed } from 'vue'
import { useProductPrice } from '@/composables/useProductPrice'
import type { MealPlan } from '@/models/MealPlan'
import { formatMoney } from '@/utils/formatMoney'

const props = defineProps<{
  mealPlan: MealPlan
}>()

const { isProductDiscounted, getDiscountedPrice } = useProductPrice()

const totalPrice = computed(() => {
  return props.mealPlan.totalPrice
})

const discounted = computed(() => {
  return isProductDiscounted('meals')
})

const discountedTotalPrice = computed(() => {
  return getDiscountedPrice('meals', props.mealPlan.totalPrice)
})

const primaryPrice = computed(() => {
  if (props.mealPlan.type === 'starter') {
    return `${formatMoney(discountedTotalPrice.value)} total`
  }

  return `${formatMoney(props.mealPlan.priceDaily)}/day`
})

const secondaryPrice = computed<string>(() => {
  // This is messy but is just for the experiment
  if (props.mealPlan.type === 'starter') {
    if (discounted.value) {
      return `Was ${formatMoney(totalPrice.value)}`
    }

    return ''
  } else if (discounted.value) {
    return `<s>${formatMoney(totalPrice.value)}</s> ${formatMoney(discountedTotalPrice.value)} total`
  } else {
    return `${formatMoney(discountedTotalPrice.value)} total`
  }
})
</script>

<template>
  <div class="price">
    <div class="price-primary" v-html="primaryPrice"></div>
    <div class="" v-html="secondaryPrice"></div>
  </div>
</template>

<style>
.price {
  @apply tw-flex tw-justify-between tw-items-center tw-gap-2 tw-whitespace-nowrap;
  @apply md:tw-flex-col;
}

.price-primary {
  @apply tw-text-lg tw-font-stylized tw-leading-tighter tw-align-baseline;
  @apply sm:tw-text-3xl;
  @apply lg:tw-text-5xl;
}

.price-secondary {
  @apply tw-text-sm;
}
</style>
