<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import { LykaFieldList, LykaInput } from '@lyka/ui'
import { clone } from 'remeda'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import StepContainer from '../StepContainer.vue'
import StepNavigation from '../StepNavigation.vue'
import MarketingConsentCheckbox from '../MarketingConsentCheckbox.vue'
import type { ContactDetailsStepData } from '@/steps/contactDetails'
import { useDogsStore } from '@/stores/dogs'
import { useEmailCaptured } from '@/composables/useEmailCaptured'
import ContactBenefits from '@/components/steps/contact/ContactBenefits.vue'

const props = defineProps<{
  stepData: ContactDetailsStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const data = reactive(clone(props.stepData))
const dogsStore = useDogsStore()
const loading = ref(false)

watch(data, (newData) => emits('save', newData), { deep: true })

const heading = computed(() => {
  const [first, second] = dogsStore.dogNames
  const count = dogsStore.dogCount

  switch (count) {
    case 1:
      return `${first}'s tailored meal plan is ready!`
    case 2:
      return `${first} and ${second}'s tailored meal plans are ready'`
    default:
      return "Your puppers' meal plans are ready"
  }
})

const captureEmail = async (): Promise<void> => {
  if (data.user.marketingCommunication) {
    await useEmailCaptured().subscribe(data.user.email, data.user.firstName)
  } else {
    await useEmailCaptured().unsubscribe(data.user.email, data.user.firstName)
  }
}

const disabled = computed(() => !props.valid || loading.value)

const submit = async ({ target }: Event): Promise<void> => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  try {
    loading.value = true
    await captureEmail()
  } finally {
    loading.value = false
  }

  emits('submit', data)
}
</script>

<template>
  <StepContainer size="lg" center>
    <div class="tw-flex 2xl:tw-items-center tw-gap-10 tw-flex-col-reverse lg:tw-flex-row">
      <ContactBenefits />
      <div class="tw-flex-1 tw-text-left">
        <div class="tw-max-w-sm tw-mx-auto">
          <div class="tw-flex tw-mb-8 sm:tw-mb-12 tw-justify-center">
            <img
              src="@/assets/images/steps/contact-details/box.svg"
              alt="A Lyka box exploding with delicious dog food"
              width="327"
              height="179"
              class="tw-h-32 sm:tw-h-40"
            />
          </div>

          <form id="formContact" v-submit class="tw-space-y-6 sm:tw-space-y-8" @submit.prevent="submit">
            <div class="tw-space-y-2 sm:tw-space-y-8">
              <h2 id="contact-details-heading" class="tw-h2">
                {{ heading }}
              </h2>
              <p>Enter your details to see your tailored plan and pricing</p>
            </div>

            <div class="tw-space-y-8">
              <LykaFieldList>
                <LykaInput
                  v-model.trim="data.user.firstName"
                  name="first-name"
                  placeholder="First name"
                  required
                  error-message="Your first name is required"
                  autocapitalize
                />
                <LykaInput
                  v-model.trim="data.user.email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  required
                  error-message="Your email is required"
                />
              </LykaFieldList>

              <MarketingConsentCheckbox v-model="data.user.marketingCommunication" />
            </div>

            <StepNavigation
              submit
              :next-disabled="disabled"
              form="formContact"
              :next-loading="loading"
              @previous="emits('previous')"
            />
          </form>
        </div>
      </div>
    </div>
  </StepContainer>
</template>
