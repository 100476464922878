import type { App, Plugin } from 'vue'

export const recaptchaPlugin: Plugin = {
  install(_app: App, { siteKey }: { siteKey: string }) {
    const script = Object.assign(document.createElement('script'), {
      src: `https://www.google.com/recaptcha/api.js?render=${siteKey}`,
    })

    document.head.appendChild(script)
  },
}
