<script lang="ts" setup>
import { onMounted } from 'vue'

const emits = defineEmits<{
  (e: 'shown'): void
}>()

onMounted(() => {
  emits('shown')
})
</script>

<template>
  <div
    class="tw-text-sm tw-text-left tw-absolute tw-top-0 tw-w-full tw-bg-[#F4EEE3] tw-shadow-md tw-p-6 tw-z-10 tw-space-y-3 tw-rounded-xl tw-animate-slide-down"
  >
    <slot />
  </div>
</template>
