// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useTitle = () => {
  const initialTitle = document.title

  const update = (title: string): void => {
    if (title) {
      document.title = `${title} | ${initialTitle}`
    } else {
      document.title = initialTitle
    }
  }

  return {
    update,
  }
}
