// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useWindowPosition = () => {
  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return {
    scrollToTop,
  }
}
