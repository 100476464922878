<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import { clone } from 'remeda'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import StepNavigation from '../StepNavigation.vue'
import StepsContainer from '@/components/StepContainer.vue'
import { useDataStore } from '@/stores/data'
import type { IllnessesStepData } from '@/steps/illnesses'
import { useDogsStore } from '@/stores/dogs'
import IllnessesListDog from '@/components/steps/illnesses/IllnessesListDog.vue'

const props = defineProps<{
  stepData: IllnessesStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const dataStore = useDataStore()
const data = reactive(clone(props.stepData))
const dogsStore = useDogsStore()

watch(
  () => data,
  (newData) => emits('save', newData),
  { deep: true },
)

const illnesses = computed(() => dataStore.illnesses)

const disabled = computed(() => !props.valid)

const onIllnessesUpdated = (dogIndex: number, illnessData: (typeof props.stepData.dogs)[0]): void => {
  data.dogs[dogIndex] = illnessData
  dogsStore.emit('illnessesUpdated', dogIndex)
}

const submit = ({ target }: Event): void => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  emits('submit', data)
}
</script>

<template>
  <StepsContainer>
    <form id="formIllness" v-submit class="tw-space-y-8" @submit.prevent="submit">
      <IllnessesListDog
        v-for="(dog, index) in data.dogs"
        :key="index"
        :dog-index="index"
        :illnesses="illnesses"
        :dog="dog"
        @update="onIllnessesUpdated"
      />

      <StepNavigation submit form="formIllness" :next-disabled="disabled" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
