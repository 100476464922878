<script setup lang="ts">
import { computed } from 'vue'
import { useProductPrice } from '@/composables/useProductPrice'
import type { MealPlan } from '@/models/MealPlan'
import { formatMoney } from '@/utils/formatMoney'

const props = defineProps<{
  mealPlan: MealPlan
  multipleDogs: boolean
  hideStarterDailyPrice?: boolean
}>()

const { isProductDiscounted, getDiscountedPrice, discount } = useProductPrice()

const totalPrice = computed(() => {
  return props.mealPlan.totalPrice
})

const discounted = computed(() => {
  return isProductDiscounted('meals')
})

const discountedTotalPrice = computed(() => {
  return getDiscountedPrice('meals', props.mealPlan.totalPrice)
})

const primaryPrice = computed(() => {
  if (props.mealPlan.type === 'starter' && props.hideStarterDailyPrice) {
    return `Total ${formatMoney(discountedTotalPrice.value)}`
  }

  return `${formatMoney(props.mealPlan.priceDaily)}/day`
})

const secondaryPrice = computed<string>(() => {
  // This is messy but is just for the experiment
  if (props.mealPlan.type === 'starter' && props.hideStarterDailyPrice) {
    if (discounted.value) {
      return `Was ${formatMoney(totalPrice.value)}`
    }

    return ''
  } else if (discounted.value) {
    return `Total: <s>${formatMoney(totalPrice.value)}</s> ${formatMoney(discountedTotalPrice.value)}`
  } else {
    return `Total: ${formatMoney(discountedTotalPrice.value)}`
  }
})
</script>

<template>
  <div class="tw-flex tw-flex-col tw-text-p1-t tw-font-light tw-mb-2 tw-space-y-2">
    <div v-if="discounted" class="tw-text-sm tw-opacity-80">{{ discount }}% off your first box</div>
    <div class="tw-text-5xl tw-font-stylized tw-leading-tighter tw-align-baseline" v-html="primaryPrice"></div>
    <div v-html="secondaryPrice"></div>
    <div v-if="multipleDogs" class="tw-text-sm tw-opacity-80">For all your dogs</div>
  </div>
</template>
