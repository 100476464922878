import { type WatchStopHandle, reactive, ref, toRaw, watch } from 'vue'
import type { Schema } from 'zod'
import merge from 'lodash.merge'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { useStateStore } from '@/stores/state'
import type { StepName } from '@/stores/steps'
import type { DogData } from '@/stores/dogs'

export type ModifySchema<T, R> = Omit<T, keyof R> & R

export interface DogStep extends Step {
  addDog(dogData: DogData): void
  removeDog(index: number): void
  loadDog(index: number): void
}

export abstract class Step<InputData extends object = object> {
  abstract name: StepName
  abstract schema?: Schema
  abstract initialState(): InputData
  abstract title: string

  /*
    Whether the step has been removed from the BAB flow.
    We may want to keep a step in code in case we need to migrate existing user state.
  */
  removed = false
  showSteps = true
  showCouponBanner = false
  persistCouponBanner = false

  state = useStateStore()
  data = reactive<InputData>({} as InputData)
  watcher?: WatchStopHandle
  valid = ref(false)

  onStart(): void {
    useSegment().track('Checkout Step Viewed', {
      stepName: this.name,
    })
  }

  onComplete(): void {
    useSegment().track('Checkout Step Completed', {
      stepName: this.name,
    })
  }

  history = true

  abstract next(): Step | undefined
  abstract prev(): Step | undefined

  constructor() {
    this.loadState()

    this.state.on('imported', () => this.loadState())

    this.state.on('reset', () => this.resetState())

    watch(
      () => this.data,
      () => this.saveState(),
      { deep: true, immediate: false },
    )

    this.validate = this.validate.bind(this)

    watch(
      () => this.data,
      () => this.validate(),
      { deep: true, immediate: true },
    )
  }

  resetState(): void {
    Object.assign(this.data, this.initialState())
  }

  update(data: Partial<InputData>): void {
    Object.assign(this.data, data)
    this.validate()
  }

  saveState(): void {
    if (!this.removed) {
      this.state.saveStepState(this.name, this.data)
    }
  }

  getSavedState(): Partial<InputData> {
    return this.state.getStepState(this.name) as Partial<InputData>
  }

  loadState(): void {
    Object.assign(this.data, merge(this.initialState(), this.data, this.getSavedState()))
  }

  validate(): void {
    this.valid.value = this.getValid()
  }

  getValid(): boolean {
    if (this.schema) {
      const result = this.schema.safeParse(toRaw(this.data))

      return result.success
    }

    return true
  }

  isSameStep(step?: Step): boolean {
    if (!step) {
      return false
    }

    return step.name === this.name
  }
}
