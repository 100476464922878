import { type Component, computed, shallowRef } from 'vue'

interface ConfirmationWithComponent {
  title?: undefined
  message?: undefined
  component: Component
}

interface ConfirmationWithDefault {
  component?: undefined
  title: string
  message: string
}

type Confirmation = (ConfirmationWithComponent | ConfirmationWithDefault) & {
  confirm?: string
  decline?: string
}

const confirmation = shallowRef<Confirmation>()

let resolve: ((value: boolean) => void) | undefined

export const reset = (): void => {
  confirmation.value = undefined
  resolve = undefined
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useConfirm = () => {
  const active = computed<boolean>(() => !!confirmation.value)

  const show = async (props: Confirmation): Promise<boolean> => {
    confirmation.value = props

    const result = await new Promise<boolean>((_resolve) => {
      resolve = _resolve
    })

    return result
  }

  const confirm = (): void => {
    resolve?.(true)
    reset()
  }

  const decline = (): void => {
    resolve?.(false)
    reset()
  }

  return {
    show,
    confirm,
    decline,
    active,
    confirmation,
  }
}
