<script lang="ts" setup>
import type { ButtonHTMLAttributes, ConcreteComponent } from 'vue'
import { computed } from 'vue'
import { createElementId } from '../utils/createElementId'
import LykaSpinner from './LykaSpinner.vue'

const props = withDefaults(
  defineProps<{
    id?: string
    href?: string
    blank?: boolean
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    textSize?: 'xs' | 'sm' | 'md' | 'lg'
    variant?: 'primary' | 'secondary' | 'alt' | 'alt-dark' | 'subtle' | 'success' | 'danger' | 'cream'
    disabled?: boolean
    disabledVariant?: 'primary' | 'secondary'
    outline?: boolean
    square?: boolean
    rounded?: 'full' | 'none' | 'left' | 'right'
    type?: ButtonHTMLAttributes['type']
    block?: boolean
    blockAt?: 'none' | 'sm' | 'md' | 'lg'
    hideAt?: 'none' | 'sm' | 'md' | 'lg'
    basic?: boolean
    textLink?: boolean
    decoration?: boolean
    loading?: boolean
    srOnly?: string
    autoWidth?: boolean
    form?: string
    transparent?: boolean
    is?: string | ConcreteComponent
  }>(),
  {
    size: 'md',
    variant: 'primary',
    disabled: false,
    disabledVariant: 'primary',
    outline: false,
    square: false,
    rounded: 'full',
    type: 'button',
    autoWidth: true,
    blockAt: 'none',
    hideAt: 'none',
  },
)

const emits = defineEmits<{
  (e: 'click', event: MouseEvent): void
}>()

const inputId = props.id ? props.id : createElementId()
const buttonInactive = computed(() => props.disabled || props.loading)
const buttonBg = computed(() => {
  if (props.basic) {
    return ''
  }
  if (buttonInactive.value && props.disabledVariant === 'secondary') {
    return 'tw-bg-neutral'
  }
  if (props.outline) {
    return `tw-btn--${props.variant}-outline`
  }
  return `tw-btn--${props.variant}`
})

const buttonText = computed(() => {
  if (props.textLink) {
    return `tw-text-${props.variant} tw-fill-current`
  }

  return ''
})

const buttonHover = computed(() => {
  if (buttonInactive.value) {
    return ''
  }
  if (props.basic || props.textLink) {
    return `tw-btn--${props.variant}-text-hover`
  }
  return props.outline ? `tw-btn--${props.variant}-outline-hover` : `tw-btn--${props.variant}-hover`
})

const buttonHeight = computed(() => {
  if (props.basic || props.textLink) {
    return ''
  }
  switch (props.size) {
    case 'xs': {
      return 'tw-global-height--xsmall'
    }
    case 'sm': {
      return 'tw-global-height--small'
    }
    case 'lg': {
      return 'tw-global-height--large'
    }
    case 'xl': {
      return 'tw-global-height--xlarge'
    }
    default: {
      return 'tw-global-height'
    }
  }
})

const buttonTextSize = computed(() => {
  if (props.textSize) {
    return `tw-text-${props.textSize}`
  }

  switch (props.size) {
    case 'xs': {
      return 'tw-text-sm'
    }
    default: {
      return 'tw-text-base'
    }
  }
})

const buttonAutoWidth = computed(() => props.autoWidth || props.square)
const buttonWidth = computed(() => {
  switch (props.blockAt) {
    case 'lg': {
      return buttonAutoWidth.value ? 'tw-min-w-full lg:tw-min-w-min' : 'tw-min-w-full lg:tw-min-w-56'
    }
    case 'md': {
      return buttonAutoWidth.value ? 'tw-min-w-full md:tw-min-w-min' : 'tw-min-w-full md:tw-min-w-56'
    }
    case 'sm': {
      return buttonAutoWidth.value ? 'tw-min-w-full sm:tw-min-w-min' : 'tw-min-w-full sm:tw-min-w-56'
    }
    default: {
      return buttonAutoWidth.value ? 'tw-min-w-min' : 'tw-min-w-56'
    }
  }
})

const buttonRadius = computed(() => {
  if (props.basic || props.textLink) {
    return ''
  }
  switch (props.rounded) {
    case 'none': {
      return ''
    }
    case 'left': {
      return 'tw-rounded-l-full'
    }
    case 'right': {
      return 'tw-rounded-r-full'
    }
    default: {
      return 'tw-rounded-full'
    }
  }
})

const buttonHideAt = computed(() => {
  switch (props.hideAt) {
    case 'lg': {
      return 'tw-hidden lg:tw-inline-flex'
    }
    case 'md': {
      return 'tw-hidden md:tw-inline-flex'
    }
    case 'sm': {
      return 'tw-hidden sm:tw-inline-flex'
    }
    default: {
      return 'tw-inline-flex'
    }
  }
})

const buttonPadding = computed(() => {
  if (props.square || props.basic || props.textLink) {
    return ''
  }

  switch (props.size) {
    case 'xs': {
      return 'tw-px-4'
    }
    case 'xl': {
      return 'tw-px-8'
    }
    default: {
      return 'tw-px-6'
    }
  }
})

const tagName = computed(() => {
  if (props.is) {
    return props.is
  }

  if (props.href) {
    return 'a'
  }

  return 'button'
})

const handleClick = (ev: PointerEvent): void => {
  emits('click', ev)
}

const buttonAspect = computed(() => (props.square ? 'tw-aspect-square' : ''))

const buttonDisabled = computed(() => {
  if (buttonInactive.value) {
    if (props.disabledVariant === 'secondary') {
      return 'tw-cursor-not-allowed tw-text-semidark-gray'
    }
    return 'tw-opacity-60 tw-cursor-not-allowed'
  }
  return 'tw-cursor-pointer'
})

const buttonBlock = computed(() => (props.block ? 'tw-flex tw-w-full' : ''))
</script>

<template>
  <component
    :is="tagName"
    :id="inputId"
    :type="tagName !== 'a' ? type : undefined"
    :formtarget="tagName === 'button' && blank ? '_blank' : undefined"
    :target="tagName === 'a' && blank ? '_blank' : undefined"
    :href="href"
    class="tw-btn"
    :class="[
      buttonDisabled,
      buttonPadding,
      buttonRadius,
      buttonHeight,
      buttonAspect,
      buttonHideAt,
      buttonWidth,
      buttonHover,
      buttonBlock,
      buttonText,
      !textLink && buttonBg,
      { 'tw-btn--transparent': transparent },
    ]"
    :form="form"
    :disabled="disabled"
    v-bind="$attrs"
    @click="handleClick"
  >
    <!-- slot -->
    <span class="tw-btn-inner" :class="[{ 'tw-space-x-2': $slots.default }]">
      <LykaSpinner v-if="loading" size="sm" :variant="variant" :outline="outline" class="tw-absolute" />

      <slot v-if="!loading" name="iconLeft" />
      <span :class="[buttonTextSize, { 'tw-opacity-0': loading }]">
        <slot />
      </span>
      <slot v-if="!loading" name="iconRight" />
    </span>

    <span v-if="srOnly" class="tw-sr-only">{{ srOnly }}</span>
    <!-- textlink decoration -->
    <span
      v-if="textLink && decoration"
      class="tw-w-full tw-h-px tw--mt-0.5 tw-text-secon"
      :class="textLink && buttonBg"
    />
  </component>
</template>

<style lang="postcss">
.tw-btn {
  @apply tw-flex-col tw-items-center tw-justify-center tw-outline-none tw-relative tw-overflow-hidden tw-select-none tw-text-base tw-transition focus:tw-ring-0;
}
.tw-btn-inner {
  @apply tw-flex tw-items-center tw-justify-center tw-translate-y-0 group-hover:tw--translate-y-11 group-focus:tw--translate-y-11 tw-whitespace-nowrap;
}

.tw-btn--primary {
  @apply tw-bg-primary tw-text-light;
}
.tw-btn--primary-outline {
  @apply tw-border tw-border-primary tw-text-primary;
}
.tw-btn--primary-hover {
  @apply hover:tw-bg-primary-hover focus:tw-bg-primary-hover;
}
.tw-btn--primary-outline-hover {
  @apply tw-btn--primary-hover hover:tw-text-light focus:tw-text-light;
}
.tw-btn--primary-text-hover {
  @apply hover:tw-text-primary-hover focus:tw-text-primary-hover;
}

.tw-btn--secondary {
  @apply tw-bg-secondary tw-text-dark;
}
.tw-btn--secondary-outline {
  @apply tw-border tw-border-secondary tw-text-secondary;
}
.tw-btn--secondary-hover {
  @apply hover:tw-bg-secondary-hover focus:tw-bg-secondary-hover;
}
.tw-btn--secondary-outline-hover {
  @apply tw-btn--secondary-hover hover:tw-text-dark focus:tw-text-dark;
}
.tw-btn--secondary-text-hover {
  @apply hover:tw-text-secondary-hover focus:tw-text-secondary-hover;
}

.tw-btn--alt {
  @apply tw-bg-alt tw-text-light;
}
.tw-btn--alt-outline {
  @apply tw-border tw-border-alt tw-text-alt;
}
.tw-btn--alt-hover {
  @apply hover:tw-bg-alt-hover focus:tw-bg-alt-hover;
}
.tw-btn--alt-outline-hover {
  @apply tw-btn--alt-hover hover:tw-text-light focus:tw-text-light;
}
.tw-btn--alt-text-hover {
  @apply hover:tw-text-alt-hover focus:tw-text-alt-hover;
}

.tw-btn--alt-dark {
  @apply tw-bg-alt-hover tw-text-light;
}
.tw-btn--alt-dark-outline {
  @apply tw-border tw-border-alt-hover tw-text-alt-hover;
}
.tw-btn--alt-dark-hover {
  @apply hover:tw-bg-alt focus:tw-bg-alt;
}
.tw-btn--alt-dark-outline-hover {
  @apply tw-btn--alt hover:tw-text-light focus:tw-text-light;
}
.tw-btn--alt-dark-text-hover {
  @apply hover:tw-text-alt focus:tw-text-alt;
}

.tw-btn--subtle {
  @apply tw-bg-white tw-text-alt tw-border tw-border-gold;
}
.tw-btn--subtle-outline {
  @apply tw-border tw-border-subtle tw-text-subtle;
}
.tw-btn--subtle-hover {
  @apply hover:tw-border-alt focus:tw-border-alt;
}
.tw-btn--subtle-outline-hover {
  @apply tw-btn--subtle-hover hover:tw-text-dark focus:tw-text-dark;
}
.tw-btn--subtle-text-hover {
  @apply hover:tw-text-subtle-hover focus:tw-text-subtle-hover;
}

.tw-btn--success {
  @apply tw-bg-success tw-text-light;
}
.tw-btn--success-outline {
  @apply tw-border tw-border-success tw-text-success;
}
.tw-btn--success-hover {
  @apply hover:tw-bg-success-hover focus:tw-bg-success-hover;
}
.tw-btn--success-outline-hover {
  @apply tw-btn--success-hover hover:tw-text-light focus:tw-text-light;
}
.tw-btn--success-text-hover {
  @apply hover:tw-text-success-hover focus:tw-text-success-hover;
}

.tw-btn--danger {
  @apply tw-bg-danger tw-text-light;
}
.tw-btn--danger-outline {
  @apply tw-border tw-border-danger tw-text-danger;
}
.tw-btn--danger-hover {
  @apply hover:tw-bg-danger-hover focus:tw-bg-danger-hover;
}
.tw-btn--danger-outline-hover {
  @apply tw-btn--danger-hover hover:tw-text-light focus:tw-text-light;
}
.tw-btn--danger-text-hover {
  @apply hover:tw-text-danger-hover focus:tw-text-danger-hover;
}
.tw-btn--transparent {
  @apply tw-bg-transparent hover:tw-bg-transparent tw-border-0;
  @apply hover:tw-text-success focus:tw-text-success;
}

.tw-btn--cream {
  @apply tw-bg-cream tw-text-semidark-gray;
}
</style>
