export const withTimeout = <T>(promise: () => Promise<T>, timeout: number): Promise<T | undefined> => {
  return new Promise<T>((resolve, reject) => {
    const id = setTimeout(() => {
      reject(new Error('Timed out'))
    }, timeout)

    promise()
      .then((result) => {
        clearTimeout(id)
        resolve(result)
      })
      .catch((error) => {
        clearTimeout(id)
        reject(error)
      })
  })
}
