import { useBreakpoints } from '@lyka/ui'
import type { Directive } from 'vue'

export const submitDirective: Directive = {
  mounted(el: HTMLFormElement) {
    el.addEventListener('keydown', (ev) => {
      // Prevent `Enter` from automatically submitting the form on small screens
      if (ev.key === 'Enter' && !useBreakpoints().value.md) {
        ev.preventDefault()
      }
    })
  },
}
