<script setup lang="ts">
import { assetUrl } from '@lyka/utils'
import type { Recipe } from '@/models/Recipe'
import type { Ingredient, IngredientAggregate } from '@/models/Ingredient'

defineProps<{
  recipe: Recipe
}>()

const ingredientImage = (ingredient: Ingredient | IngredientAggregate): string => {
  if ('imageReference' in ingredient) {
    return assetUrl(`images/ingredients/${ingredient.imageReference}`)
  }

  return assetUrl(`images/ingredients/${ingredient.ingredient.imageReference}`)
}

const ingredientName = (ingredient: Ingredient | IngredientAggregate): string => {
  if ('name' in ingredient) {
    return ingredient.name
  }

  return ingredient.ingredient.name
}

enum IngredientAttribute {
  GrassFed = 'grass_fed',
  FreeRange = 'free_range',
  BarnRaised = 'barn_raised',
}

const ingredientHasAttribute = (
  ingredient: Ingredient | IngredientAggregate,
  attribute: IngredientAttribute,
): boolean => {
  return 'humanGrade' in ingredient && ingredient.humanGrade.some(({ name }) => attribute === name)
}

const isGrassFed = (ingredient: Ingredient | IngredientAggregate): boolean => {
  return ingredientHasAttribute(ingredient, IngredientAttribute.GrassFed)
}

const isFreeRange = (ingredient: Ingredient | IngredientAggregate): boolean => {
  return ingredientHasAttribute(ingredient, IngredientAttribute.FreeRange)
}

const isBarnRaised = (ingredient: Ingredient | IngredientAggregate): boolean => {
  return ingredientHasAttribute(ingredient, IngredientAttribute.BarnRaised)
}

const grassFedImage = assetUrl(`images/ingredients/icons/human-grade/${IngredientAttribute.GrassFed}.svg`)
const freeRangeImage = assetUrl(`images/ingredients/icons/human-grade/${IngredientAttribute.FreeRange}.svg`)
const barnRaisedImage = assetUrl(`images/ingredients/icons/human-grade/${IngredientAttribute.BarnRaised}.svg`)
</script>

<template>
  <div class="tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-4 tw-gap-x-4 tw-gap-y-4">
    <div
      v-for="ingredient in recipe.ingredients"
      :key="ingredient.id"
      class="tw-text-center tw-flex tw-flex-col tw-gap-2"
    >
      <div class="tw-flex tw-justify-center tw-items-center tw-relative tw-w-auto tw-mx-auto">
        <img :src="ingredientImage(ingredient)" :alt="ingredientName(ingredient)" width="90" />
        <img
          v-if="isGrassFed(ingredient)"
          :src="grassFedImage"
          alt="Grass fed"
          class="tw-absolute tw-top-0 tw-right-0"
          title="Grass fed"
        />
        <img
          v-else-if="isFreeRange(ingredient)"
          :src="freeRangeImage"
          alt="Free range"
          class="tw-absolute tw-top-0 tw-right-0"
          title="Free range"
        />
        <img
          v-else-if="isBarnRaised(ingredient)"
          :src="barnRaisedImage"
          alt="Barn raised"
          class="tw-absolute tw-top-0 tw-right-0"
          title="Barn raised"
        />
      </div>
      <h5 class="tw-h5">
        {{ ingredientName(ingredient) }}
      </h5>
    </div>
  </div>
</template>
