<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import { clone } from 'remeda'
import { LykaFieldList, LykaRadio } from '@lyka/ui'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import StepNavigation from '../StepNavigation.vue'
import MiniHr from '../common/MiniHr.vue'
import StepHeading from '../StepHeading.vue'
import StepsContainer from '@/components/StepContainer.vue'
import type { GenderStepData } from '@/steps/gender'
import { useDogsStore } from '@/stores/dogs'
import { Gender } from '@/models/Dog'

const props = defineProps<{
  stepData: GenderStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const data = reactive(clone(props.stepData))
const dogsStore = useDogsStore()

watch(
  () => data,
  (newData) => emits('save', newData),
  { deep: true },
)

const disabled = computed(() => !props.valid)

const submit = ({ target }: Event): void => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  emits('submit', data)
}
</script>

<template>
  <StepsContainer>
    <form id="formGender" v-submit @submit.prevent="submit">
      <div v-for="(dog, index) in data.dogs" :key="index">
        <MiniHr v-if="index > 0" />

        <StepHeading>Is {{ dogsStore.dogName(index) }} a boy or a girl? </StepHeading>

        <LykaFieldList>
          <LykaRadio
            v-for="option in Gender"
            :key="option"
            v-model="dog.gender"
            block
            :input-value="option"
            type="button"
            size="lg"
            :label="option"
            :name="`gender-${index}`"
            @change="dogsStore.emit('genderUpdated', index)"
          />
        </LykaFieldList>
      </div>

      <StepNavigation submit :next-disabled="disabled" form="formGender" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
