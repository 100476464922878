import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { EventSender } from '../EventSender'

export const useCouponAppliedFailureEvent: EventSender = (couponCode: string) => {
  const send = (): void => {
    useSegment().track('Coupon Applied', {
      coupon: couponCode,
      success: false,
    })
  }

  return {
    send,
  }
}
