<script setup lang="ts">
import { computed } from 'vue'
import { type Recipe, getMicroNutrientIngredients, getNutritionData } from '@/models/Recipe'
import { formatNumber } from '@/utils/formatNumber'

const props = defineProps<{
  recipe: Recipe
}>()

const nutritionData = computed(() => getNutritionData(props.recipe))
const microNutrients = computed(() =>
  getMicroNutrientIngredients(props.recipe)
    .map(({ name }) => name)
    .join(', '),
)
</script>

<template>
  <p class="tw-mb-4">
    <small class="tw-small"
      >This Bowl is formulated to meet the nutritional levels established by AAFCO Dog Food Nutritional Profiles for all
      life stages.</small
    >
  </p>

  <table>
    <caption class="tw-caption-bottom small tw-py-4 tw-mb-4 tw-text-left md:tw-text-center tw-small">
      *Dry Matter: Excludes moisture to make it more comparable to kibble
    </caption>
    <thead>
      <tr>
        <th class="tw-border-y tw-py-2 tw-w-1/2" />
        <th class="tw-border-y tw-py-2 tw-text-sm tw-font-normal" scope="col">Average composition<br />(as fed)</th>
        <th class="tw-border-y tw-py-2 tw-text-sm tw-font-normal" scope="col">Average composition<br />(dry matter)</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(nutrient, index) in nutritionData" :key="index">
        <th class="tw-border-y tw-py-2 tw-font-normal" scope="row">
          {{ nutrient.name }}
        </th>
        <td class="tw-border-y tw-py-2">{{ nutrient.percent }}%</td>
        <td class="tw-border-y tw-py-2">{{ nutrient.dryPercent }}%</td>
      </tr>
    </tbody>
  </table>

  <dl class="tw-flex tw-flex-wrap tw-gap-x-2 tw-gap-y-1 tw-mb-1 tw-text-p2-t">
    <dt class="tw-font-bold">Metabolised energy:</dt>
    <dd class="tw-basis-1/2 tw-font-light">{{ formatNumber(recipe.metabolisedEnergy) }} kcal/kg</dd>
    <dt class="tw-font-bold">Calcium to Phosphorus Ratio:</dt>
    <dd class="tw-basis-1/2 tw-font-light">{{ recipe.calciumPhosphorousRatio }}:1</dd>
    <dt class="tw-font-bold">Omega 6 to Omega 3 Ratio:</dt>
    <dd class="tw-basis-1/2 tw-font-light">{{ recipe.omega6Omega3Ratio }}:1</dd>
  </dl>

  <p class="tw-text-p2-t">
    <span class="tw-font-bold">Vitamins &amp; Minerals: </span> <span class="tw-font-light">{{ microNutrients }}</span>
  </p>
</template>
