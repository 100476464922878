import { z } from 'zod'
import type { ActivityLevel } from './ActivityLevel'
import type { Allergy } from './Allergy'
import type { BodyShape } from './BodyShape'
import type { Breed } from './Breed'
import type { FussinessLevel } from './FussinessLevel'
import type { Illness } from './Illness'
import type { Recipe } from './Recipe'
import { BreedType } from '@/steps/breed'
import { BirthdayType } from '@/steps/age'
import { ageToBirthday, birthdayToAge } from '@/utils/birthdayConversion'

export enum Gender {
  Boy = 'Boy',
  Girl = 'Girl',
}

export enum AgeType {
  Puppy = 'Puppy',
  Adult = 'Adult',
}

export interface Dog {
  name: string
  breed: {
    type: BreedType
    primary?: Breed
    secondary?: Breed
  }
  gender?: Gender
  hasIllness: boolean
  illnesses: Illness[]
  age: {
    is: AgeType
    months: number
    years: number
    date: string
    type: BirthdayType
  }
  weight: {
    current: number
    adult: number
  }
  allergies: Allergy[]
  bodyShape?: BodyShape
  // Sic
  fusinessLevel?: FussinessLevel
  activity?: ActivityLevel
  recipes: Recipe[]
  foodTypes?: string[]
  foodBrand?: string
}

// A Dog with all of its required data defined
export type CompletedDog = Required<Pick<Dog, 'gender' | 'bodyShape' | 'fusinessLevel' | 'activity'>> & Dog

export const getDogAgeType = (years: number, months: number, breed: Breed): AgeType => {
  const dogAgeMonths = years * 12 + months
  const isAdult = dogAgeMonths >= breed.adultAgeMonthsMax

  return isAdult ? AgeType.Adult : AgeType.Puppy
}

export enum BreedTypeName {
  Mix = 'Mix Breed',
  Pure = 'Pure Breed',
  Unknown = 'Unknown',
}

export const getBreedTypeName = (dog: Dog): BreedTypeName => {
  switch (dog.breed.type) {
    case BreedType.Mix:
      return BreedTypeName.Mix
    case BreedType.Pure:
      return BreedTypeName.Pure
    default:
      return BreedTypeName.Unknown
  }
}

interface DogAgeData {
  age: Pick<Dog['age'], 'type' | 'months' | 'years' | 'date'>
}

interface DogBirthDate {
  bornYear: number
  bornMonth: number
  bornDay: null | number
}

export const getDogBirthDate = (dog: DogAgeData): DogBirthDate => {
  if (dog.age.type === BirthdayType.Date && dog.age.date && z.coerce.date().safeParse(dog.age.date).success) {
    const birthDate = new Date(dog.age.date)

    return { bornYear: birthDate.getFullYear(), bornMonth: birthDate.getMonth() + 1, bornDay: birthDate.getDate() }
  }

  if (dog.age.type === BirthdayType.Age && (dog.age.years ?? 0) + (dog.age.months ?? 0) > 0) {
    return ageToBirthday({ years: dog.age.years ?? 0, months: dog.age.months ?? 0 })
  }

  // Default case
  return { bornYear: new Date().getFullYear(), bornMonth: new Date().getMonth() + 1, bornDay: null }
}

type AgeData = Omit<Dog['age'], 'is'>

export const getDogAge = (dog: { age: AgeData }): { years: number; months: number } => {
  if (dog.age.type === BirthdayType.Age) {
    const months = dog.age.months ?? 0
    const years = dog.age.years ?? 0

    return {
      years,
      months,
    }
  }

  if (dog.age.type === BirthdayType.Date && dog.age.date && z.coerce.date().safeParse(dog.age.date).success) {
    return birthdayToAge(dog.age.date)
  }

  return { years: 0, months: 0 }
}
