<script setup lang="ts">
import { computed } from 'vue'
import { LykaButton } from '@lyka/ui'
import { type MealPlan, MealPlanType } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import { formatNumber } from '@/utils/formatNumber'
import { formatMoney } from '@/utils/formatMoney'
import type { Recipe } from '@/models/Recipe'
import feedingPlanImage from '@/assets/images/steps/plans/feeding-plan.jpg'
import { StepName, useStepsStore } from '@/stores/steps'
import { useProductPrice } from '@/composables/useProductPrice'

const props = defineProps<{
  mealWeight: MealPlanWeight
  mealPlan: MealPlan
  recipes: Recipe[]
}>()

const { getDiscountedPrice, isProductDiscounted } = useProductPrice()
const stepsStore = useStepsStore()

const prettyDogName = computed(() => `${props.mealWeight.name}'s Meal Plan`)

const dailyMealWeight = computed(() => {
  if (props.mealPlan.type === MealPlanType.Half) {
    return props.mealWeight.servingWeight / 2
  }

  return props.mealWeight.servingWeight
})

const recipesText = computed(() => {
  return props.recipes.map((r) => r.displayText).join(', ')
})
</script>

<template>
  <div class="tw-flex tw-gap-4">
    <div
      class="tw-rounded-2xl tw-aspect-square tw-flex tw-items-start tw-justify-center tw-w-16 tw-relative tw-shrink-0"
    >
      <img
        :src="feedingPlanImage"
        alt="An image of your dog's meal plan"
        class="tw-object-cover tw-aspect-square tw-rounded-lg tw-shrink-0"
        width="64"
        height="64"
      />
    </div>

    <div class="tw-flex tw-flex-col tw-justify-between tw-text-alt tw-w-full">
      <div class="tw-flex tw-flex-col tw-space-y-2">
        <div class="tw-flex tw-justify-between tw-gap-2 tw-flex-wrap">
          <div>{{ prettyDogName }}</div>

          <div class="tw-flex tw-gap-2 tw-items-baseline summary-plan-price">
            <s v-if="isProductDiscounted('meals')" class="tw-text-sm tw-text-opacity-40 tw-text-dark">
              {{ formatMoney(mealPlan.totalPrice) }}
            </s>

            {{ formatMoney(getDiscountedPrice('treats', mealPlan.totalPrice)) }}
          </div>
        </div>

        <div class="tw-flex tw-justify-between tw-items-start">
          <div class="tw-text-sm tw-font-light">
            <p>{{ recipesText }}</p>
            <p>{{ formatNumber(dailyMealWeight) }}g/day</p>
          </div>

          <LykaButton
            type="button"
            variant="success"
            text-link
            class="tw-ml-2"
            size="xs"
            @click="stepsStore.goToStep(StepName.Recipes)"
          >
            Edit meals
          </LykaButton>
        </div>
      </div>
    </div>
  </div>
</template>
