<script setup lang="ts">
import { LykaButton, LykaInput, LykaMessage } from '@lyka/ui'
import { computed, onMounted, onUnmounted, reactive, watch } from 'vue'
import { clone } from 'remeda'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import StepNavigation from '../StepNavigation.vue'
import PlusIcon from '../icons/PlusIcon.vue'
import StepHeading from '../StepHeading.vue'
import BeenHereBeforeToggle from '../BeenHereBeforeToggle.vue'
import StepContainer from '@/components/StepContainer.vue'
import { MAX_DOGS, useDogsStore } from '@/stores/dogs'
import type { NameStepData } from '@/steps/name'
import { GTMEventAction, useGTM } from '@/composables/useGTM'

const props = defineProps<{
  stepData: NameStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const data = reactive(clone(props.stepData))
const dogsStore = useDogsStore()

watch(
  () => data,
  (newData) => {
    emits('save', clone(newData))
  },
  { deep: true },
)

const onDogsChanged = (): void => {
  data.dogs = props.stepData.dogs
}

onMounted(() => {
  dogsStore.on('added', onDogsChanged)
  dogsStore.on('removed', onDogsChanged)
})

onUnmounted(() => {
  dogsStore.off('added', onDogsChanged)
  dogsStore.off('removed', onDogsChanged)
})

const addDog = (): void => {
  const count = data.dogs.length

  useGTM().sendAction(GTMEventAction.ADDED_DOG, `From ${count} to ${count + 1}`)

  dogsStore.addDog()
}

const canAddDog = computed(() => {
  return data.dogs.length < MAX_DOGS
})

const canRemoveDog = computed(() => {
  return data.dogs.length > 1
})

const removeDog = (index: number): void => {
  if (canRemoveDog.value) {
    const count = data.dogs.length

    useGTM().sendAction(GTMEventAction.REMOVED_DOG, `From ${count} to ${count - 1}`)

    dogsStore.removeDog(index)
  }
}

const disabled = computed(() => {
  return !props.valid
})

const heading = ((): string => {
  const [first, second] = useDogsStore().getDogs()

  // If the first dog has a breed but no name show the breed in the heading
  if (first && !second && !first.name && first?.breed.primary) {
    return `What's the name of your ${first.breed.primary.name}?`
  } else {
    return `What's your dog's name?`
  }
})()

const submit = ({ target }: Event): void => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  emits('submit', data)
}
</script>

<template>
  <StepContainer>
    <StepHeading>{{ heading }}</StepHeading>
    <form id="formName" v-submit class="tw-space-y-4" @submit.prevent="submit">
      <LykaInput
        v-for="(dog, index) in data.dogs"
        :key="index"
        v-model.trim="dog.name"
        :name="`name-${index}`"
        placeholder="Enter your pupper's name"
        required
        pattern="[\p{Letter}\s\d]+"
        spellcheck="false"
        error-message="Please provide your pupper's name"
        size="lg"
        autocapitalize
      >
        <template #button>
          <LykaButton
            v-if="canRemoveDog"
            variant="cream"
            class="tw-mr-1"
            size="xs"
            type="button"
            title="Remove"
            :animate="false"
            @click="removeDog(index)"
          >
            Remove
          </LykaButton>
        </template>
      </LykaInput>

      <div v-if="canAddDog" class="tw-mt-4">
        <LykaButton id="add-dog" block size="lg" variant="subtle" @click="addDog">
          <template #iconLeft>
            <PlusIcon />
          </template>
          Add another dog
        </LykaButton>
      </div>

      <StepNavigation previous-hidden :submit="true" form="formName" :next-disabled="disabled" />
    </form>

    <aside class="tw-space-y-8 tw-mt-12">
      <LykaMessage>
        👋 Create a tailored meal plan for your dog in 2 minutes by answering a few simple questions.
      </LykaMessage>

      <BeenHereBeforeToggle />
    </aside>
  </StepContainer>
</template>
