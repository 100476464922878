<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import { clone } from 'remeda'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import { assetUrl } from '@lyka/utils'
import OptionGroup from '../common/OptionGroup.vue'
import OptionTile from '../common/OptionTile.vue'
import StepNavigation from '../StepNavigation.vue'
import MiniHr from '../common/MiniHr.vue'
import StepHeading from '../StepHeading.vue'
import StepsContainer from '@/components/StepContainer.vue'
import { useDataStore } from '@/stores/data'
import type { FussinessLevel } from '@/models/FussinessLevel'
import type { FussinessStepData } from '@/steps/fussiness'
import { useDogsStore } from '@/stores/dogs'

const props = defineProps<{
  stepData: FussinessStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const data = reactive(clone(props.stepData))
const dataStore = useDataStore()
const dogsStore = useDogsStore()

const fussinessLevels = computed(() => {
  return [...dataStore.fussinessLevels].reverse()
})

watch(
  () => data,
  (newData) => emits('save', newData),
  { deep: true },
)

const fussinessImageURL = ({ name }: FussinessLevel): string => {
  return assetUrl(`images/steps/fussiness/${name}.svg`)
}

const disabled = computed(() => !props.valid)

const submit = ({ target }: Event): void => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  emits('submit', data)
}
</script>

<template>
  <StepsContainer size="lg">
    <form id="formFussy" v-submit @submit.prevent="submit">
      <div v-for="(dog, index) in data.dogs" :key="index">
        <MiniHr v-if="index > 0" />

        <StepHeading> Is {{ dogsStore.dogName(index) }} a fussy eater? </StepHeading>

        <OptionGroup>
          <OptionTile
            v-for="fussinessLevel in fussinessLevels"
            :key="fussinessLevel.id"
            v-model="dog.fussiness"
            input-type="radio"
            :input-value="fussinessLevel.id"
            :title="fussinessLevel.customerText"
            :description="fussinessLevel.customerDescription"
          >
            <template #icon>
              <img :src="fussinessImageURL(fussinessLevel)" />
            </template>
          </OptionTile>
        </OptionGroup>
      </div>

      <StepNavigation submit :next-disabled="disabled" form="formFussy" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
