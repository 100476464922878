<script setup lang="ts">
import { ref } from 'vue'
import { computed } from '@vue/reactivity'
import { LykaIcon } from '@lyka/ui'
import { assetUrl, pluralize } from '@lyka/utils'
import LykaButton from '@lyka/ui/components/LykaButton.vue'
import CheckoutTreats from './CheckoutTreats.vue'
import CheckoutOrderMealPlanDetails from './CheckoutOrderMealPlanDetails.vue'
import CheckoutDeliveryDetails from './CheckoutDeliveryDetails.vue'
import CheckoutFreeItems from './CheckoutFreeItems.vue'
import CheckoutSeparator from './CheckoutSeparator.vue'
import { useDogsStore } from '@/stores/dogs'
import { type MealPlan, getMealPlanName } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import { formatMoney } from '@/utils/formatMoney'
import { StepName, useStepsStore } from '@/stores/steps'
import { useProductPrice } from '@/composables/useProductPrice'

const props = defineProps<{
  mealPlan: MealPlan
  mealWeights: MealPlanWeight[]
  everyBox?: boolean
  treatQuantities?: Record<number, number>
  discount?: number
}>()

const dogsStore = useDogsStore()
const stepsStore = useStepsStore()
const { getDiscountedPrice } = useProductPrice()

const hasMultipleDogs = computed(() => {
  return dogsStore.multipleDogs
})

const prettyDogNames = computed<string>(() => {
  if (hasMultipleDogs.value) {
    return dogsStore.dogNames
      .map((name, idx) => {
        if (idx < dogsStore.dogNames.length - 1) {
          return `${name} & `
        }
        return `${name}'s`
      })
      .join('')
  }
  return `${dogsStore.dogNames[0]}'s`
})

const mealPlanName = computed(() => getMealPlanName(props.mealPlan.type))
const planImageURL = computed(() => {
  return assetUrl(`images/steps/plans/${props.mealPlan.type}.webp`)
})

const expanded = ref(false)

const toggle = (): void => {
  expanded.value = !expanded.value
}

const discountedTotalPrice = computed(() => {
  return getDiscountedPrice('meals', props.mealPlan.totalPrice)
})

const discountedDailyPrice = computed(() => {
  return getDiscountedPrice('meals', props.mealPlan.priceDaily)
})
</script>

<template>
  <div class="tw-mb-6">
    <h4 class="tw-font-bold md:tw-h4">Order summary</h4>
  </div>

  <div class="tw-flex tw-gap-x-4 tw-mb-6">
    <div
      class="tw-rounded-2xl tw-aspect-square tw-flex tw-items-center tw-justify-center tw-w-16 tw-relative tw-shrink-0"
    >
      <img :src="planImageURL" alt="An image of your dog's meal plan" class="tw-object-cover tw-aspect-square" />
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-1 tw-w-full">
      <div class="tw-flex tw-justify-between tw-items-start">
        <span>{{ prettyDogNames }} Plan</span>

        <LykaButton
          type="button"
          variant="success"
          text-link
          class="tw-ml-2"
          @click="stepsStore.goToStep(StepName.Plan)"
        >
          Edit
        </LykaButton>
      </div>
      <span class="tw-text-sm tw-font-light">
        {{ mealPlanName }}, {{ mealPlan.frequency }} {{ pluralize('week', mealPlan.frequency) }} of food
      </span>
      <span class="tw-text-sm tw-font-light">
        <span id="checkout-price-plan">{{ formatMoney(discountedTotalPrice) }}</span> @
        {{ formatMoney(discountedDailyPrice) }}/day
      </span>
    </div>
  </div>

  <div v-if="$slots.alert" class="tw-mb-4">
    <slot name="alert" />
  </div>

  <details id="summary-toggle" @toggle="toggle">
    <summary>
      <span class="tw-text-green tw-flex tw-items-center tw-cursor-pointer tw-underline hover:tw-no-underline tw-gap-2">
        {{ expanded ? 'Hide' : 'Show' }} summary
        <LykaIcon name="SelectDownThin" :class="{ 'tw-rotate-180': expanded }" />
      </span>
    </summary>

    <div class="tw-pt-6">
      <div v-for="(mealWeight, index) in mealWeights" :key="mealWeight.key">
        <CheckoutSeparator v-if="index > 0" />

        <CheckoutOrderMealPlanDetails
          :meal-weight="mealWeight"
          :meal-plan="mealPlan"
          :recipes="dogsStore.dogRecipes(index)"
        />
      </div>

      <CheckoutSeparator />

      <CheckoutTreats :every-box="everyBox" :treat-quantities="treatQuantities" />

      <CheckoutFreeItems />

      <CheckoutSeparator />

      <CheckoutDeliveryDetails />
    </div>
  </details>
</template>
