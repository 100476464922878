<template>
  <svg
    width="16"
    stroke="#FCFBF9"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    class="tw-fill-current"
  >
    <path d="M1 8H15M15 8L8 1M15 8L8 15" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
