<script setup lang="ts">
import { ref } from 'vue'
import { LykaError, LykaInput } from '@lyka/ui'
import LykaSpinner from '@lyka/ui/components/LykaSpinner.vue'
import { type AddressInfo, type AddressSearchResult, useAddressFinder } from '@/composables/useAddressFinder'
import type { CheckoutStepAddressData } from '@/steps/checkout'
import { type Address, formatAddress } from '@/models/Address'
import { useDebounce } from '@/composables/useDebounce'

const props = defineProps<{
  address: CheckoutStepAddressData
  invalid?: boolean
}>()

const emits = defineEmits<{
  (e: 'change', address: (typeof props)['address']): void
}>()

const element = ref<HTMLElement>()
const expanded = ref(false)
const loading = ref(false)
const addressFinder = useAddressFinder()
const addressValue = ref(formatAddress(props.address))
const addressResults = ref<AddressSearchResult[]>([])

const addressInfoToAddress = (addressInfo: AddressInfo): Partial<Address> => {
  const { addressLine1, addressLine2, postcode, localityName, stateTerritory } = addressInfo

  return {
    addressLine1,
    addressLine2,
    postcode,
    state: stateTerritory,
    suburb: localityName,
  }
}

const selectAddressResult = async (searchResult: AddressSearchResult): Promise<void> => {
  // Clear the results
  addressResults.value = []

  const addressInfo = await addressFinder.addressInfo(searchResult.id)
  const address = addressInfoToAddress(addressInfo)

  emits('change', {
    ...props.address,
    ...address,
  })

  addressValue.value = formatAddress(address)
}

const resetAddress = (): void => {
  emits('change', {
    ...props.address,
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    state: null,
    suburb: '',
  })
}

const addressSearch = useDebounce(async (ev: KeyboardEvent) => {
  if (!(ev.target instanceof HTMLInputElement)) {
    return
  }

  try {
    const response = await addressFinder.addressSearch(ev.target.value)

    if (response.success) {
      addressResults.value = response.completions
    }
  } finally {
    loading.value = false
  }
}, 500)

const onAddressChange = (ev: KeyboardEvent): void => {
  resetAddress()

  if (ev.target instanceof HTMLInputElement && ev.target.value) {
    loading.value = true
    addressSearch(ev)
  }
}

const collapse = (ev: MouseEvent): void => {
  if (element.value?.contains(ev.target as Element)) {
    return
  }

  expanded.value = false
  document.removeEventListener('click', collapse)
}

const expand = (): void => {
  expanded.value = true
  document.addEventListener('click', collapse)
}
</script>

<template>
  <div ref="element" class="sm:tw-col-span-2 tw-space-y-2 tw-relative">
    <LykaInput
      v-model="addressValue"
      name="delivery-address"
      label="Delivery address"
      placeholder="Start typing your address"
      required
      spellcheck="false"
      error-message="Your address is required"
      @input="onAddressChange"
      @focus="expand"
    />

    <div
      v-if="expanded && (loading || addressResults.length)"
      class="tw-select-dropdown tw-transform tw--translate-y-10 tw-text-alt"
    >
      <div class="tw-select-dropdown-inner">
        <div class="tw-py-2">
          <div v-if="loading" class="tw-flex tw-justify-center tw-py-4">
            <LykaSpinner size="sm" />
          </div>
          <template v-else-if="addressResults.length">
            <div
              v-for="result in addressResults"
              :key="result.id"
              class="tw-select-dropdown-item !tw-rounded-none"
              :data-option-label="result.fullAddress"
              @click="selectAddressResult(result)"
            >
              {{ result.fullAddress }}
            </div>
          </template>
        </div>
      </div>
    </div>

    <LykaError v-if="invalid"> Currently we are not delivering to this postcode </LykaError>

    <slot />
  </div>
</template>
