import mitt from 'mitt'
import { computed } from 'vue'
import { defineStore } from 'pinia'
import { useStepsStore } from '@/stores/steps'
import { type NameStepData } from '@/steps/name'
import { type GenderStepData } from '@/steps/gender'
import { type BreedStepData } from '@/steps/breed'
import { type AgeStepData, useAgeStep } from '@/steps/age'
import type { WeightStepData } from '@/steps/weight'
import type { BodyShapeStepData } from '@/steps/bodyShape'
import type { ActivityStepData } from '@/steps/activity'
import { type AllergiesStepData } from '@/steps/allergies'
import type { FussinessStepData } from '@/steps/fussiness'
import { Gender, getDogAgeType } from '@/models/Dog'
import type { AgeType, Dog } from '@/models/Dog'
import type { RecipesStepData } from '@/steps/recipes'
import type { Allergy } from '@/models/Allergy'
import type { Illness } from '@/models/Illness'
import { DogFactory } from '@/factories/DogFactory'
import type { IllnessesStepData } from '@/steps/illnesses'
import type { PreviouslyFedStepData } from '@/steps/previouslyFed'
import type { Breed } from '@/models/Breed'
import type { Recipe } from '@/models/Recipe'
import type { FussinessLevel } from '@/models/FussinessLevel'
import type { BodyShape } from '@/models/BodyShape'
import type { ActivityLevel } from '@/models/ActivityLevel'

export type DogStepsData = (NameStepData &
  GenderStepData &
  BreedStepData &
  AgeStepData &
  WeightStepData &
  BodyShapeStepData &
  ActivityStepData &
  FussinessStepData &
  PreviouslyFedStepData &
  AllergiesStepData &
  IllnessesStepData &
  RecipesStepData)['dogs']

type ArrayElementType<ArrayType extends Array<unknown>> = ArrayType[number]

export const MAX_DOGS = 10

export type DogData = Partial<ArrayElementType<DogStepsData>>

const emitter = mitt<{
  added: Partial<DogData>
  removed: number

  // This event fires for each dog when the app first loads. This is to ensure that each step validates that it has the
  // data required for each dog. For example if we introduce a new dog step into the flow it needs to initialize the
  // data for each dog that might exist.
  loaded: number

  illnessesUpdated: number
  illnessesRemoved: number

  allergiesUpdated: number
  allergiesRemoved: number

  breedUpdated: number
  genderUpdated: number
  ageUpdated: number
}>()

export const useDogsStore = defineStore('dogs', () => {
  const stepsStore = useStepsStore()
  const dogFactory = new DogFactory()
  const on = emitter.on
  const off = emitter.off
  const emit = emitter.emit

  const dogs = computed(() => {
    const dogs: Partial<DogData>[] = []

    for (const step of stepsStore.steps) {
      const { data } = step()

      if ('dogs' in data && Array.isArray(data.dogs)) {
        data.dogs.forEach((props, dogIndex) => {
          dogs[dogIndex] ??= {}

          Object.assign(dogs[dogIndex]!, {
            ...props,
          })
        })
      }
    }

    return dogs.map((dog) => dogFactory.createDog(dog as DogData))
  })

  const addDog = (dog?: DogData): void => {
    emitter.emit('added', dog ?? {})
  }

  const removeDog = (index: number): void => {
    emitter.emit('removed', index)
  }

  const dogNames = computed(() => {
    return dogs.value.map(({ name }) => name)
  })

  const dogCount = computed(() => {
    return dogs.value.length
  })

  const multipleDogs = computed(() => {
    return dogCount.value > 1
  })

  const dogName = (index: number): string | undefined => {
    return dogs.value[index]?.name
  }

  const dogGender = (index: number): Gender | undefined => {
    return dogs.value[index]?.gender
  }

  const dogPronoun = (index: number, possessive = false): string => {
    const gender = dogs.value[index]?.gender

    switch (gender) {
      case Gender.Boy: {
        return possessive ? 'his' : 'he'
      }
      case Gender.Girl: {
        return possessive ? 'her' : 'she'
      }
      default: {
        return possessive ? 'their' : 'them'
      }
    }
  }

  const dogAllergies = (index: number): Allergy[] => {
    return dogs.value[index]?.allergies ?? []
  }

  const dogIllnesses = (index: number): Illness[] => {
    return dogs.value[index]?.illnesses ?? []
  }

  const dogRecipes = (index: number): Recipe[] => {
    return dogs.value[index]?.recipes ?? []
  }

  const dogBreeds = (index: number): Breed[] => {
    const data = dogs.value[index]?.breed

    if (data) {
      const breeds: Breed[] = []

      if (data.primary) {
        breeds.push(data.primary)
      }

      if (data.secondary) {
        breeds.push(data.secondary)
      }

      return breeds
    }

    return []
  }

  const dogPrimaryBreed = (index: number): Breed | undefined => {
    return dogs.value[index]?.breed.primary
  }

  const dogHasAllergies = (index: number): boolean => {
    return !!dogs.value[index]?.allergies.length ?? false
  }

  const dogPossessivePronoun = (index: number): string => {
    switch (dogGender(index)) {
      case Gender.Boy:
        return 'his'
      case Gender.Girl:
        return 'her'
      default:
        return 'their'
    }
  }

  const getDog = (index: number): Dog | undefined => {
    return dogs.value[index]
  }

  const getDogs = (): Dog[] => {
    return dogs.value
  }

  const dogAgeType = (index: number): AgeType | undefined => {
    return dogs.value[index]?.age.is ?? undefined
  }

  const dogFussinessLevel = (index: number): FussinessLevel | undefined => {
    return dogs.value[index]?.fusinessLevel ?? undefined
  }

  const dogBodyShape = (index: number): BodyShape | undefined => {
    return dogs.value[index]?.bodyShape ?? undefined
  }

  const dogActivityLevel = (index: number): ActivityLevel | undefined => {
    return dogs.value[index]?.activity ?? undefined
  }

  // This function is called when the app first loads to ensure that each dog has the correct data for each step
  const initialize = (): void => {
    const ageStep = useAgeStep()

    // Ensure that the AgeType for each dog is up-to-date. This might have changed if the dog is older than when it was
    // first entered
    dogs.value.forEach((dog, index) => {
      const { years, months } = dog.age
      const { primary } = dog.breed

      if (primary) {
        const ageType = getDogAgeType(years, months, primary)

        // If the AgeType is incorrect, update it
        if (dog.age.is !== ageType && ageStep.data.dogs[index]) {
          ageStep.data.dogs[index]!.age.is = ageType
          ageStep.saveState()
        }
      }
    })
  }

  return {
    dogName,
    dogPossessivePronoun,
    dogAgeType,
    getDog,
    getDogs,
    dogAllergies,
    dogIllnesses,
    dogBreeds,
    dogPrimaryBreed,
    dogHasAllergies,
    dogRecipes,
    dogGender,
    dogPronoun,
    dogFussinessLevel,
    dogActivityLevel,
    dogBodyShape,

    addDog,
    removeDog,
    initialize,

    on,
    off,
    emit,

    dogNames,
    dogCount,
    multipleDogs,
  }
})
