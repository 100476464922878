<script setup lang="ts">
import { LykaCheckbox } from '@lyka/ui'
import { computed } from 'vue'

const props = defineProps<{
  modelValue: boolean
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', value: (typeof props)['modelValue']): void
}>()

const value = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    emits('update:modelValue', value)
  },
})
</script>

<template>
  <LykaCheckbox v-model="value" :input-value="true">
    Receive guides, occasional treats and new updates from Lyka. You can unsubscribe at anytime.

    <template #ancillary>
      <small class="tw-small tw-text-alt"
        >Read our
        <a href="https://lyka.com.au/privacy-and-terms" target="_blank" class="tw-underline">Privacy &amp; Terms</a> for
        more details.
      </small>
    </template>
  </LykaCheckbox>
</template>
