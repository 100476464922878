import { GTMEventAction, useGTM } from '../useGTM'
import { OutbrainEventName, useOutbrain } from '../useOutbrain'
import type { EventSender } from './EventSender'
import { useCheckoutStartedEvent } from './segment/useCheckoutStartedEvent'

interface EventArgs {
  isNewSession: boolean
}

export const useCheckoutStartedEvents: EventSender = ({ isNewSession }: EventArgs) => {
  const send = (): void => {
    useCheckoutStartedEvent(!isNewSession).send()

    const eventName = isNewSession ? GTMEventAction.STARTED_JOURNEY : GTMEventAction.RESUMED_JOURNEY

    useGTM().sendAction(eventName, 'meals')

    if (isNewSession) {
      useOutbrain().track(OutbrainEventName.BAB_START)
    }
  }

  return {
    send,
  }
}
