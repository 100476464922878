import type { CheckoutStepAddressData } from '@/steps/checkout'

export enum StateTerritory {
  NSW = 'NSW',
  QLD = 'QLD',
  VIC = 'VIC',
  WA = 'WA',
  SA = 'SA',
  TAS = 'TAS',
  ACT = 'ACT',
  NT = 'NT',
}

export interface Address {
  addressLine1: string
  addressLine2?: string
  suburb: string
  postcode: string
  state: StateTerritory
  isWork: boolean
  businessName: string
}

export const formatAddress = ({
  addressLine1,
  addressLine2,
  suburb,
  postcode,
  state,
}: Partial<CheckoutStepAddressData>): string => {
  return [addressLine1, addressLine2, suburb, state, postcode].filter((v) => !!v).join(', ')
}
