<script lang="ts" setup>
import { computed } from 'vue'
import { LykaButton } from '@lyka/ui'
import IconArrowRight from './icon/ArrowRight.vue'

type SlideDirection = 'left' | 'right'

const props = defineProps<{
  buttonInactive?: boolean
  direction: SlideDirection
  desktopOnly?: boolean
}>()

const emits = defineEmits<{
  (e: 'onClickControl', direction: SlideDirection): void
}>()

const buttonRotation = computed(() => {
  return props.direction === 'right' ? '' : 'tw-rotate-180'
})
const displayDesktopOnly = computed(() => {
  return props.desktopOnly ? ['tw-hidden', 'md:tw-flex'] : ''
})
</script>

<template>
  <LykaButton
    variant="alt"
    size="lg"
    square
    :disabled="props.buttonInactive"
    :class="[buttonRotation, displayDesktopOnly]"
    @click="emits('onClickControl', props.direction)"
  >
    <IconArrowRight />
  </LykaButton>
</template>

<style lang="postcss"></style>
