<script setup lang="ts">
import { computed, ref } from 'vue'
import LykaSelect from './LykaSelect.vue'

type ModelValue = string | number | null | undefined

type TimeSlot = 'am' | 'pm'

interface Option {
  text: string
  value: TimeSlot
}

const props = defineProps<{
  date?: string
  future?: boolean
  dates?: Array<string>
  timeOptions?: Option[]
  currentTime?: TimeSlot
}>()

const emits = defineEmits<{
  (e: 'update', value: string): void
  (e: 'update:time', value: TimeSlot): void
}>()

const DAYS_OF_WEEEK = new Set(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'])

const current = ref(props.date ? new Date(props.date) : new Date())
const time = ref<TimeSlot>(props.currentTime || 'am')
const now = new Date()

const selected = computed(() => {
  if (props.date) {
    return new Date(props.date)
  }

  return undefined
})

const year = computed(() => {
  return current.value.getFullYear()
})

const month = computed(() => {
  return current.value.getMonth()
})

const prevMonth = (): void => {
  const prev = new Date(current.value.getTime())
  prev.setMonth(prev.getMonth() - 1)

  current.value = prev
}

const nextMonth = (): void => {
  const next = new Date(current.value.getTime())
  next.setMonth(next.getMonth() + 1)

  current.value = next
}

const monthName = computed(() => {
  return current.value.toLocaleDateString('default', { month: 'long' })
})

// Month in JavaScript is 0-indexed (January is 0, February is 1, etc),
// but by using 0 as the day it will give us the last day of the prior
// month. So passing in 1 as the month number will return the last day
// of January, not February
const daysInMonths = computed((): number => {
  return new Date(Date.UTC(year.value, month.value + 1, 0)).getDate()
})

const firstDayOfMonth = computed((): number => {
  return new Date(Date.UTC(year.value, month.value, 0)).getDay() + 1
})

const ymd = (value: Date): string => {
  const [ymd] = value.toISOString().split('T')

  return ymd as string
}

const getDisabled = (date: number): boolean => {
  const then = new Date(Date.UTC(year.value, month.value, date))

  if (props.dates) {
    return !props.dates.includes(ymd(then))
  }

  if (props.future) {
    return then.getTime() < now.getTime()
  }

  return false
}

const isSameDate = (a: Date, b: Date): boolean => {
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate()
}

const getSelected = (day: number): boolean => {
  if (selected.value) {
    const then = new Date(Date.UTC(year.value, month.value, day))

    return isSameDate(then, selected.value)
  }

  return false
}

const selectDate = (date: number): void => {
  const value = ymd(new Date(Date.UTC(year.value, month.value, date)))

  emits('update', value)
}

const onTimeChange = (value: ModelValue): void => {
  emits('update:time', value as TimeSlot)
}
</script>

<template>
  <div
    id="date-time-picker"
    class="tw-max-w-full tw-text-center tw-bg-cream tw-rounded-lg tw-border tw-border-gray tw-shadow-[1px_0px_3px_1px_rgba(0,0,0,0.04)]"
  >
    <div class="tw-bg-secondary tw-text-alt tw-h-12 tw-p-3 tw-rounded-t-lg">
      <slot name="title" />
    </div>

    <div class="tw-p-4">
      <div class="tw-flex tw-justify-center tw-space-x-4 tw-mb-4">
        <button
          id="previous-month"
          class="tw-flex tw-items-center tw-justify-center tw-text-green"
          type="button"
          abbr="Previous month"
          @click="prevMonth"
        >
          <svg
            class="tw-w-6 tw-h-6 tw-fill-current"
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48"
          >
            <path
              d="M539-262 341-460q-5-5-7-10t-2-11q0-6 2-11t7-10l199-199q9-9 21.5-9t21.5 9q9 9 8.5 22t-9.5 22L406-481l177 177q9 9 9 21t-9 21q-9 9-22 9t-22-9Z"
            />
          </svg>
        </button>
        <div class="tw-flex tw-justify-center tw-font-semibold">{{ monthName }}</div>
        <button
          id="next-month"
          class="tw-flex tw-items-center tw-justify-center tw-text-green"
          type="button"
          abbr="Next month"
          @click="nextMonth"
        >
          <svg
            class="tw-w-6 tw-h-6 tw-fill-current"
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48"
          >
            <path
              d="M354-262q-8-10-8.5-22t8.5-21l176-176-177-177q-8-8-7.5-21.5T354-701q10-10 21.5-9.5T396-701l199 199q5 5 7 10t2 11q0 6-2 11t-7 10L397-262q-9 9-21 8.5t-22-8.5Z"
            />
          </svg>
        </button>
      </div>

      <div class="tw-grid tw-grid-cols-7 tw-bg-offwhite tw-rounded-md tw-border tw-border-gray" role="grid">
        <div
          v-for="(day, index) in DAYS_OF_WEEEK"
          :key="index"
          class="tw-p-2 tw-font-semibold tw-text-xs tw-bg-semilight-gray tw-border-b tw-border-gray"
          :class="{
            'tw-rounded-tl-lg': index === 0,
            'tw-rounded-tr-lg': index === DAYS_OF_WEEEK.size - 1,
          }"
          :abbr="day"
        >
          {{ day.slice(0, 3) }}
        </div>
        <div v-for="empty in firstDayOfMonth" :key="empty" class="tw-aspect-square" />
        <button
          v-for="day in daysInMonths"
          :key="day"
          type="button"
          class="tw-text-sm tw-h-12 aria-selected:tw-font-bold tw-rounded-full hover:tw-color-alt disabled:tw-cursor-not-allowed aria-selected:tw-bg-green aria-selected:tw-text-white disabled:tw-opacity-30 hover:tw-border tw-border-secondary tw-flex tw-items-center tw-justify-center"
          :disabled="getDisabled(day)"
          :aria-selected="getSelected(day)"
          @click="selectDate(day)"
        >
          {{ day }}
        </button>
      </div>

      <div v-if="timeOptions" class="tw-flex tw-items-center tw-space-x-4 tw-my-2">
        <span class="tw-mt-1">Time</span>
        <LykaSelect
          v-model="time"
          name="time"
          size="sm"
          hide-label
          :options="timeOptions"
          class="tw-text-green"
          @change="onTimeChange"
        />
      </div>
    </div>
  </div>
</template>
