<script setup lang="ts">
import { reactive, ref } from 'vue'
import { LykaButton, LykaFieldList, LykaInput } from '@lyka/ui'
import { clone } from 'remeda'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import StepContainer from '../StepContainer.vue'
import StepNavigation from '../StepNavigation.vue'
import SignBorder from '../../components/icons/SignBorder.vue'
import MarketingConsentCheckbox from '../MarketingConsentCheckbox.vue'
import { useEmailCaptured } from '@/composables/useEmailCaptured'
import type { UnservicedLocationStepData } from '@/steps/unservicedLocation'
import TickCircleIcon from '@/components/icons/TickCircleIcon.vue'
import { GTMEventName, useGTM } from '@/composables/useGTM'

const props = defineProps<{
  stepData: UnservicedLocationStepData
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const data = reactive(clone(props.stepData))
const submitting = ref(false)
const submitted = ref(false)

const captureEmail = async (): Promise<void> => {
  if (data.marketingCommunication) {
    return useEmailCaptured().subscribe(data.email, data.firstName)
  }

  return useEmailCaptured().unsubscribe(data.email, data.firstName)
}

const submitCustomerDetails = async (): Promise<void> => {
  useGTM().sendEvent(GTMEventName.RECIPE_NOT_FOUND, {
    customerfirstName: data.firstName,
    customerlastName: data.lastName,
    email: data.email,
    comingFrom: 'Recipe Not Found',
  })
}

const submit = async ({ target }: Event): Promise<void> => {
  if (!(target instanceof HTMLFormElement)) {
    return
  }

  if (!target.reportValidity()) {
    return
  }

  submitting.value = true

  try {
    await Promise.all([captureEmail(), submitCustomerDetails()])

    useSegment().track('No Suitable Recipes Email Captured', {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      subscribed: !!data.marketingCommunication,
    })

    submitted.value = true
  } finally {
    submitting.value = false
  }
}
</script>

<template>
  <StepContainer v-if="submitted">
    <div class="tw-flex tw-flex-col tw-items-center tw-space-y-8 tw-mb-28 tw-pt-16 sm:tw-pt-32">
      <TickCircleIcon />

      <h3 class="tw-h1">Thank you!</h3>
      <p>We'll send you an email when we can deliver to your area</p>
    </div>

    <LykaButton block size="lg" href="/"> Return to the Lyka website </LykaButton>
  </StepContainer>

  <StepContainer v-else>
    <div class="tw-space-y-4 sm:tw-space-y-8 tw-py-8">
      <div class="tw-flex tw-items-center tw-justify-center">
        <SignBorder text="sorry!" alt="sign displaying the word sorry" />
      </div>
      <h2 class="tw-h2">We're sorry, all our recipes include these ingredients</h2>
      <p>
        Unfortunately we won't be able to serve your pupper the food they need. But we're always adding new recipies.
        Enter your details below so we can keep you informed of any new recipes.
      </p>
    </div>

    <form id="formNoRecipes" v-submit @submit.prevent="submit">
      <div class="tw-space-y-8">
        <LykaFieldList>
          <LykaInput
            v-model.trim="data.firstName"
            name="first-name"
            label="First name"
            required
            error-message="Your first name is required"
            autocapitalize
          />
          <LykaInput
            v-model.trim="data.lastName"
            name="last-name"
            label="Last name"
            required
            error-message="Your last name is required"
            autocapitalize
          />
          <LykaInput
            v-model="data.email"
            name="email"
            label="Email"
            type="email"
            required
            error-message="Your email is required"
          />

          <MarketingConsentCheckbox v-model="data.marketingCommunication" />
        </LykaFieldList>

        <StepNavigation
          submit
          :next-loading="submitting"
          next-text="Notify me"
          :next-icon="false"
          form="formNoRecipes"
          @previous="emits('previous')"
        />
      </div>
    </form>
  </StepContainer>
</template>
