import { z } from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type DogStep, Step } from './Step'
import { registerStep } from './stepRegistry'
import { useFussinessStep } from './fussiness'
import { useAllergiesStep } from './allergies'
import { StepName } from '@/stores/steps'
import { type DogData, useDogsStore } from '@/stores/dogs'
import { useDataStore } from '@/stores/data'

const Schema = z.object({
  dogs: z
    .array(
      z.object({
        previouslyFed: z.object({
          foodTypes: z.array(z.number().int()),
          foodBrand: z.string(),
        }),
      }),
    )
    .min(1),
})

export interface PreviouslyFedStepData {
  dogs: Array<{
    previouslyFed: {
      /* @deprecated */
      foodType?: number | null
      foodTypes: number[]
      foodBrand: string
    }
  }>
}

const DEFAULT_VALUE: {
  /* @deprecated */
  foodType?: number | null
  foodTypes: number[]
  foodBrand: string
} = {
  foodTypes: [],
  foodBrand: '',
}

class PreviouslyFedStep extends Step<PreviouslyFedStepData> implements DogStep {
  name = StepName.PreviouslyFed
  schema = Schema
  dogsStore = useDogsStore()
  title = 'Previously Fed'

  constructor() {
    super()
    this.loadState()

    this.dogsStore.on('added', ({ previouslyFed = DEFAULT_VALUE }) => {
      this.data.dogs.push({ previouslyFed: { ...DEFAULT_VALUE, ...previouslyFed } })
    })

    this.dogsStore.on('removed', (index) => {
      this.data.dogs.splice(index, 1)
    })

    this.dogsStore.on('loaded', (index) => {
      this.data.dogs[index] ??= { previouslyFed: { ...DEFAULT_VALUE } }
    })
  }

  onComplete(): void {
    const dataStore = useDataStore()

    useSegment().track('Previously Fed Completed', {
      dogs: this.data.dogs.map((dog) => {
        const foodTypes = dataStore.findRecords('foodTypes', dog.previouslyFed.foodTypes).map(({ name }) => name)

        return {
          previouslyFed: {
            types: foodTypes,
            brand: dog.previouslyFed.foodBrand || null,
          },
        }
      }),
    })
  }

  addDog({ previouslyFed = DEFAULT_VALUE }: DogData = {}): void {
    this.data.dogs.push({ previouslyFed: { ...previouslyFed } })
  }

  removeDog(index: number): void {
    this.data.dogs.splice(index, 1)
  }

  loadDog(index: number): void {
    this.data.dogs[index] ??= { previouslyFed: { ...DEFAULT_VALUE } }
  }

  initialState(): PreviouslyFedStepData {
    return {
      dogs: [],
    }
  }

  prev(): Step {
    return useFussinessStep()
  }

  next(): Step {
    return useAllergiesStep()
  }
}

export const usePreviouslyFedStep = registerStep(PreviouslyFedStep)
