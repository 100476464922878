import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { SegmentEvents } from '@lyka/vue-common/events/segment'
import type { EventSender } from '../EventSender'
import type { MealPlan } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'

const getPlanData = (
  mealPlan: MealPlan,
  dailyGrams: number,
): {
  dailyGrams: number
  frequency: number
  total: number
} => {
  if (mealPlan.type === 'half') {
    dailyGrams /= 2
  }

  return {
    frequency: mealPlan.frequency,
    total: mealPlan.totalPrice,
    dailyGrams,
  }
}

export const usePlansCalculatedEvent: EventSender = ({
  mealPlans,
  mealWeights,
}: {
  mealPlans: MealPlan[]
  mealWeights: MealPlanWeight[]
}) => {
  const data = (): SegmentEvents['Plans Calculated'] => {
    const full = mealPlans.find((plan) => plan.type === 'full')
    const half = mealPlans.find((plan) => plan.type === 'half')
    const starter = mealPlans.find((plan) => plan.type === 'starter')

    const dailyGrams = mealWeights.map(({ servingWeight }) => servingWeight).reduce((total, curr) => total + curr, 0)

    return {
      full: full ? getPlanData(full, dailyGrams) : null,
      half: half ? getPlanData(half, dailyGrams) : null,
      starter: starter ? getPlanData(starter, dailyGrams) : null,
    }
  }

  const send = (): void => {
    useSegment().track('Plans Calculated', data())
  }

  return {
    send,
    data,
  }
}
