<script lang="ts" setup>
import { ref } from 'vue'
import LykaModalInstance from './LykaModalInstance.vue'

withDefaults(
  defineProps<{
    size?: 'sm' | 'md' | 'lg'
    title?: string
    closable?: boolean
    open?: boolean
  }>(),
  {
    open: true,
    closable: true,
    size: 'md',
  },
)

const emits = defineEmits<{
  (e: 'close'): void
}>()

const closing = ref(false)

const onAfterLeave = (): void => {
  closing.value = false
  emits('close')
}
</script>

<template>
  <Transition
    enter-active-class="tw-duration-300 tw-ease-out"
    enter-from-class="tw-transform tw-opacity-0"
    enter-to-class="tw-opacity-100"
    leave-active-class="tw-duration-300 tw-ease-in"
    leave-from-class="tw-opacity-100"
    leave-to-class="tw-transform tw-opacity-0"
    @after-leave="onAfterLeave"
  >
    <LykaModalInstance v-if="open" :title="title" :size="size" :closable="closable" @close="emits('close')">
      <slot />

      <template v-if="$slots.footer" #footer>
        <slot name="footer" />
      </template>
    </LykaModalInstance>
  </Transition>
</template>
