<script setup lang="ts">
import { computed } from 'vue'
import { LykaGrid, LykaInput, LykaSelect } from '@lyka/ui'
import { useDataStore } from '@/stores/data'
import {
  BREEDER_OPTION_NAME,
  DOG_TRAINER_OPTION_NAME,
  OTHER_OPTION_NAME,
  VET_OPTION_NAME,
} from '@/models/CheckoutOrder'

const props = defineProps<{
  option: number | null
  other: string
}>()

const emits = defineEmits<{
  (e: 'update', value: { option: number | null; other: string }): void
}>()

const dataStore = useDataStore()

const options = computed(() => {
  return dataStore.howDidYouHearOptions.map(({ id, name }) => {
    return {
      text: name,
      value: id,
    }
  })
})

const otherOptions: Record<string, string> = {
  [OTHER_OPTION_NAME]: 'Other',
  [BREEDER_OPTION_NAME]: 'Which breeder?',
  [VET_OPTION_NAME]: 'Which vet clinic?',
  [DOG_TRAINER_OPTION_NAME]: 'Business name',
} as const

const selectedOptionName = computed(() => {
  return options.value.find(({ value }) => value === props.option)?.text ?? ''
})

const isOtherOptionSelected = computed(() => {
  return selectedOptionName.value in otherOptions
})

const otherOptionLabel = computed(() => {
  return otherOptions[selectedOptionName.value]
})
const hasOptionalLabel = computed(() => selectedOptionName.value === OTHER_OPTION_NAME)

const optionValue = computed({
  get() {
    return props.option
  },
  set(value) {
    emits('update', {
      option: value,
      other: '',
    })
  },
})

const otherValue = computed({
  get() {
    return props.other
  },
  set(value) {
    emits('update', {
      option: props.option,
      other: value,
    })
  },
})
</script>

<template>
  <LykaGrid :cols="1" :y-gap="8" class="tw-items-start">
    <LykaSelect
      v-model="optionValue"
      name="how-did-you-hear"
      label="How did you hear about Lyka?"
      show-optional
      :options="options"
      placeholder="Select an option"
      error-message="Please select an option"
    />

    <LykaInput
      v-if="isOtherOptionSelected"
      v-model="otherValue"
      name="how-did-you-hear-other"
      :label="otherOptionLabel"
      :show-optional="hasOptionalLabel"
      placeholder="Please specify"
      error-message="Please select an option"
    />
  </LykaGrid>
</template>
