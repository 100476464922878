import { useHasIllnessesStep } from '@/steps/hasIllnesses'
import { useIllnessesStep } from '@/steps/illnesses'

const nullish = (value: unknown): boolean => value === null || value === undefined

// Copy the `hasIllnesses` property from the defunct `has-illnesses` step to the `illnesses` step
export const migrateHasIllnessesStepData = (): void => {
  const hasIllnessesStep = useHasIllnessesStep()
  const illnessesStep = useIllnessesStep()

  hasIllnessesStep.data.dogs.forEach((dog, index) => {
    const hasIllnesses = dog.hasIllnesses ?? undefined

    const illnessStepDog = illnessesStep.data.dogs[index]

    if (hasIllnesses !== undefined && illnessStepDog && nullish(illnessStepDog.hasIllnesses)) {
      illnessStepDog.hasIllnesses = hasIllnesses
    }
  })

  illnessesStep.saveState()
}
