import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useSentry } from '@/composables/useSentry'

export const useLocalitiesStore = defineStore('localities', () => {
  const records = ref<Record<string, string[]>>({})
  const loaded = ref(false)

  const load = async (): Promise<void> => {
    if (loaded.value) {
      return
    }

    try {
      const url = new URL('/data/localities.json', import.meta.url)
      const response = await fetch(url.toString())
      records.value = await response.json()
    } catch (err) {
      useSentry().error(err)
    } finally {
      loaded.value = true
    }
  }

  const findByPostcode = (postcode: string): string[] => {
    return records.value[postcode] || []
  }

  return {
    load,
    findByPostcode,
    records,
  }
})
