import { isDefined } from 'remeda'
import { type DogData, useDogsStore } from '../stores/dogs'
import { unwrapData, useApi } from './useApi'
import { useStateStore } from '@/stores/state'
import type { GhostAnimal } from '@/models/GhostAnimal'
import { AgeType, Gender, getDogAgeType } from '@/models/Dog'
import { BreedType, OLD_UNKNOWN_DOG_BREED_ID } from '@/steps/breed'
import { useDataStore } from '@/stores/data'
import { type UserData, useUserStore } from '@/stores/user'
import { useRecipesStore } from '@/stores/recipes'
import { getAdultWeight } from '@/models/Breed'
import { MealPlanType, getMealPlanTypeById } from '@/models/MealPlan'
import { type PlanStepData, usePlanStep } from '@/steps/plan'
import { useLoadingPlanStep } from '@/steps/loadingPlan'
import { runStateMigrations } from '@/migrations/index'
import { BirthdayType } from '@/steps/age'

interface BeenHereBeforeData {
  id: number
  email: string
  createdDate: string
  ghostAnimals?: GhostAnimal[]
  firstname: string
  lastname: string
  shippingPostcode: number
  subscriptionTypeId?: number
}

type PlanData = PlanStepData['plan']

interface DeserializedData {
  user: Partial<UserData>
  dogs: Partial<DogData>[]
  plan: PlanData
}

const ghostAsDogStepData = (ghost: GhostAnimal): Partial<DogData> => {
  const dataStore = useDataStore()
  const recipesStore = useRecipesStore()

  const recipes = (): number[] => {
    const recipeNames = ghost.recipesValue?.split('_').map((name) => name.toLowerCase()) ?? []

    return recipesStore.recipes.filter((recipe) => recipeNames.includes(recipe.name)).map((recipe) => recipe.id)
  }

  const getGender = (): Gender | undefined => {
    switch (ghost.gender) {
      case 'HE':
        return Gender.Boy
      case 'SHE':
        return Gender.Girl
      default:
        return undefined
    }
  }

  const getBreedData = (): NonNullable<DogData['breed']> => {
    // If Dog has the old unknown breed then remove the breed as this breed will be inactive
    if (ghost.breed1 === OLD_UNKNOWN_DOG_BREED_ID) {
      return {
        type: BreedType.Unknown,
        primary: null,
        secondary: null,
      }
    }

    const unknownBreeds = useDataStore().unknownBreeds
    const unknownBreedIds = [...unknownBreeds.map((breed) => breed.id)]

    // Set the dog as unknown breed
    if (ghost.breed1 && unknownBreedIds.includes(ghost.breed1) && !ghost.breed2) {
      return {
        type: BreedType.Unknown,
        primary: ghost.breed1,
        secondary: null,
      }
    }

    // Set the dog as mixed breed
    if (ghost.breed1 && ghost.breed2) {
      return {
        type: BreedType.Mix,
        primary: ghost.breed1,
        secondary: ghost.breed2,
      }
    }

    // Set the dog as pure breed
    if (ghost.breed1) {
      return {
        type: BreedType.Pure,
        primary: ghost.breed1,
        secondary: null,
      }
    }

    return {
      type: BreedType.Unknown,
      primary: null,
      secondary: null,
    }
  }

  const getIllnesses = (): number[] => {
    if (ghost.illnesses) {
      const names = ghost.illnesses?.split('_')

      const ids = []

      for (const name of names) {
        const record = dataStore.findRecordByName('illnesses', name)

        if (record) {
          ids.push(record.id)
        }
      }

      return ids
    }

    return []
  }

  const getAllergies = (): number[] => {
    if (ghost.allergies) {
      const names = ghost.allergies.split('_')

      const ids = []

      for (const name of names) {
        const record = dataStore.findRecordByName('allergies', name)

        if (record) {
          ids.push(record.id)
        }
      }

      return ids
    }

    return []
  }

  const getAge = (): DogData['age'] => {
    const now = new Date()
    const bornDate = new Date(
      ghost.bornYear ?? now.getFullYear(),
      ghost.bornMonth ? ghost.bornMonth - 1 : now.getMonth(),
    )

    const epoch = new Date(0)
    const delta = new Date(Date.now() - bornDate.getTime())

    const years = delta.getUTCFullYear() - epoch.getUTCFullYear()
    const months = delta.getUTCMonth() - epoch.getUTCMonth()

    let ageType = AgeType.Adult

    if (ghost.breed1) {
      const breed = useDataStore().findRecord('breeds', ghost.breed1)

      if (breed) {
        ageType = getDogAgeType(years, months, breed)
      }
    }

    return {
      is: ageType,
      years,
      months,
      type: BirthdayType.Age,
      date: '',
    }
  }

  const getAdultBreedWeight = (): number => {
    const gender = getGender()

    if (!gender) {
      return 0
    }

    const { primary, secondary } = getBreedData()

    const breedIds = []

    if (primary) {
      breedIds.push(primary)
    }

    if (secondary) {
      breedIds.push(secondary)
    }

    // Use the old unknown breed weight as a fallback
    if (!breedIds.length) {
      breedIds.push(OLD_UNKNOWN_DOG_BREED_ID)
    }

    const breeds = dataStore.findRecords('breeds', breedIds)

    return getAdultWeight(gender, ...breeds)
  }

  const dog = {
    gender: getGender(),
    breed: getBreedData(),
    age: getAge(),
    name: ghost.name,
    weight: ghost.adultWeight || ghost.currentWeight,
    bodyShape: ghost.bodyShapeId,
    hasAllergies: !!ghost.allergies?.length,
    allergies: getAllergies(),
    hasIllnesses: !!ghost.illnesses?.length,
    illnesses: getIllnesses(),
    activityLevel: ghost.activityLevelId,
    adultWeight: getAdultBreedWeight(),
    previouslyFed: {
      foodTypes: [],
      foodBrand: '',
    },
    recipes: recipes(),
    fussiness: ghost.fusinessId,
  }

  return dog
}

const getPlanType = ({ subscriptionTypeId }: BeenHereBeforeData): MealPlanType => {
  if (isDefined(subscriptionTypeId)) {
    return getMealPlanTypeById(subscriptionTypeId) ?? MealPlanType.Full
  }

  return MealPlanType.Full
}

const transformData = (data: BeenHereBeforeData): DeserializedData => {
  return {
    user: {
      firstName: data.firstname,
      lastName: data.lastname,
      email: data.email,
      postcode: data.shippingPostcode ? String(data.shippingPostcode) : '',
    },
    dogs: data.ghostAnimals?.map(ghostAsDogStepData) ?? [],
    plan: {
      type: getPlanType(data),
    },
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useBeenHereBeforeRequest = ({ email, customerHash }: { email?: string; customerHash?: string }) => {
  const user = useUserStore()
  const planStep = usePlanStep()
  const dogsStore = useDogsStore()
  const loadingPlanStep = useLoadingPlanStep()

  const fetchData = async (): Promise<BeenHereBeforeData | undefined> => {
    const query = customerHash ? { customerHash } : { email }
    const { data } = await useApi().post<BeenHereBeforeData | []>(
      'marketing/buildabox/beenHereBefore',
      query,
      unwrapData,
    )

    // If no data is found then the API returns an empty array
    if (Array.isArray(data)) {
      return undefined
    }

    return data
  }

  const importData = (data: DeserializedData): void => {
    // Reset the existing state
    useStateStore().resetState()

    // Import the user
    user.addUser(data.user)

    // Import the dogs if there are any
    if (data.dogs.length) {
      data.dogs.forEach((dog) => {
        dogsStore.addDog(dog as DogData)
      })
    }
    // Otherwise add a default dog
    else {
      dogsStore.addDog()
    }

    // Import the plan data
    planStep.update({ plan: { type: data.plan.type } })

    // If every dog has recipes then we can assume we have suitable recipes
    if (data.dogs.every((dog) => !!dog.recipes?.length)) {
      loadingPlanStep.update({ suitableRecipes: true })
    }
  }

  const load = async (): Promise<boolean> => {
    const data = await fetchData()

    if (data) {
      const payload = transformData(data)
      // Import the data
      importData(payload)
      // Migrate all data
      runStateMigrations()

      return true
    }

    return false
  }

  return {
    load,
  }
}
