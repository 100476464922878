import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type SegmentEvents } from '@lyka/vue-common/events/segment'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useButtonClickedEvent = () => {
  const send = async (data: SegmentEvents['Button Clicked']): Promise<void> => {
    await useSegment().track('Button Clicked', data)
  }

  return {
    send,
  }
}
