<script setup lang="ts">
import { computed } from 'vue'
import { LykaGrid, LykaModal } from '@lyka/ui'
import { assetUrl } from '@lyka/utils'
import {
  type Treat,
  type TreatHealthBenefit,
  getTreatHealthBenefits,
  getTreatImageURL,
  getTreatVideoURL,
} from '@/models/Treat'
import { stripQuotes } from '@/utils/stripQuotes'

const props = defineProps<{
  treat: Treat
  open: boolean
}>()

const emits = defineEmits<{
  (e: 'close'): void
}>()

const imageURL = computed(() => {
  return getTreatImageURL(props.treat)
})

const videoURL = computed(() => {
  return getTreatVideoURL(props.treat)
})

const healthBenefits = computed(() => {
  return getTreatHealthBenefits(props.treat)
})

const BENEFIT_IMAGE_MAP: Record<string, string> = {
  'green-tripe.svg': 'stomach.svg',
  'calcium.svg': 'bones.svg',
  'freeze-dried.svg': 'dog.svg',
} as const

const healthBenefitImage = (benefit: TreatHealthBenefit): string | undefined => {
  if (benefit.imageUrl) {
    let image = benefit.imageUrl

    if (image in BENEFIT_IMAGE_MAP) {
      image = BENEFIT_IMAGE_MAP[image]!
    }

    return assetUrl(`images/health-benefits/${image}`)
  }

  return undefined
}
</script>

<template>
  <LykaModal :open="open" :title="treat.displayName" size="lg" @close="emits('close')">
    <LykaGrid :cols="1" :md-cols="2" :y-gap="10" :sm-y-gap="0">
      <div
        class="tw-flex tw-justify-center tw-overflow-hidden tw-rounded-3xl tw-flex-shrink-0 tw-w-full tw-aspect-[4/3]"
      >
        <video
          v-if="videoURL"
          :poster="imageURL"
          :src="videoURL"
          class="tw-object-cover tw-aspect-[4/3]"
          playsinline
          loop
          muted
          autoplay
        />
      </div>
      <div class="tw-space-y-6">
        <div class="tw-space-y-4" v-html="stripQuotes(treat.aboutDescription)" />
        <ul class="tw-space-y-4">
          <li v-for="benefit in healthBenefits" :key="benefit.id" class="tw-flex tw-items-center tw-gap-4">
            <img v-if="healthBenefitImage(benefit)" :src="healthBenefitImage(benefit)" width="32" />
            <div>
              {{ stripQuotes(benefit.description) }}
            </div>
          </li>
        </ul>
      </div>
    </LykaGrid>
  </LykaModal>
</template>
