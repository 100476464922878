import type { DogData } from '@/stores/dogs'
import type { Breed } from '@/models/Breed'
import { AgeType, type Dog } from '@/models/Dog'
import type { Recipe } from '@/models/Recipe'
import { BreedType } from '@/steps/breed'
import { useDataStore } from '@/stores/data'
import { useRecipesStore } from '@/stores/recipes'
import { BirthdayType } from '@/steps/age'

export class DogFactory {
  private dataStore = useDataStore()
  private recipesStore = useRecipesStore()

  private getPrimaryBreed(data: DogData): Breed | undefined {
    if (data.breed?.primary) {
      return this.dataStore.findRecord('breeds', data.breed.primary)!
    }

    return undefined
  }

  private getSecondaryBreed(data: DogData): Breed | undefined {
    if (data.breed?.type === BreedType.Mix && data.breed.secondary) {
      return this.dataStore.findRecord('breeds', data.breed.secondary)
    }

    return undefined
  }

  private getDogRecipes(data: DogData): Recipe[] {
    return (data.recipes?.map((recipeId) => this.recipesStore.allRecipes.find(({ id }) => id === recipeId)) ??
      []) as Recipe[]
  }

  private getDogAge(data: DogData): {
    is: AgeType
    years: number
    months: number
    date: string
    type: BirthdayType
  } {
    return {
      is: data.age?.is ?? AgeType.Adult,
      years: data.age?.years ?? 0,
      months: data.age?.months ?? 0,
      date: data.age?.date ?? '',
      type: data.age?.type ?? BirthdayType.Date,
    }
  }

  private getFoodTypes(data: DogData): string[] {
    if (data.previouslyFed?.foodTypes) {
      const foodTypes = this.dataStore.findRecords('foodTypes', data.previouslyFed.foodTypes)

      return foodTypes.map(({ name }) => name)
    }

    return []
  }

  public createDog(data: DogData): Dog {
    const primaryBreed = this.getPrimaryBreed(data)
    const secondaryBreed = this.getSecondaryBreed(data)
    const dogAge = this.getDogAge(data)

    const dog = {
      name: data.name ?? '',
      gender: data.gender ?? undefined,
      hasAllergies: !!data.allergies?.length,
      hasIllness: data.hasIllnesses ?? false,
      age: dogAge,
      weight: {
        current: data.weight ?? 0,
        adult: data.weight || 0,
      },
      breed: {
        type: data.breed?.type ?? BreedType.Pure,
        primary: primaryBreed,
        secondary: secondaryBreed,
      },
      activity: data.activityLevel ? this.dataStore.findRecord('activityLevels', data.activityLevel) : undefined,
      // Sic
      fusinessLevel: data.fussiness ? this.dataStore.findRecord('fussinessLevels', data.fussiness) : undefined,
      bodyShape: data.bodyShape ? this.dataStore.findRecord('bodyShapes', data.bodyShape) : undefined,
      allergies: this.dataStore.findRecords('allergies', data.allergies ?? []),
      illnesses: this.dataStore.findRecords('illnesses', data.illnesses),
      recipes: this.getDogRecipes(data),
      foodBrand: data.previouslyFed?.foodBrand,
      foodTypes: this.getFoodTypes(data),
    }

    return dog
  }
}
