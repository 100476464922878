import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { pipe } from 'remeda'
import { unwrapData, useApi } from '@/composables/useApi'
import { type Breed, getAverageWeight } from '@/models/Breed'
import type { ActivityLevel } from '@/models/ActivityLevel'
import type { Allergy } from '@/models/Allergy'
import type { BodyShape } from '@/models/BodyShape'
import type { FussinessLevel } from '@/models/FussinessLevel'
import { type FoodType, FoodTypeName } from '@/models/FoodType'
import type { Illness } from '@/models/Illness'
import type { HealthBenefit } from '@/models/HealthBenefit'
import type { HowDidYouHearOption } from '@/models/HowDidYouHear'
import { OLD_UNKNOWN_DOG_BREED_ID } from '@/steps/breed'
import { useSentry } from '@/composables/useSentry'

export interface DataStore {
  activityLevels: ActivityLevel[]
  allergies: Allergy[]
  bodyShapes: BodyShape[]
  breeds: Breed[]
  foodTypes: FoodType[]
  fussinessLevels: FussinessLevel[]
  healthBenefits: HealthBenefit[]
  howDidYouHear: HowDidYouHearOption[]
  illnesses: Illness[]
}

// The API has a typo in it. Rename `fusinessLevels` to `fussinessLevels`
const renameFussinessLevelsKey = (data: Record<string, any>): Record<string, any> => {
  const { fusinessLevels, ...props } = data

  return {
    ...props,
    fussinessLevels: fusinessLevels,
  }
}

const transformer = (data: object): DataStore => {
  // Fix the typo on the fussinessLevels API data
  return pipe(data, unwrapData, renameFussinessLevelsKey) as DataStore
}

export const sortHowDidYouHearOptions = (options: HowDidYouHearOption[]): HowDidYouHearOption[] => {
  const OTHER_OPTION = 'Other'

  return options.sort((a, b) => {
    if (a.name === OTHER_OPTION) {
      return 1
    }

    if (b.name === OTHER_OPTION) {
      return -1
    }

    if (a.name.startsWith(OTHER_OPTION)) {
      return 1
    }

    if (b.name.startsWith(OTHER_OPTION)) {
      return -1
    }

    return 0
  })
}

export const filterAvailableBreeds = (data: Breed[]): Breed[] => {
  return data.filter((breed) => breed.active === true)
}

const UNKNOWN_BREED_PREFIX = 'Unknown'

export const filterKnownBreeds = (data: Breed[]): Breed[] => {
  return data.filter((breed) => !breed.name.startsWith(UNKNOWN_BREED_PREFIX))
}

export const filterUnknownBreeds = (data: Breed[]): Breed[] => {
  // Return breeds that start with `Unknown` and aren't the old `Unknown` breed
  return data.filter((breed) => breed.name.startsWith(UNKNOWN_BREED_PREFIX) && breed.id !== OLD_UNKNOWN_DOG_BREED_ID)
}

export const sortUnknownBreeds = (breeds: Breed[]): Breed[] => {
  return [...breeds].sort((a, b) => {
    const aAverageWeight = getAverageWeight(a)
    const bAverageWeight = getAverageWeight(b)

    if (aAverageWeight < bAverageWeight) {
      return -1
    }

    if (bAverageWeight > aAverageWeight) {
      return 1
    }

    return 0
  })
}

export const useDataStore = defineStore('dataStore', () => {
  const data = ref<Partial<DataStore>>({})
  const loaded = ref(false)
  const loading = ref(false)

  const breeds = computed(() => {
    return filterAvailableBreeds(data.value.breeds ?? [])
  })

  const knownBreeds = computed(() => {
    return filterKnownBreeds(breeds.value ?? [])
  })

  const unknownBreeds = computed(() => {
    return sortUnknownBreeds(filterUnknownBreeds(breeds.value ?? []))
  })

  const bodyShapes = computed(() => data.value.bodyShapes ?? [])
  const fussinessLevels = computed(() => data.value.fussinessLevels ?? [])
  const activityLevels = computed(() => data.value.activityLevels ?? [])
  const allergens = computed(() => data.value.allergies ?? [])
  const illnesses = computed(() => data.value.illnesses ?? [])

  const foodTypes = computed(() => {
    // Sort options so that 'Other' is the last item
    return (
      data.value.foodTypes?.sort((a, b) => {
        if (a.name === FoodTypeName.Other) {
          return 1
        }

        if (b.name === FoodTypeName.Other) {
          return -1
        }

        return 0
      }) ?? []
    )
  })

  const howDidYouHearOptions = computed(() => sortHowDidYouHearOptions(data.value.howDidYouHear ?? []))

  const findRecords = <K extends keyof DataStore, R extends DataStore[K][0]>(type: K, ids?: number[]): R[] => {
    const records = data.value[type] as R | undefined

    if (Array.isArray(ids) && records && Array.isArray(records)) {
      return records.filter((record) => ids.includes(record.id)) as R[]
    }

    return [] as R[]
  }

  const findRecord = <K extends keyof DataStore, R extends DataStore[K][0]>(type: K, id: number): R | undefined => {
    const records = data.value[type] as R

    if (records && Array.isArray(records)) {
      return records.find((record) => record.id === id) as R | undefined
    }

    return undefined
  }

  const findRecordByName = <K extends keyof DataStore, R extends DataStore[K][0]>(
    type: K,
    name: string,
  ): R | undefined => {
    const records = data.value[type] as R

    if (records && Array.isArray(records)) {
      return records.find((record) => record.name === name) as R | undefined
    }

    return undefined
  }

  const load = async (): Promise<void> => {
    if (loaded.value || loading.value) {
      return
    }

    const api = useApi()

    try {
      loading.value = true
      const response = await api.get<DataStore>('marketing/buildabox/initiate', {}, transformer)

      data.value = response.data
    } catch (err) {
      useSentry().error('Failed to fetch Build A Box initial data')
    } finally {
      loading.value = false
      loaded.value = true
    }
  }

  return {
    findRecord,
    findRecordByName,
    findRecords,
    load,
    loading,
    breeds,
    knownBreeds,
    unknownBreeds,
    bodyShapes,
    fussinessLevels,
    activityLevels,
    allergens,
    illnesses,
    howDidYouHearOptions,
    foodTypes,
  }
})
