<script setup lang="ts">
import { computed } from 'vue'
import { LykaFieldList, LykaRadio, LykaTransitionSlideIn } from '@lyka/ui'
import slugify from 'slugify'
import { type Illness, getIllnessDescription } from '@/models/Illness'
import type { IllnessesStepData } from '@/steps/illnesses'
import { useDogsStore } from '@/stores/dogs'
import StepHeading from '@/components/StepHeading.vue'

type DogIllnessData = IllnessesStepData['dogs'][0]

const props = defineProps<{
  dogIndex: number
  dog: DogIllnessData
  illnesses: Illness[]
}>()

const emits = defineEmits<{
  (e: 'update', dogIndex: number, data: DogIllnessData): void
}>()

const dogsStore = useDogsStore()

const illnessDescription = (illness: Illness, dogIndex: number): string => {
  const dogName = dogsStore.dogName(dogIndex)

  return dogName ? getIllnessDescription(illness, dogName) : ''
}

const dogHasIllnesses = computed({
  get() {
    return props.dog.hasIllnesses
  },
  set(value) {
    emits('update', props.dogIndex, {
      hasIllnesses: value,
      illnesses: [],
      unknownIllness: false,
    })
  },
})

const dogIllnesses = computed({
  get() {
    return props.dog.illnesses
  },
  set(value) {
    emits('update', props.dogIndex, {
      ...props.dog,
      illnesses: value,
    })
  },
})

const dogUnknownIllness = computed({
  get() {
    return props.dog.unknownIllness
  },
  set(value) {
    emits('update', props.dogIndex, {
      ...props.dog,
      unknownIllness: value,
    })
  },
})

const getIllnessSlug = (illnessName: string): string => slugify(illnessName, { lower: true, strict: true })
</script>

<template>
  <StepHeading> Does {{ dogsStore.dogName(dogIndex) }} have any health issues? </StepHeading>

  <LykaFieldList>
    <LykaRadio
      v-model="dogHasIllnesses"
      :input-value="true"
      size="lg"
      type="button"
      block
      label="Yes"
      :name="`has-illnesses-${dogIndex}`"
    />
    <LykaRadio
      v-model="dogHasIllnesses"
      :input-value="false"
      size="lg"
      type="button"
      block
      label="No"
      :name="`has-illnesses-${dogIndex}`"
    />
  </LykaFieldList>

  <LykaTransitionSlideIn>
    <div v-if="dog.hasIllnesses">
      <div class="tw-flex tw-justify-center tw-items-center tw-h-24 first:tw-hidden">
        <div class="tw-h-px tw-w-52 tw-bg-input" />
      </div>

      <StepHeading> Please select any health conditions that apply to {{ dogsStore.dogName(dogIndex) }} </StepHeading>

      <h3 class="tw-h3" />

      <LykaFieldList>
        <LykaRadio
          v-for="illness in illnesses"
          :id="`illnesses-${dogIndex}-${getIllnessSlug(illness.displayText)}`"
          :key="illness.id"
          v-model="dogIllnesses"
          :name="`illnesses-${dogIndex}`"
          :input-value="illness.id"
          :label="illness.displayText"
          type="fancy"
          size="lg"
          checkbox
        >
          <template #description>
            {{ illnessDescription(illness, dogIndex) }}
          </template>
        </LykaRadio>
        <LykaRadio
          v-model="dogUnknownIllness"
          label="None of the above"
          :name="`illnesses-${dogIndex}`"
          type="fancy"
          size="lg"
          :input-value="true"
          checkbox
        />
      </LykaFieldList>
    </div>
  </LykaTransitionSlideIn>
</template>
