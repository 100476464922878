// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFormValidator = (form: Element | EventTarget | null) => {
  const validate = (): boolean => {
    return form instanceof HTMLFormElement ? form.reportValidity() : false
  }

  return {
    validate,
  }
}
