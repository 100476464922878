import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { EventSender } from '../EventSender'
import type { Coupon } from '@/models/Coupon'

export const useCouponAppliedSuccessEvent: EventSender = (coupon: Coupon) => {
  const send = (): void => {
    useSegment().track('Coupon Applied', {
      coupon: coupon.couponCode,
      discount: coupon.discount,
      referral: !!coupon.referrer?.uuid,
      success: true,
    })
  }

  return {
    send,
  }
}
