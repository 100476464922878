<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import { LykaButton, LykaInput, LykaTransitionSlideIn } from '@lyka/ui'
import { clone } from 'remeda'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import StepContainer from '../StepContainer.vue'
import StepNavigation from '../StepNavigation.vue'
import StepHeading from '../StepHeading.vue'
import LocationDogsInPostcode from './location/LocationDogsInPostcode.vue'
import type { LocationStepData } from '@/steps/location'
import { useDogsStore } from '@/stores/dogs'
import { useVerifyPostcode } from '@/composables/useVerifyPostcode'
import { useLocalitiesStore } from '@/stores/localities'

const props = defineProps<{
  stepData: LocationStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
  (e: 'next'): void
}>()

const data = reactive(clone(props.stepData))
const dogsStore = useDogsStore()
const submitting = ref(false)
const mutated = ref(false)
const dogsInPostcode = ref<number>()
const localities = ref<string[]>([])
const verified = computed(() => data.user.postcodeValid)

watch(data, (newData) => emits('save', newData), { deep: true })

const invalidatePostcode = (): void => {
  data.user.postcodeValid = false
}

// If the postcode is modified then we need to unverify it
const onPostcodeChange = (): void => {
  mutated.value = true
  dogsInPostcode.value = undefined
  localities.value = []

  invalidatePostcode()
}

const heading = computed(() => {
  const count = dogsStore.dogCount
  const [first, second] = dogsStore.dogNames

  switch (count) {
    case 1: {
      return `Where does ${first} live?`
    }
    case 2: {
      return `Hello, ${first} and ${second}! And where do they live?`
    }
    default: {
      return 'Hello! And where do they live?'
    }
  }
})

const getLocalitiesInPostcode = async (postcode: string): Promise<void> => {
  const localitiesStore = useLocalitiesStore()
  await localitiesStore.load()

  localities.value = localitiesStore.findByPostcode(postcode)
}

const invalid = computed(() => !props.valid)

const nextButtonDisabled = computed(() => {
  return !verified.value || mutated.value
})

const submit = async ({ target }: Event): Promise<void> => {
  if (verified.value) {
    emits('next')
  }

  const valid = useFormValidator(target).validate()

  if (!valid || invalid.value || submitting.value) {
    return
  }

  submitting.value = true

  try {
    const { valid, dogs } = await useVerifyPostcode().verify(data.user.postcode)
    data.user.postcodeValid = valid

    if (valid && mutated.value) {
      await getLocalitiesInPostcode(data.user.postcode)
      dogsInPostcode.value = dogs

      emits('save', data)
    } else {
      emits('submit', data)
    }
  } finally {
    mutated.value = false
    submitting.value = false
  }
}
</script>

<template>
  <form id="formLocation" v-submit @submit.prevent="submit">
    <template v-if="Number.isInteger(dogsInPostcode)">
      <LykaTransitionSlideIn>
        <LocationDogsInPostcode
          :postcode="data.user.postcode"
          :number-of-dogs="dogsInPostcode"
          :localities="localities"
        />
      </LykaTransitionSlideIn>
    </template>

    <StepContainer v-else>
      <StepHeading>{{ heading }}</StepHeading>

      <LykaInput
        v-model="data.user.postcode"
        name="postcode"
        minlength="4"
        maxlength="4"
        required
        type="text"
        pattern="[0-9]*"
        placeholder="Enter your postcode"
        error-message="Please enter your postcode"
        @input="onPostcodeChange"
      />

      <div v-if="!verified" class="tw-mt-4">
        <LykaButton :disabled="invalid" block-at="sm" variant="alt" type="submit" :loading="submitting"
          >Check availability</LykaButton
        >
      </div>
    </StepContainer>

    <StepNavigation
      submit
      :next-disabled="nextButtonDisabled"
      form="formLocation"
      :next-loading="submitting"
      @previous="emits('previous')"
    />
  </form>
</template>
