import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { unwrapData, useApi } from './useApi'

interface EmailCapturedData {
  anonymousUserId?: string
  subscribed: boolean
  email: string
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useEmailCaptured = () => {
  const track = ({
    email,
    firstName,
    subscribed,
    eventId,
  }: {
    email: string
    firstName: string
    subscribed: boolean
    eventId: string
  }): void => {
    useSegment().identify({
      email,
      firstName,
    })

    useSegment().track(
      'Email Captured',
      {
        eventId,
        email,
        firstName,
        emailOptIn: subscribed,
      },
      true,
    )
  }

  const send = async (email: string, subscribed: boolean): Promise<string> => {
    const { getAnonymousId } = useSegment()

    const payload: EmailCapturedData = {
      anonymousUserId: await getAnonymousId(),
      subscribed,
      email,
    }

    const { data } = await useApi().post<{ eventId: string }, EmailCapturedData>(
      'users/emailCaptured',
      payload,
      unwrapData,
    )

    return data.eventId
  }

  const subscribe = async (email: string, firstName: string): Promise<void> => {
    const eventId = await send(email, true)
    track({
      email,
      firstName,
      subscribed: true,
      eventId,
    })
  }

  const unsubscribe = async (email: string, firstName: string): Promise<void> => {
    const eventId = await send(email, false)
    track({
      email,
      firstName,
      subscribed: false,
      eventId,
    })
  }

  return {
    subscribe,
    unsubscribe,
  }
}
