import type { Step } from './Step'

export type StepInstantiator<S = Step> = () => S

export const registeredSteps = new Set<StepInstantiator>()

export const instantiateSteps = (): void => {
  registeredSteps.forEach((instantiator) => {
    instantiator()
  })
}

export const registerStep = <S extends Step>(StepClass: { new (): S }): StepInstantiator<S> => {
  let instance: S

  const instantiator = (): S => {
    instance ??= new StepClass()

    return instance
  }

  registeredSteps.add(instantiator)

  return instantiator
}
