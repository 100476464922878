<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import type { LykaAccordionEvent } from '../types/events/LykaAccordionEvent'
import LykaIcon, { type Icon } from './LykaIcon.vue'
import IconSelectDown from './icon/SelectDown.vue'

const props = defineProps<{
  title?: string
  titleIcon?: Icon
  basic?: boolean
  titleActiveClass?: string
  titleInactiveClass?: string
  // These props are required for mutually exclusive close events
  index?: number
  groupIndex?: string
  emitter?: any
}>()

const active = ref(false)
const innerHeight = ref(0)
const target = ref()

const accordionBg = computed(() => {
  if (props.basic) {
    return ''
  }

  return active.value
    ? `tw-bg-alt hover:tw-bg-alt-hover focus:tw-bg-alt-hover ${props.titleActiveClass}`
    : `tw-bg-cream hover:tw-bg-secondary-hover hover:tw-bg-opacity-20 focus:tw-bg-secondary-hover focus:tw-bg-opacity-20 ${props.titleInactiveClass}`
})

const accordionTextColor = computed(() => {
  if (props.basic) {
    return 'tw-text-alt'
  }

  return active.value ? 'tw-text-light' : 'tw-text-alt'
})

const toggleAccordion = (): void => {
  if (props.emitter) {
    props.emitter.emit('accordion', { groupIdx: props.groupIndex, activeIdx: props.index })
  }
  active.value = !active.value
}

if (props.emitter) {
  props.emitter.on('accordion', (e: LykaAccordionEvent) => {
    if (e.groupIdx === props.groupIndex && e.activeIdx !== props.index) {
      active.value = false
    }
  })
}

const contentHeight = computed(() => (active.value ? innerHeight.value : 0))

onMounted(() => (innerHeight.value = target.value.scrollHeight))
</script>

<template>
  <div :class="{ 'tw-mb-2': basic }">
    <button
      type="button"
      class="tw-relative tw-rounded-3xl tw-flex tw-items-center tw-justify-between tw-text-left tw-outline-none tw-space-x-2 tw-appearance-none summary"
      :class="[accordionBg, accordionTextColor, { 'p1 tw-px-5 tw-py-2.5 tw-w-full': !basic }]"
      @click="toggleAccordion"
    >
      <LykaIcon v-if="props.titleIcon" :name="props.titleIcon" class="tw-absolute tw-w-5 tw-h-5" />
      <span :class="[{ 'tw-pl-5': props.titleIcon }]">{{ props.title }}</span>
      <IconSelectDown class="tw-transform tw-shrink-0" :class="{ 'tw-rotate-180': active }" />
    </button>

    <div
      ref="target"
      class="tw-text-alt tw-overflow-hidden tw-transition-all"
      :style="`max-height: ${contentHeight}px`"
    >
      <div class="tw-py-6" :class="{ 'tw-px-6 ': !basic }">
        <slot />
      </div>
    </div>
  </div>
</template>
