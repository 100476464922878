import { GTMEventAction, GTMEventName, useGTM } from '../useGTM'
import { OutbrainEventName, useOutbrain } from '../useOutbrain'
import { useOrderCompletedEvent } from './segment/useOrderCompletedEvent'
import { useImpactOrderDetailsEvent } from './gtm/useImpactOrderDetailsEvent'
import { useSurveyCompletedEvent } from './segment/useSurveyCompletedEvent'
import type { EventSender } from './EventSender'
import type { PaymentMethod } from '@/composables/useCheckout'
import { useCheckoutStep } from '@/steps/checkout'
import type { CheckoutOrder } from '@/models/CheckoutOrder'

export const useCheckoutOrderCreatedEvents: EventSender = ({
  order,
  totalPrice,
  eventId,
  paymentMethod,
}: {
  order: CheckoutOrder
  totalPrice: number
  eventId: string
  paymentMethod: PaymentMethod
}): { send(): void } => {
  const gtm = useGTM()

  const sendGTMHowDidYouHearEvent = (): void => {
    if (order.howDidYouHear) {
      const value = order.howDidYouHear.other ?? order.howDidYouHear.option

      if (value) {
        gtm.sendAction(GTMEventAction.ATTRIBUTION_SURVEY, value)
      }
    }
  }

  const sendGTMSMSOptInEvent = (): void => {
    if (order.user) {
      gtm.sendEvent(GTMEventName.SMS_OPT_IN, {
        customerSmsOptIn: useCheckoutStep().data.user.smsOptIn,
        email: order.user?.email,
      })
    }
  }

  const sendGTMCheckedOutEvent = (): void => {
    gtm.sendAction(GTMEventAction.CHECKED_OUT, gtm.toPriceRange(totalPrice))
  }

  const sendOutbrainCheckoutCompleteEvent = (): void => {
    useOutbrain().track(OutbrainEventName.CHECKOUT_COMPLETE)
  }

  const send = (): void => {
    sendGTMSMSOptInEvent()
    sendGTMHowDidYouHearEvent()
    sendGTMCheckedOutEvent()
    sendOutbrainCheckoutCompleteEvent()

    useSurveyCompletedEvent({ order }).send()
    useOrderCompletedEvent({ order, totalPrice, eventId, paymentMethod }).send()
    useImpactOrderDetailsEvent({ order }).send()
  }

  return {
    send,
  }
}
