<script setup lang="ts">
import { computed } from 'vue'
import { isTherapeuticRecipe } from '@/models/Recipe'
import { useRecipesStore } from '@/stores/recipes'

const includesHypoProGuide = computed(() => {
  return useRecipesStore().selectedRecipes.every(isTherapeuticRecipe)
})
</script>

<template>
  <div class="tw-space-y-8 lg:tw-space-y-12">
    <h2 class="tw-h2">
      <slot name="heading">
        Included in your
        <span class="tw-text-green tw-whitespace-nowrap">First Box</span>
      </slot>
    </h2>

    <div class="group">
      <div class="section">
        <img
          class="image"
          src="@/assets/images/steps/plans/starter/pouches.webp"
          alt="A bundle of packaged Lyka meal pouches"
          width="758"
          height="551"
        />

        <div class="tw-space-y-4">
          <h3 class="tw-h3">Your personalised meals</h3>
          <p>Freshly prepared, lightly cooked and bursting with a balanced mix of protein, wholefoods and vitamins.</p>
        </div>
      </div>

      <div class="section">
        <img
          class="image"
          src="@/assets/images/steps/plans/starter/probiotic.webp"
          alt="A container of Lyka's probiotic Go-To Gut Helper"
          width="758"
          height="551"
        />
        <div class="tw-space-y-4">
          <h3 class="tw-h3">Go-To Gut Helper</h3>
          <p>Probiotic powder to support a smooth transition to fresh feeding.</p>
        </div>
      </div>

      <div v-if="includesHypoProGuide" class="section">
        <img
          class="image"
          src="@/assets/images/steps/plans/hypoallergenic-pro-guide.jpg"
          alt="Lyka Transition Game Plan"
          width="758"
          height="551"
        />
        <div class="tw-space-y-4">
          <h3 class="tw-h3">Hypoallergenic Pro Guide</h3>
          <p>Tips for a smooth transition to a hypoallergenic diet.</p>
        </div>
      </div>

      <div v-else class="section">
        <img
          class="image"
          src="@/assets/images/steps/plans/starter/board.webp"
          alt="Lyka Transition Game Plan"
          width="758"
          height="551"
        />
        <div class="tw-space-y-4">
          <h3 class="tw-h3">Lyka Transition Game Plan</h3>
          <p>Your 28-day guide for a smooth transition to fresh food.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.group {
  @apply tw-grid tw-justify-center lg:tw-grid-flow-col lg:tw-auto-cols-fr tw-gap-8;
}

.section {
  @apply tw-space-y-8 tw-max-w-lg lg:tw-max-w-sm;
}

.image {
  @apply tw-rounded-2xl tw-bg-neutral;
}
</style>
