<template>
  <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_23545)">
      <path
        d="M3 0C1.34375 0 0 1.34375 0 3V23C0 24.6562 1.34375 26 3 26H4C4 29.3125 6.6875 32 10 32C13.3125 32 16 29.3125 16 26H24C24 29.3125 26.6875 32 30 32C33.3125 32 36 29.3125 36 26H38C39.1063 26 40 25.1063 40 24C40 22.8937 39.1063 22 38 22V18V16V14.8313C38 13.7688 37.5812 12.75 36.8312 12L32 7.16875C31.25 6.41875 30.2313 6 29.1688 6H26V3C26 1.34375 24.6562 0 23 0H3ZM26 10H29.1688L34 14.8313V16H26V10ZM7 26C7 25.2044 7.31607 24.4413 7.87868 23.8787C8.44129 23.3161 9.20435 23 10 23C10.7956 23 11.5587 23.3161 12.1213 23.8787C12.6839 24.4413 13 25.2044 13 26C13 26.7956 12.6839 27.5587 12.1213 28.1213C11.5587 28.6839 10.7956 29 10 29C9.20435 29 8.44129 28.6839 7.87868 28.1213C7.31607 27.5587 7 26.7956 7 26ZM30 23C30.7956 23 31.5587 23.3161 32.1213 23.8787C32.6839 24.4413 33 25.2044 33 26C33 26.7956 32.6839 27.5587 32.1213 28.1213C31.5587 28.6839 30.7956 29 30 29C29.2044 29 28.4413 28.6839 27.8787 28.1213C27.3161 27.5587 27 26.7956 27 26C27 25.2044 27.3161 24.4413 27.8787 23.8787C28.4413 23.3161 29.2044 23 30 23Z"
        fill="#10B193"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_23545">
        <rect width="40" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
