<script setup lang="ts">
import { LykaButton } from '@lyka/ui'
import PlusIcon from '../../icons/PlusIcon.vue'
import MinusIcon from '../../icons/MinusIcon.vue'
import type { Product } from '@/models/Product'

defineProps<{
  product: Product
  quantity: number
  disabled?: boolean
}>()

const emits = defineEmits<{
  add: [product: Product]
  remove: [product: Product]
}>()
</script>

<template>
  <div id="treat-sku" class="tw-flex tw-justify-between tw-items-center">
    <div>
      <h5>{{ product.customerSubDisplayName }}</h5>
      <span class="tw-text-success tw-font-light">{{ product.skuSize }}</span>
    </div>
    <div class="tw-flex tw-space-x-1">
      <LykaButton
        id="minus-treats"
        outline
        square
        variant="success"
        size="xs"
        aria-label="Remove treat"
        :disabled="quantity === 0"
        @click="emits('remove', product)"
      >
        <MinusIcon />
      </LykaButton>
      <input
        readonly
        tabindex="-1"
        :value="quantity"
        size="2"
        class="tw-text-center tw-outline-none tw-rounded-full tw-w-10 tw-bg-white"
      />
      <LykaButton
        id="plus-treats"
        outline
        square
        variant="success"
        size="xs"
        aria-label="Add treat"
        :disabled="disabled"
        @click="emits('add', product)"
      >
        <PlusIcon />
      </LykaButton>
    </div>
  </div>
</template>
