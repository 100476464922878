import { OLD_UNKNOWN_DOG_BREED_ID, useBreedStep } from '@/steps/breed'

export const removeOldUnknownBreed = (): void => {
  // Strip the old unknown breed off any dogs that have it. Users will need to select a different unknown breed
  for (const dog of useBreedStep().data.dogs) {
    if (dog.breed.primary === OLD_UNKNOWN_DOG_BREED_ID) {
      dog.breed.primary = null

      useBreedStep().saveState()
    }
  }
}
