<script setup lang="ts">
import { LykaInput } from '@lyka/ui'
import { computed, ref, watchEffect } from 'vue'

const emits = defineEmits<{
  (e: 'update', password: string): void
}>()

const password = ref('')
const passwordDirty = ref(false)
const confirmPassword = ref('')
const confirmPasswordDirty = ref(false)

const passwordsMatch = computed(() => {
  return password.value === confirmPassword.value
})

const showError = computed(() => {
  return !passwordsMatch.value && passwordDirty.value && confirmPasswordDirty.value
})

const passwordsVisible = ref(false)

watchEffect(() => {
  if (passwordsMatch.value) {
    emits('update', password.value)
  } else {
    emits('update', '')
  }
})

const hidePasswords = (): void => {
  passwordsVisible.value = false
}

const showPasswords = (): void => {
  passwordsVisible.value = true
}
</script>

<template>
  <div class="tw-grid tw-gap-y-8">
    <LykaInput
      v-model="password"
      name="password"
      type="password"
      label="Password"
      autocomplete="off"
      placeholder="Enter password"
      required
      minlength="5"
      error-message="Please enter a password with at least 5 characters"
      :password-visible="passwordsVisible"
      @change="passwordDirty = true"
      @password-shown="showPasswords"
      @password-hidden="hidePasswords"
    />
    <LykaInput
      v-model="confirmPassword"
      name="confirm-password"
      type="password"
      label="Confirm password"
      autocomplete="off"
      placeholder="Confirm password"
      required
      :error="showError"
      error-message="Please ensure the passwords match"
      minlength="5"
      :password-visible="passwordsVisible"
      @change="confirmPasswordDirty = true"
      @password-shown="showPasswords"
      @password-hidden="hidePasswords"
    />
  </div>
</template>
