<script setup lang="ts">
import { LykaButton, LykaModal } from '@lyka/ui'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { useSession } from '@/composables/useSession'
import { useStepsStore } from '@/stores/steps'

defineProps<{
  open: boolean
}>()

const emits = defineEmits<{
  (e: 'close'): void
}>()

const { continueSession } = useSession()
const stepsStore = useStepsStore()

const handleContinue = (): void => {
  continueSession()
  emits('close')
}

const handleAction = (action: string): void => {
  useSegment().track('Continue Session Completed', {
    action,
    stepName: stepsStore.getLastStep().name,
  })

  switch (action) {
    case 'Dismiss':
      emits('close')
      break
    case 'Continue':
      handleContinue()
  }
}
</script>

<template>
  <LykaModal :open="open" title="Continue session?" @close="emits('close')">
    <div class="tw-space-y-2">
      <h4 class="tw-h3">Welcome back!</h4>
      <p>Would you like to continue your previous session?</p>
    </div>

    <template #footer>
      <LykaButton
        id="previous-session-cancel"
        hide-at="sm"
        text-link
        decoration
        variant="alt"
        @click="handleAction('Dismiss')"
      >
        Dismiss
      </LykaButton>
      <LykaButton id="previous-session-continue" block-at="sm" @click="handleAction('Continue')">
        Yes, continue
      </LykaButton>
    </template>
  </LykaModal>
</template>
