import { ref } from 'vue'

const visible = ref(false)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useBeenHereBeforeModal = () => {
  const show = (): void => {
    visible.value = true
  }

  const hide = (): void => {
    visible.value = false
  }

  return {
    show,
    hide,
    visible,
  }
}
