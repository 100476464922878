import { AnalyticsBrowser } from '@segment/analytics-next'
import { type App, type Plugin } from 'vue'

export interface SegmentOptions {
  writeKey: string
}

declare global {
  interface Window {
    segment?: AnalyticsBrowser
  }
}

export const segmentPlugin: Plugin = {
  async install(_: App, { writeKey }: SegmentOptions): Promise<void> {
    try {
      const instance = AnalyticsBrowser.load({ writeKey })

      instance.catch(() => {
        console.error('Error loading segment')
        window.segment = undefined
      })

      window.segment ??= instance
    } catch (err) {
      console.error('Error loading segment: ', err)
    }
  },
}
