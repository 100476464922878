import { useApi } from './useApi'

interface FreeTreatRequestData {
  productName: string
}

interface OrderMeal {
  name: string
}

interface UpcomingOrder {
  deliveryDate: string
  deliverySlot: string
  orderMeals: OrderMeal[]
}

export interface OrderInfo {
  email?: string
  deliveryDate?: Date
  deliveryTime?: string
  animalNames?: string[]
}

interface UserProfile {
  data: {
    email: string
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const usePostCheckout = () => {
  const api = useApi({ authenticate: true })

  const getOrderInfo = async (): Promise<OrderInfo> => {
    const [orderResp, profileResp] = [
      await api.get<UpcomingOrder[]>('users/getUsersUpcomingOrders/1'),
      await api.get<UserProfile>('users/getUserProfile'),
    ]

    const [order] = orderResp.data ?? []
    const { email } = profileResp.data?.data ?? {}

    return {
      email,
      deliveryDate: order && new Date(order.deliveryDate),
      deliveryTime: order && order.deliverySlot,
      animalNames: order?.orderMeals?.map((meal) => meal.name),
    }
  }

  const submitFreeTreat = async ({ productName }: FreeTreatRequestData): Promise<boolean> => {
    const { status } = await api.post('orders/products/addEligibleProductPostPurchase', {
      product_name: productName,
    })

    return status === 200
  }

  return {
    submitFreeTreat,
    getOrderInfo,
  }
}
