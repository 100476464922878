<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  inputType: 'radio' | 'checkbox'
  inputValue: any
  modelValue: any
  title: string
  description?: string
  small?: boolean
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', value: any): void
}>()

const value = computed({
  get() {
    return props.modelValue
  },
  set(value: any) {
    emits('update:modelValue', value)
  },
})
</script>

<template>
  <label class="option-tile">
    <input v-model="value" class="tw-sr-only tw-peer" :type="inputType" :value="inputValue" />
    <span class="option-tile-label" :class="{ 'option-tile-label-sm': small }">
      <span class="option-tile-checked">
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" fill="none" viewBox="0 0 11 8">
          <path
            fill="currentColor"
            d="M3.6 7.8.2 4.4a.5.5 0 0 1 0-.8l.7-.8c.2-.2.6-.2.8 0L4 5.2l5-5c.3-.3.6-.3.8 0l.8.7c.2.2.2.6 0 .8L4.4 7.8c-.2.3-.5.3-.8 0Z"
          />
        </svg>
      </span>

      <span v-if="$slots.icon" class="tw-h-16 tw-min-w-[82px] tw-max-w-[82px] tw-flex">
        <slot name="icon" />
      </span>
      <span class="tw-flex tw-flex-col tw-space-y-3 tw-flex-grow">
        <span>{{ title }}</span>
        <span class="tw-block tw-text-sm tw-opacity-80 tw-space-y-2">
          <slot v-if="$slots.description" name="description" />
          <template v-else>
            {{ description }}
          </template>
        </span>
      </span>
    </span>
  </label>
</template>

<style scoped>
.option-tile {
  @apply tw-flex tw-flex-1 tw-w-full tw-max-w-xs;
}

.option-tile-label {
  @apply tw-relative;
  @apply tw-select-none tw-cursor-pointer tw-flex lg:tw-inline-flex tw-items-start tw-justify-center tw-text-left tw-p-6 tw-gap-4 tw-rounded-2xl tw-bg-white tw-w-full tw-border tw-border-gold tw-transition-all;
  @apply hover:tw-border-alt focus:tw-border-alt;
  @apply peer-checked:tw-bg-mint-green peer-checked:tw-bg-opacity-30 peer-checked:tw-border-dark;
}

.option-tile-label-sm {
  @apply tw-px-4 tw-py-6;
}

.option-tile-checked {
  @apply tw-absolute tw-right-3 tw-top-3 tw-opacity-0 tw-transition-opacity tw-duration-300 tw-scale-50 tw-ease-in-out;
}

.option-tile input:checked + .option-tile-label .option-tile-checked {
  @apply tw-opacity-100 tw-scale-100;
}
</style>
