<script setup lang="ts">
import { computed } from 'vue'
import { LykaButton, LykaInput } from '@lyka/ui'
import CheckoutAddressManual from './CheckoutAddressManual.vue'
import CheckoutAddressFinder from './CheckoutAddressFinder.vue'
import type { CheckoutStepAddressData } from '@/steps/checkout'

const props = defineProps<{
  address: CheckoutStepAddressData
  invalid?: boolean
}>()

const emits = defineEmits<{
  (e: 'update', address: (typeof props)['address']): void
}>()

const isManual = computed({
  get() {
    return props.address.isManual
  },

  set(value: boolean) {
    if (value) {
      emits('update', {
        ...props.address,
        isManual: true,
      })
    } else {
      emits('update', {
        ...props.address,
        isManual: value,
      })
    }
  },
})

const businessName = computed<string>({
  get() {
    return props.address.businessName
  },
  set(value: string) {
    emits('update', {
      ...props.address,
      businessName: value,
    })
  },
})

const isWork = computed<boolean>({
  get() {
    return props.address.isWork
  },
  set(value: boolean) {
    emits('update', {
      ...props.address,
      isWork: value,
      // If switching to non-work address clear the business name
      businessName: value ? props.address.businessName : '',
    })
  },
})

const toggleManualAddress = (): void => {
  isManual.value = !isManual.value
}
const toggleBusinessName = (): void => {
  isWork.value = !isWork.value
}
</script>

<template>
  <div class="tw-space-y-4">
    <div class="tw-text-sm tw-mb-6">
      <div>
        Delivering to a <span v-if="isWork">home</span><span v-else>workplace</span>?
        <LykaButton
          id="checkout-address-business-toggle"
          type="button"
          variant="success"
          text-size="sm"
          decoration
          text-link
          @click="toggleBusinessName"
        >
          click here
        </LykaButton>
      </div>
      <div class="tw-font-light">Note: We do not deliver to P.O boxes</div>
    </div>

    <LykaInput
      v-if="isWork"
      id="address-business-name"
      v-model="businessName"
      name="address-business-name"
      placeholder="Business name"
      required
      spellcheck="false"
      label="Company name"
      error-message="Your company name is required"
    />

    <CheckoutAddressManual
      v-if="isManual"
      :address="address"
      :invalid="invalid"
      @change="(address) => emits('update', address)"
    >
      <div class="sm:tw-col-span-2">
        <LykaButton type="button" variant="success" decoration text-link @click="toggleManualAddress">
          Return to address lookup
        </LykaButton>
      </div>
    </CheckoutAddressManual>

    <CheckoutAddressFinder v-else :address="address" :invalid="invalid" @change="(address) => emits('update', address)">
      <div class="sm:tw-col-span-2">
        <LykaButton type="button" variant="success" decoration text-link @click="toggleManualAddress">
          Enter address manually
        </LykaButton>
      </div>
    </CheckoutAddressFinder>
  </div>
</template>
