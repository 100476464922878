<script setup lang="ts">
import { onMounted, ref } from 'vue'
import type { StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { useStripe } from '@/services/useStripe'

const emits = defineEmits<{
  (e: 'created', card: StripeCardElement): void
  (e: 'change', event: StripeCardElementChangeEvent): void
}>()

const element = ref<HTMLElement>()

const mountStripeCardElement = async (element: HTMLElement): Promise<void> => {
  const card = await useStripe().createCardElement()

  card.mount(element)

  card.on('ready', () => {
    emits('created', card)
  })

  card.on('change', (ev) => {
    emits('change', ev)
  })
}

onMounted(async () => {
  if (element.value instanceof HTMLElement) {
    mountStripeCardElement(element.value)
  }
})
</script>

<template>
  <div
    class="tw-rounded-3xl tw-bg-white tw-border tw-border-gold tw-px-6 tw-h-11 tw-flex tw-items-center tw-justify-stretch"
  >
    <div ref="element" class="tw-grow" />
  </div>
</template>
