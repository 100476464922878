<script setup lang="ts"></script>

<template>
  <div class="tw-text-xs tw-text-center tw-opacity-70 tw-space-y-2">
    <p>
      By clicking "Place my order" you're agreeing to a recurring subscription. You can pause or cancel at any time.
      We'll always send a reminder before taking payment for future deliveries.
    </p>
  </div>
</template>
