import { reactive } from 'vue'
import { defer } from '@lyka/utils/src/defer'

type ExperimentVariant = 'experiment' | 'control' | string

type ExperimentName =
  | 'bab-checkout-redesign'
  | 'bab-apple-pay'
  | 'bab-checkout-free-treats'
  | 'bab-stripe-payment-element'
  | 'bab-plan-redesign-september'
  | 'bab-remove-fish'

type ExperimentFlags = Record<string, string>

const experiments = reactive(new Map<string, ExperimentVariant>())

const loaded = defer<void>()

const DEFAULT_EXPERIMENT_VARIANT = 'experiment' as const

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useExperiments = () => {
  const getVariant = (experiment: string): string | undefined => {
    return experiments.get(experiment)
  }

  const inVariant = (experiment: string, variant: string): boolean => {
    return getVariant(experiment) === variant
  }

  const inExperiment = (experiment: ExperimentName): boolean => {
    return inVariant(experiment, DEFAULT_EXPERIMENT_VARIANT)
  }

  const setExperiments = (flags: ExperimentFlags): void => {
    Object.entries(flags).forEach(([name, variant]) => {
      experiments.set(name, variant)
    })

    loaded.resolve()
  }

  const clearExperiments = (): void => {
    experiments.clear()
  }

  return {
    loaded,
    experiments,
    inVariant,
    getVariant,
    inExperiment,
    setExperiments,
    clearExperiments,
  }
}
