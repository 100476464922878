import type { Step } from '@/steps/Step'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useBrowserHistory = () => {
  const pushStepToHistory = (step: Step): void => {
    if (step.history) {
      history.pushState(
        {
          step: step.name,
        },
        '',
      )
    }
  }

  return {
    pushStepToHistory,
  }
}
