<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { LykaButton } from '@lyka/ui'
import LeftArrowIcon from './icons/LeftArrowIcon.vue'

const props = defineProps<{
  disabled?: boolean
  header?: boolean
}>()

const emits = defineEmits<{
  (e: 'click'): void
}>()

const DESKTOP_MIN_BREAK_POINT = 768
const mobile = ref(false)

const showInHeader = computed(() => {
  return mobile.value || props.header
})

const resizeHeader = (): void => {
  const width = window.innerWidth ?? 0

  mobile.value = width < DESKTOP_MIN_BREAK_POINT
}

onMounted(() => {
  resizeHeader()
  window.addEventListener('resize', resizeHeader)
})
</script>

<template>
  <Teleport to="#backBtn" :disabled="!showInHeader">
    <LykaButton
      :outline="!showInHeader"
      :square="showInHeader"
      sr-only="Previous step"
      :auto-width="false"
      :variant="showInHeader ? 'alt-dark' : 'alt'"
      type="button"
      :disabled="disabled"
      @click="emits('click')"
    >
      <template #iconLeft>
        <LeftArrowIcon :class="{ 'tw--mr-2': showInHeader }" />
      </template>
      <template v-if="!showInHeader"> Previous </template>
    </LykaButton>
  </Teleport>
</template>
