<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'
import { LykaButton, LykaError } from '@lyka/ui'
import { assetUrl } from '@lyka/utils'
import TreatCard from './CheckoutFreeTreatCard.vue'
import RightArrowIcon from '@/components/icons/RightArrowIcon.vue'
import StepBackButton from '@/components/StepBackButton.vue'
import TreatsTreatCardModal from '@/components/steps/treats/TreatsTreatCardModal.vue'
import type { Treat } from '@/models/Treat'
import { useTreatsStore } from '@/stores/treats'
import { usePostCheckout } from '@/composables/usePostCheckout'
import { useNotifications } from '@/composables/useNotifications'
import { formatMoney } from '@/utils/formatMoney'
import { useCheckoutStepViewedEvent } from '@/composables/events/segment/useCheckoutStepViewedEvent'
import { useButtonClickedEvent } from '@/composables/events/segment/useButtonClickEvent'
import { StepName } from '@/stores/steps'

const props = defineProps<{
  animalNames?: string[]
}>()

const emit = defineEmits<{
  (e: 'back'): void
}>()

const { VITE_CUSTOMER_PORTAL_URL } = import.meta.env
const DASHBOARD_REDIRECT_DELAY = 2_500

interface FreeTreat {
  name: string
  title: string
  imageSrc: string
  price?: string
}

const freeTreats: FreeTreat[] = reactive([
  {
    name: 'mussel_munchies_50g',
    title: 'Mussel Munchies',
    imageSrc: assetUrl(`images/treats/mussel_munchies/packet.jpg`),
  },
  {
    name: 'beefy_bites_100g',
    title: 'Beefy Bites',
    imageSrc: assetUrl(`images/treats/beefy_bites/packet.jpg`),
  },
])

const selection = ref('')
const modalTreat = ref<Treat | null>(null)
const isTreatModalOpen = ref(false)
const isSubmitting = ref(false)
const hasError = ref(false)
const isComplete = ref(false)

const { treats } = useTreatsStore()
const { submitFreeTreat } = usePostCheckout()
const { success: notifySuccess } = useNotifications()

const title = computed(() => {
  const animalNames = [...(props.animalNames ?? [])]
  const last = animalNames.pop()

  if (!last) return 'Treat your puppies'

  // Make it look like "Puppy1, Puppy2 & Puppy3"
  const joinedNames = [animalNames.join(', '), last].filter((name) => !!name).join(' & ')

  return `Treat ${joinedNames}`
})

const selectedTreat = computed(() => {
  return freeTreats.find((treat) => treat.name === selection.value)
})

const selectTreat = (name: string): void => {
  selection.value = name
}

const finish = (): void => {
  if (isSubmitting.value) return

  window.location.href = VITE_CUSTOMER_PORTAL_URL
}

const skip = async (): Promise<void> => {
  useButtonClickedEvent().send({
    cta: 'No thanks',
    href: '/membership/dashboard',
    page: 'free-treats',
  })

  finish()
}

const submit = async (): Promise<void> => {
  if (!selection.value || isSubmitting.value || isComplete.value) return

  hasError.value = false
  isSubmitting.value = true

  useButtonClickedEvent().send({
    cta: 'Add to 2nd order',
    href: '/membership/dashboard',
    page: 'free-treats',
  })

  try {
    const success = await submitFreeTreat({ productName: selection.value })

    hasError.value = !success
    isComplete.value = success

    if (success) {
      const treatName = selectedTreat?.value?.title ?? 'treat'
      notifySuccess(`Free ${treatName} is added to your 2nd order`)

      setTimeout(() => finish(), DASHBOARD_REDIRECT_DELAY)
    }
  } catch {
    hasError.value = true
  } finally {
    isSubmitting.value = false
  }
}

const openTreatModal = (name: string): void => {
  const nameSegments = name.split('_').slice(0)
  const productName = nameSegments.slice(0, nameSegments.length - 1).join('_')

  modalTreat.value = treats.find((t) => t.name === productName) ?? null

  if (modalTreat.value) {
    isTreatModalOpen.value = true
  }
}

const closeTreatModal = (): void => {
  isTreatModalOpen.value = false
  modalTreat.value = null
}

onMounted(async () => {
  freeTreats.forEach((ft) => {
    const treat = treats.find((t) => ft.name.startsWith(t.name))

    if (!treat) {
      return
    }

    const price = treat.products[0]?.price

    if (price) {
      ft.price = formatMoney(price)
    }
  })

  await useCheckoutStepViewedEvent().send(StepName.CheckoutFreeTreats)
})
</script>

<template>
  <StepBackButton header @click="emit('back')" />

  <div class="tw-px-8 tw-flex tw-flex-col tw-items-center tw-gap-6 tw-mt-12">
    <h2 class="tw-h2 tw-text-4xl xs:tw-text-6xl">{{ title }}</h2>

    <p class="tw-text-center">
      Let&apos;s celebrate the transition to Lyka in your 2<sup>nd</sup> order with a free packet of our single-protein,
      guilt-free treats.
    </p>

    <p class="tw-text-center"><b>Choose one free treat</b> to reward them in your 2<sup>nd</sup> order:</p>
  </div>

  <div class="tw-px-6 tw-mt-6 tw-flex tw-flex-col tw-items-center tw-gap-6">
    <div class="tw-flex tw-gap-4">
      <TreatCard
        v-for="treat in freeTreats"
        :key="treat.name"
        :title="treat.title"
        :name="treat.name"
        :image-src="treat.imageSrc"
        :price="treat.price"
        :selected="selection === treat.name"
        @select="selectTreat"
        @details-click="openTreatModal"
      />
    </div>

    <div v-if="!isComplete" class="tw-w-full">
      <LykaButton
        variant="success"
        :disabled="!selection"
        disabled-variant="secondary"
        class="tw-w-full"
        :loading="isSubmitting"
        @click="submit"
      >
        Add to your 2<sup>nd</sup> order
      </LykaButton>

      <LykaError v-if="hasError">
        <b>Something went wrong processing this request.</b> Please contact customer support at
        <a class="tw-underline" href="mailto:info@lyka.com.au">info@lyka.com.au</a> for assistance.
      </LykaError>
    </div>

    <LykaButton v-if="!isComplete && !isSubmitting" type="button" variant="success" decoration text-link @click="skip">
      <span>No thanks</span>

      <template #iconRight>
        <RightArrowIcon />
      </template>
    </LykaButton>

    <TreatsTreatCardModal v-if="modalTreat" :treat="modalTreat" :open="isTreatModalOpen" @close="closeTreatModal" />
  </div>
</template>
