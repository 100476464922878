export const NO_PREVIOUS_FOOD_TYPE_ID = -1

export enum FoodTypeName {
  Dry = 'dry',
  Wet = 'wet',
  Raw = 'raw',
  HomeMade = 'homemade',
  Treats = 'treats',
  Scraps = 'scraps',
  Other = 'other',
}

export enum FoodTypeClassification {
  Alternative = 'ALTERNATIVE',
  Traditional = 'TRADITIONAL',
}

export interface FoodType {
  id: number
  name: FoodTypeName
  classification: FoodTypeClassification
  text: string
}

export const getFoodTypeBrandable = (foodType: FoodType): boolean => {
  return [FoodTypeName.Dry, FoodTypeName.Wet, FoodTypeName.Raw, FoodTypeName.Other, FoodTypeName.Treats].includes(
    foodType.name,
  )
}
