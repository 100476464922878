<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { type Breakpoint, useBreakpoints } from '../composables/useBreakpoints'
import LykaCarouselControlButton from './LykaCarouselControlButton.vue'

const props = defineProps<{
  breakpoint?: Breakpoint
  hideArrows?: boolean
  dark?: boolean
  autoscroll?: number
}>()

const LEFT = 'left'
const RIGHT = 'right'
const MOBILE_PADDING = 24 // px

const carousel = ref<HTMLElement>()
const carouselContents = ref<HTMLElement>()
const carouselOverflow = ref<HTMLElement>()
const currentItem = ref(0)
const slidesShown = ref(1)
const pages = ref(0)
const slides = ref(0)
const breakpoints = useBreakpoints()
const autoscrollTimeout = ref<NodeJS.Timeout>()

type SlideDirection = typeof LEFT | typeof RIGHT

const enabledForBreakpoint = computed(() => {
  if (props.breakpoint) {
    return breakpoints.value[props.breakpoint]
  }

  return true
})

const getItemScrollPosition = (element: HTMLElement): number => {
  const isFirstChild = !element.firstElementChild
  const isLastChild = !element.nextElementSibling

  if (isFirstChild) {
    return 0
  }
  if (isLastChild) {
    return element.parentElement?.scrollWidth ?? 0
  }

  return element.offsetLeft - MOBILE_PADDING + (enabledForBreakpoint.value ? MOBILE_PADDING : 0)
}

const cancelAutoScroll = (): void => {
  clearInterval(autoscrollTimeout.value)
}

const scrollToItem = (index: number): void => {
  const parent = carouselOverflow.value
  const child = carouselContents.value?.children[index] as HTMLElement | undefined

  if (!child || !parent) {
    return
  }

  const left = getItemScrollPosition(child)

  parent.scrollTo?.({
    top: 0,
    left,
    behavior: 'smooth',
  })
}

const enqueueAutoScroll = (): void => {
  if (!props.autoscroll) {
    return
  }

  autoscrollTimeout.value = setTimeout(() => {
    let target = 0

    if (currentItem.value < slides.value - slidesShown.value) {
      target = currentItem.value + 1
    }

    scrollToItem(target)
    enqueueAutoScroll()
  }, props.autoscroll)
}

const onItemClick = (page: number): void => {
  cancelAutoScroll()
  currentItem.value = page
  scrollToItem(currentItem.value)
  enqueueAutoScroll()
}

const countSlides = (): number => {
  return carouselContents.value?.children?.length ?? 0
}

const countPages = (): number => {
  if (slidesShown.value) {
    return slides.value - slidesShown.value + 1
  } else {
    return 0
  }
}

const refactorCarousel = (): void => {
  slides.value = countSlides()
  pages.value = countPages()

  if (!pages.value) {
    currentItem.value = 0
  } else if (currentItem.value >= pages.value) {
    currentItem.value = pages.value - 1
  }

  scrollToItem(currentItem.value)
}

const scrollRightButtonStatus = computed(() => {
  return currentItem.value === slides.value - slidesShown.value
})

const scrollLeftButtonStatus = computed(() => {
  return currentItem.value === 0
})

const getFirstPartialItem = (): HTMLElement | undefined => {
  if (!carouselContents.value) {
    return undefined
  }

  return Array.from(carouselContents.value.children).find((el) => {
    return (
      el instanceof HTMLElement && el.parentElement && el.offsetLeft + el.clientWidth > el.parentElement.clientWidth
    )
  }) as HTMLElement | undefined
}

const getLastVisibleItem = (): HTMLElement | undefined => {
  const item = getFirstPartialItem()?.previousElementSibling

  return item as HTMLElement | undefined
}

const resizeCarousel = (): void => {
  if (!carouselContents.value) {
    return
  }

  carouselContents.value.style.removeProperty('width')

  if (!enabledForBreakpoint.value) {
    return
  }

  const lastVisibleItem = getLastVisibleItem()

  if (lastVisibleItem) {
    const width = lastVisibleItem.offsetLeft + lastVisibleItem.offsetWidth

    carouselContents.value.style.width = `${width}px`
    slidesShown.value = Math.floor(carouselContents.value.clientWidth / lastVisibleItem.clientWidth)
  } else {
    slidesShown.value = 0
  }

  if (!slidesShown.value) {
    carouselContents.value.style.removeProperty('width')
  }

  refactorCarousel()
}

const mutationObserver = new MutationObserver(() => {
  resizeCarousel()
  refactorCarousel()
})

const intersectionObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const element = entry.target

        if (!(element instanceof HTMLElement)) {
          return
        }

        const intersectingIndex = element.dataset.index ? parseInt(element.dataset.index) : -1

        if (currentItem.value < intersectingIndex) {
          currentItem.value = intersectingIndex - (slidesShown.value - 1)
        } else if (currentItem.value > intersectingIndex) {
          currentItem.value = intersectingIndex
        }
      }
    })
  },
  { root: null, rootMargin: '0px', threshold: 0.5 },
)

const onPrevNextClick = (direction: SlideDirection): void => {
  cancelAutoScroll()

  if (direction === LEFT && currentItem.value > 0) {
    currentItem.value--
  } else if (direction === RIGHT && currentItem.value < slides.value - slidesShown.value) {
    currentItem.value++
  }

  scrollToItem(currentItem.value)
  enqueueAutoScroll()
}

onMounted(() => {
  resizeCarousel()

  if (carousel.value) {
    new ResizeObserver(() => resizeCarousel()).observe(carousel.value)
  }

  if (!carouselContents.value) {
    return
  }

  mutationObserver.observe(carouselContents.value, { childList: true })
  slides.value = countSlides()
  pages.value = countPages()
  Array.from(carouselContents.value.children).forEach((child, index) => {
    if (child instanceof HTMLElement) {
      child.dataset.index = String(index)
    }

    intersectionObserver.observe(child)
  })

  enqueueAutoScroll()
})

onBeforeUnmount(() => {
  cancelAutoScroll()
})
</script>

<template>
  <div class="tw-w-full tw-space-y-8 md:tw-space-y-12 lg:tw-space-y-14">
    <div
      ref="carousel"
      class="tw-flex tw-flex-nowrap tw-flex-row tw-items-center tw-justify-center tw-text-alt tw-w-full tw-gap-6"
    >
      <LykaCarouselControlButton
        v-if="slidesShown && !hideArrows"
        :button-inactive="scrollLeftButtonStatus"
        direction="left"
        desktop-only
        @on-click-control="onPrevNextClick"
      />
      <div ref="carouselOverflow" class="tw-overflow-x-scroll tw-no-scrollbar tw-px-6 md:tw-px-0">
        <div ref="carouselContents" class="tw-flex tw-flex-row tw-flex-nowrap tw-gap-4 tw-relative carousel-contents">
          <slot />
        </div>
      </div>
      <LykaCarouselControlButton
        v-if="slidesShown && !hideArrows"
        :button-inactive="scrollRightButtonStatus"
        direction="right"
        desktop-only
        @on-click-control="onPrevNextClick"
      />
    </div>
    <div v-if="slidesShown" class="tw-flex tw-justify-center tw-space-x-1.5">
      <div
        v-for="(_, index) in Array(pages).fill(1)"
        :key="index"
        class="tw-w-2 tw-h-2 tw-rounded-full tw-bg-alt tw-cursor-pointer"
        :class="{
          'tw-opacity-60': currentItem !== index,
          'tw-bg-offwhite': dark,
        }"
        @click="onItemClick(index)"
      />
    </div>
  </div>
</template>

<style lang="postcss">
.tw-no-scrollbar::-webkit-scrollbar {
  display: none;
}
.tw-no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>

<style scoped lang="postcss"></style>
