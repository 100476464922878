<script setup lang="ts">
import { LykaMessage, LykaSpinner } from '@lyka/ui'
import { computed } from 'vue'
import { useDogsStore } from '@/stores/dogs'

const dogsStore = useDogsStore()

const heading = computed(() => {
  const [first, second] = dogsStore.dogNames
  const count = dogsStore.dogCount

  switch (count) {
    case 1:
      return `Calculating ${first}'s meal plan...`
    case 2:
      return `Calculating ${first} and ${second}'s meal plan...'`
    default:
      return 'Calculating your meal plans...'
  }
})
</script>

<template>
  <div
    class="tw-flex tw-flex-col tw-items-center tw-text-center tw-text-alt tw-w-full tw-mx-auto tw-pt-32 tw-px-6 tw-space-y-10 tw-grow tw-overflow-auto tw-mb-8 tw-max-w-xl"
  >
    <h1 class="tw-h3">
      {{ heading }}
    </h1>

    <LykaSpinner />

    <LykaMessage arrow="top">
      <p class="tw-text-center">Did you know&hellip; You get a free Go-To Gut Helper supplement in your first box 🐶</p>
    </LykaMessage>
  </div>
</template>
