import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { EventSender } from '../EventSender'
import {
  BREEDER_OPTION_NAME,
  type CheckoutOrder,
  DOG_TRAINER_OPTION_NAME,
  OTHER_OPTION_NAME,
  VET_OPTION_NAME,
} from '@/models/CheckoutOrder'

export const getEventData = (
  order: Pick<CheckoutOrder, 'howDidYouHear'>,
): {
  surveyResponse: string
  surveyResponseOther?: string
  surveyResponseSecondary?: string
} => {
  const surveyResponse = order.howDidYouHear.name
  const surveyResponseOther = order.howDidYouHear.name === OTHER_OPTION_NAME ? order.howDidYouHear.other : undefined
  const surveyResponseSecondary = [BREEDER_OPTION_NAME, VET_OPTION_NAME, DOG_TRAINER_OPTION_NAME].includes(
    surveyResponse,
  )
    ? order.howDidYouHear.other
    : undefined

  return {
    surveyResponse,
    surveyResponseOther,
    surveyResponseSecondary,
  }
}

export const useSurveyCompletedEvent: EventSender = ({ order }: { order: Pick<CheckoutOrder, 'howDidYouHear'> }) => {
  const send = (): void => {
    if (order.howDidYouHear.name) {
      useSegment().track('Survey Completed', {
        surveyName: 'HDYHAU',
        ...getEventData(order),
      })
    }
  }

  return {
    send,
  }
}
