// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useIntercom = () => {
  const show = (): void => {
    window.Intercom?.('show')
  }

  return {
    show,
  }
}
