import snakecaseKeys from 'snakecase-keys'
import { computed, ref } from 'vue'
import type { Token } from '@/models/Token'

const LOCALSTORAGE_TOKEN_KEY = 'token'

const token = ref<Token>()

export interface DeserializedToken {
  accessToken: string
  apiToken: string
  currentReferCampaign: string
  id: number
  userId: number
  uuid: string
  fullName: string
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAuthentication = () => {
  const setToken = (tokenData: Readonly<DeserializedToken>): void => {
    const transformed = snakecaseKeys(tokenData) as Token

    localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, JSON.stringify(transformed))
    token.value = transformed
  }

  const getToken = (): Token | undefined => {
    const tokenData = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY)

    if (!tokenData) {
      return
    }

    try {
      return JSON.parse(tokenData)
    } catch {}
  }

  token.value ??= getToken()

  const authenticated = computed<boolean>(() => !!token.value)

  return {
    setToken,
    token,
    authenticated,
  }
}
