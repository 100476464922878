<script setup lang="ts">
import type { InputHTMLAttributes } from 'vue'
import { computed } from 'vue'

const props = defineProps<{
  type?: 'checkbox' | 'radio'
  name?: string
  inputValue?: any
  modelValue?: any
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', value: (typeof props)['modelValue']): void
}>()

const inputType = computed<InputHTMLAttributes['type'] | undefined>(() => {
  if (props.type && ['checkbox', 'radio'].includes(props.type)) {
    return props.type
  }

  return undefined
})

const inputName = computed<string | undefined>(() => {
  if ('name' in props) {
    return props.name
  }

  return undefined
})

const selectable = computed(() => {
  return !!inputType.value
})

const tagName = computed(() => (selectable.value ? 'label' : 'span'))

const value = computed({
  get() {
    if ('type' in props) {
      return props.modelValue
    }

    return undefined
  },
  set(newValue) {
    emits('update:modelValue', newValue)
  },
})
</script>

<template>
  <component :is="tagName" class="lyka-chip-wrap">
    <input
      v-if="inputType && inputName"
      v-model="value"
      class="tw-sr-only tw-peer"
      :type="inputType"
      :value="inputValue"
      :name="inputName"
    />

    <span class="lyka-chip tw-group">
      <span class="lyka-chip-label">
        <slot />
      </span>

      <span v-if="selectable" class="lyka-chip-checked">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="9"
          fill="none"
          viewBox="0 0 13 9"
          class="tw-fill-current"
        >
          <path
            d="M12.249.395a.858.858 0 0 1 0 1.213L5.393 8.463a.858.858 0 0 1-1.214 0L.751 5.035a.858.858 0 0 1 1.213-1.213l2.823 2.82L11.038.395a.859.859 0 0 1 1.214 0h-.003Z"
          />
        </svg>
      </span>
    </span>
  </component>
</template>

<style lang="postcss">
label.lyka-chip-wrap {
  @apply tw-cursor-pointer tw-outline-none;
}

.lyka-chip {
  @apply tw-inline-flex tw-rounded-full tw-px-4 tw-py-3 tw-justify-center tw-bg-semilight-gray tw-items-center tw-transition-colors hover:tw-bg-muted-light;
  @apply peer-checked:tw-bg-green peer-checked:tw-text-white;
}

.lyka-chip-checked {
  @apply tw-rounded-full tw-w-6 tw-h-6  tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-10 tw-text-transparent tw-scale-75 tw-transition-all tw-origin-center tw-transform-gpu;
  @apply peer-checked:group-[]:tw-text-white peer-checked:group-[]:tw-scale-100 group-hover:tw-bg-opacity-20;
}

.lyka-chip-label {
  @apply tw-px-2;
}
</style>
