import { z } from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { Step } from './Step'
import { useNameStep } from './name'
import { useGenderStep } from './gender'
import { registerStep } from './stepRegistry'
import { useUnservicedLocationStep } from './unservicedLocation'
import { StepName } from '@/stores/steps'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { postcodeSchema } from '@/schemas/postcode'
import { useUserStore } from '@/stores/user'

const Schema = z.object({
  user: z.object({
    // https://en.wikipedia.org/wiki/Postcodes_in_Australia
    postcode: postcodeSchema,
    postcodeValid: z.boolean(),
  }),
})

export type LocationStepData = z.infer<typeof Schema>

class LocationStep extends Step<LocationStepData> {
  name = StepName.Location
  schema = Schema
  userStore = useUserStore()
  title = 'Your Location'

  constructor() {
    super()
    this.loadState()

    this.userStore.on('added', (user) => {
      if (user.postcode) {
        this.data.user.postcode = user.postcode
        this.data.user.postcodeValid = user.postcodeValid ?? true
      }
    })
  }

  onComplete(): void {
    super.onComplete()

    useGTM().sendAction(GTMEventAction.PROVIDED_POSTCODE, this.data.user.postcode)
    useSegment().track('Postcode Completed', {
      postcode: this.data.user.postcode,
      postcodeServiced: this.data.user.postcodeValid,
    })
  }

  initialState(): LocationStepData {
    return {
      user: {
        postcode: '',
        postcodeValid: false,
      },
    }
  }

  prev(): Step {
    return useNameStep()
  }

  next(): Step {
    if (!this.data.user.postcodeValid) {
      return useUnservicedLocationStep()
    }

    return useGenderStep()
  }
}

export const useLocationStep = registerStep(LocationStep)
