<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { LykaSpinner, LykaTransitionSlideIn } from '@lyka/ui'
import IconTick from '@lyka/ui/components/icon/Tick.vue'
import { isUTMCampaignCoupon, useCoupons } from '@/composables/useCoupons'
import { useStepsStore } from '@/stores/steps'

const REFER_MULTI_BOX_DISCOUNT_CAMPAIGNS = ['may_2023']
const APPLIED_COUPON_MESSAGE_DURATION_MS = 4_000

const stepsStore = useStepsStore()
const loading = ref(false)
const applying = ref(false)
const applied = ref(false)
const isTouchDevice = window.matchMedia('(pointer: coarse)').matches
const dismissed = ref(false)

const { activeCoupon, defaultCoupon, applyDefaultCoupon, dollarDiscountCouponAttempt } = useCoupons()

const referMultiBoxDiscount = computed<boolean>(() => {
  if (!activeCoupon.value) {
    return false
  }

  const { currentReferCampaign } = activeCoupon.value

  return !!currentReferCampaign && REFER_MULTI_BOX_DISCOUNT_CAMPAIGNS.includes(currentReferCampaign)
})

const defaultCouponDiscount = computed(() => {
  return defaultCoupon.value?.discount ?? 0
})

const persist = computed(() => {
  return stepsStore.currentStep.persistCouponBanner
})

const canApplyDefaultCoupon = computed(() => {
  // No point applying the same coupon
  if (activeCoupon.value?.couponCode === defaultCoupon.value?.couponCode) {
    return false
  }

  // Check if coupon is a multi-box discount campaign, the upfront percentage can be more than default
  if (activeCoupon.value?.multiBoxDiscountEnabled) {
    return false
  }

  return (activeCoupon.value?.discount ?? 0) < defaultCouponDiscount.value
})

const boxesDiscountedText = computed((): string => {
  // refer a friend - multi box campaign coupon
  if (referMultiBoxDiscount.value) {
    return `2 boxes!`
  }

  // multi box coupon
  if (activeCoupon.value?.multiBoxDiscountEnabled) {
    return `${activeCoupon.value?.multiBoxDiscounts?.length} deliveries`
  }

  // default coupon
  return `box!`
})

const couponMessage = computed(() => {
  if (isUTMCampaignCoupon) {
    return 'Enter your code at checkout to redeem your discount!'
  }

  // If NO active coupon or the active coupon is LESS than the default coupon then prompt the user to apply the default coupon
  if (canApplyDefaultCoupon.value) {
    return `Get ${defaultCouponDiscount.value}% off your first box,`
  }

  const discountAmount = activeCoupon.value?.discount ?? 0
  const discount = activeCoupon.value?.discountAmountType === 'percentage' ? `${discountAmount}%` : `$${discountAmount}`

  // Show the applied coupon discount
  if (applied.value) {
    return `${discount} discount applied!`
  }

  return `You've got ${discount} off your first ${boxesDiscountedText.value}`
})

const showBanner = computed(() => {
  // don't show banner if a dollar discount is attempted
  if (dollarDiscountCouponAttempt.value && activeCoupon.value?.discountAmountType !== 'dollar') {
    return false
  }

  if (persist.value) {
    return true
  }

  if (dismissed.value) {
    return false
  }

  return stepsStore.currentStep.showCouponBanner && !loading.value
})

const dismiss = (): void => {
  setTimeout(() => {
    dismissed.value = true
  }, APPLIED_COUPON_MESSAGE_DURATION_MS)
}
const applyCoupon = async (): Promise<void> => {
  if (isUTMCampaignCoupon) {
    return
  }

  // No point applying the same coupon
  if (!canApplyDefaultCoupon.value) {
    return
  }

  try {
    applying.value = true
    await applyDefaultCoupon()
    applied.value = true
  } finally {
    dismiss()
    applying.value = false
  }
}

onBeforeMount(async () => {
  loading.value = true

  try {
    await useCoupons().load()

    if (!canApplyDefaultCoupon.value) {
      dismiss()
    }
  } finally {
    loading.value = false
  }
})
</script>

<template>
  <LykaTransitionSlideIn>
    <section
      v-if="showBanner"
      class="tw-flex tw-justify-center tw-items-center tw-text-center tw-py-2.5 tw-bg-green-darker tw-text-white tw-relative tw-px-8 tw-leading-4 tw-shrink-0 tw-text-sm xs:tw-text-base"
      @click="applyCoupon"
    >
      <LykaSpinner v-if="applying" size="sm" />

      <div v-else-if="isUTMCampaignCoupon">
        {{ couponMessage }}
      </div>

      <div
        v-else-if="defaultCoupon"
        class="tw-relative tw-justify-center tw-w-full tw-origin-center tw-flex tw-gap-2 tw-items-center tw-animate-marquee tw-whitespace-nowrap xxs:tw-animate-none"
      >
        <template v-if="canApplyDefaultCoupon">
          {{ couponMessage }}

          <button type="button" class="tw-text-green">{{ isTouchDevice ? 'tap' : 'click' }} to redeem</button>
        </template>
        <template v-else>
          <IconTick class="tw-text-green" />
          {{ couponMessage }}
        </template>
      </div>
    </section>
  </LykaTransitionSlideIn>
</template>
