<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import { clone } from 'remeda'
import { LykaContainer, LykaGrid, LykaMessage } from '@lyka/ui'
import TermsCard from '../common/TermsCard.vue'
import TreatsTreatCard from '@/components/steps/treats/TreatsTreatCard.vue'
import TreatsNavigation from '@/components/steps/treats/TreatsNavigation.vue'
import type { TreatsStepData } from '@/steps/treats'
import { useTreatsStore } from '@/stores/treats'
import type { Product } from '@/models/Product'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { useRecipesStore } from '@/stores/recipes'
import { isTherapeuticRecipe } from '@/models/Recipe'

const props = defineProps<{
  stepData: TreatsStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const treatsStore = useTreatsStore()
const recipesStore = useRecipesStore()

const data = reactive(clone(props.stepData))

watch(data, (newData) => emits('save', newData), { deep: true })

const addTreat = (product: Product): void => {
  const quantity = data.products.treats[product.id] ?? 0
  const newQuantity = quantity + 1

  useGTM().sendAction(GTMEventAction.INCREASED_TREATS, product.displayName)

  data.products.treats[product.id] = newQuantity
}

const removeTreat = (product: Product): void => {
  const quantity = data.products.treats[product.id] ?? 0
  const newQuantity = Math.max(0, quantity - 1)

  useGTM().sendAction(GTMEventAction.DECREASED_TREATS, product.displayName)

  data.products.treats[product.id] = newQuantity
}

const totalTreatsPrice = computed(() => {
  let sum = 0

  for (const [id, quantity] of Object.entries(data.products.treats)) {
    const treat = treatsStore.findProduct(parseInt(id))

    if (treat) {
      sum += treat.price * quantity
    }
  }

  return sum
})

const hasTherapeuticRecipes = computed<boolean>(() => {
  return recipesStore.selectedRecipes.some(isTherapeuticRecipe)
})

const submit = async (): Promise<void> => {
  emits('submit', data)
}

const removeAllTreats = (): void => {
  data.products.treats = {}
}
</script>

<template>
  <div class="tw-space-y-14">
    <LykaContainer size="sm" class="tw-text-center">
      <header>
        <h2 class="tw-h3 tw-mb-4">Sniff-out our range of treats &amp; toppers</h2>
        <p class="tw-text-alt tw-font-light tw-mb-10">
          Air-dried and made from single proteins, our lineup of drool-worthy treats &amp; toppers gets a big paws up
          from the pack.
        </p>

        <LykaMessage>You can easily add or remove extras later via your online account</LykaMessage>
      </header>

      <TermsCard v-if="hasTherapeuticRecipes">
        <div>Hypoallergenic Pro is not recommended to be mixed with other proteins when doing an elimination diet.</div>
      </TermsCard>
    </LykaContainer>

    <LykaContainer size="lg">
      <form id="formTreats" v-submit @submit.prevent="submit">
        <LykaGrid :cols="1" :md-cols="2" :lg-cols="3" :y-gap="8" :x-gap="8">
          <TreatsTreatCard
            v-for="treat in treatsStore.treats"
            :key="treat.id"
            :treat="treat"
            :treat-quantities="data.products.treats"
            @add="addTreat"
            @remove="removeTreat"
          />
        </LykaGrid>

        <TreatsNavigation
          v-model:everyBox="data.products.everyBox"
          submit
          :total-treats-price="totalTreatsPrice"
          form="formTreats"
          @previous="emits('previous')"
          @remove-treats="removeAllTreats"
        />
      </form>
    </LykaContainer>
  </div>
</template>
