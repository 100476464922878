<script setup lang="ts">
import { computed } from 'vue'
import CheckoutTreatsProduct from './CheckoutTreatsProduct.vue'

const props = defineProps<{
  everyBox?: boolean
  treatQuantities?: Record<number, number>
}>()

const hasTreats = computed(() => {
  if (!props.treatQuantities) {
    return false
  }

  return Object.values(props.treatQuantities).some((quantity) => !!quantity)
})
</script>

<template>
  <div v-if="hasTreats" id="summary-treats">
    <div v-for="(quantity, productId) in treatQuantities" :key="productId">
      <CheckoutTreatsProduct
        :key="productId"
        :product-id="Number(productId)"
        :quantity="quantity"
        :every-box="everyBox"
      />

      <hr class="tw-my-6 tw-text-mint-green" />
    </div>
  </div>
</template>
