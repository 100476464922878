export const loadScript = (url: string, async = true): Promise<void> => {
  const script = document.createElement('script')

  script.setAttribute('src', url)
  script.setAttribute('async', String(async))

  document.body.appendChild(script)

  return new Promise((resolve, reject) => {
    script.onload = (): void => resolve()
    script.onerror = (error): void => reject(error)
  })
}
