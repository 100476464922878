type ISODate = string

export enum DeliverySlotName {
  AM = 'am',
  PM = 'pm',
}

export interface DeliverySlot {
  id: number
  cutoffId: number
  name: DeliverySlotName
  available: boolean
  availableAtCourier: boolean
  timeWindow: string | false
  timings: {
    makeDate: ISODate
    movedMakeDate: boolean
    chargeDate: ISODate
    movedChargeDate: boolean
    chargeDateCustomer: ISODate
    cutoffs: {
      courier: ISODate
      manifest: ISODate
      lykaDispatch: ISODate
      lykaDispatchBuffer: ISODate
    }
  }
}
