import { loadScript } from '@/utils/loadScript'

interface Trustpilot {
  loadFromElement(element: HTMLElement): void
}

declare global {
  interface Window {
    Trustpilot?: Trustpilot
  }
}

export const useTrustpilot = async (): Promise<Trustpilot | undefined> => {
  if (!window.Trustpilot) {
    await loadScript('https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js')
  }

  return window.Trustpilot
}
