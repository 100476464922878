export const useFrontChat = (): { loaded: boolean; show(): void } => {
  const loaded = !!window.FrontChat

  const show = (): void => {
    window.FrontChat?.('show')
  }

  return {
    loaded,
    show,
  }
}
