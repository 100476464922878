import { useDogsStore } from '../stores/dogs'
import { useApi } from './useApi'
import { useSentry } from './useSentry'
import { useCoupons } from './useCoupons'
import { useTreatsStep } from '@/steps/treats'
import { type AcquisitionData, AcquisitionDataBuilder } from '@/builders/AcquisitionDataBuilder'
import { useMealPlansStore } from '@/stores/mealPlans'
import { useUserStore } from '@/stores/user'

export enum AcquisitionPage {
  Recipes = 'selected-bowls',
  Plan = 'selected-plan',
  Checkout = 'checkout',
}

export const getAcquisitionData = async (page: string): Promise<AcquisitionData> => {
  const mealPlansStore = useMealPlansStore()
  const dogsStore = useDogsStore()
  const userStore = useUserStore()
  const products = useTreatsStep().data.products
  const selectedPlan = mealPlansStore.selectedPlan
  const user = userStore.user
  const postcode = user.postcode
  const referrer = useCoupons().referrer.value
  const mealPlans = mealPlansStore.mealPlans
  const coupon = useCoupons().activeCoupon.value

  // Load the meal plans if they don't exist
  if (!mealPlansStore.loaded) {
    await mealPlansStore.loadMeals()
  }

  const data = new AcquisitionDataBuilder()
    .addPage(page)
    .addPostcode(postcode)
    .addUser(user)
    .addProducts(products)
    .addDogs(dogsStore.getDogs(), mealPlansStore.mealWeights)

  if (referrer) {
    data.addReferrer(referrer)
  }

  if (coupon) {
    data.addCoupon(coupon)
  }

  if (mealPlans.length) {
    data.addMealPlans(mealPlans)
  }

  if (selectedPlan) {
    data.addPlan(selectedPlan)
  }

  return data.get()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAcquisitionData = () => {
  const send = async (page: AcquisitionPage): Promise<void> => {
    try {
      const data = await getAcquisitionData(page)

      await useApi().post<any, AcquisitionData>('marketing/buildabox/sendAcquisitionData', data)
    } catch (err) {
      console.error(err)
      useSentry().error(err)
    }
  }

  return {
    send,
  }
}
