<script lang="ts" setup>
import { useStepGroups } from '@/composables/useStepGroups'

const { groups, goToGroup } = useStepGroups()
</script>

<template>
  <nav class="tw-flex tw-justify-center tw-mb-16 sm:tw-mb-24 tw-text-center" role="tablist">
    <button
      v-for="(group, index) in groups"
      :key="index"
      class="tw-flex tw-flex-col tw-items-center tw-px-2.5 sm:tw-px-4 tw-outline-none tw-group tw-relative disabled:tw-cursor-not-allowed"
      type="button"
      :aria-current="group.active.value"
      :disabled="group.disabled.value"
      role="tab"
      :data-completed="group.completed.value"
      @click="goToGroup(group.name)"
    >
      <span
        class="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-border tw-aspect-square tw-text-xs tw-mb-1 tw-relative tw-z-10 tw-bg-body tw-w-[1.25rem] tw-h-[1.25rem] group-data-[completed=true]:tw-bg-secondary group-data-[completed=true]:tw-border-secondary group-data-[completed=true]:tw-text-alt group-aria-[current=true]:!tw-border-alt group-aria-[current=true]:!tw-bg-alt group-aria-[current=true]-hover:!tw-bg-alt-hover group-aria-[current=true]-focus:!tw-bg-alt-hover group-aria-[current=true]-hover:!tw-border-alt-hover group-aria-[current=true]-focus:!tw-border-alt-hover group-aria-[current=true]:!tw-text-white group-aria-[current=false]:tw-border-gold group-aria-[current=false]:tw-bg-body group-aria-[current=false]-hover:tw-bg-subtle-hover group-aria-[current=false]-focus:tw-bg-subtle-hover group-aria-[current=false]-hover:tw-border-subtle-hover group-aria-[current=false]-focus:tw-border-subtle-hover group-aria-[current=false]:tw-text-alt"
      >
        <span class="group-data-[completed=true]:tw-text-transparent">{{ index + 1 }}</span>

        <svg
          v-if="group.completed.value"
          class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-y-1/2 -tw-translate-x-1/2"
          width="9"
          height="9"
          viewBox="0 0 9 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>{{ index + 1 }}</title>
          <path d="M1 4.5L3.5 7L8 1" stroke="currentColor" stroke-width="2" />
        </svg>
      </span>
      <span
        class="tw-text-xs sm:tw-text-sm tw-whitespace-nowrap tw-text-muted group-hover:tw-text-alt-hover group-focus:tw-text-alt-hover group-aria-[current=true]:tw-text-alt"
        >{{ group.label }}
      </span>
      <span
        v-if="index + 1 !== groups.length"
        class="tw-absolute tw-top-2 tw-left-1/2 tw-h-px tw-w-full tw-bg-gold tw-z-0"
      />
    </button>
  </nav>
</template>
