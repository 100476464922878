import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useExperiments } from '@lyka/vue-common/composables/useExperiments'
import { unwrapData, useApi } from '@/composables/useApi'
import { RecipeName, isTherapeuticRecipe } from '@/models/Recipe'
import type { Recipe } from '@/models/Recipe'
import { useRecipesStep } from '@/steps/recipes'

export const useRecipesStore = defineStore('recipes', () => {
  const api = useApi()
  const recipes = ref<Recipe[]>([])
  const loaded = ref(false)
  const loading = ref(false)
  const experiments = useExperiments()

  const load = async (): Promise<void> => {
    if (loaded.value || loading.value) {
      return
    }

    try {
      loading.value = true
      const { data } = await api.get<Recipe[]>('recipesAnalysis', {}, unwrapData)

      recipes.value = data
    } finally {
      loaded.value = true
      loading.value = false
    }
  }

  const findRecipe = (id: number): Recipe | undefined => {
    return recipes.value.find((recipe) => recipe.id === id)
  }

  const findRecipeByName = (recipeName: RecipeName): Recipe | undefined => {
    return recipes.value.find((recipe) => recipe.name === recipeName)
  }

  const therapeuticRecipes = computed(() => {
    return recipes.value.filter(isTherapeuticRecipe)
  })

  const selectedRecipes = computed<Recipe[]>(() => {
    const selected: number[] = useRecipesStep().data.dogs.flatMap((dog) => dog.recipes)

    return recipes.value.filter((recipe) => selected.includes(recipe.id))
  })

  const activeRecipes = computed<Recipe[]>(() => {
    const isFishSelected = selectedRecipes.value.some((recipe) => recipe.name === RecipeName.Fish)

    if (!isFishSelected && experiments.inExperiment('bab-remove-fish')) {
      return recipes.value.filter((recipe) => recipe.name !== RecipeName.Fish)
    }

    return recipes.value
  })

  const nonTherapeuticRecipes = computed<Recipe[]>(() => {
    return activeRecipes.value.filter((recipe) => !isTherapeuticRecipe(recipe))
  })

  return {
    load,
    loading,
    allRecipes: activeRecipes,
    recipes: nonTherapeuticRecipes,
    therapeuticRecipes,
    selectedRecipes,
    findRecipe,
    findRecipeByName,
  }
})
