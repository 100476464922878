<script setup lang="ts">
import { assetUrl } from '@lyka/utils'
import { computed } from 'vue'

const props = defineProps<{
  image: string
  name: string
  description: string
  price: string
}>()

const src = computed(() => {
  return assetUrl(props.image)
})
</script>

<template>
  <div class="tw-flex tw-gap-4">
    <img :src="src" width="64" height="64" class="tw-w-16 tw-object-cover tw-rounded-lg tw-shrink-0" :alt="name" />
    <div class="tw-flex tw-justify-between tw-w-full">
      <div class="tw-space-y-2">
        <span>{{ name }}</span>
        <div class="tw-font-light tw-text-sm">{{ description }}</div>
      </div>

      <span class="tw-text-right">{{ price }}</span>
    </div>
  </div>
</template>
