<script setup lang="ts">
import { onMounted } from 'vue'
import { useSentry } from '@/composables/useSentry'
import { useStepsStore } from '@/stores/steps'

onMounted(() => {
  // Log the error to Sentry
  useSentry().error(new Error(`Failed to load step component: ${useStepsStore().currentStepName}`))
})
</script>

<template>
  <div>
    <div class="tw-text-center tw-space-y-4">
      <h2 class="tw-h3">We're sorry.</h2>
      <p class="tw-lead">There was a problem loading things.</p>
      <p>Please try reloading the page. If the problem continues please contact our support team.</p>
    </div>
  </div>
</template>
