import { ref } from 'vue'
import type { Step } from './Step'
import type { StepInstantiator } from './stepRegistry'

export enum StepGroupName {
  DogDetails = 'dog',
  DogHealth = 'health',
  PlanDetails = 'plan',
}

export class StepGroup {
  completed = ref(false)
  active = ref(false)
  disabled = ref(false)

  next?: StepGroup
  previous?: StepGroup

  constructor(public name: StepGroupName, public label: string, public firstStep: StepInstantiator) {}

  public update(currentStep: Step): void {
    this.active.value = this.getActive(currentStep)
    this.completed.value = this.getCompleted()
    this.disabled.value = this.getDisabled()
  }

  getDisabled(): boolean {
    if (this.previous) {
      return !this.previous.completed.value
    }

    return false
  }

  getCompleted(): boolean {
    if (!this.next?.firstStep) {
      return false
    }

    const steps: Array<Step> = []

    let iterator = this.firstStep()

    while (iterator.next() && !iterator.next()?.isSameStep(this.next.firstStep())) {
      steps.push(iterator)
      iterator = iterator.next()!
    }

    return steps.every((step) => step.getValid()) && (this.previous?.completed.value ?? true)
  }

  private getActive(currentStep: Step): boolean {
    const end = this.next?.firstStep?.()

    let step = this.firstStep()
    let active = false

    while (!active && step?.next() && !step.isSameStep(end)) {
      active = step.isSameStep(currentStep)
      step = step.next()!
    }

    return active
  }
}
