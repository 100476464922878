<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    class="tw-fill-current tw-w-[1em] tw-h-[1em]"
  >
    <path
      fill-rule="evenodd"
      d="M8.707 15.707a1 1 0 0 1-1.414 0l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 1.414L3.414 7H15a1 1 0 1 1 0 2H3.414l5.293 5.293a1 1 0 0 1 0 1.414Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
