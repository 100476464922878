import { BirthdayType, useAgeStep } from '@/steps/age'
// Default legacy dog age data to age tab
export const migrateAnimalAgeToBirthday = (): void => {
  const ageStep = useAgeStep()

  ageStep.data.dogs.forEach((dog) => {
    if (!dog.age.type) {
      dog.age.type = BirthdayType.Age
      dog.age.date = ''
    }
  })

  ageStep.saveState()
}
