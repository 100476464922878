import { ref } from 'vue'
import { useStateStore } from '@/stores/state'
import { useStepsStore } from '@/stores/steps'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSession = () => {
  const stateStore = useStateStore()
  const stepsStore = useStepsStore()
  const sessionId = ref(Date.now())

  const hasPreviousSession = !stepsStore.getFirstStep().isSameStep(stepsStore.getLastStep())

  const clearSession = (): void => {
    stateStore.resetState()
    stepsStore.goToFirstStep()
  }

  const continueSession = (): void => {
    stepsStore.goToLastStep()
  }

  return {
    sessionId,
    hasPreviousSession,
    clearSession,
    continueSession,
  }
}
