<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import { clone } from 'remeda'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import StepNavigation from '../StepNavigation.vue'
import StepsContainer from '@/components/StepContainer.vue'
import PreviouslyFedDog from '@/components/steps/previouslyFed/PreviouslyFedDog.vue'
import { useDataStore } from '@/stores/data'
import type { PreviouslyFedStepData } from '@/steps/previouslyFed'

const props = defineProps<{
  stepData: PreviouslyFedStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const data = reactive(clone(props.stepData))
const dataStore = useDataStore()
const foodTypes = computed(() => dataStore.foodTypes)

watch(
  () => data,
  (newData) => emits('save', newData),
  { deep: true },
)

const disabled = computed(() => {
  return !props.valid
})

const updateDog = (dogIndex: number, dogData: (typeof props)['stepData']['dogs'][0]): void => {
  data.dogs[dogIndex] = dogData

  emits('save', data)
}

const submit = ({ target }: Event): void => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  emits('submit', data)
}
</script>

<template>
  <StepsContainer>
    <form id="formPreviousRecipes" v-submit @submit.prevent="submit">
      <div class="tw-space-y-12">
        <PreviouslyFedDog
          v-for="(dog, index) in data.dogs"
          :key="index"
          :dog="dog"
          :dog-index="index"
          :food-types="foodTypes"
          @update="updateDog"
        />
      </div>

      <StepNavigation submit :next-disabled="disabled" form="formPreviousRecipes" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
