<template>
  <Transition
    appear
    enter-active-class="tw-duration-300 tw-ease-out"
    enter-from-class="tw-transform tw-opacity-0 -tw-translate-y-4"
    enter-to-class="tw-opacity-100 tw-translate-y-0"
    leave-active-class="tw-duration-200 tw-ease-in"
    leave-from-class="tw-opacity-100"
    leave-to-class="tw-transform tw-opacity-0 -tw-translate-y-4"
  >
    <slot />
  </Transition>
</template>
