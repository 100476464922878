<script setup lang="ts"></script>

<template>
  <Transition
    appear
    enter-active-class="tw-duration-500 tw-ease-out"
    enter-from-class="tw-transform tw-opacity-0"
    enter-to-class="tw-opacity-100 tw-translate-y-0"
    leave-active-class="tw-duration-500 tw-ease-in"
    leave-from-class="tw-opacity-100"
    leave-to-class="tw-transform tw-opacity-0"
  >
    <div class="nav-shadow tw-px-6 tw-bg-body">
      <nav class="tw-flex tw-items-center tw-py-4 tw-min-h-24 tw-gap-6 tw-w-full tw-justify-center tw-z-10">
        <slot />
      </nav>
    </div>
  </Transition>
</template>
