import { z } from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type DogStep, type ModifySchema, Step } from './Step'
import { useFussinessStep } from './fussiness'
import { useBodyShapeStep } from './bodyShape'
import { registerStep } from './stepRegistry'
import { StepName } from '@/stores/steps'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { useDataStore } from '@/stores/data'
import { type DogData, useDogsStore } from '@/stores/dogs'

const Schema = z.object({
  dogs: z
    .array(
      z.object({
        activityLevel: z.number().int(),
      }),
    )
    .min(1),
})

export type ActivityStepData = ModifySchema<
  z.infer<typeof Schema>,
  {
    dogs: {
      activityLevel: number | null
    }[]
  }
>

const DEFAULT_VALUE = null

class ActivityStep extends Step<ActivityStepData> implements DogStep {
  name = StepName.Activity
  schema = Schema
  dogsStore = useDogsStore()
  title = 'Activity'

  onComplete(): void {
    super.onComplete()

    const dataStore = useDataStore()

    this.data.dogs.forEach((dog) => {
      const activityLevel = dog.activityLevel
        ? dataStore.findRecord('activityLevels', dog.activityLevel)?.name
        : undefined

      if (activityLevel) {
        useGTM().sendAction(GTMEventAction.PROVIDED_ACTIVITY_LEVEL, activityLevel)
      }
    })

    useSegment().track('Activity Level Completed', {
      dogs: this.data.dogs.map((dog) => {
        const activityLevelName = dog.activityLevel
          ? dataStore.findRecord('activityLevels', dog.activityLevel)?.name
          : undefined

        return {
          activityLevel: activityLevelName ?? null,
        }
      }),
    })
  }

  constructor() {
    super()
    this.loadState()

    this.dogsStore.on('added', this.addDog.bind(this))
    this.dogsStore.on('removed', this.removeDog.bind(this))
    this.dogsStore.on('loaded', this.loadDog.bind(this))
  }

  addDog({ activityLevel = DEFAULT_VALUE }: DogData = {}): void {
    this.data.dogs.push({ activityLevel })
  }

  removeDog(index: number): void {
    this.data.dogs.splice(index, 1)
  }

  loadDog(index: number): void {
    this.data.dogs[index] ??= { activityLevel: DEFAULT_VALUE }
  }

  initialState(): ActivityStepData {
    return {
      dogs: [],
    }
  }

  prev(): Step {
    return useBodyShapeStep()
  }

  next(): Step {
    return useFussinessStep()
  }
}

export const useActivityStep = registerStep(ActivityStep)
