<script setup lang="ts">
import { LykaCard, LykaCheckbox, LykaInput } from '@lyka/ui'
import { computed } from 'vue'

const props = defineProps<{
  mobile: string
  smsOptIn: boolean
}>()

const emits = defineEmits<{
  (e: 'update:mobile', value: (typeof props)['mobile']): void
  (e: 'update:smsOptIn', value: (typeof props)['smsOptIn']): void
}>()

const mobileValue = computed({
  get() {
    return props.mobile
  },
  set(newValue) {
    emits('update:mobile', newValue)
  },
})

const smsOptInValue = computed({
  get() {
    return props.smsOptIn
  },
  set(newValue) {
    emits('update:smsOptIn', newValue)
  },
})

const formatMobileNumber = ({ target }: Event): void => {
  if (target instanceof HTMLInputElement) {
    target.value = target.value.replace(/\D/g, '')
  }
}
</script>

<template>
  <div class="tw-grid tw-gap-8 tw-content-start">
    <LykaInput
      v-model.trim="mobileValue"
      name="mobile"
      label="Mobile Number"
      placeholder="Enter mobile number"
      error-message="Please enter a valid mobile number"
      type="tel"
      required
      pattern="^04[0-9]{8}$"
      minlength="10"
      maxlength="10"
      @input="formatMobileNumber"
    />

    <div>
      <LykaCard background-color="secondary" class="tw-bg-opacity-40">
        <LykaCheckbox v-model="smsOptInValue" :input-value="true" name="sms-opt-in">
          Text me with exclusive offers, new arrival alerts and cart reminders.

          <template #ancillary>
            <small class="tw-small tw-text-alt"
              >Read our
              <a href="https://lyka.com.au/privacy-and-terms" target="_blank" class="tw-underline"
                >Privacy &amp; Terms</a
              >
              for more details.
            </small>
          </template>
        </LykaCheckbox>
      </LykaCard>
    </div>
  </div>
</template>
