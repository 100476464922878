<script setup lang="ts">
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { computed, onMounted, ref } from 'vue'
import happyPupperImage from '@/assets/images/steps/completed/happy-pupper-desktop.webp'
import CheckoutCompletedCallToActionCard from '@/components/steps/completed/CheckoutCompletedCallToActionCard.vue'
import CheckoutCompletedLayout from '@/components/steps/completed/CheckoutCompletedLayout.vue'
import { useDogsStore } from '@/stores/dogs'
import { useUserStore } from '@/stores/user'
import { conjoin } from '@/utils/conjoin'
import type { PostCheckoutCallToAction, PostCheckoutClick } from '@/events/checkoutCompleted'

const CTAStandard: PostCheckoutCallToAction[] = [
  {
    description: 'Amplify your Lyka meals for extra joint and tissue support.',
    imageFilename: 'checkout-completed/joint-supplement.svg',
    imageAltText: 'Joint supplement photo',
    buttonText: 'Try Joint Supps',
    buttonVariant: 'primary',
    path: `account/supplements#supps_joint_box`,
    internalNavigation: true,
  },
  {
    description: 'Refer a friend and enjoy 50% off for each pal that signs up!',
    imageFilename: 'checkout-completed/refur-a-friend.svg',
    imageAltText: 'Refur a friend dog',
    buttonText: 'Learn more',
    buttonVariant: 'success',
    path: `membership/refer`,
    internalNavigation: true,
  },
]

const CTASocialGifting: PostCheckoutCallToAction[] = [
  {
    description: 'Join our Facebook community page, exclusive to Lyka customers.',
    imageFilename: 'checkout-completed/refur-a-friend.svg',
    imageAltText: 'Facebook community',
    buttonText: 'Join group',
    buttonVariant: 'primary',
    path: 'https://www.facebook.com/groups/pupperparents',
    internalNavigation: false,
  },
  {
    description: 'Read about the life changing benefits of fresh feeding with Lyka.',
    imageFilename: 'checkout-completed/pouches.png',
    imageAltText: 'Refur a friend dog',
    buttonText: 'Learn more',
    buttonVariant: 'success',
    path: 'https://lyka.com.au/blog/benefits-of-real-food-for-dogs',
    internalNavigation: false,
  },
]

const dogsStore = useDogsStore()
const userStore = useUserStore()
const loading = ref(true)

const userFirstName = computed(() => userStore.user.firstName)

const dogNames = computed(() => dogsStore.dogNames)

const userAndDogNames = computed(() => {
  return conjoin([userFirstName.value, ...dogNames.value])
})

const babPageVersion = computed(() => {
  return userStore.userProfile?.babThankYouPageVersion
})

const dogPronoun = (possessive: boolean): string => dogsStore.dogPronoun(0, possessive)

const loadData = async (): Promise<void> => {
  try {
    await userStore.loadUserProfile()
  } finally {
    loading.value = false
  }
}

const multipleDogs = computed(() => {
  return dogsStore.multipleDogs
})

const openUrl = async (payload: PostCheckoutClick): Promise<void> => {
  await useSegment().track('Button Clicked', {
    cta: payload.cta,
    section: 'welcome-to-the-pack',
    page: 'thank-you',
    href: payload.url,
  })

  document.location = payload.url
}

onMounted(async () => {
  await loadData()
})
</script>

<template>
  <div class="tw-bg-secondary tw-overflow-auto tw-min-h-screen">
    <CheckoutCompletedLayout :image="happyPupperImage" @click="openUrl">
      <template #content>
        <h2 class="tw-h2">Welcome to the pack {{ userAndDogNames }}!</h2>
        <p>
          Get excited about variety and feel-good food coming to
          {{ multipleDogs ? 'their' : dogPronoun(true) }} mealtimes.
        </p>
        <div class="tw-w-full tw-flex lg:tw-flex-col tw-flex-row tw-gap-3 tw-flex-nowrap">
          <CheckoutCompletedCallToActionCard
            v-for="(action, index) in babPageVersion === 'social-gifting' ? CTASocialGifting : CTAStandard"
            :key="index"
            v-bind="action"
            @click="openUrl"
          >
          </CheckoutCompletedCallToActionCard>
        </div>
      </template>
    </CheckoutCompletedLayout>
  </div>
</template>
