import z from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { Step } from './Step'
import { usePlanStep } from './plan'
import { useCheckoutStep } from './checkout'
import { registerStep } from './stepRegistry'
import { StepName } from '@/stores/steps'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { useTreatsStore } from '@/stores/treats'

const Schema = z.object({
  products: z.object({
    everyBox: z.boolean(),

    // Products is an object keyed by the product ID to the quantity
    treats: z.record(z.coerce.number().int(), z.number().int().nonnegative()),
  }),
})

export type TreatsStepData = z.infer<typeof Schema>

export type TreatQuantites = Record<number, number>

type TreatEventData = Array<{
  quantity: number
  name: string
  sku: string
}>

const getTreatEventData = (treats: TreatQuantites): TreatEventData => {
  const treatsStore = useTreatsStore()

  return Object.entries(treats).reduce<TreatEventData>((data, [id, quantity]) => {
    const product = treatsStore.findProduct(parseInt(id))

    if (quantity && product) {
      data.push({
        quantity,
        name: product.displayName,
        sku: product.name,
      })
    }

    return data
  }, [])
}

class TreatsStep extends Step<TreatsStepData> {
  name = StepName.Treats
  schema = Schema
  title = 'Treats'
  showSteps = false

  constructor() {
    super()
    this.loadState()
  }

  onComplete(): void {
    super.onComplete()

    const treatsAdded = Object.values(this.data.products.treats).some((quantity) => quantity > 0)

    if (treatsAdded) {
      const eventLabel = this.data.products.everyBox ? 'subscription' : 'once_off'

      useGTM().sendAction(GTMEventAction.ADDED_TREATS, eventLabel)
    } else {
      useGTM().sendAction(GTMEventAction.SKIPPED_TREATS)
    }

    useSegment().track('Treats Completed', {
      treats: getTreatEventData(this.data.products.treats),
    })
  }

  initialState(): TreatsStepData {
    return {
      products: {
        everyBox: true,
        treats: {} as Record<number, number>,
      },
    }
  }

  prev(): Step {
    return usePlanStep()
  }

  next(): Step {
    return useCheckoutStep()
  }
}

export const useTreatsStep = registerStep(TreatsStep)
