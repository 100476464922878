import { defineStore } from 'pinia'
import { ref } from 'vue'
import mitt from 'mitt'
import { clone } from 'remeda'
import type { StepName } from '@/stores/steps'

type StepData = Partial<Record<StepName, object>>

export type StoredData = StepData & {
  version: string
}

const STATE_STORAGE_KEY = 'Lyka::BuildABox'

// Namespace the stored state so that if we do introduce breaking changes we can force users to the new version (and
// wipe their saved state). In general we want to avoid this and instead migrate user data (see below).
const STATE_STORAGE_VERSION = 'V1'

// Hopefully instead we can use the following value stored in the localStorage JSON to author migrations that will allow
// users with existing state to migrate to new versions.
export const VERSION = '1.0.0'

export const storageKey = (): string => {
  return [STATE_STORAGE_KEY, STATE_STORAGE_VERSION].join('::')
}

const getStoredData = (): StoredData => {
  try {
    const json = localStorage.getItem(storageKey())

    if (json) {
      const { version = VERSION, ...data } = JSON.parse(json)

      return {
        ...data,
        version,
      }
    }
  } catch {}

  return {
    version: VERSION,
  }
}

export const setStoredData = (state: StoredData): void => {
  try {
    localStorage.setItem(storageKey(), JSON.stringify(state))
  } catch {}
}

export const useStateStore = defineStore('state', () => {
  const data = ref<StoredData>({
    version: VERSION,
  })

  const loadSavedState = (): void => {
    data.value = getStoredData() ?? {
      version: VERSION,
    }
  }

  loadSavedState()

  const emitter = mitt<{
    imported: StoredData
    reset: StoredData
  }>()

  const on = emitter.on
  const off = emitter.off

  const saveState = (): void => {
    setStoredData(data.value)
  }

  const importState = (state: StepData): void => {
    data.value = {
      version: VERSION,
      ...state,
    }
    saveState()
    emitter.emit('imported', data.value)
  }

  const getState = (): StoredData => {
    return clone(data.value)
  }

  const saveStepState = (stepName: StepName, stepData: object): void => {
    data.value[stepName] = stepData
    saveState()
  }

  const getStepState = (name: StepName): object => {
    return data.value[name] ?? {}
  }

  const resetState = (): void => {
    data.value = {
      version: VERSION,
    }
    saveState()
    emitter.emit('reset', data.value)
  }

  return {
    getState,
    importState,
    saveStepState,
    getStepState,
    resetState,
    on,
    off,
  }
})
