import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { EventSender } from '../EventSender'

export const useBeenHereBeforeEvent: EventSender = (email: string, success: boolean) => {
  const send = (): void => {
    useSegment().track('Been Here Before Completed', {
      email,
      success,
    })
  }

  return {
    send,
  }
}
