import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { MealPlan } from '@/models/MealPlan'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const usePlanCaloriesModalShownEvent = () => {
  const send = ({ mealPlan }: { mealPlan: MealPlan }): void => {
    useSegment().track('Plan Calories Modal Shown', {
      planName: mealPlan.type,
    })
  }

  return {
    send,
  }
}
