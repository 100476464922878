<script lang="ts" setup>
import { LykaButton, useBreakpoints } from '@lyka/ui'
import { computed } from 'vue'
import RightArrowIcon from './icons/RightArrowIcon.vue'
import LeftArrowIcon from './icons/LeftArrowIcon.vue'
import NavigationWrap from './common/NavigationWrap.vue'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import StepNavigationButtonMask from '@/components/StepNavigationButtonMask.vue'

withDefaults(
  defineProps<{
    submit?: boolean
    previousHidden?: boolean
    nextHidden?: boolean
    nextLoading?: boolean
    nextDisabled?: boolean
    previousLoading?: boolean
    previousDisabled?: boolean
    nextText?: string
    nextIcon?: boolean
    form?: string
  }>(),
  {
    nextText: 'Next',
    nextIcon: true,
  },
)

const emits = defineEmits<{
  (e: 'previous'): void
  (e: 'next', ev?: MouseEvent): void
}>()

const breakpoints = useBreakpoints()

const isMobile = computed(() => !breakpoints.value.md)

const previous = (): void => {
  useGTM().sendAction(GTMEventAction.CLICKED_BACK)

  emits('previous')
}
</script>

<template>
  <Teleport to="#stepNav">
    <div ref="stepNav">
      <NavigationWrap>
        <Teleport to="#backBtn" :disabled="!isMobile">
          <LykaButton
            v-if="!previousHidden"
            id="previous-button"
            :outline="!isMobile"
            :disabled="previousDisabled"
            :square="isMobile"
            sr-only="previous button"
            :form="form"
            :auto-width="false"
            :variant="isMobile ? 'alt-dark' : 'alt'"
            type="button"
            :loading="previousLoading"
            @click="previous"
          >
            <template #iconLeft>
              <LeftArrowIcon :class="{ '-tw-mr-2': isMobile }" />
            </template>
            <template v-if="!isMobile"> Previous </template>
          </LykaButton>
        </Teleport>

        <StepNavigationButtonMask :disabled="nextDisabled">
          <LykaButton
            v-if="!nextHidden"
            id="next-button"
            :disabled="nextDisabled"
            block-at="sm"
            :auto-width="false"
            :form="form"
            :type="submit ? 'submit' : 'button'"
            :loading="nextLoading"
            @click="emits('next')"
          >
            {{ nextText }}
            <template v-if="nextIcon && !isMobile" #iconRight>
              <RightArrowIcon />
            </template>
          </LykaButton>
        </StepNavigationButtonMask>
      </NavigationWrap>
    </div>
  </Teleport>
</template>

<style lang="postcss">
.nav-shadow {
  box-shadow: 2px 0px 10px 1px rgba(0, 0, 0, 0.05);
}
</style>
