<script setup lang="ts">
defineProps<{
  selected?: boolean
}>()

const emits = defineEmits<{
  (e: 'select'): void
}>()
</script>

<template>
  <button
    role="tab"
    type="button"
    :aria-selected="selected"
    class="tw-h-8 xs:tw-h-11 tw-px-6 tw-flex-grow tw-flex-shrink tw-flex-basis-0 tw-uppercase tw-border-b-2 tw-border-b-transparent aria-selected:tw-text-success aria-selected:tw-border-b-current tw-bg-input xs:tw-bg-transparent tw-bg-opacity-30"
    @click="emits('select')"
  >
    <slot />
  </button>
</template>
