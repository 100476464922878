import z from 'zod'
import { Step } from './Step'
import { registerStep } from './stepRegistry'
import { useTreatsStep } from './treats'
import { useContactDetailsStep } from './contactDetails'
import { StepName } from '@/stores/steps'
import { StateTerritory } from '@/models/Address'
import { postcodeSchema } from '@/schemas/postcode'
import { mobilePhoneSchema } from '@/schemas/mobilePhone'

export enum DeliveryDateType {
  Soonest,
  Specific,
}

export const CheckoutAccountSchema = z.object({
  user: z.object({
    firstName: z.string().trim().min(1),
    lastName: z.string().trim().min(1),
    email: z.string().email(),
    mobile: mobilePhoneSchema,
    smsOptIn: z.boolean(),
  }),

  howDidYouHear: z.object({
    option: z.number().int().nullable(),
    other: z.string(),
  }),
})

export const CheckoutDeliverySchema = z.object({
  address: z
    .object({
      isWork: z.boolean(),
      businessName: z.string(),
      addressLine1: z.string().trim().min(1),
      addressLine2: z.string().trim().optional().nullable(),
      suburb: z.string().min(1),
      postcode: postcodeSchema,
      state: z.nativeEnum(StateTerritory),
    })
    .refine(({ addressLine1, addressLine2 }) => {
      // addressLine2 is only allowed if addressLine1 is also present
      if (addressLine2) {
        return !!addressLine1
      }

      return true
    }),
  delivery: z.object({
    type: z.nativeEnum(DeliveryDateType),
    date: z.coerce.date(),
    slot: z.number().int(),
    instructions: z.string(),
  }),
})

const Schema = z.intersection(CheckoutAccountSchema, CheckoutDeliverySchema)

export type CheckoutStepData = z.mergeTypes<
  z.infer<typeof Schema>,
  {
    howDidYouHear: {
      option: null | number
      other: string
    }
    address: CheckoutStepAddressData
    delivery: CheckoutDeliveryData
  }
>

export interface CheckoutStepAddressData {
  isManual: boolean
  isWork: boolean
  businessName: string
  addressLine1: string
  addressLine2?: string
  suburb: string
  postcode: string
  state: StateTerritory | null
}

export interface CheckoutDeliveryData {
  type: DeliveryDateType
  date: string | null
  slot: number | null
  instructions: string
}

class CheckoutStep extends Step<CheckoutStepData> {
  name = StepName.Checkout
  schema = Schema
  title = 'Checkout'
  showSteps = false
  showCouponBanner = true

  constructor() {
    super()
    this.loadState()
  }

  initialState(): CheckoutStepData {
    return {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        smsOptIn: true,
      },
      howDidYouHear: {
        option: null,
        other: '',
      },
      address: {
        isManual: false,
        isWork: false,
        businessName: '',
        addressLine1: '',
        addressLine2: '',
        suburb: '',
        postcode: '',
        state: null,
      },
      delivery: {
        type: DeliveryDateType.Soonest,
        date: null,
        slot: null,
        instructions: '',
      },
    }
  }

  update(data: CheckoutStepData): void {
    super.update(data)

    const contactDetails = useContactDetailsStep()

    // Sync these values with those in the contactDetails step
    contactDetails.data.user.email = data.user.email
    contactDetails.data.user.firstName = data.user.firstName
  }

  prev(): Step {
    return useTreatsStep()
  }

  next(): undefined {
    return undefined
  }
}

export const useCheckoutStep = registerStep(CheckoutStep)
