import { ref } from 'vue'

enum NotificationType {
  Success = 'success',
}

const notification = ref<
  | {
      message: string
      type: NotificationType
    }
  | undefined
>()

export const NOTIFICATION_DURATION = 2_000

let timeout: NodeJS.Timeout | undefined

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useNotifications = () => {
  const hide = (): void => {
    notification.value = undefined
  }

  const success = (message: string): void => {
    if (timeout) {
      clearTimeout(timeout)
    }

    notification.value = {
      message,
      type: NotificationType.Success,
    }

    timeout = setTimeout(() => hide(), NOTIFICATION_DURATION)
  }

  return {
    notification,
    hide,
    success,
  }
}
