<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import { clone } from 'remeda'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import StepNavigation from '../StepNavigation.vue'
import MiniHr from '../common/MiniHr.vue'
import BreedDogBreeds from './breed/BreedDogBreeds.vue'
import StepsContainer from '@/components/StepContainer.vue'
import { type BreedStepData } from '@/steps/breed'
import { useDogsStore } from '@/stores/dogs'

const props = defineProps<{
  stepData: BreedStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const dogsStore = useDogsStore()
const data = reactive(clone(props.stepData))

watch(
  () => data,
  (newData) => emits('save', newData),
  { deep: true },
)

const disabled = computed(() => !props.valid)

const submit = ({ target }: Event): void => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  emits('submit', data)
}
</script>

<template>
  <StepsContainer>
    <form id="formBreed" v-submit @submit.prevent="submit">
      <div v-for="(dog, index) in data.dogs" :key="index">
        <MiniHr v-if="index > 0" />
        <BreedDogBreeds v-model="dog.breed" :index="index" :gender="dogsStore.dogGender(index)" />
      </div>

      <StepNavigation submit :next-disabled="disabled" form="formBreed" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
