<script setup lang="ts">
import { LykaButton } from '@lyka/ui'
import RightChevronIcon from './icons/RightChevronIcon.vue'
import { useBeenHereBeforeModal } from '@/composables/useBeenHereBeforeModal'

const { show } = useBeenHereBeforeModal()
</script>

<template>
  <div>
    <div class="tw-success">
      <LykaButton name="been-here-before-button" text-link variant="success" @click="show">
        Been here before? Continue session
        <template #iconRight>
          <RightChevronIcon />
        </template>
      </LykaButton>
    </div>
  </div>
</template>
