<script setup lang="ts">
import { computed } from 'vue'
import { createElementId } from '../utils/createElementId'
import IconTick from './icon/Tick.vue'

type ModelValue = undefined | null | string | number | boolean

const props = withDefaults(
  defineProps<{
    id?: string
    disabled?: boolean
    button?: boolean
    modelValue: ModelValue
    inputValue?: any
    size?: 'md' | 'lg'
    contrast?: boolean
  }>(),
  {
    size: 'md',
    inputValue: true,
  },
)

const emits = defineEmits<{
  (e: 'update:modelValue', value: ModelValue): void
}>()

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  },
})

const inputId = props.id ? props.id : createElementId()
</script>

<template>
  <div class="tw-text-left">
    <label class="tw-flex tw-w-full" :class="{ 'tw-py-4 tw-px-2 tw-border tw-rounded-3xl': button }" :for="inputId">
      <input
        :id="inputId"
        v-bind="$attrs"
        v-model="value"
        :value="inputValue"
        type="checkbox"
        class="tw-absolute tw-opacity-0 tw-h-0 tw-w-0"
        :disabled="disabled"
        :tabindex="disabled ? '-1' : '0'"
      />

      <span class="tw-flex tw-space-x-3">
        <span
          class="tw-checkmark tw-aspect-square tw-rounded-md tw-shrink-0 tw-text-body tw-mt-1 tw-cursor-pointer tw-flex tw-items-center tw-justify-center"
          :class="{
            'tw-h-4 tw-text-xs': props.size === 'md',
            'tw-h-6 tw-text-base': props.size === 'lg',
            '!tw-border-green-dark': props.contrast && !value,
          }"
        >
          <IconTick />
        </span>
        <span v-if="$slots.default" class="tw-text-sm tw-text-alt"><slot /></span>
      </span>
    </label>
    <div v-if="$slots.ancillary" class="tw-pl-7">
      <slot name="ancillary" />
    </div>
  </div>
</template>

<style lang="postcss">
.tw-checkmark {
  @apply tw-bg-input tw-text-transparent;
}
input:checked ~ span .tw-checkmark {
  @apply tw-text-white tw-bg-success tw-border-success;
}
</style>
