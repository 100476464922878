<script setup lang="ts">
import { computed, onMounted, onUnmounted } from 'vue'
import { useBreakpoints } from '@lyka/ui'

const breakpoints = useBreakpoints()

const mobile = computed(() => {
  return !breakpoints.value.md
})

const BODY_CLASS_NAME = 'checkout-redesign-layout' as const

onMounted(() => {
  document.body?.classList.add(BODY_CLASS_NAME)
})

onUnmounted(() => {
  document.body?.classList.remove(BODY_CLASS_NAME)
})
</script>

<template>
  <div class="layout">
    <div class="md:tw-order-last">
      <slot name="summary" />
      <slot v-if="!mobile" name="reviews" />
    </div>

    <div>
      <slot name="form" />
    </div>

    <div v-if="mobile" class="tw-mt-16">
      <slot name="reviews" />
    </div>
  </div>
</template>

<style>
@media (min-width: 768px) {
  .checkout-redesign-layout #root {
    background: linear-gradient(to right, transparent 50%, #faf8f5 50%);
    background-attachment: fixed;
  }
}
</style>

<style scoped>
.layout {
  @apply md:tw-grid md:tw-gap-x-10 lg:tw-gap-x-40 md:tw-gap-y-10 lg:tw-gap-y-20 md:tw-w-full md:tw-justify-center tw-grid-cols-2 tw-max-w-4xl tw-mx-auto;
}
</style>
