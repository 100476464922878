<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-w-16">
    <div class="tw-flex tw-items-center tw-h-6 tw-mb-1 tw-text-green">
      <slot name="icon" />
    </div>
    <span class="tw-leading-tight tw-text-p1-t tw-whitespace-nowrap">
      <slot name="first" />
    </span>
    <span class="tw-leading-tight tw-font-light tw-text-xs tw-whitespace-nowrap">
      <slot name="second" />
    </span>
  </div>
</template>
