import type { Plugin } from 'vue'
import { useStateStore } from '@/stores/state'

const PRESSES_TO_RESET = 10

export const debugPlugin: Plugin = {
  install(): void {
    let presses = 0

    // If a user presses `Shift` 10 times then we ask if they want to reset their data
    // This is inherited from the last BAB and is for customer support to help resolve
    // potential issues with the state of people's BAB data.
    window.addEventListener('keydown', ({ key }) => {
      if (key === 'Shift') {
        presses++
      } else {
        presses = 0
      }

      if (presses === PRESSES_TO_RESET) {
        // eslint-disable-next-line no-alert
        const confirmed = confirm('Are you sure you want to reset your data?')

        if (confirmed) {
          useStateStore().resetState()
          location.reload()
        }

        presses = 0
      }
    })
  },
}
