export enum IllnessName {
  SkinItchiness = 'Skin Itchiness',
  IBD = 'IBD',
  Gassiness = 'Gassiness',
  UrinaryIssues = 'Urinary issues',
  Pancreatitis = 'Pancreatitis',
  Cancer = 'Cancer',
  Diabetes = 'Diabetes',
  LiverDisease = 'Liver disease',
  KidneyDisease = 'Kidney disease',
  Arthritis = 'Arthritis',
  Dental = 'Dental',
  Anxiety = 'Anxiety',
  Epilepsy = 'Epilepsy',
  CardiacDisease = 'Cardiac disease',
  Unkown = 'Uknown',
}

export interface Illness {
  id: number
  name: IllnessName
  displayText: string
  explanation: string
  lykaSuitable: 0 | 1
  displayOrder: number
}

const DOG_NAME_TEMPLATE_VALUE = '<dogName>'

// Interpolate `<dogName>` in the explanation string
export const getIllnessDescription = (illness: Illness, dogName: string): string => {
  const re = new RegExp(DOG_NAME_TEMPLATE_VALUE, 'g')

  return illness.explanation.replace(re, dogName)
}
