export const formatDate = (date: string | Date, options?: Partial<Intl.DateTimeFormatOptions>): string => {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return date.toLocaleDateString('en-AU', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    ...options,
  })
}
