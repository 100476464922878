import type { AnalyticsBrowser } from '@segment/analytics-next'
import type { App, InjectionKey, Plugin } from 'vue'
import { delay } from '@/utils/delay'

export const key = Symbol('segment') as InjectionKey<AnalyticsBrowser>

type Intercom = (eventName: string, eventData?: object) => void

declare global {
  interface Window {
    Intercom?: Intercom
    intercomSettings: Record<string, any>
  }
}

const isMobile = (): boolean => document.body.clientWidth < 768

const hideIntercomLauncher = (): void => {
  if (window.Intercom) {
    window.Intercom('update', { hide_default_launcher: true })
  } else {
    window.intercomSettings = {
      ...window.intercomSettings,
      hide_default_launcher: true,
    }
  }
}

const disableIntercomLauncher = (): void => {
  window.intercomSettings ??= {}
  window.intercomSettings.hide_default_launcher = true
  window.intercomSettings.app_id = undefined
  window.Intercom?.('shutdown')

  delay(1000).then(() => window.Intercom?.('shutdown'))
}

export const intercomPlugin: Plugin = {
  // Hide the intercom dialog prompt on small devices to avoid it obstructing the screen
  install(_: App, options?: { disabled?: boolean }): void {
    if (options?.disabled) {
      disableIntercomLauncher()
    } else if (isMobile()) {
      hideIntercomLauncher()
    }
  },
}
