<script setup lang="ts">
import { assetUrl } from '@lyka/utils'
import { capitalize, computed } from 'vue'
import PlanCardSummary from './PlanCardSummary.vue'
import PlanCardPrice from './PlanCardPrice.vue'
import { useDogsStore } from '@/stores/dogs'
import type { MealPlan } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'

const props = defineProps<{
  mealPlan: MealPlan
  mealWeights: MealPlanWeight[]
}>()

const imageURL = computed(() => {
  return assetUrl(`images/steps/plans/${props.mealPlan.type}.webp`)
})

const multipleDogs = computed(() => useDogsStore().multipleDogs)

const names = computed(() => {
  const [first, ...others] = useDogsStore().dogNames

  if (others.length || !first) {
    return 'Your'
  }

  return `${capitalize(first)}'s`
})
</script>

<template>
  <div class="tw-bg-white tw-rounded-2xl tw-max-w-3xl tw-mx-auto">
    <div class="md:tw-flex tw-gap-8 tw-p-6 md:tw-p-16 md:tw-pl-12 tw-items-center tw-mt-48 md:tw-mt-0">
      <div>
        <div class="-tw-mt-48 md:tw-mt-0 tw-relative tw-z-[1] tw-mb-2 md:tw-mb-0">
          <img :src="imageURL" alt="A photo of the Lyka food" class="tw-w-52 tw-mx-auto" width="400" height="400" />
        </div>

        <div class="tw-p-8 tw-hidden md:tw-block">
          <PlanCardSummary :meal-plan="mealPlan" :meal-weights="mealWeights" />
        </div>
      </div>

      <div class="tw-text-center md:tw-text-left">
        <div class="tw-space-y-3 tw-max-w-sm tw-mx-auto">
          <h3 class="tw-font-stylized tw-text-6xl tw-leading-tighter">
            {{ names }} <span class="tw-whitespace-nowrap">Starter Box</span>
          </h3>
          <p>
            Join the pack with two weeks of custom portioned meals based on your
            {{ multipleDogs ? 'dogs&rsquo;' : 'dog’s' }} unique profile.
          </p>
        </div>

        <div class="tw-p-4 md:tw-hidden tw-mt-4">
          <PlanCardSummary :meal-plan="mealPlan" :meal-weights="mealWeights" />
        </div>

        <div class="tw-border-t tw-border-mint-green tw-pt-4 tw-mt-4 md:tw-pt-8 md:tw-mt-8">
          <PlanCardPrice
            :meal-plan="props.mealPlan"
            :multiple-dogs="multipleDogs"
            meal-price-display="day"
            show-total-price
          />
        </div>
      </div>
    </div>
  </div>
</template>
