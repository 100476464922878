<script setup lang="ts">
import { computed } from 'vue'
import { StepChangeDirection } from '@/stores/steps'

const props = defineProps<{
  direction: StepChangeDirection
}>()

const forwards = computed(() => {
  return props.direction === StepChangeDirection.Forward
})
</script>

<template>
  <Transition
    appear
    mode="out-in"
    enter-active-class="tw-duration-300 tw-ease-out"
    :enter-from-class="`tw-transform tw-opacity-0 ${forwards ? 'tw-translate-x-16' : '-tw-translate-x-16'}`"
    enter-to-class="tw-opacity-100 tw-translate-x-0"
    leave-active-class="tw-duration-200 tw-ease-in"
    leave-from-class="tw-opacity-100 tw-translate-x-0"
    :leave-to-class="`tw-transform tw-opacity-0 ${forwards ? '-tw-translate-x-16' : 'tw-translate-x-16'}`"
  >
    <slot />
  </Transition>
</template>
