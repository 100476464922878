<script setup lang="ts">
import { LykaButton, LykaModal } from '@lyka/ui'
import { useConfirm } from '@/composables/useConfirm'
import { addLineBreaks } from '@/utils/linebreaks'

const { confirm, decline, active, confirmation } = useConfirm()
</script>

<template>
  <LykaModal v-if="active && confirmation" open closable @close="decline">
    <template v-if="'component' in confirmation && confirmation.component">
      <component :is="confirmation.component" />
    </template>

    <template v-else>
      <h3 class="tw-h3 tw-text-center">{{ confirmation.title }}</h3>

      <div class="tw-flex tw-flex-col tw-items-center tw-w-full">
        <p class="tw-mt-8 tw-text-center tw-max-w-lg" v-html="addLineBreaks(confirmation.message)"></p>
      </div>
    </template>

    <div class="tw-mt-12 tw-flex tw-justify-center tw-w-full">
      <div class="tw-flex tw-gap-4 tw-w-full tw-max-w-sm">
        <LykaButton variant="subtle" class="tw-grow" @click="decline">{{
          confirmation.decline ?? 'Cancel'
        }}</LykaButton>
        <LykaButton variant="primary" class="tw-grow" @click="confirm">{{
          confirmation.confirm ?? 'Confirm'
        }}</LykaButton>
      </div>
    </div>
  </LykaModal>
</template>
