<script setup lang="ts">
import TransitionSlideIn from '@lyka/ui/components/transition/SlideIn.vue'
import IconTick from '@lyka/ui/components/icon/Tick.vue'
import { useNotifications } from '@/composables/useNotifications'

const { notification, hide } = useNotifications()
</script>

<template>
  <TransitionSlideIn>
    <div
      v-if="notification"
      role="alert"
      class="tw-fixed tw-z-50 tw-rounded-2xl tw-bg-white tw-py-3 tw-px-5 tw-border tw-border-gold tw-text-alt tw-gap-3 tw-flex tw-items-center tw-shadow-[0px_3px_6px_-3px_rgba(23,24,24,0.04),0px_8px_20px_-4px_rgba(23,24,24,0.04)] tw-cursor-pointer tw-left-6 tw-right-6 tw-bottom-24 tw-text-sm sm:tw-max-w-lg sm:tw-bottom-36 sm:tw-left-1/2 sm:-tw-translate-x-1/2 sm:tw-w-full sm:tw-text-base hover:tw-border-success"
      @click="hide"
    >
      <IconTick v-if="notification.type === 'success'" class="tw-w-5 tw-h-5 tw-text-success" />
      <div>
        {{ notification.message }}
      </div>
    </div>
  </TransitionSlideIn>
</template>
