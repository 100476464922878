<script setup lang="ts">
import { useIntercom } from '@/composables/useIntercom'
import { useFrontChat } from '@/composables/useFrontChat'

const showSupportDialog = (): void => {
  if (useFrontChat().loaded) {
    useFrontChat().show()
  } else {
    useIntercom().show()
  }
}

const year = new Date().getFullYear()
</script>

<template>
  <footer
    class="tw-flex tw-flex-col tw-space-y-4 lg:tw-flex-row lg:tw-items-center lg:tw-justify-between lg:tw-space-y-0 tw-px-6 tw-py-6 lg:tw-py-4 tw-bg-alt tw-text-light tw-text-xs"
  >
    <span>© {{ year }} Lyka</span>
    <span class="tw-font-light">
      <strong>Get in touch</strong>:
      <span class="tw-underline tw-cursor-pointer tw-font-bold" @click="showSupportDialog">Live chat</span> or call us
      on <a class="tw-underline tw-font-bold" href="tel:1300851936">1300 851 936</a> (Mon-Fri 8am-6pm & Sat-Sun 10am-6pm
      AEST)
    </span>
    <span class="tw-font-light">
      This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
    </span>
  </footer>
</template>
