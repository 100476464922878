type Callback = (...args: any[]) => any

export const useDebounce = <C extends Callback>(callback: C, delay: number): Callback => {
  let timer: NodeJS.Timeout

  return (...args: any) => {
    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => callback(...args), delay)
  }
}
