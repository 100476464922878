import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { Dog } from '@/models/Dog'

interface EventData {
  weight: number
  minAverageWeight: number
  maxAverageWeight: number
  dog: Dog
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useWeightWarningEvent = ({ weight, dog, minAverageWeight, maxAverageWeight }: EventData) => {
  const send = (): void => {
    useSegment().track('Dog Weight Warning Shown', {
      weight,
      minAverageWeight,
      maxAverageWeight,
      breedPrimary: dog.breed.primary?.name ?? null,
      breedSecondary: dog.breed.secondary?.name ?? null,
      breedType: dog.breed.type,
    })
  }

  return {
    send,
  }
}
