<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { experimentsFromFlags, useFlagsmith } from '@lyka/vue-common/composables/useFlagsmith'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { useExperiments } from '@lyka/vue-common/composables/useExperiments'
import { LykaSpinner } from '@lyka/ui'
import { assetUrl } from '@lyka/utils'
import PreviousSessionModal from './components/PreviousSessionModal.vue'
import { useSession } from './composables/useSession'
import TheCouponBanner from './components/TheCouponBanner.vue'
import { useDogsStore } from './stores/dogs'
import TheNotifications from './components/TheNotifications.vue'
import CurrentStep from './components/CurrentStep.vue'
import GettingStarted from './components/GettingStarted.vue'
import { useGettingStarted } from './composables/useGettingStarted'
import { useBeenHereBeforeModal } from './composables/useBeenHereBeforeModal'
import BeenHereBeforeModal from './components/BeenHereBeforeModal.vue'
import { isUTMCampaignCoupon, useCoupons } from './composables/useCoupons'
import StepError from './components/StepError.vue'
import { usePlanStep } from './steps/plan'
import type { MealPlanType } from './models/MealPlan'
import { BreedType, useBreedStep } from './steps/breed'
import CheckoutCompleted from '@/components/steps/completed/CheckoutCompleted.vue'
import CheckoutCompletedV2 from '@/components/steps/completed/CheckoutCompletedV2.vue'
import TheHeader from '@/components/TheHeader.vue'
import TheSteps from '@/components/TheSteps.vue'
import { useStepsStore } from '@/stores/steps'
import { useDataStore } from '@/stores/data'
import { useRecipesStore } from '@/stores/recipes'
import { useTreatsStore } from '@/stores/treats'
import { getURLParam } from '@/utils/getURLParam'
import { useRecoverSession } from '@/composables/useRecoverSession'
import { useExperimentEvents } from '@/composables/events/segment/useExperimentEvents'
import { runStateMigrations } from '@/migrations'
import { useCheckoutStartedEvents } from '@/composables/events/useCheckoutStartedEvents'
import TheConfirmModal from '@/components/TheConfirmModal.vue'

const DOWN_FOR_MAINTENANCE_FLAG = 'down-for-maintenance' as const

const stepsStore = useStepsStore()
const dataStore = useDataStore()
const recipesStore = useRecipesStore()
const treatsStore = useTreatsStore()
const dogsStore = useDogsStore()
const session = useSession()
const gettingStarted = useGettingStarted()
const beenHereBeforeModal = useBeenHereBeforeModal()
const coupons = useCoupons()

const loading = ref(true) // Is true while the app loads its initial data
const error = ref(false)
const downForMaintenance = ref(false)

const hideStepCount = computed(() => !stepsStore.currentStep.showSteps)

const previousSessionModalOpened = ref(false)

const showGetStartedBeenHereBefore = (): void => {
  beenHereBeforeModal.show()
  gettingStarted.hide()
}

const openPreviousSessionModal = (): void => {
  previousSessionModalOpened.value = true
}

const closePreviousSessionModal = (): void => {
  previousSessionModalOpened.value = false
}

const createFirstDog = (): void => {
  if (dogsStore.dogCount === 0) {
    dogsStore.addDog()
  }
}

const loadData = async (): Promise<void> => {
  await Promise.all([dataStore.load(), recipesStore.load(), treatsStore.load()])
}

const runInitializers = async (): Promise<void> => {
  dogsStore.initialize()
}

const initializeSession = (): boolean => {
  const { hasPreviousSession } = useSession()

  if (hasPreviousSession) {
    // Run migrations to correct any user-state issues
    runStateMigrations()

    if (stepsStore.isFirstStep) {
      openPreviousSessionModal()
    }
  }

  // Create the users first dog if none exists
  createFirstDog()

  // Return true if the session is new
  return !hasPreviousSession
}

const experimentsFromURL = (): Record<string, string> => {
  const experiments: Record<string, string> = {}

  // Try to parse the URL for experiment flags
  try {
    const params = new URL(window.location.href).searchParams

    for (const [k, v] of params) {
      if (k.startsWith('experiment-')) {
        const experimentName = k.replace('experiment-', '')

        experiments[experimentName] = v || 'experiment'
      }
    }
  } catch {}

  return experiments
}

const identifyUserWithExperiments = async (): Promise<void> => {
  const anonymousId = await useSegment().getAnonymousId()
  const flags = await useFlagsmith().loadFlags(anonymousId)

  if (DOWN_FOR_MAINTENANCE_FLAG in flags) {
    downForMaintenance.value = flags[DOWN_FOR_MAINTENANCE_FLAG].enabled
  }

  const experiments = experimentsFromFlags(flags)

  useExperiments().setExperiments({ ...experiments, ...experimentsFromURL() })

  // Don't trigger events for experiments from URL
  useExperimentEvents(experiments).send()
}

const inCheckoutFreeTreatsExperiment = computed(() => useExperiments().inExperiment('bab-checkout-free-treats'))

const showGettingStarted = computed(() => {
  return !session.hasPreviousSession && gettingStarted.visible.value && stepsStore.isFirstStep && !getURLParam('breed')
})

const determineInitialStep = (): void => {
  // Check to see if we have a step param in the URL
  const stepParam = getURLParam('step')

  // If we do then skip to that step, otherwise start at the first step
  const stepName = stepsStore.isStepName(stepParam) ? stepParam : undefined

  // Initialize the steps with the step
  stepsStore.initialize(stepName)
}

const applyDefaultCoupon = async (): Promise<void> => {
  if (!coupons.activeCoupon.value && !isUTMCampaignCoupon) {
    await useCoupons().applyDefaultCoupon()
  }
}

const loadPlanFromURL = (): void => {
  const plan = getURLParam('plan') as MealPlanType | undefined

  if (plan) {
    usePlanStep().update({ plan: { type: plan } })
  }
}

const loadBreedFromURL = (): void => {
  const breed = getURLParam('breed') as string

  if (breed) {
    const breedId = parseInt(breed)

    if (breedId > -1) {
      useBreedStep().updateBreed(0, { type: BreedType.Pure, primary: breedId, secondary: null })
    }
  }
}

const showCouponBanner = computed<boolean>(() => {
  return !!(coupons.activeCoupon.value || coupons.defaultCoupon.value)
})

onMounted(async () => {
  try {
    // Determine the initial step. If a user has `step=example` in the query string then try to jump to that step
    determineInitialStep()

    await Promise.all([
      // Identify the current user with their experiments
      identifyUserWithExperiments(),

      // Load the BAB data models (breeds, illnesses, etc.)
      loadData(),

      coupons.load(),
    ])

    await runInitializers()

    await applyDefaultCoupon()

    loadPlanFromURL()

    // Check if the user is trying to recover their session. This checks the URL for a `continue` param with a customer
    // hash that if present attempts to load the checkout state.
    await useRecoverSession().load()

    // Initialize the session
    const isNewSession = initializeSession()

    loadBreedFromURL()

    // Send checkout started events
    useCheckoutStartedEvents({ isNewSession }).send()
  } catch {
    error.value = true
  } finally {
    loading.value = false
  }
})
</script>

<template>
  <div id="root" class="tw-flex tw-flex-col tw-overflow-hidden tw-min-h-screen tw-text-alt">
    <div v-if="loading">
      <div class="tw-flex tw-items-center tw-justify-center tw-h-[35vh]">
        <LykaSpinner />
      </div>
    </div>

    <div v-else-if="downForMaintenance">
      <div class="tw-max-w-screen-xs tw-space-y-4 tw-mx-auto tw-mt-20 tw-px-6 tw-text-center">
        <div>
          <img
            :src="assetUrl('images/maintenance/sleeping.svg')"
            alt="Maintenance"
            class="tw-w-full tw-max-w-xs tw-mx-auto tw-mb-10"
          />
        </div>
        <h1 class="tw-h2">We'll be back soon</h1>
        <p class="tw-lead">Lyka is down for maintenance while we make some upgrades. We'll be back online soon.</p>
      </div>
    </div>

    <template v-else>
      <template v-if="stepsStore.completed">
        <CheckoutCompletedV2 v-if="inCheckoutFreeTreatsExperiment" />
        <CheckoutCompleted v-else />
      </template>

      <GettingStarted
        v-else-if="showGettingStarted"
        @dismiss="gettingStarted.hide"
        @continue="showGetStartedBeenHereBefore"
      />

      <template v-else>
        <TheHeader />
        <TheCouponBanner v-if="showCouponBanner" />

        <div id="alert"></div>

        <main class="tw-h-full tw-grow tw-flex tw-flex-col tw-relative tw-overflow-hidden">
          <div class="tw-flex tw-flex-col tw-grow tw-overflow-auto">
            <div
              class="tw-flex tw-flex-col tw-grow tw-overflow-x-hidden tw-pb-28"
              :class="[
                {
                  'sm:tw-pt-16 tw-pt-6': hideStepCount,
                  'tw-pt-6 sm:tw-pt-8': !hideStepCount,
                },
              ]"
            >
              <TheSteps v-if="!hideStepCount" />
              <StepError v-if="error" />
              <CurrentStep v-else />
            </div>
          </div>
        </main>

        <PreviousSessionModal :open="previousSessionModalOpened" @close="closePreviousSessionModal" />
      </template>

      <BeenHereBeforeModal :open="beenHereBeforeModal.visible.value" @close="beenHereBeforeModal.hide" />
    </template>

    <div id="stepNav" class="tw-w-full tw-fixed tw-bottom-0 tw-left-0 tw-z-20" />
    <TheNotifications />
    <TheConfirmModal />

    <!-- Used to render into via Teleport -->
    <footer id="app-footer"></footer>
  </div>
</template>

<style>
/* Hide recaptcha badge */
.grecaptcha-badge {
  visibility: hidden;
}
</style>
