<script setup lang="ts">
defineProps<{
  disabled?: boolean
}>()

const getFormFromEvent = ({ currentTarget }: Event): HTMLFormElement | undefined => {
  if (currentTarget instanceof HTMLElement) {
    return (currentTarget.firstElementChild as HTMLButtonElement | undefined)?.form ?? undefined
  }

  return undefined
}

const validateForm = (ev: MouseEvent): void => {
  const form = getFormFromEvent(ev)

  if (!form) {
    return
  }

  const valid = form.checkValidity()

  if (valid) {
    return
  }

  const invalidField = form.querySelector('input:invalid, select:invalid')

  if (invalidField) {
    invalidField.scrollIntoView({
      behavior: 'smooth',
    })
  }
}
</script>

<template>
  <span class="tw-relative tw-block tw-cursor-pointer tw-w-full sm:tw-w-auto" @click="validateForm">
    <slot />
    <span v-if="disabled" class="tw-absolute tw-inset-0" />
  </span>
</template>
