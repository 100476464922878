import type { IdentifyParams, PageParams } from '@segment/analytics-next/dist/types/core/arguments-resolver'
import type { AnalyticsBrowser, User } from '@segment/analytics-next'
import type { SegmentEvents } from '@lyka/vue-common/events/segment'
import snakecaseKeys from 'snakecase-keys'
import { getSegment } from '../services/segment'
import { withTimeout } from '../utils/withTimeout'
import { useEventGateway } from './useEventGateway'

const transformEvent = (eventData: Record<string, unknown>): Record<string, unknown> => {
  const snakecased = eventData ? snakecaseKeys(eventData) : {}

  return snakecased
}

const SEGMENT_ANONYMOUS_USER_ID_KEY = 'ajs_anonymous_id'

const getOrCreateAnonymousId = (): string => {
  const existingAnonymousId = localStorage.getItem(SEGMENT_ANONYMOUS_USER_ID_KEY)

  if (existingAnonymousId) {
    return existingAnonymousId
  }

  const uuid = crypto.randomUUID()
  localStorage.setItem(SEGMENT_ANONYMOUS_USER_ID_KEY, uuid)

  return uuid
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSegment = () => {
  const duplicateEvent = async (segment: AnalyticsBrowser, eventName: string, eventData?: object): Promise<void> => {
    const user = await segment?.user()

    const anonymousId = user?.anonymousId()
    const userId = user?.id()

    await useEventGateway().send({
      event: eventName,
      properties: eventData,
      anonymousId,
      userId,
    })
  }

  const track = async <K extends keyof SegmentEvents>(
    eventName: K,
    eventData: SegmentEvents[K],
    duplicate?: boolean,
  ): Promise<void> => {
    const transformedData = transformEvent(eventData ?? {})
    const segment = getSegment()

    try {
      getSegment()?.track(eventName, transformedData)
    } catch (error) {}

    if (duplicate && segment) {
      try {
        await duplicateEvent(segment, eventName, transformedData)
      } catch (error) {}
    }
  }

  const identify = (...args: IdentifyParams): void => {
    getSegment()?.identify(...args)
  }

  const page = (...args: PageParams): void => {
    getSegment()?.page(...args)
  }

  const getUser = async (): Promise<User | undefined> => {
    try {
      const segment = getSegment()

      if (!segment) {
        return undefined
      }

      const user = await withTimeout<User>(() => segment.user(), 2_000)

      return user
    } catch (err) {
      console.error('Failed to fetch Segment User', err)

      return undefined
    }
  }

  const getAnonymousId = async (): Promise<string> => {
    const user = await getUser()

    if (user) {
      const id = user.anonymousId()

      if (id) {
        return id
      }
    }

    // If Segment failed to initialize, we'll create our own anonymous ID
    return getOrCreateAnonymousId()
  }

  return {
    track,
    identify,
    page,
    getAnonymousId,
  }
}
