<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_23533)">
      <path
        d="M32 2C32 9.1 26.7125 14.9688 19.8625 15.875C19.4187 12.5375 17.95 9.525 15.7812 7.16875C18.175 2.89375 22.75 0 28 0H30C31.1063 0 32 0.89375 32 2ZM0 6C0 4.89375 0.89375 4 2 4H4C11.7312 4 18 10.2688 18 18V20V30C18 31.1063 17.1063 32 16 32C14.8938 32 14 31.1063 14 30V20C6.26875 20 0 13.7312 0 6Z"
        fill="#10B193"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_23533">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
