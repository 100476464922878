import { z } from 'zod'
import { Builder } from './Builder'
import { getBreedTypeName } from '@/models/Dog'
import { AgeType, BreedTypeName, type Dog } from '@/models/Dog'
import { type MealPlan, MealPlanType } from '@/models/MealPlan'
import type { MealPlanWeight } from '@/models/MealPlanWeight'
import type User from '@/models/User'
import { BirthdayType } from '@/steps/age'
import { type Coupon, getDiscountedPrice } from '@/models/Coupon'

interface IdentifiableRecord {
  id: number
}

interface NamedRecord extends IdentifiableRecord {
  name: string
}

const namedRecord = z.object({
  name: z.string(),
})

const identifiableRecord = z.object({
  id: z.number(),
})

const acquisitionDogDataSchema = z.object({
  name: z.string(),
  gender: z.enum(['Boy', 'Girl']),
  breed: z.object({
    type: z.nativeEnum(BreedTypeName),
    primary: identifiableRecord,
    secondary: identifiableRecord.optional(),
  }),
  age: z.object({
    is: z.nativeEnum(AgeType),
    years: z.number(),
    months: z.number(),
    date: z.string(),
    type: z.nativeEnum(BirthdayType),
  }),
  weight: z.object({
    current: z.number(),
    adult: z.number().optional(),
  }),
  bodyShape: identifiableRecord,
  activity: identifiableRecord,
  fusinessLevel: namedRecord,
  hasAllergies: z.boolean(),
  hasIllness: z.boolean(),
  illnesses: z.array(namedRecord),
  allergies: z.array(namedRecord),
  plan_weight: z.number(),
  serving_weight: z.number(),
  selectedRecipes: z.array(z.string()),
})

export type AcquisitionDogData = z.infer<typeof acquisitionDogDataSchema>

const acquisitionPlanDataSchema = z.object({
  type: z.nativeEnum(MealPlanType),
  frequency: z.number(),
  daily_price: z.number(),
  weekly_price: z.number(),
  subscription_price: z.number(),
})

const acquisitionProductDataSchema = z.object({
  id: z.number(),
  quantity: z.number(),
})

const acquisitionDataSchema = z.object({
  referrer: z.string().optional(),
  firstname: z.string(),
  lastname: z.string(),
  page: z.string(),
  dogs: z.array(acquisitionDogDataSchema).min(1),
  postcode: z.number(),
  email: z.string(),
  selectedPlan: acquisitionPlanDataSchema.or(z.object({})), // Plan data or an empty object
  selectedProducts: z.array(acquisitionProductDataSchema).optional(),
  coupon: z
    .object({
      discount: z.number().optional(),
      code: z.string(),
    })
    .optional(),
  plans: z.record(
    z.nativeEnum(MealPlanType),
    z.object({
      totalPrice: z.number(),
      discountedTotalPrice: z.number().optional(),
    }),
  ),
})

export type AcquisitionData = z.infer<typeof acquisitionDataSchema>

const asNamedRecord = ({ id, name }: NamedRecord): NamedRecord => {
  return {
    id,
    name,
  }
}

const asIdentifiableRecord = ({ id }: IdentifiableRecord): IdentifiableRecord => {
  return {
    id,
  }
}

const initialData = {
  selectedPlan: {},
  dogs: [],
  plans: {} satisfies Record<string, object>,
}

export class AcquisitionDataBuilder extends Builder {
  public data: Pick<AcquisitionData, 'plans' | 'dogs' | 'selectedPlan'> & Partial<AcquisitionData> = {
    ...initialData,
  }

  private validate(data: typeof this.data): data is AcquisitionData {
    const result = acquisitionDataSchema.safeParse(data)

    if (result.success) {
      return true
    }

    console.error(result.error)

    return false
  }

  reset(): void {
    this.data = { ...initialData }
  }

  addPage(page: string): this {
    this.data.page = page

    return this
  }

  addUser({ email, firstName, lastName }: Pick<User, 'email' | 'firstName' | 'lastName'>): this {
    Object.assign(this.data, {
      email,
      firstname: firstName,
      lastname: lastName,
    })

    return this
  }

  addPostcode(postcode: string): this {
    this.data.postcode = parseInt(postcode)

    return this
  }

  addPlan(mealPlan: MealPlan): this {
    this.data.selectedPlan = {
      type: mealPlan.type,
      daily_price: mealPlan.priceDaily,
      weekly_price: mealPlan.priceWeekly,
      subscription_price: mealPlan.totalPrice,
      frequency: mealPlan.frequency,
    }

    return this
  }

  addDogs(dogs: Dog[], mealWeights: MealPlanWeight[]): this {
    this.data.dogs = dogs.map((dog, index) => {
      const mealWeight = mealWeights[index]
      const primaryBreed = dog.breed.primary ? asNamedRecord(dog.breed.primary) : undefined
      const secondaryBreed = dog.breed.secondary ? asNamedRecord(dog.breed.secondary) : undefined

      return {
        name: dog.name,
        age: dog.age,
        weight: dog.weight,
        gender: dog.gender!,
        hasIllness: dog.hasIllness,
        hasAllergies: !!dog.allergies.length,
        bodyShape: asIdentifiableRecord(dog.bodyShape!),
        activity: asIdentifiableRecord(dog.activity!),
        fusinessLevel: asNamedRecord(dog.fusinessLevel!),
        illnesses: dog.illnesses.map(asNamedRecord),
        allergies: dog.allergies.map(asNamedRecord),
        selectedRecipes: dog.recipes.map(({ name }) => name),
        plan_weight: mealWeight?.planWeight ?? 0,
        serving_weight: mealWeight?.servingWeight ?? 0,

        breed: {
          primary: primaryBreed,
          secondary: secondaryBreed,
          type: getBreedTypeName(dog),
        },
      }
    }) as Array<AcquisitionDogData>

    return this
  }

  addProducts({ treats }: { everyBox: boolean; treats: Record<number, number> }): this {
    const products = []

    for (const [id, quantity] of Object.entries(treats)) {
      if (quantity > 0) {
        products.push({
          quantity,
          id: parseInt(id),
        })
      }
    }

    this.data.selectedProducts = products

    return this
  }

  addReferrer(uuid: string): this {
    this.data.referrer = uuid

    return this
  }

  private setDiscountedPlanPrices(): void {
    if (!this.data.coupon) {
      return
    }

    Object.values(this.data.plans).forEach((plan) => {
      plan.discountedTotalPrice = getDiscountedPrice(plan.totalPrice, this.data.coupon)
    })
  }

  addCoupon(coupon: Coupon): this {
    this.data.coupon = {
      discount: coupon.discount,
      code: coupon.couponCode,
    }

    this.setDiscountedPlanPrices()

    return this
  }

  addMealPlans(plans: MealPlan[]): this {
    this.data.plans = {}

    for (const plan of plans) {
      this.data.plans[plan.type] = {
        totalPrice: plan.totalPrice,
      }
    }

    this.setDiscountedPlanPrices()

    return this
  }

  get(): AcquisitionData {
    if (!this.validate(this.data)) {
      throw new Error('Invalid acquisition data')
    }

    return this.data
  }
}
