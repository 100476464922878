import { useCookies } from '@lyka/vue-common/composables/useCookies'
import { computed, ref } from 'vue'
import { useExperiments } from '@lyka/vue-common/composables/useExperiments'
import { defer } from '@lyka/utils/src/defer'
import { DEFAULT_COUPON_CODE } from '@lyka/constants'
import { useApi } from './useApi'
import type { Coupon, CouponReferrer } from '@/models/Coupon'
import { getURLParam } from '@/utils/getURLParam'
import type { MealPlanType } from '@/models/MealPlan'

interface CouponResponseData {
  // The data is returned as an empty array if there was an error
  data?: Coupon | []
  message:
    | 'coupon_expired'
    | 'invalid_coupon'
    | 'email_exists'
    | 'invalid_plan_type'
    | 'max_redemptions_reached'
    | 'invalid_qualifier'
    | 'Success!'
}

interface CouponResult {
  success: boolean
  coupon?: Coupon
  error?: string
}

const COUPON_COOKIE_NAME = 'coupon'
const REFERRER_COOKIE_NAME = 'referrer'
const COUPON_QUERY_PARAM = 'cp'
const COUPON_MAX_AGE_SECONDS = 14 * 24 * 60 * 60 // 14 Days
const COUPON_VALID_RESPONSE_MESSAGE = 'Success!'
const COUPON_MINIMUM_SPEND_RESPONSE_MESSAGE = 'invalid_qualifier'

export const UTM_CAMPAIGN_QUERY_PARAM = 'utm_campaign'
export const UTM_CAMPAIGN_LIST = ['raf_social_good'] as const

const UTM_CAMPAIGNS_STRINGS: readonly string[] = UTM_CAMPAIGN_LIST

export const isUTMCampaignCoupon = UTM_CAMPAIGNS_STRINGS.includes(UTM_CAMPAIGN_QUERY_PARAM)

// Create a map of human-readable error messages
const errorMessages = new Map<CouponResponseData['message'], string>([
  ['invalid_coupon', 'Please enter a valid coupon.'],
  ['email_exists', 'Coupon has already been used.'],
  ['invalid_plan_type', 'This coupon is only valid for Starter Boxes.'],
  ['max_redemptions_reached', 'The maximum number of redemptions for this coupon has been exceeded.'],
  ['coupon_expired', 'This coupon is no longer valid. Please contact info@lyka.com.au for further support.'],
  ['invalid_qualifier', 'Your order does not meet the requirements for this coupon.'],
])

const FALLBACK_ERROR_MESSAGE = 'There was a problem applying coupon. Please contact support'

const activeCoupon = ref<Coupon>()
const referrer = ref<string>()
const defaultCoupon = ref<Coupon>()

const dollarDiscountCouponAttempt = ref<boolean>()

let loaded = defer<void>()

// For testing purposes
export const resetCoupons = (): void => {
  referrer.value = undefined
  activeCoupon.value = undefined
  defaultCoupon.value = undefined
  loaded = defer()
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCoupons = () => {
  const clearSavedCoupon = (): void => {
    useCookies().remove(COUPON_COOKIE_NAME)
  }

  const setReferrer = (couponReferrer: CouponReferrer): void => {
    referrer.value = couponReferrer.uuid

    const ONE_YEAR_SECONDS = 365 * 24 * 60 * 60

    useCookies().set(REFERRER_COOKIE_NAME, couponReferrer.uuid, ONE_YEAR_SECONDS)
  }

  const setCoupon = (coupon: Coupon | undefined): void => {
    if (!coupon) {
      return
    }

    activeCoupon.value = coupon
    useCookies().set(COUPON_COOKIE_NAME, coupon.couponCode, COUPON_MAX_AGE_SECONDS)

    if (coupon.referrer) {
      setReferrer(coupon.referrer)
    }
  }

  const getCoupon = async (
    couponCode: string,
    email?: string,
    mealPlanType?: MealPlanType,
    mealPlanPrice?: number,
  ): Promise<CouponResult> => {
    const api = useApi()

    const { data } = await api.post<CouponResponseData>('coupons/validate', {
      email,
      couponCode,
      mealPlanType,
      mealPlanPrice,
    })

    if (data.message === COUPON_VALID_RESPONSE_MESSAGE && !Array.isArray(data.data)) {
      const couponData = data.data

      return {
        success: true,
        coupon: couponData,
      }
    }

    if (data.message === COUPON_MINIMUM_SPEND_RESPONSE_MESSAGE) {
      dollarDiscountCouponAttempt.value = true
    }

    const errorMessage = errorMessages.get(data.message) ?? FALLBACK_ERROR_MESSAGE

    return {
      success: false,
      error: errorMessage,
    }
  }

  const verifyCoupon = async (
    couponCode: string,
    email?: string,
    mealPlanType?: MealPlanType,
    mealPlanPrice?: number,
  ): Promise<CouponResult> => {
    const result = await getCoupon(couponCode, email, mealPlanType, mealPlanPrice)

    if (result.success && result.coupon) {
      setCoupon(result.coupon)
    }

    return result
  }

  const defaultCouponCode = computed(() => {
    return DEFAULT_COUPON_CODE
  })

  const getDefaultCoupon = async (): Promise<Coupon | undefined> => {
    if (!defaultCoupon.value && defaultCouponCode.value) {
      const result = await getCoupon(defaultCouponCode.value)

      if (result.success) {
        defaultCoupon.value = result.coupon
      }
    }

    return defaultCoupon.value
  }

  const applyDefaultCoupon = async (): Promise<void> => {
    const coupon = await getDefaultCoupon()

    if (!coupon) {
      return
    }

    setCoupon(coupon)
  }

  const applyCouponCode = async (couponCode: string): Promise<Coupon | undefined> => {
    const { success, coupon } = await verifyCoupon(couponCode)

    if (success) {
      setCoupon(coupon)

      return coupon
    }

    return undefined
  }

  const getCouponFromCookie = async (): Promise<Coupon | undefined> => {
    const couponCode = useCookies().get(COUPON_COOKIE_NAME)

    if (couponCode) {
      const coupon = await applyCouponCode(couponCode)

      return coupon
    }

    return undefined
  }

  const fetchCouponFromURL = (): string | undefined => {
    return getURLParam(COUPON_QUERY_PARAM)
  }

  const getCouponFromURL = async (): Promise<Coupon | undefined> => {
    const couponCode = fetchCouponFromURL()

    if (couponCode) {
      const coupon = await applyCouponCode(couponCode)

      return coupon
    }

    return undefined
  }

  const loadActiveCoupon = async (): Promise<Coupon | undefined> => {
    if (activeCoupon.value) {
      return activeCoupon.value
    }

    const coupon = (await getCouponFromURL()) ?? (await getCouponFromCookie())

    return coupon
  }

  const loadReferrer = (): void => {
    const uuid = useCookies().get(REFERRER_COOKIE_NAME)

    if (uuid) {
      referrer.value = uuid
    }
  }

  const load = async (): Promise<void> => {
    if (loaded.resolving || loaded.resolved || loaded.rejected) {
      return loaded
    }

    loaded.start()

    // The default coupon might be influenced by experiments. Wait for experiments to be loaded before loading the default coupon
    await useExperiments().loaded

    await Promise.all([getDefaultCoupon(), loadActiveCoupon(), loadReferrer()])

    return loaded.resolve()
  }

  const removeActiveCoupon = (): void => {
    activeCoupon.value = undefined
    clearSavedCoupon()
  }

  const freeStarterCoupon = computed<boolean>(() => {
    return (
      !!activeCoupon.value &&
      activeCoupon.value.discount === 100 &&
      activeCoupon.value.discountType === 'meals' &&
      activeCoupon.value.couponType === 'freeBoxReferralCoupon'
    )
  })

  const socialGiftingCoupon = computed<boolean>(() => {
    return (
      !!activeCoupon.value &&
      activeCoupon.value.discountType === 'meals' &&
      activeCoupon.value.couponType === 'freeBoxReferralCoupon'
    )
  })

  return {
    applyDefaultCoupon,
    removeActiveCoupon,
    verifyCoupon,
    setCoupon,
    fetchCouponFromURL,
    load,
    referrer,
    activeCoupon,
    defaultCoupon,
    freeStarterCoupon,
    socialGiftingCoupon,
    dollarDiscountCouponAttempt,
  }
}
