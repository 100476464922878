<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import { clone } from 'remeda'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import { assetUrl } from '@lyka/utils'
import StepNavigation from '../StepNavigation.vue'
import MiniHr from '../common/MiniHr.vue'
import StepHeading from '../StepHeading.vue'
import OptionGroup from '@/components/common/OptionGroup.vue'
import OptionTile from '@/components/common/OptionTile.vue'
import StepsContainer from '@/components/StepContainer.vue'
import { useDataStore } from '@/stores/data'
import type { BodyShape } from '@/models/BodyShape'
import type { BodyShapeStepData } from '@/steps/bodyShape'
import { useDogsStore } from '@/stores/dogs'

const props = defineProps<{
  stepData: BodyShapeStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const data = reactive(clone(props.stepData))
const dataStore = useDataStore()
const dogsStore = useDogsStore()

const bodyShapes = computed(() => {
  return dataStore.bodyShapes
})

watch(
  () => data,
  (newData) => emits('save', newData),
  { deep: true },
)

const bodyShapeImageURL = ({ name }: BodyShape): string => {
  return assetUrl(`images/steps/body-shape/${name}.svg`)
}

const disabled = computed(() => !props.valid)

const submit = ({ target }: Event): void => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  emits('submit', data)
}
</script>

<template>
  <StepsContainer size="lg">
    <form id="formShape" v-submit @submit.prevent="submit">
      <div v-for="(dog, index) in data.dogs" :key="index">
        <MiniHr v-if="index > 0" />

        <StepHeading> What is {{ dogsStore.dogName(index) }}’s body shape? </StepHeading>

        <OptionGroup>
          <OptionTile
            v-for="bodyShape in bodyShapes"
            :key="bodyShape.id"
            v-model="dog.bodyShape"
            :title="bodyShape.customerText"
            :description="bodyShape.customerDescription"
            :input-value="bodyShape.id"
            input-type="radio"
          >
            <template #icon>
              <img :src="bodyShapeImageURL(bodyShape)" />
            </template>
          </OptionTile>
        </OptionGroup>
      </div>

      <StepNavigation submit :next-disabled="disabled" form="formShape" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
