export class DeferredPromise<T> extends Promise<T> {
  resolved = false
  rejected = false
  resolving = false
  declare resolve: (value: T | PromiseLike<T>) => void
  declare reject: (reason?: unknown) => void

  start(): void {
    this.resolving = true
  }
}

export const defer = <T>(): DeferredPromise<T> => {
  let _resolve: (value: T | PromiseLike<T>) => void
  let _reject: (reason?: unknown) => void

  const promise = new DeferredPromise<T>((resolve, reject) => {
    _resolve = resolve
    _reject = reject
  })

  promise.resolve = (value: T | PromiseLike<T>): void => {
    promise.resolved = true
    _resolve(value)
  }

  promise.reject = (reason?: any): void => {
    promise.rejected = true
    _reject(reason)
  }

  return promise
}
