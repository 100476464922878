import { type ComputedRef, computed, ref } from 'vue'

// https://tailwindcss.com/docs/screens
const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
}

export type Breakpoint = keyof typeof BREAKPOINTS

const width = ref<number>()
let bound = false

export const useBreakpoints = (): ComputedRef<Record<Breakpoint, boolean>> => {
  if (window && !bound) {
    bound = true
    window.addEventListener('resize', () => {
      width.value = window.innerWidth
    })
  }

  width.value ??= window ? window.innerWidth : 0

  return computed(() => {
    return Object.entries(BREAKPOINTS).reduce((values, [k, v]: [string, number]) => {
      return {
        ...values,
        [k]: v < (width.value ?? 0),
      }
    }, {}) as Record<Breakpoint, boolean>
  })
}
