import type { AllergenName } from '@/models/Allergy'
import type { IllnessName } from '@/models/Illness'
import { type Recipe, isRecipeSuitable } from '@/models/Recipe'
import { useDogsStore } from '@/stores/dogs'
import { useRecipesStore } from '@/stores/recipes'

const filterSuitableRecipes = (recipes: Recipe[], illnesses: IllnessName[], allergies: AllergenName[]): Recipe[] => {
  return recipes.filter((recipe) => {
    return isRecipeSuitable(recipe, { illnesses, allergies })
  })
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useRecommendedMeals = () => {
  const recipesStore = useRecipesStore()
  const dogsStore = useDogsStore()

  const suitableRecipesForDog = (dogIndex: number): Recipe[] => {
    const allergyNames = dogsStore.dogAllergies(dogIndex).map(({ name }) => name)
    const illnessNames = dogsStore.dogIllnesses(dogIndex).map(({ name }) => name)

    return filterSuitableRecipes(recipesStore.recipes, illnessNames, allergyNames)
  }

  const unsuitableRecipesForDog = (dogIndex: number): Recipe[] => {
    const suitableRecipeIds = suitableRecipesForDog(dogIndex).map(({ id }) => id)

    return recipesStore.recipes.filter(({ id }) => !suitableRecipeIds.includes(id))
  }

  const therapeuticRecipesForDog = (dogIndex: number): Recipe[] => {
    const allergyNames = dogsStore.dogAllergies(dogIndex).map(({ name }) => name)
    const illnessNames = dogsStore.dogIllnesses(dogIndex).map(({ name }) => name)

    return filterSuitableRecipes(recipesStore.therapeuticRecipes, illnessNames, allergyNames)
  }

  const unsuitableTherapeuticRecipesForDog = (dogIndex: number): Recipe[] => {
    const suitableIds = therapeuticRecipesForDog(dogIndex).map(({ id }) => id)

    return recipesStore.therapeuticRecipes.filter(({ id }) => !suitableIds.includes(id))
  }

  return {
    suitableRecipesForDog,
    unsuitableRecipesForDog,
    therapeuticRecipesForDog,
    unsuitableTherapeuticRecipesForDog,
  }
}
