<script setup lang="ts">
import { computed } from 'vue'
import { formatMoney } from '@/utils/formatMoney'

const props = defineProps<{
  totalPrice: number
  discountedTotalPrice: number
}>()

const discounted = computed(() => {
  return props.totalPrice !== props.discountedTotalPrice
})
</script>

<template>
  <div class="tw-flex tw-justify-between">
    <span class="tw-font-bold">Order Total</span>

    <div>
      <div class="tw-font-bold tw-flex tw-gap-2">
        <s v-if="discounted" class="tw-text-opacity-40 tw-text-dark">{{ formatMoney(totalPrice) }}</s>

        <span id="checkout-price-total">{{ formatMoney(discountedTotalPrice) }}</span>
      </div>
    </div>
  </div>
</template>
