<script setup lang="ts">
import { computed } from 'vue'
import { LykaError, LykaGrid, LykaInput, LykaSelect } from '@lyka/ui'
import { StateTerritory } from '@/models/Address'
import type { CheckoutStepAddressData } from '@/steps/checkout'

const props = defineProps<{
  address: CheckoutStepAddressData
  invalid?: boolean
}>()

const emits = defineEmits<{
  (e: 'change', address: (typeof props)['address']): void
}>()

const addressLine1 = computed({
  get() {
    return props.address.addressLine1
  },
  set(value: string) {
    emits('change', {
      ...props.address,
      addressLine1: value,
    })
  },
})

const postcode = computed({
  get() {
    return props.address.postcode
  },
  set(value: string) {
    emits('change', {
      ...props.address,
      postcode: value,
    })
  },
})

const suburb = computed({
  get() {
    return props.address.suburb
  },
  set(value: string) {
    emits('change', {
      ...props.address,
      suburb: value,
    })
  },
})

const state = computed({
  get() {
    return props.address.state
  },
  set(value: StateTerritory | null) {
    emits('change', {
      ...props.address,
      state: value,
    })
  },
})

const stateTerritoryOptions = computed(() => {
  return Object.values(StateTerritory).map((stateTerritory) => {
    return {
      value: stateTerritory,
      text: stateTerritory,
    }
  })
})
</script>

<template>
  <div class="tw-space-y-2 sm:tw-space-y-4 sm:tw-col-span-2">
    <LykaInput
      v-model="addressLine1"
      name="street-address"
      required
      placeholder="Enter your address"
      error-message="Your address is required"
      label="Street address"
    />

    <p class="tw-text-center md:tw-text-left">(Please note we do not deliver to P.O boxes)</p>

    <div class="tw-space-y-2 sm:tw-space-y-4 tw-mt-6">
      <LykaInput
        v-model="suburb"
        name="suburb"
        label="Suburb"
        placeholder="Lykaville"
        required
        error-message="Your suburb is required"
      />

      <LykaGrid>
        <LykaSelect
          v-model="state"
          name="state"
          label="State/Territory"
          required
          error-message="Your state/territory is required"
          :options="stateTerritoryOptions"
        />
        <LykaInput
          v-model="postcode"
          name="postcode"
          inputmode="numeric"
          label="Postcode"
          required
          minlength="4"
          maxlength="4"
          pattern="[0-9]{4}"
          placeholder="2154"
          error-message="Your postcode is required"
        />
      </LykaGrid>

      <LykaError v-if="invalid"> Currently we are not delivering to this postcode </LykaError>
      <slot />
    </div>
  </div>
</template>
