export const pluralize = (noun: string, number: number | undefined, altPlural?: string): string => {
  switch (number) {
    case 1: {
      return noun
    }
    default: {
      if (altPlural) {
        return altPlural
      }
      return `${noun}s`
    }
  }
}
