export enum MealPlanType {
  Full = 'full',
  Half = 'half',
  Starter = 'starter',
}

export interface MealPlan {
  type: MealPlanType
  frequency: number
  priceDaily: number
  priceWeekly: number
  totalPrice: number
  mealPrice: number
}

export const getMealPlanName = (type: MealPlanType): string => {
  switch (type) {
    case MealPlanType.Full:
      return 'Full bowl plan'
    case MealPlanType.Half:
      return 'Half bowl plan'
    case MealPlanType.Starter:
      return 'Starter box'
    default:
      return ''
  }
}

export const getMealPlanTypeById = (id: number): MealPlanType | undefined => {
  switch (id) {
    case 1: {
      return MealPlanType.Full
    }
    case 2: {
      return MealPlanType.Half
    }
    case 3: {
      return MealPlanType.Starter
    }
  }

  return undefined
}
