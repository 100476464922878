<script setup lang="ts">
import { computed, ref } from 'vue'
import { LykaButton } from '@lyka/ui'
import TreatsTreatCardQuantities from './TreatsTreatCardQuantities.vue'
import TreatsTreatCardModal from './TreatsTreatCardModal.vue'
import { getTreatImageURL, getTreatPrice, getTreatVideoURL } from '@/models/Treat'
import type { Treat } from '@/models/Treat'
import type { Product } from '@/models/Product'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { useProductPrice } from '@/composables/useProductPrice'
import { formatMoney } from '@/utils/formatMoney'
import { useCoupons } from '@/composables/useCoupons'

const props = defineProps<{
  treat: Treat
  treatQuantities: Record<number, number>
}>()

const emits = defineEmits<{
  (e: 'add', product: Product): void
  (e: 'remove', product: Product): void
}>()

const videoElement = ref<HTMLVideoElement>()
const videoPlaying = ref(false)
const open = ref(false)
const ready = ref(false)

const title = computed(() => {
  return props.treat.displayName
})

const description = computed(() => {
  return props.treat.treatDetails[0]?.treatBabAboutDescription ?? ''
})

const treatImageURL = computed(() => {
  return getTreatImageURL(props.treat)
})

const treatVideoURL = computed(() => {
  return getTreatVideoURL(props.treat)
})

const isOutOfStock = computed(() => {
  return props.treat.limitedStock
})

const playVideo = async (): Promise<void> => {
  try {
    await videoElement.value?.play()
  } catch {}
  ready.value = true
}

const stopVideo = (): void => {
  if (videoElement.value && ready.value) {
    videoElement.value.pause()
    videoElement.value.currentTime = 0
  }
}

const price = computed(() => {
  return getTreatPrice(props.treat)
})

const discounted = computed(() => {
  if (useCoupons().dollarDiscountCouponAttempt.value) {
    return false
  }

  return useProductPrice().isProductDiscounted('treats')
})

const discountedPrice = computed(() => {
  return useProductPrice().getDiscountedPrice('treats', price.value)
})

const onVideoPlay = (): void => {
  videoPlaying.value = true
}

const onVideoPause = (): void => {
  videoPlaying.value = false
}

const addTreat = (treatProduct: Product): void => {
  emits('add', treatProduct)
}

const removeTreat = (treatProduct: Product): void => {
  emits('remove', treatProduct)
}

const getProductQuantity = (productId: number): number => {
  return props.treatQuantities[productId] ?? 0
}

const openModal = (): void => {
  open.value = true

  useGTM().sendAction(GTMEventAction.VIEWED_TREAT_DETAILS, props.treat.displayName)
}

const closeModal = (): void => {
  open.value = false
}
</script>

<template>
  <div
    id="treat-card-container"
    class="tw-rounded-3xl tw-bg-cream tw-text-alt tw-w-full"
    @mouseenter="playVideo"
    @mouseleave="stopVideo"
  >
    <div class="tw-h-[342px] tw-rounded-3xl tw-overflow-clip tw-relative">
      <img
        v-if="treatImageURL"
        class="tw-object-cover tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0"
        :src="treatImageURL"
        :alt="`A picture of Lyka's ${title}`"
      />
      <video
        v-if="!isOutOfStock"
        ref="videoElement"
        class="tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0 tw-object-cover tw-opacity-0 hover:tw-opacity-100 tw-transition-opacity tw-duration-300"
        preload="metadata"
        :src="treatVideoURL"
        playsinline
        loop
        muted
        @play="onVideoPlay"
        @pause="onVideoPause"
      />
      <div v-if="isOutOfStock" class="tw-absolute tw-left-0 tw-right-0 tw-bottom-0 tw-top-0 tw-bg-[rgba(0,0,0,0.4)]">
        <div
          class="tw-absolute tw-left-4 tw-top-4 tw-rounded-3xl tw-bg-alt tw-text-white tw-uppercase tw-text-xs tw-font-semibold tw-px-4 tw-py-2"
        >
          Out of stock
        </div>
      </div>
      <div class="tw-absolute tw-right-4 tw-bottom-4">
        <LykaButton @click="openModal"> More details </LykaButton>
      </div>
    </div>
    <div class="tw-p-6 tw-space-y-3">
      <div class="tw-flex tw-items-center tw-justify-between">
        <h4 class="tw-font-stylized tw-text-5xl tw-leading-tighter">
          {{ title }}
        </h4>
        <span class="tw-whitespace-nowrap">
          <s v-if="discounted" class="tw-opacity-50">{{ formatMoney(price) }}</s>
          &nbsp;{{ formatMoney(discountedPrice) }}
        </span>
      </div>

      <p class="tw-font-light">
        {{ description }}
      </p>

      <hr class="tw-text-mint-green" />

      <TreatsTreatCardQuantities
        v-for="product of treat.products"
        :key="product.id"
        :quantity="getProductQuantity(product.id)"
        :product="product"
        :disabled="isOutOfStock"
        @add="addTreat"
        @remove="removeTreat"
      />
    </div>

    <TreatsTreatCardModal :treat="treat" :open="open" @close="closeModal" />
  </div>
</template>
