import { z } from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type DogStep, type ModifySchema, Step } from './Step'
import { useBreedStep } from './breed'
import { useWeightStep } from './weight'
import { registerStep } from './stepRegistry'
import { StepName } from '@/stores/steps'
import { AgeType } from '@/models/Dog'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { type DogData, useDogsStore } from '@/stores/dogs'
import { birthdayToAge } from '@/utils/birthdayConversion'

export enum BirthdayType {
  Date = 'Date',
  Age = 'Age',
}

const dateTypeSchema = z.object({
  type: z.literal(z.nativeEnum(BirthdayType).enum.Date),
  is: z.nativeEnum(AgeType),
  date: z.coerce.date(),
})

const ageTypeSchema = z.object({
  type: z.literal(z.nativeEnum(BirthdayType).enum.Age),
  is: z.nativeEnum(AgeType),
  years: z.coerce.number().int().min(0).max(30),
  months: z.coerce.number().int().min(0).max(11),
})

const Schema = z.object({
  dogs: z
    .array(
      z
        .object({
          age: z.discriminatedUnion('type', [dateTypeSchema, ageTypeSchema]),
        })
        .refine((data) => {
          if (data.age.type === BirthdayType.Date) {
            const today = new Date()
            const birthday = new Date(data.age.date)

            if (birthday > today) {
              return false
            }

            const { years } = birthdayToAge(data.age.date)
            return years < 31
          }

          return true
        }),
    )
    .min(1),
})

export type AgeStepData = ModifySchema<
  z.infer<typeof Schema>,
  {
    dogs: Array<{
      age: {
        is: AgeType | null
        years: number
        months: number
        date: string
        type: BirthdayType
      }
    }>
  }
> & {
  updated?: string
}

export const getAgeFromRelativeDate = (
  { years, months }: { years: number; months: number },
  date: Date,
): { years: number; months: number } => {
  const now = new Date()

  // Get the total number of months between the updated date and now (eg. 35 months)
  const durationInMonths = now.getMonth() + 1 - (date.getMonth() + 1) + (now.getFullYear() - date.getFullYear()) * 12

  // Get the current total age of the dog in months
  const currentAgeInMonths = (months ?? 0) + (years ?? 0) * 12

  // Get the new total age of the dog in months
  const newAgeInMonths = currentAgeInMonths + durationInMonths

  // Get the new years and months from the new total age
  const newYears = Math.floor(newAgeInMonths / 12)
  const newMonths = newAgeInMonths % 12

  return {
    years: newYears,
    months: newMonths,
  }
}

export const updateStaleDogAges = (data: Partial<AgeStepData>): Partial<AgeStepData> => {
  const copy = { ...data }

  if (copy.updated && copy.dogs?.length) {
    const then = new Date(copy.updated)

    copy.dogs = copy.dogs.map(({ age, ...dog }) => {
      const { years, months } = getAgeFromRelativeDate({ years: age.years, months: age.months }, then)

      return {
        ...dog,
        age: {
          ...age,
          years,
          months,
        },
      }
    })

    // Change the updated date to now
    copy.updated = new Date().toISOString()
  }

  return copy
}

const DEFAULT_VALUE = {
  is: null,
  years: 0,
  months: 0,
  date: '',
  type: BirthdayType.Date,
}

class AgeStep extends Step<AgeStepData> implements DogStep {
  name = StepName.Age
  schema = Schema
  dogsStore = useDogsStore()
  title = 'Age'

  constructor() {
    super()
    this.loadState()

    this.dogsStore.on('added', this.addDog.bind(this))
    this.dogsStore.on('removed', this.removeDog.bind(this))
    this.dogsStore.on('loaded', this.loadDog.bind(this))
  }

  addDog({ age = DEFAULT_VALUE }: DogData = {}): void {
    this.data.dogs.push({ age: { ...DEFAULT_VALUE, ...age } })
  }

  removeDog(index: number): void {
    this.data.dogs.splice(index, 1)
  }

  loadDog(index: number): void {
    this.data.dogs[index] ??= { age: { ...DEFAULT_VALUE } }
  }

  onComplete(): void {
    super.onComplete()

    this.data.dogs.forEach((dog) => {
      const roundedYears = dog.age.years + Math.round(dog.age.months / 12)

      useGTM().sendAction(GTMEventAction.PROVIDED_AGE, `${roundedYears} years`)
    })

    useSegment().track('Age Completed', {
      dogs: this.data.dogs.map(({ age }) => {
        return {
          ageStage: age.is,
          ageYears: age.years,
          ageMonths: age.months,
        }
      }),
    })
  }

  initialState(): AgeStepData {
    return {
      dogs: [],
    }
  }

  next(): Step {
    return useWeightStep()
  }

  prev(): Step {
    return useBreedStep()
  }

  getSavedState(): Partial<AgeStepData> {
    const state = super.getSavedState()

    // When the data is loaded from localStorage, update the ages of the dogs so that they are relative to current date
    // as opposed to the date they were last updated
    return updateStaleDogAges(state)
  }

  update(data: Partial<AgeStepData>): void {
    super.update({
      ...data,
      // When the age step data is updated, also update the `updated` property
      updated: new Date().toISOString(),
    })
  }
}

export const useAgeStep = registerStep(AgeStep)
