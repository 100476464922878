import { useSegment } from '@lyka/vue-common/composables/useSegment'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useJumpToStepGroupEvent = () => {
  const send = (group: string): void => {
    useSegment().track('Jump To Step Group', {
      group,
    })
  }

  return {
    send,
  }
}
