<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    padding?: boolean
    backgroundColor?: string
    rounded?: boolean
  }>(),
  {
    padding: true,
    backgroundColor: 'white',
    rounded: true,
  },
)

const backgroundClass = computed(() => `tw-bg-${props.backgroundColor}`)

const paddingClass = computed(() => {
  if (props.padding) {
    return 'tw-p-5'
  }

  return ''
})

const roundedClass = computed(() => {
  if (props.rounded) {
    return 'tw-rounded-2xl'
  }

  return ''
})
</script>

<template>
  <div :class="[paddingClass, backgroundClass, roundedClass]">
    <slot />
  </div>
</template>
