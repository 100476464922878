import { migrateAnimalAgeToBirthday } from './migrateAnimalAgeToBirthday'
import { migrateHasIllnessesStepData } from './migrateHasIllnessesStepData'
import { migratePreviouslyFedFromSingleToMultipleValues } from './migratePreviouslyFedFromSingleToMultipleValues'
import { removeInactiveBreeds } from './removeInactiveBreeds'
import { removeOldUnknownBreed } from './removeOldUnknownBreed'

export const runStateMigrations = (): void => {
  removeInactiveBreeds()
  migrateHasIllnessesStepData()
  migratePreviouslyFedFromSingleToMultipleValues()
  removeOldUnknownBreed()
  migrateAnimalAgeToBirthday()
}
