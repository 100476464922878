import { computed } from 'vue'
import { useCoupons } from './useCoupons'
import {
  type DiscountType,
  getCouponDiscountDescription,
  getDiscountedPrice as getDiscountedCouponPrice,
  isProductTypeDiscounted,
} from '@/models/Coupon'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useProductPrice = () => {
  const coupons = useCoupons()

  const coupon = computed(() => coupons.activeCoupon.value)

  const discountType = computed(() => {
    return coupon.value?.discountType
  })

  const discount = computed(() => {
    return coupon.value?.discount ?? 0
  })

  const isProductDiscounted = (productType: DiscountType): boolean => isProductTypeDiscounted(productType, coupon.value)

  const getDiscountAmount = (productType: 'all' | 'meals' | 'treats'): number => {
    if (isProductDiscounted(productType)) {
      return discount.value
    }

    return 0
  }

  const getDiscountedPrice = (productType: 'meals' | 'treats', price: number): number => {
    if (isProductDiscounted(productType)) {
      return getDiscountedCouponPrice(price, coupon.value)
    }

    return price
  }

  const getDiscountDescription = (productType: 'all' | 'meals' | 'treats'): string | undefined => {
    if (coupon.value && isProductDiscounted(productType)) {
      return getCouponDiscountDescription(coupon.value)
    }
  }

  return {
    discount,
    getDiscountedPrice,
    getDiscountAmount,
    getDiscountDescription,
    isProductDiscounted,
    discountType,
  }
}
