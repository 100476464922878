<script setup lang="ts">
import { LykaDateTimePicker, LykaInput } from '@lyka/ui'
import { computed, ref } from 'vue'
import type { DeliveryDate } from '@/models/DeliveryDate'
import { formatDate } from '@/utils/formatDate'
import { type DeliverySlot, DeliverySlotName } from '@/models/DeliverySlot'

const props = defineProps<{
  modelValue: string
  slot?: number | null
  dates: Array<DeliveryDate>
  slots?: Array<DeliverySlot>
  soonestDeliveryDate?: string
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', value: (typeof props)['modelValue']): void
  (e: 'update:slot', value: number): void
  (e: 'expanded', open: boolean): void
}>()

const expanded = ref(false)

const availableDates = computed(() => {
  return props.dates.map((date) => date.date)
})

const timeForSlot = (slotName: DeliverySlotName): string => {
  switch (slotName) {
    case DeliverySlotName.AM:
      return '00:00 - 07:00'
    case DeliverySlotName.PM:
      return '08:00 - 18:00'
  }
}

const displayTimeForSlot = (slotName: DeliverySlotName): string => {
  switch (slotName) {
    case DeliverySlotName.AM:
      return '12AM-7AM'
    case DeliverySlotName.PM:
      return '8AM-6PM'
  }
}

const availableTimes = computed(() => {
  return props.slots?.map((slot) => {
    return { text: timeForSlot(slot.name), value: slot.name }
  })
})

const currentSlot = computed(() => {
  return props.slots?.find((slot) => slot.cutoffId === props.slot)
})

const currentTime = computed((): DeliverySlotName | undefined => {
  return currentSlot.value?.name
})

const currentDisplayTime = computed(() => {
  if (currentTime.value) {
    return displayTimeForSlot(currentTime.value)
  }
  return ''
})

const onClick = (): void => {
  expanded.value = !expanded.value
  emits('expanded', expanded.value)
}

const updateDate = (date: string): void => {
  emits('update:modelValue', date)

  expanded.value = false
}

const updateTime = (time: string): void => {
  // get slot id from name
  const slot = props.slots?.find((slot) => slot.name === time)
  if (slot) {
    emits('update:slot', slot.cutoffId)
  }
}

const formattedDate = computed<string | undefined>(() => {
  if (props.modelValue) {
    return formatDate(props.modelValue, {
      month: 'short',
      weekday: 'short',
    } as const)
  }
  return undefined
})

const aboveInputLabel = computed<string | undefined>(() => {
  if (props.modelValue === props.soonestDeliveryDate) {
    return 'Next Available Delivery'
  }

  return undefined
})
</script>

<template>
  <div class="tw-space-y-2">
    <LykaInput
      label="Delivery date"
      size="xl"
      :model-value="formattedDate"
      :above-input="aboveInputLabel"
      readonly
      :placeholder="formattedDate ?? 'Enter an address'"
      name="delivery-date"
      @click.stop="onClick"
    >
      <template #afterInput>
        <span class="tw-font-semibold tw-whitespace-nowrap tw-pr-4">{{ currentDisplayTime }}</span>
      </template>
      <template #suffix>
        <div>
          <slot name="suffix"></slot>
        </div>
      </template>
    </LykaInput>
    <LykaDateTimePicker
      v-if="expanded"
      :current-time="currentTime"
      :date="props.modelValue"
      :time-options="availableTimes"
      :dates="availableDates"
      @update="updateDate"
      @update:time="updateTime"
    >
      <template #title>Edit delivery date</template>
    </LykaDateTimePicker>
  </div>
</template>
