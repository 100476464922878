const EMOJI_ENCODING = [
  '[\u2700-\u27BF]',
  '[\uE000-\uF8FF]',
  '[\u2011-\u26FF]',
  '[\u2580-\u27BF]',
  '\uD83C[\uDC00-\uDFFF]',
  '\uD83D[\uDC00-\uDFFF]',
  '\uD83E[\uDD10-\uDDFF]',
]

export const stripEmojis = (str: string): string => {
  return str.replace(new RegExp(EMOJI_ENCODING.join('|'), 'g'), '')
}
