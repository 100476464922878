import { z } from 'zod'
import { Step } from './Step'
import { registerStep } from './stepRegistry'
import { useLocationStep } from './location'
import { StepName } from '@/stores/steps'

const Schema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  marketingCommunication: z.boolean(),
})

export type UnservicedLocationStepData = z.infer<typeof Schema>

class UnservicedLocationStep extends Step<UnservicedLocationStepData> {
  name = StepName.UnservicedLocation
  schema = Schema
  title = 'Your Location'
  showSteps = false

  initialState(): UnservicedLocationStepData {
    return {
      firstName: '',
      lastName: '',
      email: '',
      marketingCommunication: true,
    }
  }

  loadState(): void {}
  saveState(): void {}

  prev(): Step {
    return useLocationStep()
  }

  next(): undefined {
    return undefined
  }
}

export const useUnservicedLocationStep = registerStep(UnservicedLocationStep)
