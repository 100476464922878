<script setup lang="ts">
import { computed } from 'vue'
interface Props {
  cols?: number
  smCols?: number
  mdCols?: number
  lgCols?: number
  yGap?: number
  smYGap?: number
  mdYGap?: number
  lgYGap?: number
  xGap?: number
  smXGap?: number
  mdXGap?: number
  lgXGap?: number
}

const props = withDefaults(
  defineProps<{
    cols?: number
    smCols?: number
    mdCols?: number
    lgCols?: number
    yGap?: number
    smYGap?: number
    mdYGap?: number
    lgYGap?: number
    xGap?: number
    smXGap?: number
    mdXGap?: number
    lgXGap?: number
  }>(),
  {
    cols: 2,
    yGap: 8,
    xGap: 8,
  },
)
const sizes = ['sm', 'md', 'lg']
const columnClassName = computed(() =>
  [
    `tw-grid-cols-${props.cols}`,
    ...sizes
      .filter((size) => props[`${size}Cols` as keyof Props])
      .map((size) => `${size}:tw-grid-cols-${props[`${size}Cols` as keyof Props]}`),
  ].join(' '),
)
const yGapClassName = computed(() =>
  [
    `tw-gap-y-${props.yGap}`,
    ...sizes
      .filter((size) => props[`${size}YGap` as keyof Props])
      .map((size) => `${size}:tw-gap-y-${props[`${size}YGap` as keyof Props]}`),
  ].join(' '),
)
const xGapClassName = computed(() =>
  [
    `tw-gap-x-${props.xGap}`,
    ...sizes
      .filter((size) => props[`${size}XGap` as keyof Props])
      .map((size) => `${size}:tw-gap-x-${props[`${size}XGap` as keyof Props]}`),
  ].join(' '),
)
</script>

<template>
  <div class="tw-grid" :class="[columnClassName, yGapClassName, xGapClassName]">
    <slot />
  </div>
</template>
