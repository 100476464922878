<script setup lang="ts">
import { onMounted, ref } from 'vue'
import Confirmation from './CheckoutCompletedConfirmation.vue'
import FreeTreatSelection from './CheckoutFreeTreatSelection.vue'
import TheHeader from '@/components/TheHeader.vue'
import { type OrderInfo, usePostCheckout } from '@/composables/usePostCheckout'

const isConfirmationDismissed = ref(false)

const dismissConfirmation = (): void => {
  isConfirmationDismissed.value = true
}

const order = ref<OrderInfo>()

const { getOrderInfo } = usePostCheckout()

onMounted(async () => {
  order.value = await getOrderInfo()
})
</script>

<template>
  <div class="tw-overflow-auto tw-min-h-screen tw-bg-offwhite tw-relative">
    <TheHeader />

    <div class="tw-max-w-md tw-mx-auto">
      <Confirmation
        v-if="!isConfirmationDismissed"
        :email="order?.email"
        :delivery-date="order?.deliveryDate"
        :delivery-time="order?.deliveryTime"
        :animal-names="order?.animalNames"
        @dismiss="dismissConfirmation"
      />

      <FreeTreatSelection v-else :animal-names="order?.animalNames" @back="isConfirmationDismissed = false" />
    </div>
  </div>
</template>
