// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStringSearch = (values: any[], searchKey = 'text') => {
  const filter = (query: string): any[] => {
    return values.filter((option) => {
      return !query || option[searchKey].toLowerCase().includes(query.toLowerCase())
    })
  }

  const sort = (values: any[], query: string): any[] => {
    return (
      values
        // sort options by shorter result
        .sort((a, b) => (a[searchKey].length < b[searchKey].length ? -1 : 1))
        // sort options by earlier search occurence
        .sort((a, b) =>
          a[searchKey].toLowerCase().indexOf(query) < b[searchKey].toLowerCase().indexOf(query) ? -1 : 1,
        )
    )
  }

  const search = (query: string): any[] => {
    return sort(filter(query), query)
  }

  return { search }
}
