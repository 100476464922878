<script lang="ts" setup>
import { LykaButton, LykaRadio, LykaRadioGroup } from '@lyka/ui'
import { computed, onMounted, ref } from 'vue'
import NavigationWrap from '@/components/common/NavigationWrap.vue'
import RightArrowIcon from '@/components/icons/RightArrowIcon.vue'
import LeftArrowIcon from '@/components/icons/LeftArrowIcon.vue'
import { formatMoney } from '@/utils/formatMoney'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import HelpTooltip from '@/components/common/HelpTooltip.vue'
import { useProductPrice } from '@/composables/useProductPrice'

const props = withDefaults(
  defineProps<{
    submit?: boolean
    previousHidden?: boolean
    nextHidden?: boolean
    nextLoading?: boolean
    nextDisabled?: boolean
    previousLoading?: boolean
    previousDisabled?: boolean
    totalTreatsPrice: number
    everyBox: boolean
    form?: string
  }>(),
  {},
)

const emits = defineEmits<{
  (e: 'previous'): void
  (e: 'next'): void
  (e: 'update:everyBox', value: (typeof props)['everyBox']): void
  (e: 'removeTreats'): void
}>()

const everyBoxValue = computed({
  get() {
    return props.everyBox
  },
  set(value) {
    const eventLabel = value ? 'Every box' : 'One off'
    useGTM().sendAction(GTMEventAction.CHANGED_TREATS_PURCHASE_MODE, eventLabel)

    emits('update:everyBox', value)
  },
})

const hasTreats = computed(() => {
  return props.totalTreatsPrice > 0
})

const discountedTreatsPrice = computed(() => {
  return useProductPrice().getDiscountedPrice('treats', props.totalTreatsPrice)
})

const nextButtonLabel = computed(() => {
  if (hasTreats.value) {
    return `Add to box - ${formatMoney(discountedTreatsPrice.value)}`
  }

  return 'Skip'
})

const previous = (): void => {
  useGTM().sendAction(GTMEventAction.CLICKED_BACK)

  emits('previous')
}

const stepNav = ref<HTMLElement>()
const mobileVersion = ref(false)
const DESKTOP_MIN_BREAK_POINT = 950

const resizeHeader = (): void => {
  const width = stepNav.value?.clientWidth ?? 0

  mobileVersion.value = width < DESKTOP_MIN_BREAK_POINT
}

onMounted(() => {
  resizeHeader()
  window.addEventListener('resize', resizeHeader)
})
</script>

<template>
  <Teleport to="#stepNav">
    <div ref="stepNav">
      <NavigationWrap>
        <div class="tw-w-full tw-max-w-7xl tw-mx-auto">
          <div
            class="tw-flex tw-flex-col sm:tw-flex-row tw-gap-x-4"
            :class="hasTreats ? 'tw-justify-between' : 'tw-items-center tw-justify-center sm:tw-space-x-4'"
          >
            <div class="tw-flex tw-gap-x-4">
              <Teleport to="#backBtn" :disabled="!mobileVersion">
                <LykaButton
                  :outline="!mobileVersion"
                  :disabled="previousDisabled"
                  :square="mobileVersion || hasTreats"
                  sr-only="previous button"
                  :auto-width="hasTreats"
                  :variant="mobileVersion ? 'alt-dark' : 'alt'"
                  type="button"
                  :form="form"
                  :loading="previousLoading"
                  @click="previous"
                >
                  <template #iconLeft>
                    <LeftArrowIcon :class="{ 'tw--mr-2': mobileVersion || hasTreats }" />
                  </template>
                  <template v-if="!mobileVersion && !hasTreats"> Previous </template>
                </LykaButton>
              </Teleport>
              <LykaButton
                v-if="hasTreats"
                id="remove-treats-button"
                outline
                type="button"
                variant="alt"
                hide-at="md"
                @click="emits('removeTreats')"
              >
                Remove <span class="tw-hidden lg:tw-inline-block">selection</span>
              </LykaButton>
            </div>

            <div class="tw-w-full sm:tw-w-auto tw-flex tw-flex-col tw-items-center sm:tw-flex-row tw-gap-4">
              <LykaRadioGroup v-if="hasTreats">
                <LykaRadio v-model="everyBoxValue" :input-value="true" type="button" name="every-box" rounded="left">
                  Add to every box

                  <HelpTooltip>
                    <h5>Add to every box</h5>
                    <p>
                      Treats will be added as a subscription to your regular Lyka delivery. You can change from your
                      account at anytime.
                    </p>

                    <h5>One-off addition</h5>
                    <p>
                      Treats will be added to your first delivery only. You can order more or subscribe from your
                      account at anytime.
                    </p>
                  </HelpTooltip>
                </LykaRadio>
                <LykaRadio
                  v-model="everyBoxValue"
                  :input-value="false"
                  type="button"
                  name="every-box"
                  label="One-off addition"
                  rounded="right"
                />
              </LykaRadioGroup>

              <LykaButton
                v-if="!nextHidden"
                :disabled="nextDisabled"
                :form="form"
                :auto-width="false"
                block-at="sm"
                :type="submit ? 'submit' : 'button'"
                :loading="nextLoading"
                @click="emits('next')"
              >
                {{ nextButtonLabel }}
                <template #iconRight>
                  <RightArrowIcon />
                </template>
              </LykaButton>
            </div>
          </div>
        </div>
      </NavigationWrap>
    </div>
  </Teleport>
</template>
