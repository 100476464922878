<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import { clone } from 'remeda'
import { useFormValidator } from '@lyka/vue-common/composables/useFormValidator'
import StepNavigation from '../StepNavigation.vue'
import WeightDog from './weight/WeightDog.vue'
import StepsContainer from '@/components/StepContainer.vue'
import type { WeightStepData } from '@/steps/weight'

const props = defineProps<{
  stepData: WeightStepData
  valid: boolean
}>()

const emits = defineEmits<{
  (e: 'submit', data: typeof props.stepData): void
  (e: 'save', data: typeof props.stepData): void
  (e: 'previous'): void
}>()

const data = reactive(clone(props.stepData))

watch(
  () => data,
  (newData) => emits('save', newData),
  { deep: true },
)

const disabled = computed(() => !props.valid)

const submit = ({ target }: Event): void => {
  const valid = useFormValidator(target).validate()

  if (!valid || disabled.value) {
    return
  }

  emits('submit', data)
}

const updateDogWeight = (dogIndex: number, weight: number): void => {
  if (data.dogs[dogIndex]) {
    data.dogs[dogIndex]!.weight = weight
  }
}
</script>

<template>
  <StepsContainer>
    <form id="formWeight" v-submit @submit.prevent="submit">
      <WeightDog
        v-for="(dog, index) in data.dogs"
        :key="index"
        :weight="dog.weight"
        :index="index"
        @update="(weight) => updateDogWeight(index, weight)"
      />

      <StepNavigation submit :next-disabled="disabled" form="formWeight" @previous="emits('previous')" />
    </form>
  </StepsContainer>
</template>
