import { z } from 'zod'
import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { type ModifySchema, Step } from './Step'
import { useActivityStep } from './activity'
import { registerStep } from './stepRegistry'
import { usePreviouslyFedStep } from './previouslyFed'
import { StepName } from '@/stores/steps'
import { GTMEventAction, useGTM } from '@/composables/useGTM'
import { useDataStore } from '@/stores/data'
import { useDogsStore } from '@/stores/dogs'

const Schema = z.object({
  dogs: z
    .array(
      z.object({
        fussiness: z.number().int(),
      }),
    )
    .min(1),
})

export type FussinessStepData = ModifySchema<
  z.infer<typeof Schema>,
  {
    dogs: {
      fussiness: number | null
    }[]
  }
>

const DEFAULT_VALUE = null

class FussinessStep extends Step<FussinessStepData> {
  name = StepName.Fussiness
  schema = Schema
  dogsStore = useDogsStore()
  title = 'Fussiness'

  constructor() {
    super()
    this.loadState()

    this.dogsStore.on('added', ({ fussiness = DEFAULT_VALUE }) => {
      this.data.dogs.push({ fussiness })
    })

    this.dogsStore.on('removed', (index) => {
      this.data.dogs.splice(index, 1)
    })

    this.dogsStore.on('loaded', (index) => {
      this.data.dogs[index] ??= { fussiness: DEFAULT_VALUE }
    })
  }

  onComplete(): void {
    super.onComplete()

    const dataStore = useDataStore()

    this.data.dogs.forEach((dog) => {
      const fussiness = dog.fussiness ? dataStore.findRecord('fussinessLevels', dog.fussiness)?.name : undefined

      if (fussiness) {
        useGTM().sendAction(GTMEventAction.PROVIDED_FUSSINESS, fussiness)
      }
    })

    useSegment().track('Fussiness Completed', {
      dogs: this.data.dogs.map(({ fussiness }) => {
        const fussinessLevelName = fussiness ? dataStore.findRecord('fussinessLevels', fussiness)?.name : undefined

        return {
          fussiness: fussinessLevelName ?? null,
        }
      }),
    })
  }

  initialState(): FussinessStepData {
    return {
      dogs: [],
    }
  }

  prev(): Step {
    return useActivityStep()
  }

  next(): Step {
    return usePreviouslyFedStep()
  }
}

export const useFussinessStep = registerStep(FussinessStep)
