import { watchEffect } from 'vue'
import { useStepsStore } from '../stores/steps'
import { useJumpToStepGroupEvent } from './events/segment/useJumpToStepGroupEvent'
import { StepGroup, StepGroupName } from '@/steps/StepGroup'
import { useBodyShapeStep } from '@/steps/bodyShape'
import type { Step } from '@/steps/Step'
import type { StepInstantiator } from '@/steps/stepRegistry'
import { useLoadingPlanStep } from '@/steps/loadingPlan'
import { useNameStep } from '@/steps/name'

const createGroup = (
  name: StepGroupName,
  label: string,
  firstStep: StepInstantiator,
  previous?: StepGroup,
): StepGroup => {
  const next = new StepGroup(name, label, firstStep)

  if (previous) {
    next.previous = previous
    previous.next = next
  }

  return next
}

// Create the groups
const details = createGroup(StepGroupName.DogDetails, 'Dog details', useNameStep)
const health = createGroup(StepGroupName.DogHealth, 'Dog health', useBodyShapeStep, details)
const plan = createGroup(StepGroupName.PlanDetails, 'Plan details', useLoadingPlanStep, health)

const groups = [details, health, plan]

// Link the groups
details.next = health
health.previous = details
health.next = plan
plan.previous = health

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStepGroups = () => {
  const stepsStore = useStepsStore()

  watchEffect(() => {
    const currentStep = stepsStore.currentStep as unknown as Step
    groups.forEach((group) => group.update(currentStep))
  })

  const goToGroup = (groupName: StepGroupName): void => {
    const step = groups.find(({ name }) => name === groupName)?.firstStep()

    if (step) {
      stepsStore.goToStep(step.name)
      useJumpToStepGroupEvent().send(groupName)
    }
  }

  return {
    groups,
    goToGroup,
  }
}
