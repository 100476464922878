import { useSegment } from '@lyka/vue-common/composables/useSegment'
import { unwrapData, useApi } from './useApi'

export const useCheckoutStartedRequest = (): { send(): Promise<string | undefined> } => {
  const send = async (): Promise<string | undefined> => {
    const anonymousUserId = await useSegment().getAnonymousId()

    if (anonymousUserId) {
      const { data } = await useApi().post<{ eventId?: string }>(
        'checkout/checkoutStarted',
        {
          anonymousUserId,
        },
        unwrapData,
      )

      return data?.eventId
    }
  }

  return {
    send,
  }
}
