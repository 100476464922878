import { useSegment } from '@lyka/vue-common/composables/useSegment'
import type { EventSender } from '../EventSender'
import type { Recipe } from '@/models/Recipe'

export const useRecipeDetailsViewedEvent: EventSender = (recipe: Recipe) => {
  const send = (): Promise<void> => {
    return useSegment().track('Recipe Details Viewed', {
      recipe: recipe.name,
    })
  }

  return {
    send,
  }
}
